// src/auth/components/AuthErrorMessage.jsx
import React from 'react';
import { Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AuthErrorMessage = ({ message, severity = 'error', onClose }) => {
  return (
    <Alert
      severity={severity}
      sx={{
        alignItems: 'center',
        backgroundColor: severity === 'error' ? '#FEF2F2' : '#F3F4F6',
        color: severity === 'error' ? '#991B1B' : '#1F2937',
        '& .MuiAlert-icon': {
          color: severity === 'error' ? '#991B1B' : '#1F2937',
        },
        marginBottom: 2,
      }}
      action={
        onClose && (
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )
      }
    >
      {message}
    </Alert>
  );
};

export default AuthErrorMessage;