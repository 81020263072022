import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  Card,
  CardContent,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Stack,
  Tabs,
  Tab,
  Divider,
  useTheme,
  useMediaQuery,
  Chip
} from '@mui/material';
import {
  Error as ErrorIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  ArrowForward as ArrowForwardIcon,
  Info as InfoIcon,
  School as SchoolIcon,
  Timeline as TimelineIcon,
  Assessment as AssessmentIcon,
  TrendingUp as TrendingUpIcon,
  BarChart as BarChartIcon,
  QuestionAnswer as QuestionAnswerIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

// Custom TabPanel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`methodology-tabpanel-${index}`}
      aria-labelledby={`methodology-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// Props for accessibility
function a11yProps(index) {
  return {
    id: `methodology-tab-${index}`,
    'aria-controls': `methodology-tabpanel-${index}`,
  };
}

const MethodologyDocs = () => {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabSections = [
    { 
      label: 'Purpose', 
      icon: <SchoolIcon />, 
      content: (
        <Stack spacing={3}>
          <Typography variant="h5" color="primary.main" gutterBottom>
            Purpose of Enrollment Planning Tools
          </Typography>
          
          <Typography>
            To support forward-looking enrollment planning, the Education Data Collective School
            Explorer application creates projected enrollment scenarios using historical data.
          </Typography>
          
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>Key Questions We Answer:</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card variant="outlined" sx={{ height: '100%', borderLeft: '4px solid', borderColor: 'primary.light' }}>
                  <CardContent>
                    <Typography variant="h6" color="primary.main">What?</Typography>
                    <Typography variant="body2">
                      Geographic Explorer shows what's happening around your campus
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card variant="outlined" sx={{ height: '100%', borderLeft: '4px solid', borderColor: 'primary.main' }}>
                  <CardContent>
                    <Typography variant="h6" color="primary.main">So What?</Typography>
                    <Typography variant="body2">
                      Competitive Explorer reveals enrollment trends at nearby schools
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card variant="outlined" sx={{ height: '100%', borderLeft: '4px solid', borderColor: 'primary.dark' }}>
                  <CardContent>
                    <Typography variant="h6" color="primary.main">Now What?</Typography>
                    <Typography variant="body2">
                      Projections Explorer helps predict your school's future enrollment
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      ) 
    },
    { 
      label: 'Methodology', 
      icon: <AssessmentIcon />, 
      content: (
        <Stack spacing={3}>
          <Typography variant="h5" color="primary.main" gutterBottom>
            Methodology Summary
          </Typography>
          
          <Paper elevation={0} sx={{ p: 3, bgcolor: 'grey.50', borderRadius: 2 }}>
            <Stack spacing={2}>
              <Typography>
                The tool uses historical data from NCES and users (most recent school year) that balance
                longer-term history with most recent trends and creates three scenarios: high, midpoint, and
                low to allow users to see a range of potential outcomes.
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
                <Chip label="Historical Analysis" color="primary" size="small" />
                <Chip label="NCES Data Integration" color="primary" size="small" />
                <Chip label="Multiple Scenarios" color="primary" size="small" />
              </Box>
              <Divider sx={{ my: 1 }} />
              <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                Note: These projections should be treated as a starting point for internal conversations
                about near-term and long-term enrollment targets.
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      ) 
    },
    { 
      label: 'Process', 
      icon: <TimelineIcon />, 
      content: (
        <Stack spacing={3}>
          <Typography variant="h5" color="primary.main" gutterBottom>
            Four-Step Projection Process
          </Typography>
          
          <Box>
            <Grid container spacing={3}>
              {[
                {
                  step: 1,
                  title: "Estimate Entry Grade",
                  description: "Calculate enrollment for 1, 3, and 5 year periods using historical data",
                  color: theme.palette.primary.light
                },
                {
                  step: 2,
                  title: "Calculate Survival Rates",
                  description: "Determine 1-year, 3-year, and 5-year historical survival rates",
                  color: theme.palette.primary.main
                },
                {
                  step: 3,
                  title: "Establish Scenarios",
                  description: "Find min, median, and max rates at each grade level",
                  color: theme.palette.primary.dark
                },
                {
                  step: 4,
                  title: "Generate Projections",
                  description: "Create 5-year projections at grade and school level",
                  color: theme.palette.secondary.main
                }
              ].map((item) => (
                <Grid item xs={12} sm={6} md={3} key={item.step}>
                  <Card sx={{ 
                    height: '100%',
                    position: 'relative',
                    overflow: 'visible',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
                  }}>
                    <Box sx={{ 
                      position: 'absolute',
                      top: -16,
                      left: 16,
                      width: 32, 
                      height: 32, 
                      borderRadius: '50%', 
                      bgcolor: item.color,
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                    }}>
                      {item.step}
                    </Box>
                    <CardContent sx={{ pt: 3 }}>
                      <Typography variant="h6" gutterBottom>{item.title}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      ) 
    },
    { 
      label: 'Confidence', 
      icon: <BarChartIcon />, 
      content: (
        <Stack spacing={3}>
          <Typography variant="h5" color="primary.main" gutterBottom>
            Projection Confidence Levels
          </Typography>
          
          <Typography>
            All schools receive enrollment projections, with confidence indicators that reflect the amount 
            of historical data available for analysis.
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Paper 
                elevation={2} 
                sx={{ 
                  p: 3, 
                  height: '100%', 
                  borderTop: '4px solid',
                  borderColor: 'error.main',
                  borderRadius: 2
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                  <ErrorIcon color="error" />
                  <Typography variant="h6">Low Confidence</Typography>
                </Stack>
                <Typography variant="body2">
                  Fewer than 3 years of enrollment history available. Interpret projections with 
                  additional caution.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper 
                elevation={2} 
                sx={{ 
                  p: 3, 
                  height: '100%', 
                  borderTop: '4px solid',
                  borderColor: 'warning.main',
                  borderRadius: 2
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                  <WarningIcon color="warning" />
                  <Typography variant="h6">Moderate Confidence</Typography>
                </Stack>
                <Typography variant="body2">
                  3+ years of enrollment history. Enough data to identify patterns while acknowledging 
                  limitations.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper 
                elevation={2} 
                sx={{ 
                  p: 3, 
                  height: '100%', 
                  borderTop: '4px solid',
                  borderColor: 'success.main',
                  borderRadius: 2
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                  <CheckCircleIcon color="success" />
                  <Typography variant="h6">High Confidence</Typography>
                </Stack>
                <Typography variant="body2">
                  5+ years of enrollment history. Most robust data for trend analysis and projections.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Stack>
      ) 
    },
    { 
      label: 'Survival Rates', 
      icon: <TrendingUpIcon />, 
      content: (
        <Stack spacing={3}>
          <Typography variant="h5" color="primary.main" gutterBottom>
            Cohort Survival Rate Overview
          </Typography>
          
          <Paper elevation={0} sx={{ p: 3, bgcolor: 'grey.50', borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>What is Cohort Survival Rate?</Typography>
            <Typography paragraph>
              Cohort survival rate measures the ability for a school to retain and backfill students as they
              matriculate from one grade to the next.
            </Typography>
            <Card sx={{ mt: 2, borderLeft: '4px solid', borderColor: 'primary.main' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Example Calculation:</Typography>
                <Stack spacing={1}>
                  <Typography>2022-23 Kinder: 100 students</Typography>
                  <Typography>2023-24 1st Grade: 102 students</Typography>
                  <Typography color="primary" fontWeight="medium">
                    Cohort matriculation rate: 102% (102 / 100)
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Paper>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', boxShadow: '0 4px 20px rgba(0,0,0,0.08)' }}>
                <CardContent>
                  <Typography variant="h6" color="primary.main" gutterBottom>What it Measures</Typography>
                  <Typography color="text.secondary" paragraph>
                    Looks at the overall set of students from grade-to-grade, not specific students. 
                    Individual student tracking is handled by attrition and re-enrollment rates.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', boxShadow: '0 4px 20px rgba(0,0,0,0.08)' }}>
                <CardContent>
                  <Typography variant="h6" color="primary.main" gutterBottom>Interpretation</Typography>
                  <Typography color="text.secondary">
                    Rates of 100% indicate steady enrollment, while rates below 100% show fewer 
                    students moving through the school.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Stack>
      ) 
    },
    { 
      label: 'Entry Grades', 
      icon: <QuestionAnswerIcon />, 
      content: (
        <Stack spacing={3}>
          <Typography variant="h5" color="primary.main" gutterBottom>
            Entry Grade Estimation
          </Typography>
          
          <Paper elevation={0} sx={{ p: 3, bgcolor: 'grey.50', borderRadius: 2 }}>
            <Typography paragraph>
              Entry grades are defined as the initial grade for the school ID. For schools with PreK,
              kindergarten is used due to the variability of PreK data.
            </Typography>
          </Paper>

          <Card sx={{ boxShadow: '0 4px 20px rgba(0,0,0,0.08)' }}>
            <CardContent>
              <Typography variant="h6" color="primary.main" gutterBottom>Calculation Process</Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <ArrowForwardIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Calculate averages for entry grade enrollment over:"
                    secondary="1 year, 3 year, and 5 year periods"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <ArrowForwardIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Set projections using these periods:"
                    secondary="Low estimate, Midpoint estimate, High estimate"
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>

          <Alert 
            severity="warning" 
            variant="outlined"
            icon={<WarningIcon />}
            sx={{ borderRadius: 2 }}
          >
            <Typography variant="h6" gutterBottom>Edge Cases</Typography>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <InfoIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Missing year data is excluded from average calculations" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <InfoIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Period without data sets average to 0" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <InfoIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Schools without defined entry grade are skipped" />
              </ListItem>
            </List>
          </Alert>
        </Stack>
      ) 
    },
    { 
      label: 'Projections', 
      icon: <TimelineIcon />, 
      content: (
        <Stack spacing={3}>
          <Typography variant="h5" color="primary.main" gutterBottom>
            Generate Projections
          </Typography>
          
          <Typography>
            Calculate enrollment projections for each school over a specified number of future years.
            Below we outline the steps for the median enrollment scenario.
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card sx={{ 
                height: '100%', 
                borderRadius: 2,
                boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                position: 'relative',
                overflow: 'visible'
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: -10,
                  left: 16,
                  bgcolor: 'primary.main',
                  color: 'white',
                  py: 0.5,
                  px: 2,
                  borderRadius: 1,
                  fontSize: 14,
                  fontWeight: 'medium'
                }}>
                  Step 1
                </Box>
                <CardContent sx={{ pt: 4 }}>
                  <Typography variant="h6" color="primary.main" gutterBottom>Starting Point</Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Use most recent year's enrollment data" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Apply grade-level survival rates" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Project enrollments for future years" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ 
                height: '100%', 
                borderRadius: 2,
                boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                position: 'relative',
                overflow: 'visible'
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: -10,
                  left: 16,
                  bgcolor: 'primary.main',
                  color: 'white',
                  py: 0.5,
                  px: 2,
                  borderRadius: 1,
                  fontSize: 14,
                  fontWeight: 'medium'
                }}>
                  Step 2
                </Box>
                <CardContent sx={{ pt: 4 }}>
                  <Typography variant="h6" color="primary.main" gutterBottom>Entry Grade Handling</Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Use scenario's entry grade estimate" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Maintain constant estimate for projections" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Apply specific entry grade calculations" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ 
                height: '100%', 
                borderRadius: 2,
                boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                position: 'relative',
                overflow: 'visible'
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: -10,
                  left: 16,
                  bgcolor: 'primary.main',
                  color: 'white',
                  py: 0.5,
                  px: 2,
                  borderRadius: 1,
                  fontSize: 14,
                  fontWeight: 'medium'
                }}>
                  Step 3
                </Box>
                <CardContent sx={{ pt: 4 }}>
                  <Typography variant="h6" color="primary.main" gutterBottom>Other Grades</Typography>
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Apply cohort survival rate to previous grade" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Use actual enrollment for first projection" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Round all projections to whole numbers" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Paper sx={{ p: 3, bgcolor: 'grey.50', borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>Edge Cases & Special Considerations</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="subtitle1" color="primary.main" gutterBottom>Data Handling</Typography>
                    <List dense>
                      <ListItem>
                        <ListItemText primary="Uses all grades from most recent year" />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Skips schools with no enrollment data" />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Default 100% survival rate if unavailable" />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="subtitle1" color="primary.main" gutterBottom>Important Notes</Typography>
                    <List dense>
                      <ListItem>
                        <ListItemText primary="Projections change year over year" />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Survival rates applied cumulatively" />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Rates based on historical data only" />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Stack>
      ) 
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header with back link */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center', 
        mb: 4 
      }}>
        <Link to="/resources" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Button 
            startIcon={<ArrowBackIcon />}
            sx={{ color: 'text.secondary' }}
          >
            Back to Resources
          </Button>
        </Link>
      </Box>
      
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 4, 
          borderRadius: 2,
          background: 'linear-gradient(45deg, #f3f4f6 30%, #ffffff 90%)',
          borderLeft: '4px solid',
          borderColor: 'primary.main'
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom>
          Projections Scenarios Documentation
        </Typography>
        <Typography color="text.secondary">
          A comprehensive guide to understanding how enrollment projections are calculated and interpreted
        </Typography>
      </Paper>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          variant={isMobile ? "scrollable" : "fullWidth"}
          scrollButtons={isMobile ? "auto" : false}
          textColor="primary"
          indicatorColor="primary"
          aria-label="methodology documentation tabs"
        >
          {tabSections.map((section, index) => (
            <Tab 
              key={index}
              label={section.label} 
              icon={section.icon}
              iconPosition="start"
              {...a11yProps(index)}
              sx={{ 
                minHeight: 72,
                fontWeight: tabValue === index ? 'bold' : 'normal',
              }}
            />
          ))}
        </Tabs>
      </Box>

      {tabSections.map((section, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          {section.content}
        </TabPanel>
      ))}

      {/* Support Contact Section */}
      <Paper 
        elevation={1}
        sx={{ 
          mt: 6, 
          p: 3,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          justifyContent: 'space-between',
          gap: 2,
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
          background: 'linear-gradient(45deg, #f5f7fa 0%, #ffffff 100%)',
        }}
      >
        <Box>
          <Typography variant="h6" color="primary.dark" gutterBottom={isMobile}>
            Need Additional Help?
          </Typography>
          <Typography color="text.secondary">
            If you have specific questions, reach out to support@educationdatacollective
          </Typography>
        </Box>
        <Button 
          variant="contained"
          size="large"
          onClick={() => window.location.href = 'mailto:support@educationdatacollective'}
          sx={{ 
            borderRadius: 2,
            px: 3,
            py: 1,
            boxShadow: '0 4px 14px rgba(0,0,0,0.12)',
          }}
        >
          Contact Support
        </Button>
      </Paper>
    </Container>
  );
};

export default MethodologyDocs;