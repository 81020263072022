// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize core Firebase services immediately
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);  
const db = getFirestore(app);
const functions = getFunctions(app);

// Set persistence
setPersistence(auth, browserLocalPersistence).catch(console.error);

// Analytics with non-blocking initialization
let analytics = {
  logEvent: (...args) => {} // Empty function instead of console.log
};

const initializeAnalytics = async () => {
  try {
    const analyticsSupported = await isSupported();
    if (analyticsSupported) {
      analytics = getAnalytics(app);
    } else {
      analytics = {
        logEvent: (...args) => {} // Empty function for fallback
      };
    }
  } catch (error) {
    analytics = {
      logEvent: (...args) => {} // Empty function for error case
    };
  }
};

initializeAnalytics().catch(console.error);

export const isAnalyticsInitialized = () => analytics !== null;
export { app, auth, analytics, db, functions };