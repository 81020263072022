import React, { useState, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const DistrictMapOverlay = ({ position, children }) => {
  const map = useMap();
  const [point, setPoint] = useState({ x: 0, y: 0 });
  const [displayBelow, setDisplayBelow] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!map || !position || !containerRef.current) return;

    const updatePosition = () => {
      const latLng = L.latLng(position[0], position[1]);
      const point = map.latLngToContainerPoint(latLng);
      
      // Get popup dimensions
      const popupHeight = containerRef.current.offsetHeight;
      
      // Check if popup would go off the top of the screen
      const wouldGoOffTop = point.y - popupHeight - 40 < 0; // 40px buffer
      
      setDisplayBelow(wouldGoOffTop);
      setPoint(point);
    };

    updatePosition();

    map.on('zoom', updatePosition);
    map.on('move', updatePosition);
    map.on('moveend', updatePosition);

    return () => {
      map.off('zoom', updatePosition);
      map.off('move', updatePosition);
      map.off('moveend', updatePosition);
    };
  }, [map, position]);

  if (!position) return null;

  return (
    <div 
      ref={containerRef}
      style={{
        position: 'absolute',
        left: point.x,
        top: point.y,
        transform: displayBelow ? 
          'translate(-50%, 20px)' : 
          'translate(-50%, -120%)',
        pointerEvents: 'auto',
        zIndex: 900
      }}
    >
      {children}
    </div>
  );
};

export default DistrictMapOverlay;