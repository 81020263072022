// Utility function to convert table data to CSV format
export const convertToCSV = (schools, columns) => {
  if (!schools || schools.length === 0) return '';
  
  // Create header row with column labels, including group context
  const headerRow = columns.map(column => {
    let headerLabel = column.label;
    
    // Add group context to the column headers
    switch (column.id) {
      case 'enrollment':
      case 'marketShare':
        headerLabel = `2024 ${headerLabel}`;
        break;
      case 'populationTrend':
        headerLabel = `Area Student Population Trend (2020-2024)`;
        break;
      case 'enrollmentTrend':
        headerLabel = `Enrollment Trend (2020-2024)`;
        break;
      case 'marketShareTrend':
        headerLabel = `Market Share Trend (2020-2024)`;
        break;
      case 'populationProjection':
        headerLabel = `Area Population Projection (2024-2029)`;
        break;
      case 'enrollmentProjection':
        headerLabel = `School Enrollment Projection (2024-2029)`;
        break;
      default:
        // Keep the original label for other columns
        break;
    }
    
    return `"${headerLabel}"`;
  });
  
  // Add NCESSCH ID column
  headerRow.unshift('"School ID (NCESSCH)"');
  
  // Create data rows
  const dataRows = schools.map(school => {
    const rowData = columns.map(column => {
      // For columns with custom render functions, we need to extract the raw value
      let cellValue;
      
      switch (column.id) {
        case 'name':
          cellValue = school.name || '';
          break;
        case 'enrollment':
          cellValue = school.enrollment?.current || '';
          break;
        case 'marketShare':
          cellValue = school.market_share?.current 
            ? Math.round(school.market_share.current) + '%'
            : '';
          break;
        case 'populationTrend':
          cellValue = school.population?.trend
            ? Math.round(school.population.trend) + '%'
            : '';
          break;
        case 'enrollmentTrend':
          if (school.is_newer) {
            cellValue = 'New School';
          } else {
            cellValue = school.enrollment?.trend
              ? Math.round(school.enrollment.trend) + '%'
              : '';
          }
          break;
        case 'marketShareTrend':
          if (school.is_newer) {
            cellValue = 'New School';
          } else {
            cellValue = school.market_share?.trend
              ? Math.round(school.market_share.trend) + '%'
              : '';
          }
          break;
        case 'populationProjection':
          cellValue = school.population?.projection_trend
            ? Math.round(school.population.projection_trend) + '%'
            : '';
          break;
        case 'enrollmentProjection':
          cellValue = school.enrollment?.projection_trend
            ? Math.round(school.enrollment.projection_trend) + '%'
            : '';
          break;
        default:
          cellValue = '';
      }
      
      // Escape quotes in cell values and wrap in quotes
      return `"${String(cellValue).replace(/"/g, '""')}"`;
    });
    
    // Add NCESSCH ID as the first column
    rowData.unshift(`"${school.ncessch || ''}"`);
    
    return rowData.join(',');
  });
  
  // Combine header and data rows
  return [headerRow.join(','), ...dataRows].join('\n');
};

// Function to trigger CSV download
export const downloadCSV = (schools, columns, filename = 'district-analysis.csv') => {
  const csvContent = convertToCSV(schools, columns);
  
  // Create a Blob containing the CSV data
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
  // Create a download link and trigger the download
  const link = document.createElement('a');
  
  // Create object URL for the Blob
  const url = URL.createObjectURL(blob);
  
  link.href = url;
  link.setAttribute('download', filename);
  link.style.display = 'none';
  
  // Add link to the document, click it, and remove it
  document.body.appendChild(link);
  link.click();
  
  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};