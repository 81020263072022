// src/components/SessionWarningDialog.tsx
import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography 
} from '@mui/material';
import { useAuth } from '../AuthContext';
import { tokenManager } from '../TokenManager';

export function SessionWarningDialog() {
  const [open, setOpen] = useState(false);
  const { user, logout } = useAuth();

  useEffect(() => {
    if (!user) {
      setOpen(false);  // Close dialog when user becomes null
      return;
    }
  
    const checkSession = () => {
      if (tokenManager.isSessionExpiringSoon()) {
        setOpen(true);
      }
    };
  
    const unsubscribe = tokenManager.addListener(() => {
      checkSession();
    });
  
    // Initial check
    checkSession();
  
    const interval = setInterval(checkSession, 60000);
    
    return () => {
      clearInterval(interval);
      unsubscribe();
    };
  }, [user]);
  

  const handleExtend = async () => {
    try {
      await tokenManager.getToken(true);
      setOpen(false);
    } catch (error) {
      console.error('Error extending session:', error);
      if (error.code === 'auth/user-token-expired') {
        await logout();
      }
    }
  };

  const handleLogout = async () => {
    try {
      setOpen(false);  // Close dialog before logout
      await logout();
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  
  return (
    <Dialog 
      open={open}
      onClose={() => {}} 
    >
      <DialogTitle>Session Expiring Soon</DialogTitle>
      <DialogContent>
        <Typography>
          Your session will expire soon. Would you like to extend it?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLogout} color="error">
          Logout
        </Button>
        <Button onClick={handleExtend} color="primary" autoFocus>
          Extend Session
        </Button>
      </DialogActions>
    </Dialog>
  );
}