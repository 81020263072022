import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { 
  Container, 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../../auth/AuthContext';
import { getAuth } from 'firebase/auth';
import customLogger from '../../utils/customLogger';
import mailchimpService from '../../services/mailChimpService';


const MIN_PASSWORD_LENGTH = 8;

const LoadingState = ({ message }) => (
  <Container maxWidth="sm" sx={{ mt: 8, textAlign: 'center' }}>
    <CircularProgress />
    <Typography sx={{ mt: 2 }}>{message}</Typography>
  </Container>
);

export default function PasswordSetup() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const auth = getAuth();
  const state = searchParams.get('state');

  // All state declarations at the top
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [firebaseReady, setFirebaseReady] = useState(false);
  const [verificationStarted, setVerificationStarted] = useState(false);

  // Helper functions
  const getErrorMessage = (error) => {
    switch (error?.code) {
      case 'auth/invalid-action-code':
        return 'This setup link is invalid. If the page doesn\'t redirect automatically in a few seconds, please contact your administrator for assistance.';
      case 'auth/expired-action-code':
        return 'This setup link has expired. If the page doesn\'t redirect automatically in a few seconds, please contact your administrator for assistance.';
      default:
        return error.message || 'An error occurred. Please try again or contact support if the problem persists.';
    }
  };

  const validatePasswords = (currentPassword, currentConfirmPassword) => {
    let isValid = true;
    let newPasswordError = '';
    let newConfirmPasswordError = '';
    
    if (currentPassword.length < MIN_PASSWORD_LENGTH) {
      newPasswordError = `Password must be at least ${MIN_PASSWORD_LENGTH} characters (${currentPassword.length} currently)`;
      isValid = false;
    }
    
    if (currentConfirmPassword) {
      if (currentPassword !== currentConfirmPassword) {
        newConfirmPasswordError = 'Passwords do not match';
        isValid = false;
      }
    }
    
    setPasswordError(newPasswordError);
    setConfirmPasswordError(newConfirmPasswordError);
    setFormValid(isValid);
    
    return isValid;
  };

  // Firebase initialization effect
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      setFirebaseReady(true);
    });
    return () => unsubscribe();
  }, [auth]);

  // Replace both existing useEffect blocks for verification with this single one
  useEffect(() => {
    if (!firebaseReady || verificationStarted || !state) {
      if (!state) {
        setError('Missing state parameter');
        setLoading(false);
      }
      return;
    }
    
    const verifyCode = async () => {
      setVerificationStarted(true);
      
      try {
        // Verify state with backend only - we're not using Firebase oobCode anymore
        const verifyResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/verify-reset-state`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ state })
        });
  
        if (!verifyResponse.ok) {
          const data = await verifyResponse.json();
          throw new Error(data.error || 'Failed to verify setup state');
        }
        
        // Get email from backend response
        const responseData = await verifyResponse.json();
        if (responseData.email) {
          setEmail(responseData.email);
          customLogger(`Email retrieved from state token: ${responseData.email}`, 'info');
        } else {
          throw new Error('Email not found in verification response');
        }
      } catch (err) {
        console.error('Verification error:', err);
        setError(getErrorMessage(err));
  
        // Track failed attempt if we have a user
        if (user?.uid) {
          try {
            await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/track-password-attempt/${user.uid}`, {
              method: 'POST'
            });
          } catch (trackError) {
            console.error('Error tracking password attempt:', trackError);
          }
        }
      } finally {
        setLoading(false);
      }
    };
  
    verifyCode();
  }, [firebaseReady, state, user, auth, verificationStarted]);
  
  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validatePasswords(password, confirmPassword)) {
      return;
    }
  
    setLoading(true);
    setError('');
  
    try {
      // Use the new direct endpoint to set password
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/mailchimp/set-password-direct`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ state, password })
      });
      
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to set password');
      }
      
      // Get response data
      const data = await response.json();
      
      // Track password creation if needed
      try {
        await mailchimpService.trackPasswordCreated(email || data.email);
      } catch (error) {
        console.warn('Failed to track password creation, but continuing process');
      }
      
      setSuccess(true);
      customLogger('Password set successfully', 'info');
      
      // Navigate to login directly
      navigate('/login', { 
        state: { 
          message: 'Password set successfully. Please log in with your new password.' 
        }
      });
    } catch (err) {
      console.error('Password reset error:', err);
      setError(err.message || 'Failed to set password');
      setLoading(false);
    }
  };

  // Render loading states
  if (!firebaseReady) {
    return <LoadingState message="Initializing secure connection..." />;
  }

  if (loading && !verificationStarted) {
    return <LoadingState message="Preparing password setup..." />;
  }

  if (loading && verificationStarted) {
    return <LoadingState message="Verifying your reset link..." />;
  }

  // Render error state
  if (error) {
    return (
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Paper sx={{ p: 4 }}>
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            This can happen if:
            <ul>
              <li>You've already used this link to set up your password</li>
              <li>The link has expired (links expire after 24 hours)</li>
              <li>The link was modified or is invalid</li>
            </ul>
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              component={Link}
              to="/login"
              sx={{ 
                mt: 2,
                backgroundColor: '#4F46E5',
                '&:hover': {
                  backgroundColor: '#4338CA',
                },
              }}
            >
              Return to Login
            </Button>
          </Box>
        </Paper>
      </Container>
    );
  }

  // Render main form
  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h5" gutterBottom>
          Set Your Password
        </Typography>
        
        {success ? (
          <Alert severity="success">
            Password set successfully! Redirecting to dashboard...
          </Alert>
        ) : (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Email"
              value={email}
              disabled
              sx={{ mb: 3 }}
            />
            
            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label="New Password"
              value={password}
              onChange={(e) => {
                const newPassword = e.target.value;
                setPassword(newPassword);
                validatePasswords(newPassword, confirmPassword);
              }}
              required
              error={Boolean(passwordError)}
              helperText={passwordError}
              sx={{ mb: 3 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
  
            <TextField
              fullWidth
              type={showConfirmPassword ? 'text' : 'password'}
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => {
                const newConfirmPassword = e.target.value;
                setConfirmPassword(newConfirmPassword);
                validatePasswords(password, newConfirmPassword);
              }}
              required
              error={Boolean(confirmPasswordError)}
              helperText={confirmPasswordError}
              sx={{ mb: 3 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
  
            <Typography 
              variant="caption" 
              color="text.secondary"
              sx={{ mt: -2, mb: 2, display: 'block' }}
            >
              Password must be at least {MIN_PASSWORD_LENGTH} characters long
            </Typography>
  
            <Button
              fullWidth
              type="submit"
              variant="contained"
              disabled={loading || !formValid || !password || !confirmPassword}
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Set Password'}
            </Button>
          </Box>
        )}
      </Paper>
    </Container>
  );
};