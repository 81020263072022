import React from 'react';
import { TableRow, TableCell, alpha } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getDistrictColor } from '../../../utils/districtColorUtils';

const DistrictTableColorCodedRow = ({ row, columns, selectedHeadline }) => {
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(`/school-summary?school=${row.ncessch}`);
  };

  const color = getDistrictColor(row, selectedHeadline);
  const backgroundColor = alpha(color, 0.1);

  return (
    <TableRow
      hover
      onClick={handleRowClick}
      sx={{
        cursor: 'pointer',
        backgroundColor,
        '&:hover': {
          backgroundColor: alpha(color, 0.15),
        }
      }}
    >
      {columns.map((column, colIndex) => (
        <TableCell 
          key={column.id}
          align={column.align || 'center'}
          sx={{
            p: 1,
            fontSize: '0.875rem',
            borderBottom: '1px solid',
            borderBottomColor: 'divider',
            '&:not(:last-child)': {
              borderRight: '1px solid',
              borderRightColor: 'divider',
            },
            ...(colIndex === 0 && {
              position: 'sticky',
              left: 0,
              bgcolor: backgroundColor || 'inherit',
              zIndex: 2,
            })
          }}
        >
          {column.render(row)}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default DistrictTableColorCodedRow;