// components/shared_components/accordionContainer.js
import React from 'react';
import { Box } from '@mui/material';

const AccordionContainer = ({ 
  expandedSections,
  onSectionChange,
  children 
}) => {
  return (
    <Box>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) return child;
        
        return React.cloneElement(child, {
          expanded: expandedSections[child.props.id],
          onChange: onSectionChange(child.props.id),
          disableExpansion: child.props.disabled, // Rename to avoid MUI's built-in disabled behavior
          disabled: false, // Don't use MUI's disabled prop
        });
      })}
    </Box>
  );
};

export default AccordionContainer;