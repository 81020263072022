import React, { useState, useMemo } from 'react';
import { 
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  TableSortLabel,
} from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  TrendingFlat as TrendingFlatIcon,
  School as SchoolIcon,
  LocationOn as LocationOnIcon,
  Close as CloseIcon,
  GetApp as DownloadIcon,
  TableChart as TableChartIcon
} from '@mui/icons-material';
import DistrictTableColorCodedRow from './DistrictTableColorCodedRow';
import { downloadCSV } from '../../../utils/csvDownload';

const TrendIndicator = ({ trend, value }) => {
  const getIcon = () => {
    switch (trend) {
      case 'up':
        return <TrendingUpIcon sx={{ color: 'success.main' }} />;
      case 'down':
        return <TrendingDownIcon sx={{ color: 'error.main' }} />;
      default:
        return <TrendingFlatIcon sx={{ color: 'action.disabled' }} />;
    }
  };

  const getColor = () => {
    switch (trend) {
      case 'up':
        return 'success.main';
      case 'down':
        return 'error.main';
      default:
        return 'text.secondary';
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 1,
      justifyContent: 'center',
      width: '100%'
    }}>
      {getIcon()}
      <Typography sx={{ color: getColor(), fontSize: '0.875rem' }}>
        {value > 0 ? '+' : ''}{value}%
      </Typography>
    </Box>
  );
};

const DistrictAnalysisTableExpanded = ({
  open,
  onClose,
  schools = [],
  selectedHeadline,
  onSchoolSelect,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  // Define column groups
  const columnGroups = [
    {
      title: '',  // Empty for school name
      columns: ['name']
    },
    {
      title: '2024',
      columns: ['enrollment', 'marketShare']
    },
    {
      title: 'Historical Trends (since 2020)',
      columns: ['populationTrend', 'enrollmentTrend', 'marketShareTrend']
    },
    {
      title: 'Forward-Looking (through 2029)',
      columns: ['populationProjection', 'enrollmentProjection']
    }
  ];

  // Column definitions
  const columns = useMemo(() => [
    {
      id: 'name',
      label: 'School name',
      sortable: true,
      align: 'left',
      render: (row) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {row.charter === 'Yes' ? (
            <SchoolIcon sx={{ color: 'primary.main', fontSize: 16 }} />
          ) : (
            <LocationOnIcon sx={{ color: 'primary.main', fontSize: 16 }} />
          )}
          <Typography sx={{ fontSize: '0.875rem' }}>{row.name}</Typography>
        </Box>
      )
    },
    {
      id: 'enrollment',
      label: 'Enrollment',
      sortable: true,
      align: 'center',
      render: (row) => {
        if (row.enrollment && typeof row.enrollment.current === 'number') {
          return <Typography sx={{ fontSize: '0.875rem' }}>
            {row.enrollment.current.toLocaleString()}
          </Typography>;
        }
        
        return <Typography>N/A</Typography>;
      }
    },
    {
      id: 'marketShare',
      label: 'Market Share',
      group: '2024',
      sortable: true,
      align: 'center',
      render: (row) => {
        if (row.market_share && typeof row.market_share.current === 'number') {
          return <Typography sx={{ fontSize: '0.875rem' }}>
            {Math.round(row.market_share.current)}%
          </Typography>;
        }
        
        return <Typography>N/A</Typography>;
      }
    },
    {
      id: 'populationTrend',
      label: 'Area Student Population',
      group: 'Historical Trends (since 2020)',
      sortable: true,
      align: 'center',
      render: (row) => {
        if (row.population?.status && typeof row.population.trend === 'number') {
          const trendValue = row.population.trend;
          
          let trendDirection;
          if (row.population.status === 'growing') {
            trendDirection = 'up';
          } else if (row.population.status === 'declining') {
            trendDirection = 'down';
          } else {
            trendDirection = 'neutral';
          }
          
          return <TrendIndicator 
            trend={trendDirection}
            value={Math.round(trendValue)} 
          />;
        }
        
        return <Typography>N/A</Typography>;
      }
    },
    {
      id: 'enrollmentTrend',
      label: 'Enrollment',
      group: 'Historical Trends (since 2020)',
      sortable: true,
      align: 'center',
      render: (row) => {
        if (row.is_newer) {
          return <Typography sx={{ 
            fontSize: '0.875rem',
            color: 'text.secondary',
            fontStyle: 'italic'
          }}>
            New School
          </Typography>;
        }
    
        if (row.enrollment && typeof row.enrollment.trend === 'number') {
          const trendValue = row.enrollment.trend;
          
          let trendDirection;
          if (row.enrollment.status === 'growing') {
            trendDirection = 'up';
          } else if (row.enrollment.status === 'declining') {
            trendDirection = 'down';
          } else {
            trendDirection = 'neutral';
          }
          
          return <TrendIndicator 
            trend={trendDirection}
            value={Math.round(trendValue)} 
          />;
        }
        
        return <Typography>N/A</Typography>;
      }
    },
    {
      id: 'marketShareTrend',
      label: 'Market Share',
      group: 'Historical Trends (since 2020)',
      sortable: true,
      align: 'center',
      render: (row) => {
        if (row.is_newer) {
          return <Typography sx={{ 
            fontSize: '0.875rem',
            color: 'text.secondary',
            fontStyle: 'italic'
          }}>
            New School
          </Typography>;
        }
    
        if (row.market_share && typeof row.market_share.trend === 'number') {
          const trendValue = row.market_share.trend;
          let trendDirection;
          if (row.market_share.status === 'gaining') {
            trendDirection = 'up';
          } else if (row.market_share.status === 'losing') {
            trendDirection = 'down';
          } else {
            trendDirection = 'neutral';
          }
          
          return <TrendIndicator 
            trend={trendDirection}
            value={Math.round(trendValue)} 
          />;
        }
        
        return <Typography>N/A</Typography>;
      }
    },
    {
      id: 'populationProjection',
      label: 'Area Population',
      group: 'Forward-Looking',
      sortable: true,
      align: 'center',
      render: (row) => {
        if (row.population?.projection_status && typeof row.population.projection_trend === 'number') {
          const trendValue = row.population.projection_trend;
          
          let trendDirection;
          if (row.population.projection_status === 'growing') {
            trendDirection = 'up';
          } else if (row.population.projection_status === 'declining') {
            trendDirection = 'down';
          } else {
            trendDirection = 'neutral';
          }
          
          return <TrendIndicator 
            trend={trendDirection}
            value={Math.round(trendValue)} 
          />;
        }
        
        return <Typography>N/A</Typography>;
      }
    },
    {
      id: 'enrollmentProjection',
      label: 'School Enrollment',
      group: 'Forward-looking',
      sortable: true,
      align: 'center',
      render: (row) => {
        if (row.enrollment?.projection_status && typeof row.enrollment.projection_trend === 'number') {
          const trendValue = row.enrollment.projection_trend;
          
          let trendDirection;
          if (row.enrollment.projection_status === 'growing') {
            trendDirection = 'up';
          } else if (row.enrollment.projection_status === 'declining') {
            trendDirection = 'down';
          } else {
            trendDirection = 'neutral';
          }
          
          return <TrendIndicator 
            trend={trendDirection}
            value={Math.round(trendValue)} 
          />;
        }
        
        return <Typography>N/A</Typography>;
      }
    }
  ], []);

  const sortedSchools = useMemo(() => {
    if (!schools || schools.length === 0) return [];
    
    return [...schools].sort((a, b) => {
      let aValue, bValue;
      
      switch (orderBy) {
        case 'name':
          aValue = a.name || '';
          bValue = b.name || '';
          return order === 'asc' 
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
            
        case 'enrollment':
          aValue = a.enrollment?.current || 0;
          bValue = b.enrollment?.current || 0;
          break;
          
        case 'marketShare':
          aValue = a.market_share?.current || 0;
          bValue = b.market_share?.current || 0;
          break;
          
        case 'populationTrend':
          aValue = a.population?.trend || 0;
          bValue = b.population?.trend || 0;
          break;
          
        case 'enrollmentTrend':
          if (a.is_newer && !b.is_newer) return order === 'asc' ? 1 : 1;
          if (!a.is_newer && b.is_newer) return order === 'asc' ? -1 : -1;
          if (a.is_newer && b.is_newer) return 0;
          
          aValue = a.enrollment?.trend || 0;
          bValue = b.enrollment?.trend || 0;
          break;
          
        case 'marketShareTrend':
          if (a.is_newer && !b.is_newer) return order === 'asc' ? 1 : 1;
          if (!a.is_newer && b.is_newer) return order === 'asc' ? -1 : -1;
          if (a.is_newer && b.is_newer) return 0;
          
          aValue = a.market_share?.trend || 0;
          bValue = b.market_share?.trend || 0;
          break;
          
        case 'populationProjection':
          aValue = a.population?.projection_trend || 0;
          bValue = b.population?.projection_trend || 0;
          break;
          
        case 'enrollmentProjection':
          aValue = a.enrollment?.projection_trend || 0;
          bValue = b.enrollment?.projection_trend || 0;
          break;
          
        default:
          return 0;
      }
      
      if (aValue === null || aValue === undefined) aValue = 0;
      if (bValue === null || bValue === undefined) bValue = 0;
      
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    });
  }, [schools, order, orderBy]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleDownloadCSV = () => {
    const filename = `district-analysis-${new Date().toISOString().slice(0, 10)}.csv`;
    downloadCSV(sortedSchools, columns, filename);
  };

  // Common header cell styles
  const headerCellStyles = {
    bgcolor: 'primary.main', 
    color: 'white',
    fontWeight: 700,
    fontSize: '0.875rem',
    whiteSpace: 'nowrap'
  };

  // Common sort label styles
  const sortLabelStyles = {
    '&.MuiTableSortLabel-root': { 
      color: 'white',
      fontWeight: 700
    },
    '&.MuiTableSortLabel-root:hover': { color: 'white' },
    '&.MuiTableSortLabel-root.Mui-active': { color: 'white' },
    '& .MuiTableSortLabel-icon': { color: 'white !important' }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Title and Actions */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TableChartIcon sx={{ color: 'primary.main' }} />
              <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.125rem' }}>
                School Details
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleDownloadCSV} sx={{ mr: 1 }}>
                <DownloadIcon />
              </IconButton>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          
          {/* Subtitle */}
          <Typography 
            variant="body2" 
            sx={{ 
              bgcolor: 'rgba(25, 118, 210, 0.1)',
              color: 'primary.main',
              p: 1,
              borderRadius: 1,
              fontWeight: 500,
            }}
          >
            Detailed information for {sortedSchools.length} schools
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TableContainer sx={{ maxHeight: '70vh' }}>
          <Table stickyHeader size="small">
            <TableHead>
              {/* Column Group Headers */}
              <TableRow>
                {columnGroups.map((group, index) => (
                  <TableCell
                    key={group.title || index}
                    colSpan={group.columns.length}
                    align="center"
                    sx={{
                      ...headerCellStyles,
                      p: 1,
                      borderBottom: 0,
                      '&:not(:last-child)': {
                        borderRight: '1px solid',
                        borderRightColor: 'primary.light',
                      },
                      ...(index === 0 && {
                        bgcolor: 'transparent',
                        borderRight: 'none'
                      })
                    }}
                  >
                    {group.title}
                  </TableCell>
                ))}
              </TableRow>
              {/* Column Headers */}
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    sortDirection={orderBy === column.id ? order : false}
                    align={column.align || 'center'}
                    sx={{
                      bgcolor: 'primary.main',
                      fontWeight: 600,
                      fontSize: '0.75rem',
                      py: 0.5,
                      px: 1,
                      lineHeight: 1.2,
                      color: 'white',
                      '&:not(:last-child)': {
                        borderRight: '1px solid',
                        borderRightColor: 'primary.light',
                      },
                      ...(index === 0 && {
                        position: 'sticky',
                        left: 0,
                        bgcolor: 'primary.main',
                        zIndex: 3,
                        align: 'left'
                      })
                    }}
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={() => handleRequestSort(column.id)}
                        sx={sortLabelStyles}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedSchools.map((row) => (
                <DistrictTableColorCodedRow
                  key={row.ncessch}
                  row={row}
                  columns={columns}
                  selectedHeadline={selectedHeadline}
                  onSchoolSelect={onSchoolSelect}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default DistrictAnalysisTableExpanded;