import React, { useMemo } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { MapsHomeWork, Launch } from '@mui/icons-material';
import { formatGradeList } from '../../../utils/schoolUtils';
import { canAccessExplorers } from '../../../utils/explorerAccess';

const PopulationAnalysis = React.memo(({ 
  selectedSchool,
  populationTotals,
  currentVsPastChange,
  selectedDriveTime,
  selectedGrades,
  user
}) => {
  // Move calculations into useMemo before any conditional returns
  const { trendInfo, gradeRangeText } = useMemo(() => {
    if (!selectedSchool || !populationTotals) {
      return {
        trendInfo: { text: '', color: 'text.primary' },
        gradeRangeText: ''
      };
    }

    const getTrendInfo = () => {
      if (Math.abs(currentVsPastChange) < 5) {
        return {
          text: 'remained steady',
          color: 'text.primary'
        };
      }
      return currentVsPastChange > 0 
        ? { text: 'grown', color: 'success.main' }
        : { text: 'declined', color: 'error.main' };
    };

    return {
      trendInfo: getTrendInfo(),
      gradeRangeText: selectedGrades.length > 0 
        ? formatGradeList(selectedGrades)
        : 'all grades'
    };
  }, [selectedSchool, populationTotals, currentVsPastChange, selectedGrades]);

  if (!selectedSchool || !populationTotals) {
    return null;
  }

  const explorerUrl = `/geographic-explorer?school=${selectedSchool.ncessch}`;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper 
        elevation={0}
        sx={{
          mb: 2,
          py: 2,
          px: 3,
          backgroundColor: 'grey.50',
          color: 'text.primary',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: 6,
            backgroundColor: 'primary.main',
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8
          }
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="h8" sx={{ fontWeight: 200 }}>
            The school-age population for {gradeRangeText} within a {selectedDriveTime}-minute drive of <strong>{selectedSchool.name}</strong> has{' '}
            <strong style={{ color: trendInfo.color }}>{trendInfo.text}</strong>, 
            from <strong>{Math.round(populationTotals.past).toLocaleString()}</strong> children in 2020 
            to <strong>{Math.round(populationTotals.current).toLocaleString()}</strong> children today
            {Math.abs(currentVsPastChange) >= 5 && 
              ` (${currentVsPastChange > 0 ? '+' : ''}${Math.round(currentVsPastChange)}%)`}.
          </Typography>
        </Box>
        {canAccessExplorers(user?.role) && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<MapsHomeWork />}
            endIcon={<Launch />}
            href={explorerUrl}
            sx={{ 
              backgroundColor: 'primary.dark',
              color: 'white',
              '&:hover': {
                backgroundColor: 'primary.main'
              }
            }}
          >
            OPEN IN GEOGRAPHIC EXPLORER
          </Button>
        )}
      </Paper>
    </Box>
  );
});

PopulationAnalysis.displayName = 'PopulationAnalysis';
export default PopulationAnalysis;