// apiService.js
import apiClient from './apiClient';
import { useState } from 'react';

// Debug tracking
const debugApiCalls = {};

const trackApiCall = (name) => {
  if (!debugApiCalls[name]) {
    debugApiCalls[name] = [];
  }
  const stack = new Error().stack;
  debugApiCalls[name].push({
    timestamp: Date.now(),
    stack: stack
  });
};

export const handleApiError = (error, enqueueSnackbar) => {
  if (!enqueueSnackbar) return;  // Early return if no snackbar provided
  
  if (error.isNetwork) {
    enqueueSnackbar('Unable to connect to the server. Please check your connection.', { 
      variant: 'error' 
    });
  } else if (error.isServer) {
    enqueueSnackbar('An unexpected error occurred. Please try again later.', { 
      variant: 'error' 
    });
  } else {
    enqueueSnackbar(error.message || 'An error occurred', { variant: 'error' });
  }
};

// Result cache with proper memory management
const resultCache = new Map();
const resultTimeouts = new Map();
const CACHE_DURATION = 3600000; // 60 minutes

const clearResultCache = (key) => {
  if (resultTimeouts.has(key)) {
    clearTimeout(resultTimeouts.get(key));
  }
  resultCache.delete(key);
  resultTimeouts.delete(key);
};

const withCache = async (key, fetchFn) => {
  const now = Date.now();
  const cached = resultCache.get(key);
  
  // Add request deduplication
  if (cached?.promise) {
    return cached.promise;
  }
  
  if (cached?.data && now - cached.timestamp < CACHE_DURATION) {
    return cached.data;
  }

  // Create a promise for the request
  const promise = fetchFn();
  
  // Store the promise in cache temporarily
  resultCache.set(key, {
    promise,
    timestamp: now
  });

  try {
    const data = await promise;
    // Store the actual data
    resultCache.set(key, {
      data,
      timestamp: now
    });
    return data;
  } catch (error) {
    // Clear cache on error
    resultCache.delete(key);
    throw error;
  }
};

// Improved cache invalidation
const invalidateCache = (pattern) => {
  for (const key of resultCache.keys()) {
    if (pattern instanceof RegExp ? pattern.test(key) : key.includes(pattern)) {
      clearResultCache(key);
    }
  }
};

export const schoolsApi = {
  async getAllSchools(enqueueSnackbar, options = {}) {
    const cacheKey = options.limit ? `getAllSchools-limit-${options.limit}` : 'getAllSchools';
    return withCache(cacheKey, async () => {
      trackApiCall('getAllSchools');
      try {
        // If limit is provided, use the paginated API with appropriate page size
        if (options.limit) {
          const response = await apiClient.get('/schools', {
            params: {
              paginated: 'true',
              page: 1,
              page_size: options.limit
            }
          });
          return response.schools || [];
        } else {
          // Regular call without pagination
          const response = await apiClient.get('/schools');
          return response;
        }
      } catch (error) {
        handleApiError(error, enqueueSnackbar);
        throw error;
      }
    });
  },

  // Add a specific method for paginated schools
  async getSchoolsPaginated(page = 1, pageSize = 100, enqueueSnackbar) {
    return withCache(`schools-page-${page}-size-${pageSize}`, async () => {
      trackApiCall('getSchoolsPaginated');
      try {
        const response = await apiClient.get('/schools', {
          params: {
            paginated: 'true',
            page,
            page_size: pageSize
          }
        });
        return response;
      } catch (error) {
        handleApiError(error, enqueueSnackbar);
        throw error;
      }
    });
  },

  async getMarketShareData(schoolId, enqueueSnackbar) {
    return withCache(`marketShare-${schoolId}`, async () => {
      try {
        return await apiClient.get(`/school/${schoolId}/esri-market-share`);
      } catch (error) {
        handleApiError(error, enqueueSnackbar);
        throw error;
      }
    });
  },

  getEnrollmentProjections: async (schoolId, enqueueSnackbar) => {
    return withCache(`projections-${schoolId}`, async () => {
      try {
        return await apiClient.get(`/school/${schoolId}/enrollment-projections`);
      } catch (error) {

        let errorMessage;
        if (error.status === 401 || error.status === 403) {
          errorMessage = 'You do not have permission to view this school\'s projections';
        } else if (error.status === 404) {
          errorMessage = 'School projections not found';
        } else if (typeof error.response === 'string' && error.response.includes('<!DOCTYPE')) {
          errorMessage = 'Authentication error. Please try logging in again.';
        } else {
          errorMessage = error.message || 'Failed to fetch enrollment projections';
        }

        error.message = errorMessage;
        handleApiError(error, enqueueSnackbar);
        throw error;
      }
    });
  },

  submitEnrollmentData: async (data, enqueueSnackbar) => {
    try {
      const response = await apiClient.post('/submit-enrollment-data', data);
      invalidateCache(/projections-/);
      return response;
    } catch (error) {
      handleApiError(error, enqueueSnackbar);
      throw error;
    }
  },

  updateProjections: async (ncessch, enqueueSnackbar) => {
    try {
      const response = await apiClient.post('/update-projections', { ncessch });
      invalidateCache(`projections-${ncessch}`);
      return response;
    } catch (error) {
      handleApiError(error, enqueueSnackbar);
      throw error;
    }
  },
  
  submitEnrollmentTargets: async (data, enqueueSnackbar) => {
    try {
      const response = await apiClient.post('/submit-enrollment-targets', data);
      invalidateCache(`targets-${data.schoolId}`);
      return response;
    } catch (error) {
  
      let errorMessage;
      if (error.status === 401 || error.status === 403) {
        errorMessage = 'You do not have permission to submit enrollment targets for this school';
      } else if (error.status === 404) {
        errorMessage = 'School not found';
      } else if (typeof error.response === 'string' && error.response.includes('<!DOCTYPE')) {
        errorMessage = 'Authentication error. Please try logging in again.';
      } else {
        errorMessage = error.message || 'Failed to submit enrollment targets';
      }
  
      error.message = errorMessage;
      handleApiError(error, enqueueSnackbar);
      throw error;
    }
  },
  
  getEnrollmentTargets: async (schoolId, enqueueSnackbar) => {
    return withCache(`targets-${schoolId}`, async () => {
      try {
        return await apiClient.get(`/school/${schoolId}/enrollment-targets`);
      } catch (error) {
  
        let errorMessage;
        if (error.status === 401 || error.status === 403) {
          errorMessage = 'You do not have permission to view this school\'s enrollment targets';
        } else if (error.status === 404) {
          errorMessage = 'School targets not found';
        } else if (typeof error.response === 'string' && error.response.includes('<!DOCTYPE')) {
          errorMessage = 'Authentication error. Please try logging in again.';
        } else {
          errorMessage = error.message || 'Failed to fetch enrollment targets';
        }
  
        error.message = errorMessage;
        handleApiError(error, enqueueSnackbar);
        throw error;
      }
    });
  },
  
  async getNearbySchools(schoolId, driveTime, type = 'current', enqueueSnackbar) {
    return withCache(`nearby-${schoolId}-${driveTime}-${type}`, async () => {
      try {
        return await apiClient.get(`/school/${schoolId}/nearby-schools/${driveTime}?type=${type}`);
      } catch (error) {
        handleApiError(error, enqueueSnackbar);
        return [];  // Keeping existing behavior of returning empty array
      }
    });
  },
  
  async getAllNearbySchools(schoolId, enqueueSnackbar) {
    return withCache(`allNearby-${schoolId}`, async () => {
      try {
        const results = {
          current: {},
          comparison: {},
          gradeData: {}
        };
        
        const driveTimes = [5, 10, 15];
        const [currentResponses, comparisonResponses] = await Promise.all([
          Promise.all(driveTimes.map(time => 
            this.getNearbySchools(schoolId, time, 'current', enqueueSnackbar)
          )),
          Promise.all(driveTimes.map(time => 
            this.getNearbySchools(schoolId, time, 'comparison', enqueueSnackbar)
          ))
        ]);
  
        // Rest of the processing code remains the same
        driveTimes.forEach((time, index) => {
          results.current[time] = currentResponses[index];
          results.comparison[time] = comparisonResponses[index];
  
          if (currentResponses[index].gradeData) {
            results.gradeData = {
              ...results.gradeData,
              ...currentResponses[index].gradeData
            };
          }
          if (comparisonResponses[index].gradeData) {
            results.gradeData = {
              ...results.gradeData,
              ...comparisonResponses[index].gradeData
            };
          }
        });
        
        return results;
      } catch (error) {
        handleApiError(error, enqueueSnackbar);
        throw error;
      }
    });
  },
  
  async getSchoolsEnrollmentData(schoolIds, selectedSchoolId, enqueueSnackbar) {
    if (!schoolIds?.length) return [];
  
    const cacheKey = `enrollment-${selectedSchoolId}-${schoolIds.join(',')}`;
    return withCache(cacheKey, async () => {
      try {
        const CHUNK_SIZE = 100;
        const results = [];
        
        for (let i = 0; i < schoolIds.length; i += CHUNK_SIZE) {
          const chunk = schoolIds.slice(i, i + CHUNK_SIZE);
          
          const response = await apiClient.post('/schools-enrollment-by-grade', { 
            ncesschList: chunk,
            selectedSchool: selectedSchoolId
          });
          
          if (response && Array.isArray(response)) {
            results.push(...response);
          } else {
          }
        }
        
        return results;
      } catch (error) {
        handleApiError(error, enqueueSnackbar);
        throw error;
      }
    });
  },
  
  async getDistrictMetrics(enqueueSnackbar) {
    return withCache('districtMetrics', async () => {
      try {
        const response = await apiClient.get('/district-metrics');
        
        if (!response?.metrics) {
          throw new Error('Invalid district metrics response');
        }

        return response;
      } catch (error) {
        handleApiError(error, enqueueSnackbar);
        throw error;
      }
    });
  }
};

export function useApiCall(apiCall, onSuccess, onError, showLoading = true) {
  const [loading, setLoading] = useState(showLoading);
  const [error, setError] = useState(null);

  const execute = async (...args) => {
    try {
      setLoading(true);
      setError(null);

      const result = await apiCall(...args);
      
      if (!result || (typeof result === 'string' && result.includes('<!DOCTYPE'))) {
        throw new Error('Invalid response received');
      }

      if (onSuccess) onSuccess(result);
      return result;
    } catch (err) {

      let errorMessage = err.message;
      
      if (err.status === 401 || 
          err.message.includes('unauthorized') || 
          err.message.includes('<!DOCTYPE')) {
        errorMessage = 'Your session has expired. Please log in again.';
      }

      setError(errorMessage);
      if (onError) onError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { execute, loading, error };
}