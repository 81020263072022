import React from 'react';
import { 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Box,
  Typography,
  Tooltip
} from '@mui/material';
import { 
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  TrendingFlat as TrendingFlatIcon,
  ShowChart as ShowChartIcon,
  People as PeopleIcon,
  PieChart as PieChartIcon
} from '@mui/icons-material';

const DistrictMetricToggles = ({ filters, onFilterChange }) => {
  // Standard options for population and enrollment
  const standardOptions = [
    { label: "All", value: "", icon: null },
    { label: "Growing", value: "Growing", icon: <TrendingUpIcon sx={{ color: 'success.main' }} /> },
    { label: "Steady", value: "Steady", icon: <TrendingFlatIcon sx={{ color: 'action.disabled' }} /> },
    { label: "Declining", value: "Declining", icon: <TrendingDownIcon sx={{ color: 'error.main' }} /> }
  ];

  // Special options for market share
  const marketShareOptions = [
    { label: "All", value: "", icon: null },
    { label: "Gaining", value: "Growing", icon: <TrendingUpIcon sx={{ color: 'success.main' }} /> },
    { label: "Stable", value: "Steady", icon: <TrendingFlatIcon sx={{ color: 'action.disabled' }} /> },
    { label: "Losing", value: "Declining", icon: <TrendingDownIcon sx={{ color: 'error.main' }} /> }
  ];

  const metrics = [
    {
      id: "areaPopulation",
      label: "Area Population",
      helperText: "±2% change since 2020",
      value: filters.areaPopulation || [],
      icon: <ShowChartIcon color="primary" sx={{ opacity: 0.8, '&:hover': { opacity: 1 } }} />,
      options: standardOptions
    },
    {
      id: "schoolEnrollment",
      label: "School Enrollment",
      helperText: "±2% change since 2019",
      value: filters.schoolEnrollment || [],
      icon: <PeopleIcon color="primary" sx={{ opacity: 0.8, '&:hover': { opacity: 1 } }} />,
      options: standardOptions
    },
    {
      id: "marketShare",
      label: "Market Share",
      helperText: "±1.4% change since 2019",
      value: filters.marketShare || [],
      icon: <PieChartIcon color="primary" sx={{ opacity: 0.8, '&:hover': { opacity: 1 } }} />,
      options: marketShareOptions
    }
  ];

  return (
    <Grid 
      container 
      spacing={2} 
      sx={{ 
        justifyContent: 'center',  
        px: 2
      }}
    >
      {metrics.map((metric) => (
        <Grid item xs={12} sm={6} md={3} lg={2.4} key={metric.id}>  
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Tooltip title={metric.label}>
              {metric.icon}
            </Tooltip>
            <FormControl fullWidth size="small">
              <InputLabel>{metric.label}</InputLabel>
              <Select
                multiple
                value={metric.value}
                label={metric.label}
                onChange={(e) => {
                    const values = e.target.value;
                    // If "All" is selected, clear the filter
                    if (values.includes("")) {
                    onFilterChange(metric.id, []);
                    } else {
                    onFilterChange(metric.id, values);
                    }
                }}
                renderValue={(selected) => {
                  // For market share, translate Growing → Gaining, Declining → Losing
                  if (metric.id === 'marketShare') {
                    return selected.map(item => {
                      if (item === 'Growing') return 'Gaining';
                      if (item === 'Declining') return 'Losing';
                      if (item === 'Steady') return 'Stable';
                      return item;
                    }).join(', ');
                  }
                  return selected.join(', ');
                }}
                MenuProps={{
                  PaperProps: {
                    sx: { maxHeight: 300 }
                  }
                }}
              >
                {metric.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 1,
                      minWidth: 100
                    }}>
                      {option.icon}
                      <Box>
                        <Typography>{option.label}</Typography>
                        {metric.helperText && option.value === "" && (
                          <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                            {metric.helperText}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default DistrictMetricToggles;