import axios from 'axios';
import { schoolCache } from '../services/cacheService';
import { districtCache } from '../services/districtCacheService';
import config from '../config';

// Constants
const VERSION_KEY = 'app_version';
const LAST_CHECK_KEY = 'last_version_check';
const LAST_UPDATE_KEY = 'last_update_time';
const UPDATE_COOLDOWN = 5000; // 5 seconds
const CHECK_INTERVAL = 60 * 60 * 1000; // Check every hour
const UPDATE_TIMEOUT = 30000; // 30 second timeout for update

// State tracking
let checkInProgress = false;
let checkPromise = null;
let versionCheckInterval = null;

/**
 * Check if the application needs to be updated
 * @param {boolean} force Force check regardless of cooldown
 * @returns {Promise<boolean>} True if update is in progress
 */
export async function checkAppVersion(force = false) {
  // Avoid concurrent checks
  if (checkInProgress && !force) {
    return checkPromise;
  }
  
  // Skip checking if we recently updated (prevents flickering)
  const lastUpdate = localStorage.getItem(LAST_UPDATE_KEY);
  if (lastUpdate && Date.now() - parseInt(lastUpdate) < UPDATE_COOLDOWN && !force) {
    return false;
  }
  
  // Skip frequent checks
  const lastCheck = localStorage.getItem(LAST_CHECK_KEY);
  if (!force && lastCheck && Date.now() - parseInt(lastCheck) < 5 * 60 * 1000) {
    return false;
  }
  
  // Handle local development
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    if (!localStorage.getItem('version_check_complete')) {
      localStorage.setItem('version_check_complete', 'true');
      
      // Show and hide dev environment message
      const overlay = document.getElementById('version-update-overlay');
      if (overlay) {
        const overlayText = overlay.querySelector('p');
        if (overlayText) {
          overlayText.textContent = 'Development environment initialized! Overlay will close shortly...';
        }
        
        overlay.style.display = 'flex';
        setTimeout(() => {
          overlay.style.display = 'none';
        }, 3000);
      }
    }
    return false;
  }
  
  // Set check in progress
  checkInProgress = true;
  localStorage.setItem(LAST_CHECK_KEY, Date.now().toString());
  
  // Create promise for the check
  checkPromise = (async () => {
    try {
      // Get current and new version
      const currentVersion = localStorage.getItem(VERSION_KEY);
      const response = await axios.get(`${config.apiUrl}/version`);
      const { version } = response.data;

      // Check if we need to update
      if (!currentVersion || version !== currentVersion) {
        console.log(`New version detected: ${version}. Current: ${currentVersion}`);
        
        // Show update overlay
        const overlay = document.getElementById('version-update-overlay');
        if (overlay) {
          overlay.style.display = 'flex';
        }
        
        try {
          // Clear caches
          await Promise.all([
            schoolCache.clearAllCache(),
            districtCache.clearAllCache()
          ]);
        } catch (cacheError) {
          console.warn('Cache clearing failed:', cacheError);
        }
        
        // Update stored version
        localStorage.setItem(VERSION_KEY, version);
        localStorage.setItem(LAST_UPDATE_KEY, Date.now().toString());
        
        // Set a flag to indicate update in progress with timestamp
        localStorage.setItem('update_in_progress', Date.now().toString());
        
        // Reload with cache-busting URL parameter
        setTimeout(() => {
          // Create a clean URL without existing version parameters
          const baseUrl = window.location.href.split('?')[0];
          const existingParams = new URLSearchParams(window.location.search);
          
          // Remove any existing version or timestamp params
          existingParams.delete('version');
          existingParams.delete('t');
          
          // Add new version and timestamp
          existingParams.append('version', version);
          existingParams.append('t', Date.now());
          
          // Build the new URL
          const queryString = existingParams.toString();
          const newUrl = baseUrl + (queryString ? '?' + queryString : '');
          
          // Navigate to the new URL
          window.location.href = newUrl;
        }, 1500);
        
        return true;
      }
      
      return false;
    } catch (error) {
      console.error('Error checking app version:', error);
      
      // Hide overlay if error occurs
      const overlay = document.getElementById('version-update-overlay');
      if (overlay) {
        overlay.style.display = 'none';
      }
      
      // Clear update flag if there was an error
      localStorage.removeItem('update_in_progress');
      
      return false;
    } finally {
      checkInProgress = false;
    }
  })();
  
  return checkPromise;
}

/**
 * Start periodic version checking
 * @returns {Function} Cleanup function to stop interval
 */
export function startVersionCheckInterval() {
  // Clear existing interval if any
  if (versionCheckInterval) {
    clearInterval(versionCheckInterval);
    versionCheckInterval = null;
  }
  
  // Check if an update has been in progress for too long (30 seconds)
  const updateInProgress = localStorage.getItem('update_in_progress');
  if (updateInProgress) {
    const updateStartTime = parseInt(updateInProgress);
    const currentTime = Date.now();
    
    // If the timestamp is invalid or the update has been in progress for too long
    if (isNaN(updateStartTime) || currentTime - updateStartTime > UPDATE_TIMEOUT) {
      console.warn('Update process timed out or stuck, resetting update state');
      localStorage.removeItem('update_in_progress');
      
      const overlay = document.getElementById('version-update-overlay');
      if (overlay) {
        overlay.style.display = 'none';
      }
    }
  }
  
  // Check if we need to keep overlay visible from previous update
  if (localStorage.getItem('update_in_progress')) {
    const overlay = document.getElementById('version-update-overlay');
    if (overlay) {
      overlay.style.display = 'flex';
      
      // After page assets are fully loaded, we can hide the overlay
      window.addEventListener('load', () => {
        setTimeout(() => {
          overlay.style.display = 'none';
          localStorage.removeItem('update_in_progress');
          console.log('Update completed successfully');
        }, 500); // Small delay to ensure everything is rendered
      });
    } else {
      // If overlay isn't available, just clear the flag
      localStorage.removeItem('update_in_progress');
    }
  }
  
  // Initial check
  checkAppVersion().catch(error => {
    console.error('Initial version check failed:', error);
  });
  
  // Set up interval
  versionCheckInterval = setInterval(() => {
    checkAppVersion().catch(error => {
      console.error('Periodic version check failed:', error);
    });
  }, CHECK_INTERVAL);
  
  // Return cleanup function
  return () => {
    if (versionCheckInterval) {
      clearInterval(versionCheckInterval);
      versionCheckInterval = null;
    }
  };
}

/**
 * Hook for React components to use for route change checks
 * @returns {Object} Version check functions
 */
export function useVersionCheck() {
  return {
    checkOnRouteChange: () => {
      checkAppVersion().catch(error => {
        console.error('Route change version check failed:', error);
      });
    }
  };
}