import React from 'react';
import { 
  Box, 
  Card, 
  CardContent,
  CardActionArea,
  Stack,
  Divider,
  Typography,
  CircularProgress,
  alpha
} from '@mui/material';
import { TrendingUp, TrendingDown, TrendingFlat } from '@mui/icons-material';
import { CHART_COLORS } from '../../../utils/schoolUtils';

/**
 * Loading indicator for headline metrics
 */
export const LoadingMetric = ({ label, align = 'left' }) => (
  <Box sx={{ 
    display: 'flex',
    flexDirection: 'column',
    alignItems: align === 'right' ? 'flex-end' : 'flex-start'
  }}>
    <Typography variant="caption" color="text.secondary">
      {label}
    </Typography>
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      mt: 0.5
    }}>
      <CircularProgress 
        size={14} 
        sx={{ 
          mr: align === 'right' ? 0 : 1,
          ml: align === 'right' ? 1 : 0,
          color: 'primary.light'
        }} 
      />
    </Box>
  </Box>
);

/**
 * Enhanced HeadlineBox that properly shows loading states
 */
export const HeadlineBoxWithLoading = ({ 
  title, 
  trend = 'neutral', 
  isSelected, 
  onClick, 
  metrics,
  isLoading = false 
}) => {
  const getTrendIcon = () => {
    switch (trend) {
      case 'up':
        return <TrendingUp color="success" />;
      case 'down':
        return <TrendingDown color="error" />;
      default:
        return <TrendingFlat color="action" />;
    }
  };

  const getTrendColor = () => {
    switch (trend) {
      case 'up':
        return CHART_COLORS.GROWING;
      case 'down':
        return CHART_COLORS.DECLINING;
      default:
        return CHART_COLORS.NEUTRAL;
    }
  };

  return (
    <Card 
      elevation={isSelected ? 2 : 0}
      sx={{
        border: '1px solid',
        borderColor: isSelected ? 'primary.main' : 'divider',
        bgcolor: isSelected ? 'grey.50' : 'background.paper',
        transition: 'all 0.2s ease-in-out',
        width: '100%',
        '&:hover': {
          borderColor: isSelected ? 'primary.main' : 'primary.light',
          bgcolor: isSelected ? alpha('#fff', 0.9) : alpha('#fff', 0.7),
          transform: isLoading ? 'none' : 'translateX(4px)'
        }
      }}
    >
      <CardActionArea onClick={onClick} disabled={isLoading}>
        <CardContent sx={{ pb: '8px !important' }}>
          <Stack spacing={1}>
            {/* Header with icon and title */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {getTrendIcon()}
              <Typography variant="h6" sx={{ 
                fontSize: '1.1rem', 
                fontWeight: 600,
                color: isSelected ? 'primary.main' : 'text.primary',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                {title}
              </Typography>
              
              {isLoading && (
                <CircularProgress 
                  size={16} 
                  sx={{ ml: 'auto', color: 'primary.light' }} 
                />
              )}
            </Box>

            <Divider />

            {/* Metrics */}
            <Box sx={{ 
              display: 'flex', 
              gap: 3,
              justifyContent: 'space-between',
              px: 1
            }}>
              {isLoading ? (
                /* When loading, show loading metrics */
                <>
                  <LoadingMetric 
                    label={metrics?.[0]?.label || 'Loading...'} 
                    align="left"
                  />
                  <LoadingMetric 
                    label={metrics?.[1]?.label || 'Loading...'} 
                    align="right"
                  />
                </>
              ) : (
                /* When loaded, show actual metrics */
                metrics?.map((metric, index) => (
                  <Box key={index} sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: index === 1 ? 'flex-end' : 'flex-start'
                  }}>
                    <Typography variant="caption" color="text.secondary">
                      {metric.label}
                    </Typography>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        fontWeight: 600,
                        color: getTrendColor()
                      }}
                    >
                      {metric.value}
                    </Typography>
                  </Box>
                ))
              )}
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};