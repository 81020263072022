import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  Box,
  Tabs,
  Tab,
  Chip,
  Paper,
  useTheme,
  useMediaQuery,
  Button,
  Avatar,
  Stack
} from '@mui/material';
import {
  PlayCircleOutline as PlayCircleOutlineIcon,
  ArrowBack as ArrowBackIcon,
  VideoLibrary as VideoLibraryIcon,
} from '@mui/icons-material';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { Link } from 'react-router-dom';

// Video data organized by tool category
const videoData = [
  {
    category: "Geographic Explorer",
    icon: <MapsHomeWorkOutlinedIcon />,
    description: "Learn how to use location-based data to understand your community demographics.",
    bgColor: 'primary.light',
    videos: [
      {
        id: "b6a29b34572143b780c57f6b2620f1d1",
        title: "Overview of the Geographic Explorer",
        description: "Get started with a complete overview of the tool's capabilities",
        duration: "5:26",
        isOverview: true
      },
      {
        id: "0d649ff2d72648319e297a1951a6b9e3",
        title: "GeoX: Your Community Deeper Dive",
        description: "Explore demographic data in your school's community",
        duration: "4:18"
      },
      {
        id: "5bc74c386c4246ddbbc08f63ee6420d6", 
        title: "GeoX: Enrollment Trends Deeper Dive",
        description: "Analyze enrollment patterns by location over time",
        duration: "6:42"
      },
      {
        id: "a9eb9189bc5c4b4cad9c7287aed22668",
        title: "GeoX: Nearby Schools Comparison Deeper Dive",
        description: "Compare your school to others in the same area",
        duration: "5:10"
      }
    ]
  },
  {
    category: "Competitive Explorer",
    icon: <DonutSmallOutlinedIcon />,
    description: "Discover how to analyze and compare your school with nearby alternatives.",
    bgColor: 'primary.main',
    videos: [
      {
        id: "10c2543490d34a1dbd300543e3ceb54b", 
        title: "Overview of the Competitive Explorer",
        description: "Learn the fundamentals of competitive analysis tools",
        duration: "4:50",
        isOverview: true
      },
      {
        id: "e2dc823b00b24c2d87434dbb9d536848", 
        title: "CompX: Your Community Deeper Dive",
        description: "Understand your position in the local education market",
        duration: "6:03"
      },
      {
        id: "39ce708acea34c13ad50867780c555ce", 
        title: "CompX: Enrollment & Demographic Trends Deeper Dive",
        description: "Track changes in enrollment patterns over time",
        duration: "7:12"
      },
      {
        id: "d72302f699c541cfaf4239a018aafd2d",
        title: "CompX: Nearby Schools Comparison Deeper Dive",
        description: "Compare metrics with competing schools in your area",
        duration: "5:36"
      }
    ]
  },
  {
    category: "Projections Explorer",
    icon: <AutoGraphOutlinedIcon />,
    description: "Master the tools for forecasting future enrollment scenarios.",
    bgColor: 'primary.dark',
    videos: [
      {
        id: "3e7a737811b14b2cba3f42f6784f8d83",
        title: "Overview of the Projections Explorer",
        description: "Learn how to create and interpret enrollment projections",
        duration: "6:18",
        isOverview: true
      },
      {
        id: "7d90dcaa7aa04a84856bd1bf0a9d103e", 
        title: "ProjectX: Risk Assessment",
        description: "Identify potential enrollment risks and opportunities",
        duration: "5:42"
      },
      {
        id: "caebe6dd881b45398daea11abff91414", 
        title: "ProjectX: Strategy Impact Analysis Deeper Dive",
        description: "Model how different strategies affect future enrollment",
        duration: "8:15"
      }
    ]
  },
  {
    category: "School Analysis",
    icon: <AssessmentOutlinedIcon />,
    description: "Discover how to interpret and use comprehensive school data.",
    bgColor: 'secondary.main',
    videos: [
      {
        id: "54e2847091b045539569df7d1fd98df0",
        title: "Overview of the School Summary",
        description: "Get a complete tour of the school summary dashboard",
        duration: "4:24",
        isOverview: true
      },
      {
        id: "7d0fa0c5a5bb47a2aa9c6c56c486def8",
        title: "Overview of All Schools Analysis",
        description: "Learn how to compare metrics across multiple schools",
        duration: "5:56",
        isOverview: true
      }
    ]
  }
];

// Video component that embeds a Loom video
const VideoEmbed = ({ videoId, videoTitle }) => {
  return (
    <Box sx={{ 
      position: 'relative', 
      paddingBottom: '56.25%', 
      height: 0, 
      overflow: 'hidden', 
      maxWidth: '100%', 
      mb: 2,
      borderRadius: 2,
      boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
    }}>
      <iframe 
        title={`${videoTitle} - Video Tutorial`}
        src={`https://www.loom.com/embed/${videoId}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
        frameBorder="0" 
        webkitallowfullscreen="true"
        mozallowfullscreen="true" 
        allowFullScreen={true}
        style={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100%',
          borderRadius: '8px'
        }}
      />
    </Box>
  );
};

// Video card component
const VideoCard = ({ video, onClick, categoryColor }) => {
  return (
    <Card 
      sx={{ 
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
        overflow: 'visible',
        position: 'relative',
        transition: 'all 0.3s ease',
        '&:hover': { 
          transform: 'translateY(-4px)', 
          boxShadow: '0 12px 20px rgba(0,0,0,0.12)'
        },
        ...(video.isOverview && {
          borderTop: '4px solid',
          borderColor: categoryColor || 'primary.main',
        })
      }}
      onClick={() => onClick(video)}
    >
      <CardContent sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column',
        p: 3
      }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
          <Avatar
            sx={{ 
              bgcolor: video.isOverview ? (categoryColor || 'primary.main') : 'grey.200',
              color: video.isOverview ? 'white' : 'grey.700',
              width: 40, 
              height: 40,
              mr: 2
            }}
          >
            <PlayCircleOutlineIcon />
          </Avatar>
          <Box>
            <Typography 
              variant="h6" 
              component="h3" 
              sx={{ 
                fontSize: '1rem', 
                fontWeight: 600,
                lineHeight: 1.2,
                mb: 0.5
              }}
            >
              {video.title}
            </Typography>
            {video.duration && (
              <Typography variant="caption" color="text.secondary">
                {video.duration}
              </Typography>
            )}
          </Box>
        </Box>
        
        {video.description && (
          <Typography 
            variant="body2" 
            color="text.secondary" 
            sx={{ mb: 2, flexGrow: 1 }}
          >
            {video.description}
          </Typography>
        )}
        
        {video.isOverview && (
          <Chip 
            label="Overview" 
            size="small" 
            color="primary" 
            sx={{ alignSelf: 'flex-start', mt: 'auto' }}
          />
        )}
      </CardContent>
    </Card>
  );
};

// Category header component
const CategoryHeader = ({ category, icon, description, bgColor }) => {
  return (
    <Paper 
      elevation={0}
      sx={{ 
        p: 3, 
        mb: 3, 
        borderRadius: 2,
        backgroundColor: 'grey.50',
        borderLeft: '4px solid',
        borderColor: bgColor || 'primary.main'
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Avatar 
            sx={{ 
              bgcolor: bgColor || 'primary.main',
              width: 48,
              height: 48
            }}
          >
            {icon || <VideoLibraryIcon />}
          </Avatar>
        </Grid>
        <Grid item xs>
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 0 }}>
            {category}
          </Typography>
          {description && (
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

// Main component
const VideoTutorials = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedVideo(null); // Reset selected video when changing tabs
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Find category for a video
  const getCategoryForVideo = (videoId) => {
    for (const category of videoData) {
      if (category.videos.some(v => v.id === videoId)) {
        return category;
      }
    }
    return null;
  };

  const selectedCategory = selectedVideo ? getCategoryForVideo(selectedVideo.id) : null;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header with back link */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center', 
        mb: 4 
      }}>
        <Link to="/resources" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Button 
            startIcon={<ArrowBackIcon />}
            sx={{ color: 'text.secondary' }}
          >
            Back to Resources
          </Button>
        </Link>
      </Box>

      {/* Page Header */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 4, 
          borderRadius: 2,
          background: 'linear-gradient(45deg, #f3f4f6 30%, #ffffff 90%)',
          borderLeft: '4px solid',
          borderColor: 'primary.main'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
          <Avatar 
            sx={{ 
              bgcolor: 'primary.main',
              width: 56,
              height: 56
            }}
          >
            <VideoLibraryIcon fontSize="large" />
          </Avatar>
          <Box>
            <Typography variant="h4" component="h1" gutterBottom>
              Video Tutorials
            </Typography>
            <Typography variant="body1">
              Watch step-by-step guides on using our enrollment planning tools.
            </Typography>
          </Box>
        </Box>
      </Paper>

      {/* Selected video player */}
      {selectedVideo && selectedCategory && (
        <Box sx={{ mb: 4 }}>
          <Paper 
            elevation={1} 
            sx={{ 
              p: 3, 
              borderRadius: 2,
              boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
            }}
          >
            <Stack spacing={2}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <Chip 
                  icon={selectedCategory.icon}
                  label={selectedCategory.category}
                  color="primary"
                  size="small"
                />
                {selectedVideo.isOverview && (
                  <Chip 
                    label="Overview" 
                    size="small" 
                    color="secondary"
                  />
                )}
              </Box>
              
              <Typography 
                variant="h5" 
                component="h2" 
                sx={{ fontWeight: 'medium' }}
              >
                {selectedVideo.title}
              </Typography>
              
              {selectedVideo.description && (
                <Typography variant="body2" color="text.secondary" paragraph>
                  {selectedVideo.description}
                </Typography>
              )}
              
              <VideoEmbed videoId={selectedVideo.id} videoTitle={selectedVideo.title} />
              
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setSelectedVideo(null)}
                >
                  Back to All Videos
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Box>
      )}

      {/* Tabs for category selection */}
      <Box sx={{ 
        borderBottom: 1, 
        borderColor: 'divider', 
        mb: 4,
        boxShadow: '0 2px 4px rgba(0,0,0,0.02)',
        borderRadius: '8px 8px 0 0',
        backgroundColor: 'white'
      }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange} 
          variant={isMobile ? "scrollable" : "fullWidth"}
          scrollButtons={isMobile ? "auto" : false}
          aria-label="video tutorial categories"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab 
            label="All Tutorials" 
            icon={<VideoLibraryIcon />}
            iconPosition="start"
            sx={{ minHeight: 64 }}
          />
          {videoData.map((category, index) => (
            <Tab 
              key={index} 
              label={category.category} 
              icon={category.icon}
              iconPosition="start"
              sx={{ minHeight: 64 }}
            />
          ))}
        </Tabs>
      </Box>

      {/* Display all videos when "All Tutorials" tab is selected */}
      {activeTab === 0 ? (
        <>
          {videoData.map((category, categoryIndex) => (
            <Box key={categoryIndex} sx={{ mb: 5 }}>
              <CategoryHeader 
                category={category.category} 
                icon={category.icon}
                description={category.description}
                bgColor={category.bgColor}
              />
              
              <Grid container spacing={3}>
                {category.videos.map((video, videoIndex) => (
                  <Grid item xs={12} sm={6} md={4} key={videoIndex}>
                    <VideoCard 
                      video={video} 
                      onClick={handleVideoClick} 
                      categoryColor={category.bgColor}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </>
      ) : (
        // Display videos for selected category
        <>
          <CategoryHeader 
            category={videoData[activeTab - 1].category} 
            icon={videoData[activeTab - 1].icon}
            description={videoData[activeTab - 1].description}
            bgColor={videoData[activeTab - 1].bgColor}
          />
          
          <Grid container spacing={3}>
            {videoData[activeTab - 1].videos.map((video, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <VideoCard 
                  video={video} 
                  onClick={handleVideoClick} 
                  categoryColor={videoData[activeTab - 1].bgColor}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {/* Support Section */}
      <Paper 
        elevation={1}
        sx={{ 
          mt: 6, 
          p: 3,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          justifyContent: 'space-between',
          gap: 2,
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
          background: 'linear-gradient(45deg, #f5f7fa 0%, #ffffff 100%)',
        }}
      >
        <Box>
          <Typography variant="h6" color="primary.dark" gutterBottom={isMobile}>
            Need Additional Help?
          </Typography>
          <Typography color="text.secondary">
            If you have specific questions about using these tools, reach out to our support team.
          </Typography>
        </Box>
        <Button 
          variant="contained"
          size="large"
          onClick={() => window.location.href = 'mailto:support@educationdatacollective'}
          sx={{ 
            borderRadius: 2,
            px: 3,
            py: 1,
            boxShadow: '0 4px 14px rgba(0,0,0,0.12)',
          }}
        >
          Contact Support
        </Button>
      </Paper>
    </Container>
  );
};

export default VideoTutorials;