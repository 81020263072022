import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button, 
  Paper,
  IconButton,
  Stack,
} from '@mui/material';
import { 
  Close as CloseIcon,
  Launch as LaunchIcon,
  School as SchoolIcon,
  LocationOn as LocationOnIcon,
  AutoStories as GradesIcon,
  AccountBalance as GovernanceIcon,
  Category as TypeIcon
} from '@mui/icons-material';

const DistrictMapPopup = ({ 
  school, 
  onClose 
}) => {
  const navigate = useNavigate();

  if (!school) return null;

  const handleViewSchoolSummary = () => {
    navigate(`/school-summary?school=${school.ncessch}`);
    if (onClose) onClose();
  };

  return (
    <Paper
      elevation={3}
      sx={{
        width: 300,
        backgroundColor: 'white',
        borderRadius: 2,
        overflow: 'hidden'
      }}
    >
      {/* Header */}
      <Box sx={{ 
        p: 2, 
        pb: 1.5,
        bgcolor: 'grey.50',
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}>
        <IconButton
          onClick={onClose}
          size="small"
          sx={{ 
            position: 'absolute', 
            right: 8, 
            top: 8
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>

        <Box sx={{ pr: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            {school.charter === 'Yes' ? (
              <SchoolIcon sx={{ color: 'primary.main' }} />
            ) : (
              <LocationOnIcon sx={{ color: 'primary.main' }} />
            )}
            <Typography variant="subtitle1" sx={{ fontWeight: 600, lineHeight: 1.2 }}>
              {school.name}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Details */}
      <Stack spacing={1.5} sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <GradesIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
          <Typography variant="body2">
            {school.grade_range}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <GovernanceIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
          <Typography variant="body2">
            {school.charter === 'Yes' ? 'Charter School' : 'District School'}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TypeIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
          <Typography variant="body2">
            {school.type || 'Traditional'}
          </Typography>
        </Box>

        <Button
          variant="contained"
          fullWidth
          endIcon={<LaunchIcon />}
          onClick={handleViewSchoolSummary}
          sx={{
            mt: 1,
            bgcolor: 'primary.main',
            '&:hover': {
              bgcolor: 'primary.dark'
            }
          }}
        >
          View School Summary
        </Button>
      </Stack>
    </Paper>
  );
};

export default DistrictMapPopup;