import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { schoolsApi } from '../utils/apiService';
import { formatSchoolName } from '../utils/nameFormatter';
import { categorizeSchools } from '../utils/schoolCategorization';

const processEnrollmentData = (data) => {
  return data.map(school => {
    return {
      ncessch: school.ncessch,
      ...school,
      // Maintain the nested structure for race_data
      race_data: {
        current: school.race_data?.current || {},
        comparison: school.race_data?.comparison || {}
      },
      // Handle other fields as before
      enrollment_by_grade: {
        current: school.enrollment_by_grade?.current || {},
        comparison: school.enrollment_by_grade?.comparison || {}
      }
    };
  });
};

const useSchoolStore = create(
  devtools((set, get) => ({
    // Separate initialization flags
    schoolInitialized: false,

    // Cache states - removed districtDataCache
    schoolDataCache: {},  // Cache by ncessch
    lastSchoolModified: null,

    // Data states
    schools: [],
    selectedSchool: null,
    esriData: null,
    nearbySchools: {},
    rawNearbySchools: {},
    enrollmentProjections: null,
    schoolsEnrollmentData: [],
    
    // Loading states
    schoolsLoading: false,
    contentLoading: false,
    error: null,

    // Action to fetch all schools
    fetchSchools: async () => {
      try {
        set({ schoolsLoading: true });
        const schools = await schoolsApi.getAllSchools();
        
        // Deduplicate schools by ncessch
        const uniqueSchools = Array.from(
          new Map(schools.map(school => [
            school.ncessch, 
            {...school, name: formatSchoolName(school.name)}
          ])).values()
        ).sort((a, b) => a.name.localeCompare(b.name));
    
        set({ schools: uniqueSchools, schoolsLoading: false });
      } catch (error) {
        console.error('Error fetching schools:', error);
        set({ error: error.message, schoolsLoading: false });
      }
    },
    
    // Action to set selected school and fetch its data
    selectSchool: async (school) => {
      const { schoolDataCache } = get();

      // Immediately set selected school and start loading
      set({ 
        selectedSchool: school,
        contentLoading: true,
        // Initialize loading states for each data type
        loadingStates: {
          marketShare: true,
          nearby: true,
          projections: true,
          enrollment: true
        }
      });

      if (!school) {
        set({ contentLoading: false });
        return;
      }

      try {
        // Check cache first
        if (schoolDataCache[school.ncessch]) {
          const cachedData = schoolDataCache[school.ncessch];
          
          // Progressive updates from cache
          set(state => ({ 
            esriData: cachedData.marketShareData,
            loadingStates: { ...state.loadingStates, marketShare: false }
          }));
          set(state => ({ 
            nearbySchools: cachedData.nearbySchools,
            rawNearbySchools: cachedData.rawNearbySchools,
            loadingStates: { ...state.loadingStates, nearby: false }
          }));
          set(state => ({ 
            enrollmentProjections: cachedData.projectionsData,
            loadingStates: { ...state.loadingStates, projections: false }
          }));
          set(state => ({ 
            schoolsEnrollmentData: cachedData.enrollmentData,
            loadingStates: { ...state.loadingStates, enrollment: false }
          }));
          
          set({ contentLoading: false, error: null });
          return;
        }

        // If no cache, fetch new data with progressive updates
        try {
          // Start market share data fetch first as it's typically fastest
          const marketSharePromise = schoolsApi.getMarketShareData(school.ncessch)
            .then(data => {
              set(state => ({ 
                esriData: data,
                loadingStates: { ...state.loadingStates, marketShare: false }
              }));
              return data;
            });

          // Start nearby schools fetch
          const nearbySchoolsPromise = schoolsApi.getAllNearbySchools(school.ncessch)
            .then(data => {
              set(state => ({ 
                nearbySchools: data,
                rawNearbySchools: data,
                loadingStates: { ...state.loadingStates, nearby: false }
              }));
              return data;
            });

          // Start projections fetch
          const projectionsPromise = schoolsApi.getEnrollmentProjections(school.ncessch)
            .then(data => {
              set(state => ({ 
                enrollmentProjections: data,
                loadingStates: { ...state.loadingStates, projections: false }
              }));
              return data;
            });

          // Wait for these three in parallel
          const [marketShareData, nearbySchoolsResponse, projectionsData] = 
            await Promise.all([
              marketSharePromise,
              nearbySchoolsPromise,
              projectionsPromise
            ]);

          // Process school IDs for enrollment data
          const schoolIds = new Set([
            school.ncessch,
            ...Object.values(nearbySchoolsResponse.current || {})
              .flatMap(schools => schools.map(s => s.ncessch)),
            ...Object.values(nearbySchoolsResponse.comparison || {})
              .flatMap(schools => schools.map(s => s.ncessch)),
            ...Object.keys(nearbySchoolsResponse.gradeData || {})
          ]);

          // Fetch enrollment data
          const enrollmentData = await schoolsApi.getSchoolsEnrollmentData(
            Array.from(schoolIds),
            school.ncessch
          );
      
          const processedData = processEnrollmentData(enrollmentData);
          const categorizedData = categorizeSchools(
            processedData, 
            school.ncessch, 
            nearbySchoolsResponse.gradeData
          );

          // Update enrollment data state
          set(state => ({ 
            schoolsEnrollmentData: categorizedData,
            loadingStates: { ...state.loadingStates, enrollment: false }
          }));

          // Cache all the fetched data
          const newCache = {
            ...schoolDataCache,
            [school.ncessch]: {
              marketShareData,
              nearbySchools: nearbySchoolsResponse,
              rawNearbySchools: nearbySchoolsResponse,
              projectionsData,
              enrollmentData: categorizedData,
              cachedAt: new Date().toISOString()
            }
          };
          
          set({ 
            schoolDataCache: newCache,
            lastSchoolModified: new Date().toISOString(),
            contentLoading: false,
            error: null
          });

        } catch (error) {
          throw error; // Let the outer catch handle it
        }

      } catch (error) {
        console.error('Error fetching school data:', error);
        set({ 
          error: error.message, 
          contentLoading: false,
          loadingStates: {
            marketShare: false,
            nearby: false,
            projections: false,
            enrollment: false
          }
        });
      }
    },

    initializeSchool: async (school) => {
      try {
        set({ contentLoading: true });
        await get().selectSchool(school);
        set({ contentLoading: false });
      } catch (error) {
        console.error('Error initializing school:', error);
        set({ 
          contentLoading: false,
          error: error.message 
        });
      }
    },

    clearSchoolData: () => {
      const { selectedSchool } = get();
      if (selectedSchool) {
        const { schoolDataCache } = get();
        delete schoolDataCache[selectedSchool.ncessch];
        set({ schoolDataCache });
      }
      
      set({
        selectedSchool: null,
        esriData: null,
        nearbySchools: {},
        rawNearbySchools: {},
        enrollmentProjections: null,
        schoolsEnrollmentData: [],
        error: null
      });
    },

    markDataStale: () => set(state => ({
        // Don't clear map data completely, just mark as needing refresh
        contentLoading: true,
        loadingStates: {
          marketShare: true,
          nearby: true,
          projections: true,
          enrollment: true
        }
      })),

    clearAllData: () => {
      localStorage.removeItem('access_token'); // Clear token
      set({
        // Clear school data and cache
        selectedSchool: null,
        esriData: null,
        nearbySchools: {},
        rawNearbySchools: {},
        enrollmentProjections: null,
        schoolsEnrollmentData: [],
        schoolDataCache: {},
        lastSchoolModified: null,
        schoolInitialized: false,
        
        // Reset other states
        error: null,
        schools: []
      });
    }
  }))
);

export default useSchoolStore;