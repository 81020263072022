// src/utils/apiClient.js
import axios from 'axios';
import { auth } from '../firebase';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

// Add setAuthToken method to update authorization header
const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

// Enhanced request interceptor with token refresh
api.interceptors.request.use(async (config) => {
  const user = auth.currentUser;
  if (user) {
    try {
      const token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      console.error('Error getting token:', error);
    }
  }
  return config;
});

// Enhanced response interceptor with token refresh retry
api.interceptors.response.use(
    (response) => {
      return response.data;
    },
    async (error) => {
      console.error(`Error response from: ${error.config.url}`, 
                    { status: error.response?.status });

    const originalRequest = error.config;
    
    // Handle 401 errors with token refresh and retry
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      try {
        const user = auth.currentUser;
        if (user) {
          // Force token refresh
          const newToken = await user.getIdToken(true);
          setAuthToken(newToken);
          
          // Retry the original request with new token
          return api(originalRequest);
        }
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
      }
      
      // Redirect to login if refresh fails
      window.location.href = '/login';
    }
    
    // Enhanced error info
    const enhancedError = {
      message: error.response?.data?.message || error.message,
      status: error.response?.status,
      isNetwork: !error.response,
      isServer: error.response?.status >= 500
    };

    return Promise.reject(enhancedError);
  }
);

// Create the apiClient object with all methods
const apiClient = {
  ...api,
  setAuthToken
};

// Export the apiClient
export default apiClient;