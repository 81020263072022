export const ROLES = {
  ADMIN: 'admin',
  UPDATED_WRITE: 'updated_write',
  UPDATED_READ: 'updated_read',
  PUBLIC: 'public',
  SUMMARY_UPDATED: 'summary_updated',
  SUMMARY_PUBLIC: 'summary_public'
};

// Define route access by role
export const ROLE_ACCESS = {
  [ROLES.ADMIN]: ['*'], // Admin has access to everything
  [ROLES.UPDATED_WRITE]: [
    '/dashboard',
    '/district-analysis',
    '/school-summary',
    '/school-summary/*',
    '/geographic-explorer',
    '/competitive-explorer',
    '/projections-explorer',
    '/data-reports',
    '/data-reports/drive-time',
    '/preferences',
    '/resources', 
    '/resources/methodology',
    '/resources/tutorials'
  ],
  [ROLES.UPDATED_READ]: [
    '/dashboard',
    '/district-analysis',
    '/school-summary',
    '/school-summary/*',
    '/geographic-explorer',
    '/competitive-explorer',
    '/projections-explorer',
    '/data-reports',
    '/data-reports/drive-time',
    '/preferences',
    '/resources', 
    '/resources/methodology',
    '/resources/tutorials'
  ],
  [ROLES.PUBLIC]: [
    '/dashboard',
    '/district-analysis',
    '/school-summary',
    '/school-summary/*',
    '/geographic-explorer',
    '/competitive-explorer',
    '/projections-explorer',
    '/data-reports',
    '/data-reports/drive-time',
    '/preferences',
    '/resources', 
    '/resources/methodology',
    '/resources/tutorials'
  ],
  [ROLES.SUMMARY_UPDATED]: [
    '/dashboard',
    '/district-analysis',
    '/school-summary',
    '/school-summary/*',
    '/preferences',
    '/resources', 
    '/resources/methodology',
    '/resources/tutorials'
  ],
  [ROLES.SUMMARY_PUBLIC]: [
    '/dashboard',
    '/district-analysis',
    '/school-summary',
    '/school-summary/*',
    '/preferences',
    '/resources', 
    '/resources/methodology',
    '/resources/tutorials'
  ]
};

// Define role capabilities for components
export const ROLE_CAPABILITIES = {
  [ROLES.ADMIN]: {
    canView: true,
    canEdit: true,
    hasUpdatedAccess: true,
    hasWriteAccess: true
  },
  [ROLES.UPDATED_WRITE]: {
    canView: true,
    canEdit: true,
    hasUpdatedAccess: true,
    hasWriteAccess: true
  },
  [ROLES.UPDATED_READ]: {
    canView: true,
    canEdit: false,
    hasUpdatedAccess: true,
    hasWriteAccess: false
  },
  [ROLES.PUBLIC]: {
    canView: false,
    canEdit: false,
    hasUpdatedAccess: false,
    hasWriteAccess: false
  },
  [ROLES.SUMMARY_UPDATED]: {
    canView: false,
    canEdit: false,
    hasUpdatedAccess: true,
    hasWriteAccess: false
  },
  [ROLES.SUMMARY_PUBLIC]: {
    canView: false,
    canEdit: false,
    hasUpdatedAccess: false,
    hasWriteAccess: false
  }
};

// Helper functions
export const hasRouteAccess = (role, path) => {
  const userRoutes = ROLE_ACCESS[role] || [];
  return userRoutes.includes('*') || userRoutes.includes(path);
};

export const getUserPermissions = (role) => {
  return ROLE_CAPABILITIES[role] || ROLE_CAPABILITIES[ROLES.PUBLIC];
};