import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  getAuth, 
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { CircularProgress, Box } from '@mui/material';
import tokenManager from './TokenManager';
import { districtCache } from '../services/districtCacheService';
import mailchimpService from '../services/mailChimpService';

const AuthContext = createContext({
  user: null,
  loading: true,
  login: async () => {},
  logout: async () => {},
  resetPassword: async () => {}
});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          // Get initial token and decode claims
          const token = await firebaseUser.getIdToken();
          const decodedToken = JSON.parse(atob(token.split('.')[1]));
          
          // Get Firestore profile data
          const userProfileRef = doc(db, 'user_profiles', firebaseUser.uid);
          const userProfileSnap = await getDoc(userProfileRef);
          const userProfile = userProfileSnap.exists() ? userProfileSnap.data() : {};
  
          const enhancedUser = {
            ...firebaseUser,
            role: decodedToken?.role || userProfile.role || 'public',
            customClaims: decodedToken,
            teams: userProfile.teams || {}, // Keep as object
            name: userProfile.name,
            department: userProfile.department,
            lastUpdated: userProfile.lastUpdated
          };
          
          if (enhancedUser.teams) {
            const teamKeys = Object.keys(enhancedUser.teams);
            if (teamKeys.length > 0) {
            } else {
            }
          }
  
          setUser(enhancedUser);
          
          // Set up token listener
          const unsubscribeToken = tokenManager.addListener((tokenInfo) => {
            if (tokenInfo) {
              setUser(current => ({
                ...current,
                role: tokenInfo.claims?.role || current.role,
                customClaims: tokenInfo.claims
              }));
            }
          });
          
          return () => unsubscribeToken();
        } else {
          setUser(null);
        }
      } catch (error) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    });
  
    return () => unsubscribe();
  }, [auth, db]);

  const login = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      
      // Get user profile and token as before
      const userProfileRef = doc(db, 'user_profiles', result.user.uid);
      const userProfileSnap = await getDoc(userProfileRef);
      const userProfile = userProfileSnap.exists() ? userProfileSnap.data() : {};
      
      const token = await result.user.getIdToken();
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
  
      // Track login in Mailchimp - in a way that won't disrupt login if it fails
      try {
        // This runs in the background and doesn't block the login process
        setTimeout(async () => {
          try {
            await mailchimpService.trackLogin(result.user.email);
          } catch (mailchimpError) {
          }
        }, 0);
      } catch (trackingError) {
      }
  
      // Start prefetching data
      // Note: We use the base team ID initially since team data might not be loaded yet
      districtCache.prefetchDistrictData('base').catch(error => {
      });
  
      return {
        user: result.user,
        profile: userProfile,
        token: token,
        claims: decodedToken
      };
    } catch (error) {
      
      // Enhanced error handling with more error codes
      switch (error.code) {
        case 'auth/invalid-credential':
        case 'auth/wrong-password':
        case 'auth/user-not-found':
          throw new Error('Invalid email or password. Please try again.');
        
        case 'auth/too-many-requests':
          throw new Error('Too many login attempts. Please try again later or reset your password.');
        
        case 'auth/user-disabled':
          throw new Error('This account has been disabled. Please contact support.');
          
        case 'auth/invalid-email':
          throw new Error('Please enter a valid email address.');
          
        default:
          throw new Error('An error occurred during sign in. Please try again.');
      }
    }
  };

  const logout = async () => {
    try {
      // Sign out first to ensure the user is logged out even if cache clearing fails
      await signOut(auth);
      
      try {
        // Clear caches in a separate try-catch to handle failure gracefully
        await districtCache.clearAllCache();
      } catch (cacheError) {
      }
      
      // Clear only auth-related items from localStorage
      const consentKeys = ['analyticsConsent', 'hasExplicitConsent'];
      const keysToKeep = new Set(consentKeys);
      
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (!keysToKeep.has(key)) {
          localStorage.removeItem(key);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        throw new Error('No account found with this email address');
      }
      throw error;
    }
  };

  const value = {
    user,
    loading,
    login,
    logout,
    resetPassword
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' 
        }}>
          <CircularProgress />
        </Box>
      )}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};