import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  useTheme,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

import { ROLES } from '../../constants/roleAccess';

const menuItems = [
  { 
    text: 'District Analysis', 
    icon: <MapOutlinedIcon />, 
    path: '/district-analysis'
  },
  { 
    text: 'School Summary', 
    icon: <AssessmentOutlinedIcon />, 
    path: '/school-summary'
  },
  { 
    text: 'Geographic Explorer', 
    icon: <MapsHomeWorkOutlinedIcon />, 
    path: '/geographic-explorer',
    roles: [ROLES.ADMIN, ROLES.UPDATED_WRITE, ROLES.UPDATED_READ, ROLES.PUBLIC]
  },
  { 
    text: 'Competitive Explorer', 
    icon: <DonutSmallOutlinedIcon />, 
    path: '/competitive-explorer',
    roles: [ROLES.ADMIN, ROLES.UPDATED_WRITE, ROLES.UPDATED_READ, ROLES.PUBLIC]
  },
  { 
    text: 'Projections Explorer', 
    icon: <AutoGraphOutlinedIcon />, 
    path: '/projections-explorer',
    roles: [ROLES.ADMIN, ROLES.UPDATED_WRITE, ROLES.UPDATED_READ, ROLES.PUBLIC]
  },
  { 
    text: 'Support & Resources', 
    icon: <LightbulbOutlinedIcon />, 
    path: '/resources'
  },
  { 
    text: 'Admin', 
    icon: <AdminPanelSettingsOutlinedIcon />, 
    path: '/admin', 
    roles: [ROLES.ADMIN]
  }
];

const Sidebar = ({ user, onToggle }) => {
  const location = useLocation();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useAuth();


  const fullName = user?.name || user?.displayName || user?.email;

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
    onToggle(!isOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const drawerWidth = isOpen ? 240 : 70;

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: theme.palette.grey[200],
            color: theme.palette.text.primary,
            borderRight: 'none',
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {  
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxShadow: '4px 0 10px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        {/* Drawer content remains the same */}
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {/* Logo section */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: isOpen ? 200 : 80, 
            mb: 2,
            pt: isOpen ? 4 : 0,
          }}>
            <Box
              component={RouterLink}
              to="/dashboard"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': { opacity: 0.8 },
              }}
            >
              <img 
                src={isOpen ? "/images/edc_logo.png" : "/images/edc_logo_circle.png"} 
                alt="EDC Logo" 
                style={{ 
                  height: isOpen ? 175 : 50, 
                  maxWidth: '100%', 
                  objectFit: 'contain'
                }} 
              />
            </Box>
          </Box>

          {/* Menu Items */}
          <List sx={{ flexGrow: 1, mt: 2 }}>
            {menuItems
              .filter(item => {
                // If no roles specified, show to everyone
                if (!item.roles) {
                  return true;
                }
                // Otherwise, check if user's role is in the allowed roles
                return item.roles.includes(user?.role);
              })
              .map((item) => (
                <Tooltip key={item.text} title={!isOpen ? item.text : ""} placement="right">
                  <ListItem
                    button
                    component={RouterLink}
                    to={item.path}
                    selected={location.pathname === item.path}
                    sx={{
                      py: 1.5,
                      minHeight: 48,
                      px: isOpen ? 3 : 0,
                      justifyContent: 'center',
                      '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                      },
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: isOpen ? 3 : 0,
                        justifyContent: 'center',
                        color: location.pathname === item.path ? theme.palette.primary.main : theme.palette.text.secondary,
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    {isOpen && (
                      <ListItemText 
                        primary={item.text} 
                        primaryTypographyProps={{
                          fontSize: '0.9rem',
                          fontWeight: location.pathname === item.path ? 'bold' : 'normal',
                          color: location.pathname === item.path ? theme.palette.primary.main : theme.palette.text.primary,
                        }}
                      />
                    )}
                  </ListItem>
                </Tooltip>
              ))}
          </List>

          {/* User Profile Section */}
          {user && (
            <>
              <Divider sx={{ my: 1 }} />
              <Box sx={{ p: isOpen ? 2 : 0 }}>
              <Tooltip title={!isOpen ? fullName : ""} placement="right">
                  <ListItem
                    button
                    onClick={handleMenuOpen}
                    sx={{
                      borderRadius: 1,
                      justifyContent: 'center',
                      px: isOpen ? 2 : 0,
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                  >
                    <ListItemIcon sx={{ 
                      minWidth: 0,
                      mr: isOpen ? 3 : 0,
                      justifyContent: 'center'
                    }}>
                      <AccountCircleIcon color="primary" />
                    </ListItemIcon>
                    {isOpen && (
                      <ListItemText 
                        primary={fullName}
                        primaryTypographyProps={{
                          fontSize: '0.9rem',
                          fontWeight: 'medium',
                        }}
                      />
                    )}
                  </ListItem>
                </Tooltip>
              </Box>
            </>
          )}

          {/* Toggle Button */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Tooltip title={isOpen ? "Close sidebar" : "Open sidebar"} placement="right">
              <IconButton onClick={handleDrawerToggle}>
                {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Drawer>

      {/* User Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiMenuItem-root': {
              padding: '10px 15px',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
          },
        }}
      >
        <MenuItem component={RouterLink} to="/preferences">
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Privacy Preferences</ListItemText>
        </MenuItem>
        <MenuItem 
          onClick={async () => {
            handleMenuClose();
            await logout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Sidebar;