import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Grid, 
  Typography, 
  Card, 
  CardContent,
  CardActionArea,
  alpha,
  Stack,
  Divider,
} from '@mui/material';
import { 
  TrendingUp, 
  TrendingDown, 
  TrendingFlat,
} from '@mui/icons-material';

// Import visualization components
import DistrictMap from './map/DistrictMap';
import DistrictAnalysisTable from './table/DistrictAnalysisTable';
import DistrictAnalysisFilters from './filters/DistrictAnalysisFilters';
import DistrictAnalysisLoading from './loading/DistrictAnalysisLoading';
import LoadingErrorWrapper from '../shared_components/loadingErrorWrapper';

// Import shared components
import PageLayout from '../shared_components/pageLayout';
import { useInsight } from '../../contexts/InsightContext';

// Store district data in cache
import { useDistrictCache } from '../../hooks/useDistrictCache';

const HeadlineBox = ({ title, subtitle, trend = 'neutral', isSelected, onClick, metrics, note, totalSchools }) => {
  const getTrendIcon = () => {
    switch (trend) {
      case 'up':
        return <TrendingUp color="success" />;
      case 'down':
        return <TrendingDown color="error" />;
      default:
        return <TrendingFlat color="action" />;
    }
  };

  const getMetricColor = (index) => {
    if (index === 0) return 'success.main';
    if (index === metrics.length - 1) return 'error.main';
    return 'text.secondary';
  };

  return (
    <Card 
      elevation={isSelected ? 2 : 0}
      sx={{
        border: '1px solid',
        borderColor: isSelected ? 'primary.main' : 'divider',
        bgcolor: isSelected ? 'grey.50' : 'background.paper',
        transition: 'all 0.2s ease-in-out',
        width: '100%',
        '&:hover': {
          borderColor: isSelected ? 'primary.main' : 'primary.light',
          bgcolor: isSelected ? alpha('#fff', 0.9) : alpha('#fff', 0.7),
          transform: 'translateX(4px)'
        }
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardContent sx={{ pb: '8px !important' }}>
          <Stack spacing={1}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {getTrendIcon()}
                <Typography variant="h6" sx={{ 
                  fontSize: '1.1rem', 
                  fontWeight: 600,
                  color: isSelected ? 'primary.main' : 'text.primary',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  {title}
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: 'text.secondary',
                  ml: 4
                }}
              >
                {subtitle}
              </Typography>
            </Box>
            
            <Divider />
            {metrics && (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                px: 1
              }}>
                {metrics.map((metric, index) => (
                  <Box key={index}>
                    <Typography variant="caption" color="text.secondary">
                      {metric.label}
                    </Typography>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        fontWeight: 600,
                        color: getMetricColor(index),
                        textAlign: 'center'
                      }}
                    >
                      {metric.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            {note && (
              <Typography 
                variant="caption" 
                sx={{ 
                  fontStyle: 'italic',
                  color: 'text.secondary',
                  mt: 1
                }}
              >
                {note}
              </Typography>
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const DistrictAnalysis = () => {
  const navigate = useNavigate();
  const { districtData, loading: districtLoading, error } = useDistrictCache();

  // State declarations
  const [selectedHeadline, setSelectedHeadline] = useState(0);
  const [tableCollapsed, setTableCollapsed] = useState(false);
  const [filters, setFilters] = useState({
    network: '',
    state: '',
    city: [],
    gradeLevel: '',
    areaPopulation: [],
    schoolEnrollment: [],
    marketShare: []
  });
  const [availableFilters, setAvailableFilters] = useState({
    networks: [],
    states: [],
    cities: [],
    gradeLevels: []
  });
  const [loadingStep, setLoadingStep] = useState(0);
  const { setToolAndSection } = useInsight();


  // Set loading steps
  useEffect(() => {
    if (districtLoading) setLoadingStep(0);
    else if (!districtData) setLoadingStep(1);
    else setLoadingStep(2);
  }, [districtLoading, districtData]);

  // Set default tool section
  useEffect(() => {
    setToolAndSection('district-analysis', 'population-trends');
    return () => {
      const districtAnalysisExists = document.querySelector('[data-tool="district-analysis"]');
      if (!districtAnalysisExists) {
        setToolAndSection(null, null);
      }
    };
  }, [setToolAndSection]);

  // Set available filters from district data
  useEffect(() => {
    if (districtData?.metrics) {
      const networks = [...new Set(districtData.metrics.map(school => school.region))].filter(Boolean).sort();
      const states = [...new Set(districtData.metrics.map(school => school.state))].filter(Boolean).sort();
      const cities = [...new Set(districtData.metrics.map(school => school.city))].filter(Boolean).sort();
      const gradeLevels = [...new Set(districtData.metrics.map(school => school.level))].filter(Boolean).sort();

      setAvailableFilters({
        networks,
        states,
        cities,
        gradeLevels
      });
    }
  }, [districtData?.metrics]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  const resetFilters = () => {
    setFilters({
      network: '',
      state: '',
      city: [],
      gradeLevel: '',
      areaPopulation: [],
      schoolEnrollment: [],
      marketShare: []
    });
  };

  // Filter schools based on selected filters
  const filteredSchools = useMemo(() => {
    // Consistently access metrics as an array
    const metricsArray = districtData?.metrics || [];
        
    return metricsArray.filter(filterSchool);
    
    // Helper filter function
    function filterSchool(school) {
      // Skip schools with missing required properties
      if (!school || !school.population || !school.enrollment || !school.market_share) {
        return false;
      }
      
      if (filters.city.length > 0 && !filters.city.includes(school.city)) return false;
      if (filters.network && school.region !== filters.network) return false;
      if (filters.state && school.state !== filters.state) return false;
      if (filters.gradeLevel && school.level !== filters.gradeLevel) return false;

      if (filters.areaPopulation.length > 0) {
        const matches = {
          Growing: school.population.status === 'growing',
          Steady: school.population.status === 'stable',
          Declining: school.population.status === 'declining'
        };
        if (!filters.areaPopulation.some(filter => matches[filter])) return false;
      }

      // For school enrollment filter, exclude newer schools
      if (filters.schoolEnrollment.length > 0) {
        // Skip newer schools when filtering by enrollment
        if (school.is_newer) return false;
        
        const matches = {
          Growing: school.enrollment.status === 'growing',
          Steady: school.enrollment.status === 'stable',
          Declining: school.enrollment.status === 'declining'
        };
        if (!filters.schoolEnrollment.some(filter => matches[filter])) return false;
      }

      // For market share filter, exclude newer schools
      if (filters.marketShare.length > 0) {
        // Skip newer schools when filtering by market share
        if (school.is_newer) return false;
        
        // Market share uses gaining/losing/stable instead of growing/declining/stable
        const matches = {
          Growing: school.market_share.status === 'gaining', // Map 'Growing' filter to 'gaining' status
          Steady: school.market_share.status === 'stable',
          Declining: school.market_share.status === 'losing' // Map 'Declining' filter to 'losing' status
        };
        if (!filters.marketShare.some(filter => matches[filter])) return false;
      }

      return true;
    }
  }, [districtData, filters]);

  const calculateMetricCounts = (schools, type, statusField = 'status') => {
    const total = schools.length;
    
    // For population trends/projections and enrollment projections,
    // include all schools regardless of newer status
    if (type !== 'market_share' && !(type === 'enrollment' && statusField === 'status')) {
      // Process all schools
      const growing = schools.filter(s => s[type][statusField] === 'growing').length;
      const declining = schools.filter(s => s[type][statusField] === 'declining').length;
      const stable = total - growing - declining;
      
      return {
        total,
        growing,
        declining,
        stable,
        newer: 0,
        growingPercent: total > 0 ? (growing / total) * 100 : 0,
        decliningPercent: total > 0 ? (declining / total) * 100 : 0,
        stablePercent: total > 0 ? (stable / total) * 100 : 0,
        newerPercent: 0
      };
    }
    
    // For market share and enrollment trends, handle newer schools differently
    // Calculate newer schools and percentage
    const newer = schools.filter(s => s.is_newer).length;
    const newerPercent = (newer / total) * 100;
    
    // Filter to non-newer schools for status calculations
    const nonNewerSchools = schools.filter(s => !s.is_newer);
    
    if (type === 'market_share') {
      // Market share uses different status categories: gaining, losing, stable
      const gaining = nonNewerSchools.filter(s => s[type][statusField] === 'gaining').length;
      const losing = nonNewerSchools.filter(s => s[type][statusField] === 'losing').length;
      const stable = nonNewerSchools.length - gaining - losing;
      
      // Calculate percentages based on total for all categories to sum to 100%
      return {
        total,
        gaining,
        losing,
        stable,
        newer,
        // For market share display
        gainingPercent: total > 0 ? (gaining / total) * 100 : 0,
        losingPercent: total > 0 ? (losing / total) * 100 : 0,
        stablePercent: total > 0 ? (stable / total) * 100 : 0,
        // For compatibility with existing code
        growing: gaining,
        declining: losing,
        growingPercent: total > 0 ? (gaining / total) * 100 : 0,
        decliningPercent: total > 0 ? (losing / total) * 100 : 0,
        newerPercent
      };
    } else {
      // Enrollment trends uses growing, declining, stable
      const growing = nonNewerSchools.filter(s => s[type][statusField] === 'growing').length;
      const declining = nonNewerSchools.filter(s => s[type][statusField] === 'declining').length;
      const stable = nonNewerSchools.length - growing - declining;
      
      // Calculate percentages based on total for all categories to sum to 100%
      return {
        total,
        growing,
        declining,
        stable,
        newer,
        growingPercent: total > 0 ? (growing / total) * 100 : 0,
        decliningPercent: total > 0 ? (declining / total) * 100 : 0,
        stablePercent: total > 0 ? (stable / total) * 100 : 0,
        newerPercent
      };
    }
  };

  const headlines = useMemo(() => {
    const formatPercentage = (value) => {
      if (typeof value === 'number') {
        return `${value.toFixed(0)}%`;
      }
      return '0%';
    };
  
    // Create empty metrics object to use when no schools match filters
    const emptyMetrics = {
      total: 0,
      growing: 0,
      declining: 0,
      stable: 0,
      newer: 0,
      growingPercent: 0,
      decliningPercent: 0,
      stablePercent: 0,
      newerPercent: 0,
      gaining: 0,
      losing: 0,
      gainingPercent: 0,
      losingPercent: 0
    };
  
    // Calculate metrics using our updated function, or use empty metrics if no schools
    const populationMetrics = filteredSchools?.length 
      ? calculateMetricCounts(filteredSchools, 'population')
      : emptyMetrics;
      
    const projectionMetrics = filteredSchools?.length 
      ? calculateMetricCounts(filteredSchools, 'population', 'projection_status')
      : emptyMetrics;
      
    const marketShareMetrics = filteredSchools?.length 
      ? calculateMetricCounts(filteredSchools, 'market_share')
      : emptyMetrics;
      
    const enrollmentTrendMetrics = filteredSchools?.length 
      ? calculateMetricCounts(filteredSchools, 'enrollment')
      : emptyMetrics;
      
    const projectedEnrollmentMetrics = filteredSchools?.length 
      ? calculateMetricCounts(filteredSchools, 'enrollment', 'projection_status')
      : emptyMetrics;
  
    // For market share, get trend based on gaining vs losing
    const getMarketShareTrend = (metrics) => {
      return metrics.gaining > metrics.losing ? 'up' : 
             metrics.losing > metrics.gaining ? 'down' : 'neutral';
    };
  
    // For other metrics, get trend based on growing vs declining  
    const getTrend = (metrics) => {
      return metrics.growing > metrics.declining ? 'up' : 
             metrics.declining > metrics.growing ? 'down' : 'neutral';
    };
  
    return [
      {
        title: 'Area Population Trends',
        subtitle: 'Since 2020',
        content: `${populationMetrics.total} areas analyzed, with ${populationMetrics.growing} showing population growth.`,
        trend: getTrend(populationMetrics),
        metrics: [
          { label: 'Growing', value: formatPercentage(populationMetrics.growingPercent) },
          { label: 'Stable', value: formatPercentage(populationMetrics.stablePercent) },
          { label: 'Declining', value: formatPercentage(populationMetrics.decliningPercent) }
        ]
        // No "newer schools" note for population trends
      },
      {
        title: 'Area Population Projections',
        subtitle: 'Through 2029',
        content: `${projectionMetrics.total} areas analyzed through 2029, with ${projectionMetrics.growing} projected to grow.`,
        trend: getTrend(projectionMetrics),
        metrics: [
          { label: 'Growing', value: formatPercentage(projectionMetrics.growingPercent) },
          { label: 'Stable', value: formatPercentage(projectionMetrics.stablePercent) },
          { label: 'Declining', value: formatPercentage(projectionMetrics.decliningPercent) }
        ]
        // No "newer schools" note for population projections
      },
      {
        title: 'School Enrollment Trends',
        subtitle: 'Since 2019',
        content: `${enrollmentTrendMetrics.total} schools analyzed, with ${enrollmentTrendMetrics.growing} showing enrollment growth.`,
        trend: getTrend(enrollmentTrendMetrics),
        metrics: [
          { label: 'Growing', value: formatPercentage(enrollmentTrendMetrics.growingPercent) },
          { label: 'Stable', value: formatPercentage(enrollmentTrendMetrics.stablePercent) },
          { label: 'Declining', value: formatPercentage(enrollmentTrendMetrics.decliningPercent) }
        ],
        // Include "newer schools" note only for enrollment trends if there are any
        note: enrollmentTrendMetrics.newerPercent > 0 ? 
          `${Math.round(enrollmentTrendMetrics.newerPercent)}% of schools are newer since 2019` : 
          null
      },
      {
        title: 'School Enrollment Projections',
        subtitle: '2024 through 2029',
        content: `${projectedEnrollmentMetrics.total} schools with projections, ${projectedEnrollmentMetrics.growing} projected to grow through 2029.`,
        trend: getTrend(projectedEnrollmentMetrics),
        metrics: [
          { label: 'Growing', value: formatPercentage(projectedEnrollmentMetrics.growingPercent) },
          { label: 'Stable', value: formatPercentage(projectedEnrollmentMetrics.stablePercent) },
          { label: 'Declining', value: formatPercentage(projectedEnrollmentMetrics.decliningPercent) }
        ]
        // No "newer schools" note for enrollment projections
      },
      {
        title: 'Market Share Analysis',
        subtitle: 'Since 2020',
        content: `${marketShareMetrics.total} schools analyzed, with ${marketShareMetrics.gaining} gaining market share.`,
        trend: getMarketShareTrend(marketShareMetrics),
        metrics: [
          { label: 'Gaining', value: formatPercentage(marketShareMetrics.gainingPercent) },
          { label: 'Stable', value: formatPercentage(marketShareMetrics.stablePercent) },
          { label: 'Losing', value: formatPercentage(marketShareMetrics.losingPercent) }
        ],
        // Include "newer schools" note only for market share analysis if there are any
        note: marketShareMetrics.newerPercent > 0 ? 
          `${Math.round(marketShareMetrics.newerPercent)}% of schools are newer since 2019` : 
          null
      }
    ];
  }, [filteredSchools]);

  const getInsightSection = (index) => {
    switch(index) {
      case 0: return 'population-trends';
      case 1: return 'population-projections';
      case 2: return 'enrollment-trends';
      case 3: return 'enrollment-projections';
      case 4: return 'market-share';
      default: return 'population-trends';
    }
  };

  const adaptedSchoolsForComponents = useMemo(() => {
    if (!filteredSchools?.length) return [];
    
    return filteredSchools.map(school => ({
      ...school,
      // Add minimum properties needed for map
      latitude: school.latitude || 0,
      longitude: school.longitude || 0,
      
      // Add placeholders for table component
      enrollment_by_grade: {
        current: {},  // Backend now provides enrollment.current instead
        comparison: {} // Backend now provides enrollment.past instead
      },
      esriData: {
        // Minimal data structure to prevent errors
        ages: {'4_17': {}}
      },
      enrollmentProjections: null,
      // Use category from backend if available
      category: school.is_newer ? 'NEWER_STABLE' : 'ESTABLISHED'
    }));
  }, [filteredSchools]);

  return (
    <PageLayout
      data-tool="district-analysis"
      title="All Schools Analysis"
      description={`Analyze trends in enrollment, demographics, and market share across ${filteredSchools?.length.toLocaleString() || 0} schools`}
    >
      <LoadingErrorWrapper
        loading={districtLoading}
        error={error}
        initialLoading={!districtData}
        loadingComponent={<DistrictAnalysisLoading />}
        skeletonCount={4}
        loadingStep={loadingStep}
      >
        <DistrictAnalysisFilters
          filters={filters}
          onFilterChange={handleFilterChange}
          availableFilters={availableFilters}
          onReset={resetFilters}
        />
        <Box sx={{ height: '100%' }}>
          <Grid container spacing={0} sx={{
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            width: '100%',
            margin: 0,
            flexWrap: 'nowrap',
            minWidth: 0
          }}>
            {/* Headlines Column */}
            <Grid item xs={12} lg="auto" sx={{
              width: { lg: '360px' },
              flexShrink: 0,
              pr: { lg: 2 },
              minWidth: { lg: '360px' }
            }}>
              <Stack spacing={2}>
              {headlines.map((headline, index) => (
                <HeadlineBox
                  key={index}
                  title={headline.title}
                  subtitle={headline.subtitle}
                  trend={headline.trend}
                  metrics={headline.metrics}
                  note={headline.note}
                  totalSchools={filteredSchools?.length || 0}
                  isSelected={selectedHeadline === index}
                  onClick={() => {
                    setSelectedHeadline(index);
                    setToolAndSection('district-analysis', getInsightSection(index));
                  }}
                />
              ))}
              </Stack>
            </Grid>
  
            {/* Map and Table Column */}
            <Grid item xs={12} lg sx={{
              flexGrow: 1,
              width: '100%',
              mt: { xs: 3, lg: 1 },
              minWidth: 0,
              overflow: 'visible',
            }}>
              <Box sx={{ width: '100%' }}>
                {/* Map Container */}
                <Box sx={{ 
                  height: '400px',
                  width: '100%',
                  mb: 3,
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  overflow: 'hidden'
                }}>
                  <DistrictMap
                    schools={adaptedSchoolsForComponents}
                    onSchoolSelect={(school) => {
                      navigate(`/school-summary?school=${school.ncessch}`);
                    }}
                    initialZoom={11}
                    selectedHeadline={selectedHeadline}
                  />
                </Box>
  
                {/* Table Container */}
                <Box>
                <DistrictAnalysisTable
                    schools={adaptedSchoolsForComponents}
                    selectedHeadline={selectedHeadline}
                    onSchoolSelect={(school) => {
                      navigate(`/school-summary?school=${school.ncessch}`);
                    }}
                    isCollapsed={tableCollapsed}
                    onToggleCollapse={() => setTableCollapsed(!tableCollapsed)}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </LoadingErrorWrapper>
    </PageLayout>
  );
};

export default DistrictAnalysis;