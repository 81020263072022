import React from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  CardActionArea,
  Stack,
  Divider,
  Skeleton,
  alpha
} from '@mui/material';
import { TrendingFlat } from '@mui/icons-material';

export const HeadlineBoxSkeleton = ({ isSelected = false }) => {
  return (
    <Card 
      elevation={isSelected ? 2 : 0}
      sx={{
        border: '1px solid',
        borderColor: isSelected ? 'primary.main' : 'divider',
        bgcolor: isSelected ? 'grey.50' : 'background.paper',
        transition: 'all 0.2s ease-in-out',
        width: '100%',
        '&:hover': {
          borderColor: isSelected ? 'primary.main' : 'primary.light',
          bgcolor: isSelected ? alpha('#fff', 0.9) : alpha('#fff', 0.7)
        }
      }}
    >
      <CardActionArea>
        <CardContent sx={{ pb: '8px !important' }}>
          <Stack spacing={1}>
            {/* Header with icon and title */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TrendingFlat color="action" />
              <Skeleton variant="text" width="80%" height={28} />
            </Box>

            <Divider />

            {/* Metrics */}
            <Box sx={{ 
              display: 'flex', 
              gap: 3,
              justifyContent: 'space-between',
              px: 1
            }}>
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}>
                <Skeleton variant="text" width={60} height={14} />
                <Skeleton variant="text" width={40} height={20} />
              </Box>
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
              }}>
                <Skeleton variant="text" width={60} height={14} />
                <Skeleton variant="text" width={40} height={20} />
              </Box>
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export const HeadlinesColumnSkeleton = () => {
  return (
    <Box sx={{ 
      '& > :not(:last-child)': {
        mb: 2
      }
    }}>
      {/* Show 5 headline skeletons */}
      {[...Array(5)].map((_, index) => (
        <HeadlineBoxSkeleton 
          key={index} 
          isSelected={index === 0} 
        />
      ))}
    </Box>
  );
};