import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import DistrictMapMarkers from './DistrictMapMarkers';
import DistrictMapPopup from './DistrictMapPopup';
import DistrictMapOverlay from './DistrictMapOverlay';
import DistrictMapExpanded from './DistrictMapExpanded';
import { 
  Box, 
  Button, 
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  Fullscreen as FullscreenIcon,
  Map as MapIcon,
  InfoOutlined
} from '@mui/icons-material';

// Map bounds fitting component
const MapBoundsFitter = ({ schools }) => {
  const map = useMap();
  
  useEffect(() => {
    if (schools.length > 0) {
      const bounds = L.latLngBounds(schools.map(school => [school.latitude, school.longitude]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [schools, map]);
  
  return null;
};

const DistrictMap = ({ 
  schools, 
  onSchoolSelect,
  height = "100%",
  width = "100%",
  initialZoom = 4,
  selectedHeadline = 0,
  title = "All Schools" 
}) => {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [expandedModalOpen, setExpandedModalOpen] = useState(false);

  const handleSchoolClick = (school) => {
    setSelectedSchool(school);
  };

  const handleClosePopup = () => {
    setSelectedSchool(null);
  };

  const handleExpandMap = () => {
    setExpandedModalOpen(true);
  };

  return (
    <div style={{ position: 'relative', height, width, display: 'flex', flexDirection: 'column' }}>
      {/* Header section */}
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <MapIcon sx={{ color: 'primary.main' }} />
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            variant="contained"
            size="small"
            onClick={handleExpandMap}
            endIcon={<FullscreenIcon fontSize="small" />}
          >
            Expand View
          </Button>
          <Tooltip title="View geographic distribution of schools">
            <IconButton size="small">
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
  
      {/* Map container */}
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <MapContainer 
          style={{ height: '100%', width: '100%' }}
          zoom={initialZoom}
          center={[39.8283, -98.5795]}
          zoomControl={false}
        >
          <TileLayer
            url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
          />
          <MapBoundsFitter schools={schools} />
          <DistrictMapMarkers 
            schools={schools}
            selectedHeadline={selectedHeadline}
            onSchoolSelect={handleSchoolClick}
          />
          {selectedSchool && (
            <DistrictMapOverlay position={[selectedSchool.latitude, selectedSchool.longitude]}>
              <DistrictMapPopup
                school={selectedSchool}
                onClose={handleClosePopup}
                onSelectSchool={onSchoolSelect}
              />
            </DistrictMapOverlay>
          )}
        </MapContainer>
      </Box>
  
      {/* Expanded Map Modal */}
      <DistrictMapExpanded
        open={expandedModalOpen}
        onClose={() => setExpandedModalOpen(false)}
        schools={schools}
        onSchoolSelect={onSchoolSelect}
        selectedHeadline={selectedHeadline}
      />
    </div>
  );
};

export default DistrictMap;