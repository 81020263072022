import LoadingErrorWrapper from '../shared_components/loadingErrorWrapper';
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { 
  Box, 
  Grid, 
  Typography, 
  Link,
  Chip
} from '@mui/material';
import { useLocation } from 'react-router-dom';


import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

// Import visualization components
import ProjectXProjectionsChart from '../projections_explorer/dashboard/ProjectXProjectionsChart';
import CompXCommunityPlot from '../competitive_explorer/community/CompXCommunityPlot';
import UnifiedSchoolMap from '../shared_components/unifiedSchoolMap';
import GeoXNearbyMapPopup from '../geographic_explorer/nearby/GeoXNearbyMapPopup';
import GeoXEnrollmentCompositionChanges from '../geographic_explorer/enrollment/GeoXEnrollmentCompositionChanges';
import GeoXCommunityTrends from '../geographic_explorer/community/GeoXCommunityTrends';
import GeoXCommunityDemographics from '../geographic_explorer/community/GeoXCommunityDemographics';

// Shared Components
import PageLayout from '../shared_components/pageLayout';
import InitialSchoolSelector from '../shared_components/initialSchoolSelector';
import withIndexedDBErrorHandler from '../shared_components/withIndexedDBErrorHandler';
import { useInsight } from '../../contexts/InsightContext';

// Display Components
import SchoolSummaryFilters from './SchoolSummaryFilters';
import MarketShareAnalysis from './display_components/MarketShareAnalysis';
import DemographicAnalysis from './display_components/DemographicAnalysis';
import ProjectionsAnalysis from './display_components/ProjectionsAnalysis';
import PopulationAnalysis from './display_components/PopulationAnalysis';
import ComparisonAnalysis from './display_components/ComparisonAnalysis';
import SimplePopulationTable from './display_components/SimplePopulationTable';
import { 
  PopulationTrendsSkeleton,
  ProjectionsSkeleton,
  MarketShareSkeleton,
  ComparisonSkeleton,
  DemographicsSkeleton
} from './loading_components/SkeletonLoaders';
import { HeadlinesColumnSkeleton } from './loading_components/HeadlineSkeletons';
import { HeadlineBoxWithLoading } from './loading_components/HeadlineLoadingComponents';

// Store
import useSchoolStore from '../../stores/schoolStore';
import useSchoolMetricsStore from '../../stores/schoolMetricsStore';

// Cache and API
import { useTeamSchoolCache } from '../../hooks/teamCacheService';
import { schoolsApi } from '../../utils/apiService';

// Utils
import { 
  filterSchools,
  calculateEnrollmentTrends,
  getSchoolGrades,
  calculateEnrollment,
  hasGradeOverlap,
  calculateGradeFilteredPopulation,
  formatGrade 
} from '../../utils/schoolUtils';
import { calculateMarketShareStats } from '../../utils/marketShareUtils';
import chartAnimationState from '../../utils/chartAnimationControl';


// Auth
import { useAuth } from '../../auth/AuthContext';

const SchoolSummary = () => {

  const getOrdinalSuffix = (number) => {
    const j = number % 10;
    const k = number % 100;
    if (j === 1 && k !== 11) return 'st';
    if (j === 2 && k !== 12) return 'nd';
    if (j === 3 && k !== 13) return 'rd';
    return 'th';
  };

  // State declarations
  const [selectedDriveTime, setSelectedDriveTime] = useState(10);
  const [selectedHeadline, setSelectedHeadline] = useState(0);
  const [focusedSchool, setFocusedSchool] = useState(null);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [governanceFilter, setGovernanceFilter] = useState('All');
  const [schoolTypeFilter, setSchoolTypeFilter] = useState('All');

  const setSchoolMetrics = useSchoolMetricsStore(state => state.setSchoolMetrics);

  const [sectionLoadingStates, setSectionLoadingStates] = useState({
    population: { loading: false, metricsLoading: false },
    projections: { loading: false, metricsLoading: false },
    marketShare: { loading: false, metricsLoading: false },
    comparison: { loading: false, metricsLoading: false },
    demographics: { loading: false, metricsLoading: false }
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const schoolId = params.get('school');
  const currentSchoolIdRef = useRef(null);


  // Get data from team school cache
  const { 
    schools, 
    loading: schoolsLoading, 
  } = useTeamSchoolCache();

  // Get data from school store
  const {
    selectedSchool,
    esriData,
    error,
    nearbySchools,
    schoolsEnrollmentData,
    enrollmentProjections
  } = useSchoolStore();

  const isSectionDataLoaded = useCallback((sectionIndex) => {
  switch (sectionIndex) {
    case 0: // Population
      return !!esriData;
    case 1: // Projections
      return !!enrollmentProjections;
    case 2: // Market Share
      return !!esriData && schoolsEnrollmentData.length > 0;
    case 3: // Comparison
      return !!nearbySchools && schoolsEnrollmentData.length > 0;
    case 4: // Demographics
      return !!esriData && schoolsEnrollmentData.length > 0;
    default: 
      return false;
  }
}, [esriData, enrollmentProjections, nearbySchools, schoolsEnrollmentData.length]);
  

  // Improved isSectionLoading function
  const isSectionLoading = useCallback((index) => {
    const sectionKey = getInsightSection(index);
    return sectionLoadingStates[sectionKey]?.loading || 
           sectionLoadingStates[sectionKey]?.metricsLoading;
  }, [sectionLoadingStates]);

  // Helper function to check if metrics for a section are loading
  const areMetricsLoading = (index) => {
    const sectionKey = getInsightSection(index);
    
    // Consider section metrics loading if explicitly marked as loading
    // OR if we don't have the metrics data yet
    if (sectionLoadingStates[sectionKey]?.metricsLoading === true) {
      return true;
    }
    
    // Also check if we have actual metrics data
    switch(index) {
      case 0: // Population
        return !populationTotals?.current;
      case 1: // Projections
        return !enrollmentProjections?.actual_enrollment;
      case 2: // Market Share
        return !marketShareData?.current;
      case 3: // Comparison
        return !selectedSchoolTrends?.selectedSchool?.percentChange;
      case 4: // Demographics
        return !selectedSchoolTrends?.largestGroup;
      default:
        return false;
    }
  };

  // Filter schools for comparison (from CompetitiveExplorer)
  const filteredSchools = useMemo(() => {
    if (!selectedSchool || !nearbySchools) return [];
    
    const schoolsForSelectedDriveTime = nearbySchools?.current?.[selectedDriveTime] || [];
    
    const allSchools = [
      { 
        ...selectedSchool,
        drive_time: 0 
      }, 
      ...schoolsForSelectedDriveTime.map(school => ({
        ...school,
        drive_time: selectedDriveTime
      }))
    ];

    return filterSchools(
      allSchools,
      {
        selectedGrades,
        governanceFilter,
        schoolTypeFilter
      },
      schoolsEnrollmentData
    );
  }, [
    selectedSchool,
    nearbySchools,
    selectedDriveTime,
    selectedGrades,
    governanceFilter,
    schoolTypeFilter,
    schoolsEnrollmentData
  ]);

  const { user } = useAuth();

  const { setToolAndSection } = useInsight();

  useEffect(() => {
    if (schools.length === 0) {
      useSchoolStore.getState().fetchSchools();
    }
  }, [schools.length]);

  // Handle school selection from URL
  useEffect(() => {
    if (schools.length > 0 && schoolId) {
      // Use ref to track if we've already processed this schoolId
      if (currentSchoolIdRef.current !== schoolId) {
        const school = schools.find(s => s.ncessch === schoolId);
        if (school) {
          currentSchoolIdRef.current = schoolId;
          useSchoolStore.getState().selectSchool(school);
          
          // Reset loading states when explicitly changing schools
          setSectionLoadingStates({
            population: { loading: false, metricsLoading: false },
            projections: { loading: false, metricsLoading: false },
            marketShare: { loading: false, metricsLoading: false },
            comparison: { loading: false, metricsLoading: false },
            demographics: { loading: false, metricsLoading: false }
          });
        }
      }
    }
  }, [schoolId, schools]);

  useEffect(() => {
    if (selectedSchool) {
      const controller = new AbortController();
      const signal = controller.signal;
      
      // Store the section we're currently loading
      const currentSection = getInsightSection(selectedHeadline);

      // Reset loading state when school changes
        setSectionLoadingStates(prev => ({
          ...prev,
          [currentSection]: {
            loading: false,
            metricsLoading: false
          }
        }));
      
      const loadSectionData = async () => {
        try {
          // First, set metrics as loading immediately
          setSectionLoadingStates(prev => ({
            ...prev,
            [currentSection]: { 
              ...prev[currentSection],
              metricsLoading: true 
            }
          }));
          
          // Then set full content loading after a short delay
          // Only if data isn't already loaded
          if (!isSectionDataLoaded(selectedHeadline)) {
            setTimeout(() => {
              setSectionLoadingStates(prev => ({
                ...prev,
                [currentSection]: { 
                  ...prev[currentSection], 
                  loading: true 
                }
              }));
            }, 50);
          }
    
          const startTime = performance.now();
    
          // Only load data for the current section
          switch (selectedHeadline) {
            case 0: // Population Trends
              if (!esriData) {
                await schoolsApi.getMarketShareData(selectedSchool.ncessch, { signal });
              }
              break;
  
            case 1: // Projected Enrollment
              if (!enrollmentProjections) {
                await schoolsApi.getEnrollmentProjections(selectedSchool.ncessch, { signal });
              }
              break;
  
            case 2: // Market Share Analysis
              await Promise.all([
                !esriData && schoolsApi.getMarketShareData(selectedSchool.ncessch, { signal }),
                !schoolsEnrollmentData.length && schoolsApi.getSchoolsEnrollmentData(
                  [selectedSchool.ncessch],
                  selectedSchool.ncessch,
                  { signal }
                )
              ].filter(Boolean));
              break;
  
            case 3: // Area School Comparison
              if (!nearbySchools || !schoolsEnrollmentData.length) {
                const nearbyData = await schoolsApi.getAllNearbySchools(selectedSchool.ncessch, { signal });
                if (nearbyData && !signal.aborted) {
                  const schoolIds = new Set([
                    selectedSchool.ncessch,
                    ...Object.values(nearbyData.current || {})
                      .flatMap(schools => schools.map(s => s.ncessch))
                  ]);
                  await schoolsApi.getSchoolsEnrollmentData(
                    Array.from(schoolIds),
                    selectedSchool.ncessch,
                    { signal }
                  );
                }
              }
              break;
  
            case 4: // Demographic Composition
              await Promise.all([
                !esriData && schoolsApi.getMarketShareData(selectedSchool.ncessch, { signal }),
                !schoolsEnrollmentData.length && schoolsApi.getSchoolsEnrollmentData(
                  [selectedSchool.ncessch],
                  selectedSchool.ncessch,
                  { signal }
                )
              ].filter(Boolean));
              break;

              default:
              // Either do nothing or handle unexpected cases
              console.warn(`Unexpected headline index: ${selectedHeadline}`);
              break;
          }
  
          // Calculate how long the data loading took
          const loadTime = performance.now() - startTime;
        
          if (!signal.aborted) {
            // REPLACE THIS SECTION with the new code:
            
            // After data fetch, check if data is actually available before turning off loading
            if (isSectionDataLoaded(selectedHeadline)) {
              // First turn off metrics loading
              setSectionLoadingStates(prev => ({
                ...prev,
                [currentSection]: { 
                  ...prev[currentSection],
                  metricsLoading: false 
                }
              }));
              
              // Keep skeleton visible longer to ensure data is processed
              const minDisplayTime = Math.max(1200 - loadTime, 800);
              
              if (minDisplayTime > 0) {
                await new Promise(resolve => setTimeout(resolve, minDisplayTime));
              }
              
              if (!signal.aborted) {
                setSectionLoadingStates(prev => ({
                  ...prev,
                  [currentSection]: { 
                    ...prev[currentSection],
                    loading: false 
                  }
                }));
              }
            } else {
              // If data still isn't available after loading, keep loading state
              console.debug(`Data for section ${currentSection} not fully available yet`);
            }
            // END of replacement section
          }
        } catch (error) {
          // Error handling
          if (error.name === 'AbortError') {
            return;
          }
          
          console.error('Error loading section data:', error);
          
          if (!signal.aborted) {
            setSectionLoadingStates(prev => ({
              ...prev,
              [currentSection]: { 
                metricsLoading: false,
                loading: false 
              }
            }));
          }
        }
      };
    
      loadSectionData();
      
      return () => {
        controller.abort();
      };
    }
  }, [
    selectedSchool, 
    selectedHeadline, 
    esriData, 
    enrollmentProjections, 
    nearbySchools,
    schoolsEnrollmentData.length,
    isSectionDataLoaded
  ]);

  // Calculate enrollment trends (from GeoXNearbyHeadlines)
  const selectedSchoolTrends = useMemo(() => {
    if (!selectedSchool || !schoolsEnrollmentData) return null;
    
    const selectedSchoolData = schoolsEnrollmentData.find(s => s.ncessch === selectedSchool.ncessch);
    return calculateEnrollmentTrends({
      selectedSchool,
      selectedSchoolData,
      filteredSchools,
      schoolsEnrollmentData,
      selectedGrades,
      governanceFilter
    });
  }, [selectedSchool, schoolsEnrollmentData, filteredSchools, selectedGrades, governanceFilter]);

  // School data for nearby comparisons
  const schoolData = useMemo(() => {
    if (!selectedSchool || !nearbySchools) {
      return [];
    }
    
    const driveTime = selectedDriveTime;
    const schoolsForSelectedDriveTime = nearbySchools?.current?.[driveTime] || [];
    
    // Filter out schools with no grade overlap and missing data
    const validSchools = schoolsForSelectedDriveTime
      .filter(school => {
        // First check it's not the selected school
        if (school.ncessch === selectedSchool.ncessch) return false;
        
        // Then check grade overlap
        const hasOverlap = hasGradeOverlap(school.grade_range, selectedGrades.join(', '));
        if (!hasOverlap) return false;
        
        // Then check for required data
        const schoolData = schoolsEnrollmentData.find(d => d.ncessch === school.ncessch);
        return schoolData && schoolData.enrollment_by_grade?.current;
      });

    return [
      { 
        ...selectedSchool,
        drive_time: 0,
        isMainSchool: true,
        isFocused: false,
        currentEnrollment: calculateEnrollment(
          schoolsEnrollmentData.find(d => d.ncessch === selectedSchool.ncessch)
            ?.enrollment_by_grade?.current || {},
          selectedGrades
        ),
        previousEnrollment: calculateEnrollment(
          schoolsEnrollmentData.find(d => d.ncessch === selectedSchool.ncessch)
            ?.enrollment_by_grade?.comparison || {},
          selectedGrades
        )
      }, 
      ...validSchools.map(school => {
        const schoolEnrollmentData = schoolsEnrollmentData.find(d => d.ncessch === school.ncessch);
        return {
          ...school,
          drive_time: driveTime,
          isMainSchool: false,
          isFocused: focusedSchool?.ncessch === school.ncessch,
          currentEnrollment: calculateEnrollment(
            schoolEnrollmentData?.enrollment_by_grade?.current || {},
            selectedGrades
          ),
          previousEnrollment: calculateEnrollment(
            schoolEnrollmentData?.enrollment_by_grade?.comparison || {},
            selectedGrades
          )
        };
      })
    ];
  }, [selectedSchool, nearbySchools, selectedDriveTime, focusedSchool, schoolsEnrollmentData, selectedGrades]);

  // Effect to set initial grades based on selected school
  useEffect(() => {
    if (selectedSchool?.grade_range) {
      const schoolGrades = getSchoolGrades(selectedSchool.grade_range);
      setSelectedGrades(schoolGrades);
    }
  }, [selectedSchool]);

  // Calculate community data similar to GeoXCommunityHeadlines
  const {
    populationTotals,
    currentVsPastChange,
  } = useMemo(() => {
    const totals = calculateGradeFilteredPopulation({
      esriData,
      selectedDriveTime,
      selectedGrades
    });
  
    const change = totals.past > 0 ? 
      ((totals.current - totals.past) / totals.past) * 100 : 0;
  
    return {
      populationTotals: totals,
      currentVsPastChange: change
    };
  }, [esriData, selectedDriveTime, selectedGrades]);

  const totalChange = useMemo(() => {
    return ((populationTotals.future - populationTotals.past) / populationTotals.past) * 100;
  }, [populationTotals]);

  // Market share calculation using calculateMarketShareStats
  const marketShareData = useMemo(() => {
    if (!selectedSchool || !schoolsEnrollmentData || !esriData) return null;
    
    const selectedSchoolData = schoolsEnrollmentData.find(d => d.ncessch === selectedSchool.ncessch);
    if (!selectedSchoolData) return null;

    const marketShareStats = calculateMarketShareStats({
      type: 'population',  // Use population-based calculation
      school: selectedSchool,
      schoolData: selectedSchoolData,
      selectedGrades,
      esriData,
      selectedDriveTime
    });

    // Get population change
    const totals = calculateGradeFilteredPopulation({
      esriData,
      selectedDriveTime,
      selectedGrades
    });

    const populationChange = totals.current > 0 && totals.past > 0 ?
      ((totals.current - totals.past) / totals.past) * 100 : 0;

    return {
      current: marketShareStats.current,
      past: marketShareStats.past,
      change: marketShareStats.change,
      populationChange,
      metadata: {
        ...marketShareStats.metadata,
        populationTotals: {
          past: totals.past,
          current: totals.current
        }
      }
    };
  }, [selectedSchool, schoolsEnrollmentData, esriData, selectedGrades, selectedDriveTime]);

  // Headlines data
  const populationHeadline = useMemo(() => ({
    title: 'Population Trends',
    content: `${Math.abs(populationTotals.current).toLocaleString()} school-age children live within a ${selectedDriveTime}-minute drive, which is ${currentVsPastChange >= 0 ? 'up' : 'down'} ${Math.abs(currentVsPastChange).toFixed(0)}% since 2020.`,
    trend: Math.abs(currentVsPastChange) < 5 ? 'neutral' : currentVsPastChange > 0 ? 'up' : 'down',
    metrics: [
      {
        label: 'Current Population',
        value: populationTotals.current.toLocaleString()
      },
      {
        label: '2020-24 Change',
        value: `${currentVsPastChange >= 0 ? '+' : ''}${Math.round(currentVsPastChange)}%`
      }
    ]
  }), [populationTotals, currentVsPastChange, selectedDriveTime]);

  // Projections headline
  const projectionsHeadline = useMemo(() => {
    const actualYears = Object.keys(enrollmentProjections?.actual_enrollment || {}).sort();
    const latestActualYear = actualYears[actualYears.length - 1];
    
    if (!latestActualYear || !enrollmentProjections?.actual_enrollment[latestActualYear]) {
      return {
        title: 'Projected Enrollment',
        content: 'View enrollment projections and growth scenarios for your school.',
        trend: 'neutral',
        metrics: [
          { label: 'Current Enrollment', value: 'N/A' },
          { label: 'Future Projection', value: 'N/A' }
        ]
      };
    }
  
    const currentYearData = enrollmentProjections.actual_enrollment[latestActualYear];
    
    // Get all grades and filter out PreK
    const allGrades = Object.keys(currentYearData);
    const nonPreKGrades = allGrades
      .filter(grade => {
        const lowerGrade = grade.toLowerCase();
        return !(lowerGrade.includes('pre') || lowerGrade.includes('pk'));
      })
      .map(grade => {
        // Convert "Grade X" to "X" for numeric grades
        if (grade === 'Kindergarten') return grade;
        return grade.replace('Grade ', '');
      });
  
    // Calculate current year total
    const latestActualTotal = calculateEnrollment(currentYearData, nonPreKGrades);
  
    // Get projection data
    const projectionYears = Object.keys(enrollmentProjections?.projections?.median || {}).sort();
    const lastProjectionYear = projectionYears[projectionYears.length - 1];
    const projectionData = lastProjectionYear ? 
      enrollmentProjections.projections.median[lastProjectionYear] : null;
    
    // Calculate projection total using the same grade list
    const lastProjectionTotal = projectionData ? 
      calculateEnrollment(projectionData, nonPreKGrades) : null;
  
    // Get starting grade (including PreK if it exists)
    const startingGrade = allGrades.length > 0 ? 
      formatGrade(allGrades.sort((a, b) => {
        const aIsPK = a.toLowerCase().includes('pre') || a.toLowerCase().includes('pk');
        const bIsPK = b.toLowerCase().includes('pre') || b.toLowerCase().includes('pk');
        if (aIsPK) return -1;
        if (bIsPK) return 1;
        if (a === 'Kindergarten') return -1;
        if (b === 'Kindergarten') return 1;
        return a.localeCompare(b);
      })[0]) : null;
  
    return {
      title: 'Projected Enrollment',
      content: `${startingGrade ? `Starting with ${startingGrade}, ` : ''}View enrollment projections and growth scenarios for your school.`,
      trend: lastProjectionTotal && latestActualTotal ? 
            (lastProjectionTotal > latestActualTotal ? 'up' : 'down') : 'neutral',
      metrics: [
        {
          label: `${latestActualYear} Enrollment`,
          value: latestActualTotal?.toLocaleString() || 'N/A'
        },
        {
          label: `${lastProjectionYear} Projection`,
          value: lastProjectionTotal?.toLocaleString() || 'N/A'
        }
      ]
    };
  }, [enrollmentProjections]);

  const marketShareHeadline = useMemo(() => ({
    title: 'Market Share Analysis',
    content: selectedSchool ? `${selectedSchool.name} enrolls ${
      selectedSchoolTrends?.selectedSchool?.newEnrollment?.toLocaleString() || 'N/A'
    } students, representing ${
      Math.round(marketShareData?.current) || 'N/A'
    }% of area children${
      marketShareData?.metadata?.isNewOrNewer
        ? ` (opened in ${selectedSchoolTrends?.selectedSchool?.timeframe?.endLabel || 'N/A'})`
        : marketShareData?.current != null && marketShareData?.past != null
          ? `, ${marketShareData.current > marketShareData.past ? 'up' : 'down'} from ${Math.round(marketShareData.past)}% in 2020` +
            (marketShareData.change != null ? ` (${marketShareData.change > 0 ? '+' : '-'}${Math.round(Math.abs(marketShareData.change))}% change)` : '')
          : ''
    }.` : 'Select a school to view market share analysis.',
    trend: !selectedSchool ? 'neutral' :
          !marketShareData?.change ? 'neutral' :
          marketShareData.change > 0 ? 'up' : 
          marketShareData.change < 0 ? 'down' : 'neutral',
    metrics: [
      {
        label: 'Current Share',
        value: `${marketShareData?.current != null ? Math.round(marketShareData.current) : 'N/A'}%`
      },
      {
        label: 'Share Change',
        value: !selectedSchool ? 'N/A' :
              marketShareData?.metadata?.isNewOrNewer
                ? 'N/A (newer school)'
                : marketShareData?.change != null
                  ? `${marketShareData.change > 0 ? '+' : '-'}${Math.round(Math.abs(marketShareData.change))}%`
                  : 'N/A'
      }
    ]
  }), [selectedSchool, selectedSchoolTrends, marketShareData]);

  const comparisonHeadline = useMemo(() => {
    const getRank = () => {
      if (!selectedSchool || !schoolData.length) return null;
      const sortedSchools = [...schoolData].sort((a, b) => {
        const changeA = a.currentEnrollment && a.previousEnrollment ? 
          ((a.currentEnrollment - a.previousEnrollment) / a.previousEnrollment) * 100 : -Infinity;
        const changeB = b.currentEnrollment && b.previousEnrollment ? 
          ((b.currentEnrollment - b.previousEnrollment) / b.previousEnrollment) * 100 : -Infinity;
        return changeB - changeA;
      });
      const rank = sortedSchools.findIndex(s => s.ncessch === selectedSchool.ncessch) + 1;
      return rank;
    };

    const rank = getRank();

    return {
      title: 'Area School Comparison',
      content: selectedSchool ? 
        `${selectedSchool.name}'s enrollment change of ${Math.round(selectedSchoolTrends?.selectedSchool?.percentChange || 0)}% ranks ${rank}${getOrdinalSuffix(rank)} out of ${schoolData.length} nearby schools between 2019-20 and 2023-24.` :
        'School comparison data not available.',
      trend: selectedSchoolTrends?.selectedSchool?.percentChange > 0 ? 'up' : 
            selectedSchoolTrends?.selectedSchool?.percentChange < 0 ? 'down' : 'neutral',
      metrics: [
        {
          label: 'Enrollment Change',
          value: selectedSchoolTrends?.selectedSchool?.percentChange ? 
            `${selectedSchoolTrends.selectedSchool.percentChange > 0 ? '+' : ''}${Math.round(selectedSchoolTrends.selectedSchool.percentChange)}%` :
            'N/A (Newer School)'
        },
        {
          label: 'Area Rank',
          value: rank ? `${rank} of ${schoolData.length}` : 'N/A'
        }
      ]
    };
  }, [selectedSchool, selectedSchoolTrends, schoolData]);

  const demographicsHeadline = useMemo(() => ({
    title: 'Demographic Composition',
    content: selectedSchoolTrends?.largestGroup ? 
      `The selected grades at this school serve a ${Math.round(Math.abs(selectedSchoolTrends.demographicDifference || 0))}% ${selectedSchoolTrends.demographicDifference >= 0 ? 'higher' : 'lower'} percentage of ${selectedSchoolTrends.largestGroup} students compared to nearby schools.` :
      `Demographic comparison data is not available.`,
    trend: selectedSchoolTrends?.largestGroupChange > 0 ? 'up' : 
          selectedSchoolTrends?.largestGroupChange < 0 ? 'down' : 'neutral',
    metrics: [
      {
        label: 'Largest Group',
        value: selectedSchoolTrends?.largestGroup || 'N/A'
      },
      {
        label: '5-Year Change',
        value: selectedSchoolTrends?.largestGroupChange ? 
          `${selectedSchoolTrends.largestGroupChange > 0 ? '+' : ''}${Math.round(selectedSchoolTrends.largestGroupChange)}%` :
          'N/A (newer school)'
      }
    ]
  }), [selectedSchoolTrends]);

  // Final headlines array memo
  const headlines = useMemo(() => [
    populationHeadline,
    projectionsHeadline,
    marketShareHeadline,
    comparisonHeadline,
    demographicsHeadline
  ], [
    populationHeadline,
    projectionsHeadline,
    marketShareHeadline,
    comparisonHeadline,
    demographicsHeadline
  ]);

  const getInsightSection = (index) => {
    switch(index) {
      case 0: return 'population';
      case 1: return 'projections';
      case 2: return 'market-share';
      case 3: return 'comparison';
      case 4: return 'demographics';
      default: return 'population';
    }
  };

  // Determine if section should have animation
  const getShouldAnimateSection = (sectionIndex) => {
    const sectionKey = getInsightSection(sectionIndex);
    return chartAnimationState.shouldAnimate(sectionKey, selectedSchool?.ncessch);
  };

  const handleHeadlineSelect = useCallback((index) => {
    if (selectedHeadline === index) return;
    
    // Clear loading states for previous section
    const prevSection = getInsightSection(selectedHeadline);
    setSectionLoadingStates(prev => ({
      ...prev,
      [prevSection]: { loading: false, metricsLoading: false }
    }));
    
    // Set loading for new section only if needed
    const newSection = getInsightSection(index);
    if (!isSectionDataLoaded(index)) {
      setSectionLoadingStates(prev => ({
        ...prev,
        [newSection]: { loading: true, metricsLoading: true }
      }));
    }
    
    setSelectedHeadline(index);
    setToolAndSection('school-summary', newSection);
  }, [selectedHeadline, setToolAndSection, isSectionDataLoaded]);
  
  const handleSchoolChange = useCallback((event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      // Reset to Population Trends headline when changing schools
      setSelectedHeadline(0);
      // Reset section loading states
      setSectionLoadingStates({
        population: { loading: false, metricsLoading: false },
        projections: { loading: false, metricsLoading: false },
        marketShare: { loading: false, metricsLoading: false },
        comparison: { loading: false, metricsLoading: false },
        demographics: { loading: false, metricsLoading: false }
      });
      // Update the tool and section in the Insight context
      setToolAndSection('school-summary', 'population');
      // Select the new school
      useSchoolStore.getState().selectSchool(school);
    }
  }, [schools, setToolAndSection]);
  
  const handleDriveTimeChange = useCallback((event) => {
    setSelectedDriveTime(Number(event.target.value));
  }, []);

  const handleGovernanceFilterChange = (event) => {
    setGovernanceFilter(event.target.value);
  };
  
  const handleSchoolTypeFilterChange = (event) => {
    setSchoolTypeFilter(event.target.value);
  };

  const metricsData = useMemo(() => {
    if (!selectedSchool) return null;
    
    return {
      populationTotals,
      currentVsPastChange,
      marketShareData,
      selectedSchoolTrends,
      enrollmentProjections,
      schoolData,
      filteredSchools
    };
  }, [
    selectedSchool,
    populationTotals,
    currentVsPastChange,
    marketShareData,
    selectedSchoolTrends,
    enrollmentProjections,
    schoolData,
    filteredSchools
  ]);
  
  useEffect(() => {
    if (selectedSchool?.ncessch && selectedDriveTime === 10 && metricsData) {
      setSchoolMetrics(selectedSchool.ncessch, metricsData);
    }
  }, [selectedSchool?.ncessch, selectedDriveTime, metricsData, setSchoolMetrics]);

  return (
    <PageLayout
      data-tool="school-summary"
      title="School Summary"
      description={selectedSchool
        ? "A comprehensive overview of your school's performance and context"
        : "Get a quick snapshot of key metrics for any school. Start by selecting a school."
      }
    >
      {/* Initial loading state for schools list */}
      <LoadingErrorWrapper
        loading={schoolsLoading}
        error={error}
        initialLoading={!schools || !schools.length}
        hideSteps={true}
      >
        {!selectedSchool ? (
          <InitialSchoolSelector
            schools={schools}
            selectedSchool={selectedSchool}
            onSchoolChange={handleSchoolChange}
          />
        ) : (
          <Box>
            {/* Filters always visible */}
            <SchoolSummaryFilters
              schools={schools}
              selectedSchool={selectedSchool}
              selectedDriveTime={selectedDriveTime}
              governanceFilter={governanceFilter}
              schoolTypeFilter={schoolTypeFilter}
              onSchoolChange={handleSchoolChange}
              onDriveTimeChange={handleDriveTimeChange}
              onGovernanceFilterChange={handleGovernanceFilterChange}
              onSchoolTypeFilterChange={handleSchoolTypeFilterChange}
            />
  
            <Box sx={{ mt: 4 }}>
              <Grid 
                container 
                spacing={0}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' },
                  width: '100%',
                  margin: 0,
                  flexWrap: 'nowrap',
                  minWidth: 0
                }}
              >

                {/* Headlines Column - Always visible */}
                <Grid 
                  item 
                  xs={12} 
                  lg="auto" 
                  sx={{
                    width: { lg: '360px' }, 
                    flexShrink: 0,
                    pr: { lg: 2 },
                    minWidth: { lg: '360px' }
                  }}
                >
                  {schoolsLoading ? (
                    <HeadlinesColumnSkeleton />
                  ) : (
                    <Box sx={{ 
                      '& > :not(:last-child)': {
                        mb: 2
                      }
                    }}>
                      {headlines.map((headline, index) => {
                        // Combine loading states - should be loading if either metrics or full content is loading
                        const isLoadingAny = areMetricsLoading(index) || isSectionLoading(index);
                          
                        return (
                          <HeadlineBoxWithLoading
                            key={index}
                            title={headline.title}
                            trend={headline.trend}
                            metrics={headline.metrics}
                            isSelected={selectedHeadline === index}
                            onClick={() => handleHeadlineSelect(index)}
                            isLoading={isLoadingAny}
                          />
                        );
                      })}
                    </Box>
                  )}
                </Grid>
  
                {/* Visualizations Column */}
                <Grid 
                  item 
                  xs={12}
                  lg  
                  sx={{
                    flexGrow: 1,
                    width: '100%',
                    mt: { xs: 3, lg: 1 },
                    minWidth: 0,
                    overflow: 'visible'
                  }}
                >
                  {/* Population Section */}
                  {selectedHeadline === 0 && (
                    isSectionLoading(0) ? (
                      <PopulationTrendsSkeleton />
                    ) : (
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        width: '100%',
                        pt: 2
                      }}>
                        <PopulationAnalysis
                          selectedSchool={selectedSchool}
                          populationTotals={populationTotals}
                          currentVsPastChange={currentVsPastChange}
                          selectedDriveTime={selectedDriveTime}
                          selectedGrades={selectedGrades}
                          user={user}
                        />
                        
                        <Box sx={{ 
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 1,
                          p: 0,
                          width: '100%',
                          minWidth: 0,
                          overflow: 'hidden'
                        }}>
                          <GeoXCommunityTrends
                            populationTotals={populationTotals}
                            totalChange={totalChange}
                            selectedDriveTime={selectedDriveTime}
                            animationEnabled={getShouldAnimateSection(0)}
                            cardProps={{
                              elevation: 0,
                              sx: { 
                                height: '100%',
                                boxShadow: 'none'
                              }
                            }}
                          />
                        </Box>

                        <Box sx={{ 
                          mt: 2,
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <Typography variant="caption" color="text.secondary">
                            Source: {' '}
                            <Link 
                              href="https://doc.arcgis.com/en/esri-demographics/latest/reference/methodologies.htm" 
                              target="_blank" 
                              rel="noopener noreferrer"
                            >
                              Esri
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                    )
                  )}
  
                  {/* Projections Section */}
                  {selectedHeadline === 1 && (
                    isSectionLoading(1) ? (
                      <ProjectionsSkeleton />
                    ) : (
                      <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        pt: 2
                      }}>
                        <ProjectionsAnalysis
                          selectedSchool={selectedSchool}
                          enrollmentData={enrollmentProjections}
                          user={user}
                        />
                        
                        <Box sx={{ 
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 1,
                          p: 2,
                          mt: -1,
                          width: '100%',
                          minWidth: 0,
                          overflow: 'hidden'
                        }}>
                          <Box sx={{ mb: 3 }}>
                            <Box sx={{ 
                              display: 'flex', 
                              alignItems: 'center',
                              gap: 1,
                              mb: 1
                            }}>
                              <AutoGraphIcon sx={{ color: 'primary.main' }} />
                              <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
                                Enrollment Trends & Projections
                              </Typography>
                            </Box>
                            <Chip
                              icon={<InfoOutlinedIcon sx={{ fontSize: 16 }} />}
                              label={`Data source: ${enrollmentProjections?.based_on_user_data ? 
                                "Historical and user-inputted data" : 
                                "Historical fall count day enrollment only"}`}
                              color="primary"
                              variant="outlined"
                              size="small"
                            />
                          </Box>

                          <Box sx={{ 
                            display: 'flex', 
                            flexDirection: { xs: 'column', lg: 'row' },
                            gap: 3,
                            width: '100%',
                            minWidth: 0
                          }}>
                            <Box sx={{ 
                              flex: { xs: '1 1 100%', lg: '1 1 70%' },
                              minWidth: 0
                            }}>
                              <ProjectXProjectionsChart
                                school={selectedSchool}
                                enrollmentData={enrollmentProjections}
                                selectedGrades={selectedGrades}
                                timeframe="5-year"
                                view="school-level"
                                targets={{}}
                                selectedScenarios={['low', 'median', 'high']}
                              />
                            </Box>
                          </Box>
                        </Box>

                        <Box sx={{ 
                          mt: 2,
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <Typography variant="caption" color="text.secondary">
                            Source: {' '}
                            <Link href="https://nces.ed.gov/ccd/" target="_blank" rel="noopener noreferrer">
                              NCES 2019-20 through 2023-24
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                    )
                  )}
  
                  {/* Market Share Section */}
                  {selectedHeadline === 2 && (
                    isSectionLoading(2) ? (
                      <MarketShareSkeleton />
                    ) : (
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        width: '100%',
                        pt: 2
                      }}>
                        <MarketShareAnalysis
                          selectedSchool={selectedSchool}
                          selectedSchoolTrends={selectedSchoolTrends}
                          marketShareData={marketShareData}
                          user={user}
                        />
                        
                        <Box sx={{ 
                          p: 0,
                          width: '100%',
                          minWidth: 0,
                          overflow: 'hidden'
                        }}>
                          {selectedSchool ? (
                            <CompXCommunityPlot
                              selectedSchool={selectedSchool}
                              marketShareData={marketShareData}
                              currentVsPastChange={marketShareData?.populationChange}
                              schoolCategory={selectedSchoolTrends?.category}
                              timeframeData={selectedSchoolTrends?.selectedSchool?.timeframe}
                            />
                          ) : (
                            <Typography>No school selected</Typography>
                          )}
                        </Box>

                        <Box sx={{ 
                          mt: 2,
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <Typography variant="caption" color="text.secondary">
                            Sources: {' '}
                            <Link href="https://www.esri.com/en-us/home" target="_blank" rel="noopener noreferrer">
                              Esri
                            </Link>
                            {' and '}
                            <Link href="https://nces.ed.gov/ccd/" target="_blank" rel="noopener noreferrer">
                              NCES 2019-20 through 2023-24
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                    )
                  )}
  
                  {/* Comparison Section */}
                  {selectedHeadline === 3 && (
                    isSectionLoading(3) ? (
                      <ComparisonSkeleton />
                    ) : (
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        width: '100%',
                        pt: 2
                      }}>
                        <ComparisonAnalysis
                          selectedSchool={selectedSchool}
                          selectedSchoolTrends={selectedSchoolTrends}
                          schoolData={schoolData}
                          user={user}
                        />
                        
                        <Box sx={{ 
                          display: 'flex',
                          flexDirection: { xs: 'column', lg: 'row' },
                          gap: 3,
                          width: '100%',
                          minWidth: 0
                        }}>
                          <Box sx={{ 
                            flex: { xs: '1 1 100%', lg: '1 1 70%' },
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 1,
                            p: 2
                          }}>
                            <Box sx={{ mb: 3 }}>
                              <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center',
                                gap: 1,
                                mb: 1
                              }}>
                                <MapsHomeWorkIcon sx={{ color: 'primary.main' }} />
                                <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
                                  School Locations
                                </Typography>
                              </Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  bgcolor: 'rgba(25, 118, 210, 0.1)',
                                  color: 'primary.main',
                                  p: 1,
                                  borderRadius: 1,
                                  fontWeight: 500
                                }}
                              >
                                Click on map markers to view detailed school information
                              </Typography>
                            </Box>

                            <Box sx={{ 
                              height: 400,
                              position: 'relative',
                              overflow: 'hidden'
                            }}>
                              <UnifiedSchoolMap
                                mode="geographic"
                                selectedSchool={selectedSchool}
                                filteredSchools={schoolData}
                                esriData={esriData}
                                visibleDriveTimes={[selectedDriveTime]}
                                schoolsEnrollmentData={schoolsEnrollmentData}
                                governanceFilter={governanceFilter}
                                selectedGrades={selectedGrades}
                                schoolTypeFilter={schoolTypeFilter}
                                PopupComponent={GeoXNearbyMapPopup}
                                focusedSchool={focusedSchool}
                                onSchoolSelect={(ncessch) => setFocusedSchool(
                                  ncessch ? schoolData.find(s => s.ncessch === ncessch) : null
                                )}
                                initialZoom={11}
                              />
                            </Box>
                          </Box>

                          <Box sx={{ 
                            flex: { xs: '1 1 100%', lg: '1 1 50%' },
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 1,
                            p: 2,
                            height: 550,
                            overflow: 'hidden'
                          }}>
                            <SimplePopulationTable 
                              schools={schoolData}
                              selectedSchoolTrends={selectedSchoolTrends}
                              focusedSchool={focusedSchool}
                              onSchoolSelect={(ncessch) => setFocusedSchool(
                                ncessch ? schoolData.find(s => s.ncessch === ncessch) : null
                              )}
                            />
                          </Box>
                        </Box>

                        <Box sx={{ 
                          mt: 2,
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <Typography variant="caption" color="text.secondary">
                            Source: {' '}
                            <Link href="https://nces.ed.gov/ccd/" target="_blank" rel="noopener noreferrer">
                              NCES 2019-20 through 2023-24
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                    )
                  )}
  
                  {/* Demographics Section */}
                  {selectedHeadline === 4 && (
                    isSectionLoading(4) ? (
                      <DemographicsSkeleton />
                    ) : (
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        width: '100%',
                        pt: 2
                      }}>
                        <DemographicAnalysis
                          selectedSchool={selectedSchool}
                          selectedSchoolTrends={selectedSchoolTrends}
                          user={user}
                        />
                        
                        <Box sx={{ 
                          display: 'grid', 
                          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, 
                          gap: 3,
                          mt: -2
                        }}>
                          <Box sx={{ 
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 1,
                            p: 0,
                            mt: 2
                          }}>
                            <GeoXCommunityDemographics
                              esriData={esriData}
                              selectedDriveTime={selectedDriveTime}
                              animationEnabled={getShouldAnimateSection(0)}
                              cardProps={{
                                elevation: 0,
                                sx: { 
                                  height: '100%',
                                  boxShadow: 'none',
                                  border: 'none',
                                  '& .MuiBox-root': {
                                    px: 0
                                  }
                                }
                              }}
                            />
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <GeoXEnrollmentCompositionChanges
                              selectedSchool={selectedSchool}
                              schoolsEnrollmentData={schoolsEnrollmentData}
                              filteredSchools={filteredSchools}
                              selectedGrades={selectedGrades}
                              governanceFilter={governanceFilter}
                              timeframe={selectedSchoolTrends?.selectedSchool?.timeframe}
                              animationEnabled={getShouldAnimateSection(0)}
                            />
                          </Box>
                        </Box>

                        <Box sx={{ 
                          mt: 2,
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <Typography variant="caption" color="text.secondary">
                            Sources: {' '}
                            <Link href="https://www.esri.com/en-us/home" target="_blank" rel="noopener noreferrer">
                              Esri
                            </Link>
                            {' and '}
                            <Link href="https://nces.ed.gov/ccd/" target="_blank" rel="noopener noreferrer">
                              NCES 2019-20 through 2023-24
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                    )
                  )}

                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </LoadingErrorWrapper>
    </PageLayout>
  );
};

const SchoolSummaryWithErrorHandler = withIndexedDBErrorHandler(SchoolSummary);
export default SchoolSummaryWithErrorHandler;