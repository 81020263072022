import React, { useState, useEffect, useCallback, useMemo, useReducer } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Box,
  IconButton
} from '@mui/material';
import {
  Delete as DeleteIcon
} from '@mui/icons-material';
import { schoolsApi } from '../../utils/apiService';
import { parseGradeRange } from '../../utils/schoolUtils';

const GRADE_ORDER = [
'Kindergarten', 
  'Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 
  'Grade 7', 'Grade 8', 'Grade 9', 'Grade 10', 'Grade 11', 'Grade 12'
];

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_GRADE':
      return {
        ...state,
        enrollmentData: {
          ...state.enrollmentData,
          [action.grade]: action.value
        }
      };
    case 'SET_ADDITIONAL_GRADE':
      return {
        ...state,
        additionalGrades: state.additionalGrades.map((grade, i) => 
          i === action.index ? { ...grade, [action.field]: action.value } : grade
        )
      };
    case 'ADD_GRADE':
      return {
        ...state,
        additionalGrades: [...state.additionalGrades, { grade: '', enrollment: '' }]
      };
    case 'REMOVE_GRADE':
      return {
        ...state,
        additionalGrades: state.additionalGrades.filter((_, i) => i !== action.index)
      };
    case 'INIT_FORM':
      return {
        ...state,
        enrollmentData: action.enrollmentData,
        additionalGrades: action.additionalGrades
      };
    default:
      return state;
  }
};

const EnrollmentForm = React.memo(({ 
  school, 
  enrollmentData, 
  validationErrors, 
  additionalGrades,
  onGradeChange,
  onAdditionalGradeChange,
  onRemoveGrade,
  onAddGrade,
  isEdit
}) => {
  const enrollmentOptions = useMemo(() => [
    { value: 'no_longer_offered', label: 'No longer offered' },
    ...Array.from({ length: 999 }, (_, i) => ({ value: i + 1, label: i + 1 }))
  ], []);

  const sortedGrades = useMemo(() => {
    return parseGradeRange(school?.grade_range)
      .map(grade => grade === 'K' ? 'Kindergarten' : 
        !isNaN(grade) ? `Grade ${grade}` : grade);
  }, [school?.grade_range]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {isEdit ? 'Update' : 'Enter'} enrollment data for {school?.name}
        </Typography>
      </Grid>
      {sortedGrades.map((grade) => (
        <Grid item xs={12} sm={6} key={grade}>
          <FormControl fullWidth error={!!validationErrors[grade]}>
            <InputLabel>{grade}</InputLabel>
            <Select
                value={enrollmentData[grade] || ''}
                onChange={(e) => onGradeChange(grade, e)}
                label={grade}
                required
              >
              {enrollmentOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {validationErrors[grade] && (
              <Typography variant="caption" color="error">
                {validationErrors[grade]}
              </Typography>
            )}
          </FormControl>
        </Grid>
      ))}
      {additionalGrades.map((grade, index) => (
        <Grid item xs={12} container spacing={2} key={`additional-${index}`}>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel>Additional Grade</InputLabel>
              <Select
                value={grade.grade}
                onChange={(e) => onAdditionalGradeChange(index, 'grade', e)}
                label="Additional Grade"
              >
                {GRADE_ORDER.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth error={!!validationErrors[`additional-${index}`]}>
              <InputLabel>Enrollment</InputLabel>
              <Select
                  value={grade.enrollment}
                  onChange={(e) => onAdditionalGradeChange(index, 'enrollment', e)}
                  label="Enrollment"
                >
                {enrollmentOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => onRemoveGrade(index)} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      {additionalGrades.length < 3 && (
        <Grid item xs={12}>
          <Button onClick={onAddGrade} variant="outlined">
            Add Grade
          </Button>
        </Grid>
      )}
    </Grid>
  );
});

const ProjectXEnrollmentEditor = ({ 
  open, 
  onClose, 
  school, 
  onSuccess, 
  existingData = null, 
  isEdit = false 
}) => {
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [formState, dispatch] = useReducer(formReducer, {
    enrollmentData: {},
    additionalGrades: []
  });
  

  // Load existing data only when editing and data changes
  useEffect(() => {
    if (isEdit && existingData) {
      const formattedData = {};
      const additional = [];
      
      Object.entries(existingData.grades || {}).forEach(([grade, enrollment]) => {
        if (school.grades?.includes(grade)) {
          formattedData[grade] = enrollment === -1 ? 'no_longer_offered' : enrollment;
        } else {
          additional.push({
            grade,
            enrollment: enrollment === -1 ? 'no_longer_offered' : enrollment
          });
        }
      });
      
      dispatch({ 
        type: 'INIT_FORM', 
        enrollmentData: formattedData, 
        additionalGrades: additional 
      });
    }
  }, [isEdit, existingData, school.grades]);

  const validateGrade = useCallback((grade, value) => {
    if (value === '') {
      setValidationErrors(prev => ({
        ...prev,
        [grade]: 'Enrollment is required for all grades.'
      }));
    } else if (value !== 'no_longer_offered' && parseInt(value, 10) > 999) {
      setValidationErrors(prev => ({
        ...prev,
        [grade]: 'Enrollment exceeds 999 students. Please verify.'
      }));
    } else {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[grade];
        return newErrors;
      });
    }
  }, []);

  const handleGradeChange = useCallback((grade, event) => {
    const { value } = event.target;
    dispatch({ type: 'SET_GRADE', grade, value });
    validateGrade(grade, value);
  }, [validateGrade]);
  
  const handleAdditionalGradeChange = useCallback((index, field, event) => {
    const { value } = event.target;
    dispatch({ type: 'SET_ADDITIONAL_GRADE', index, field, value });
    if (field === 'enrollment') {
      validateGrade(`additional-${index}`, value);
    }
  }, [validateGrade]);
  
  const handleAddGrade = useCallback(() => {
    dispatch({ type: 'ADD_GRADE' });
  }, []);
  
  const handleRemoveGrade = useCallback((index) => {
    dispatch({ type: 'REMOVE_GRADE', index });
  }, []);

  const handleSubmit = useCallback(async () => {
    if (Object.keys(validationErrors).length > 0) return;
   
    setLoading(true);
    try {
      const grades = { ...formState.enrollmentData };
      formState.additionalGrades.forEach(({ grade, enrollment }) => {
        if (grade && enrollment) {
          grades[grade] = enrollment;
        }
      });
  
      const submissionData = {
        enrollmentData: {
          "2024-2025": {
            grades: Object.entries(grades).reduce((acc, [grade, enrollment]) => {
              if (enrollment !== '') {
                acc[grade] = enrollment === 'no_longer_offered' ? -1 : parseInt(enrollment, 10);
              }
              return acc;
            }, {})
          }
        },
        schoolId: school.ncessch,
        schoolName: school.name
      };
   
      await schoolsApi.submitEnrollmentData(submissionData);
      onSuccess();
      onClose();
    } catch (error) {
      console.error('Error submitting enrollment data:', error);
    } finally {
      setLoading(false);
    }
  }, [formState, validationErrors, school, onSuccess, onClose]);

  const memoizedFormProps = useMemo(() => ({
    school,
    enrollmentData: formState.enrollmentData,
    validationErrors,
    additionalGrades: formState.additionalGrades,
    onGradeChange: handleGradeChange,
    onAdditionalGradeChange: handleAdditionalGradeChange,
    onRemoveGrade: handleRemoveGrade,
    onAddGrade: handleAddGrade,
    isEdit
  }), [
    school,
    formState,
    validationErrors,
    handleGradeChange,
    handleAdditionalGradeChange,
    handleRemoveGrade,
    handleAddGrade,
    isEdit
  ]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            {isEdit ? 'Edit' : 'Enter'} 2024-2025 Enrollment Data
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <EnrollmentForm {...memoizedFormProps} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          disabled={loading || Object.keys(validationErrors).length > 0}
        >
          {loading ? <CircularProgress size={24} /> : isEdit ? 'Update' : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ProjectXEnrollmentEditor);