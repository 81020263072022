// src/auth/TokenManager.js
import { auth } from '../firebase';

class TokenManager {
  constructor() {
    this.listeners = new Set();
    this.SESSION_WARNING_TIME = 5 * 60 * 1000; // 5 minutes
  }

  addListener(callback) {
    this.listeners.add(callback);
    return () => this.listeners.delete(callback);
  }

  notifyListeners(tokenInfo) {
    this.listeners.forEach(listener => {
      try {
        listener(tokenInfo);
      } catch (error) {
        console.error('Error in token listener:', error);
      }
    });
  }

  async getToken(forceRefresh = false) {
    const user = auth.currentUser;
    if (!user) {
      this.notifyListeners(null);
      return null;
    }

    try {
      const token = await user.getIdToken(forceRefresh);
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const tokenInfo = {
        token,
        expirationTime: decodedToken.exp * 1000,
        claims: decodedToken
      };
      this.notifyListeners(tokenInfo);
      return token;
    } catch (error) {
      console.error('Token fetch failed:', error);
      this.notifyListeners(null);
      throw error;
    }
  }

  isSessionExpiringSoon() {
    const user = auth.currentUser;
    if (!user) return false;
    
    try {
      const decodedToken = JSON.parse(atob(user.stsTokenManager.accessToken.split('.')[1]));
      const expirationTime = decodedToken.exp * 1000;
      return (expirationTime - Date.now()) <= this.SESSION_WARNING_TIME;
    } catch (error) {
      return false;
    }
  }
}

export const tokenManager = new TokenManager();
export default tokenManager;