import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper,
  CircularProgress,
  CssBaseline
} from '@mui/material';
import { styled, keyframes } from "@mui/material/styles";
import mailchimpService from '../../services/mailChimpService';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PageWrapper = styled(Box)({
  minHeight: '100vh',
  width: '100vw',
  backgroundColor: '#F9FAFB',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
});

const LogoWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-50px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100px',
  height: '100px',
  '& .logo-content': {
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: '50%',
    padding: '4px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    animation: `${fadeInUp} 0.8s ease-out`,
  },
  '& .logo': {
    width: '100%',
    height: '100%',
    backgroundImage: 'url("/images/edc_logo_circle.png")',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }
}));

const StyledContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
});

const StyledResetContainer = styled(Paper)({
  backgroundColor: '#ffffff',
  border: '1px solid rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.05)',
  maxWidth: '600px',
  width: '100%',
  padding: '40px',
  paddingTop: '80px',
  textAlign: 'center',
  position: 'relative',
  marginTop: '60px',
});

const InputLabel = styled(Typography)({
  textAlign: 'left',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '14px',
  fontWeight: 500,
  color: '#111827',
  marginBottom: '6px',
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    '& fieldset': {
      borderColor: '#E5E7EB',
    },
    '&:hover fieldset': {
      borderColor: '#E5E7EB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6366F1',
    },
  },
  '& .MuiInputBase-input': {
    padding: '14px 16px',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&::placeholder': {
      color: '#9CA3AF',
    }
  },
});

const StyledButton = styled(Button)({
  backgroundColor: '#4F46E5',
  color: '#ffffff',
  fontSize: '16px',
  fontWeight: 600,
  padding: '14px',
  borderRadius: '32px',
  textTransform: 'none',
  boxShadow: '0px 4px 6px rgba(79, 70, 229, 0.3)',
  marginTop: '12px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  '&:hover': {
    backgroundColor: '#4338CA',
    boxShadow: '0px 4px 8px rgba(79, 70, 229, 0.4)',
  },
});

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const RESET_COOLDOWN = 5 * 60 * 1000; // 5 minutes in milliseconds

  // Check for oobCode and state in URL
  useEffect(() => {
    const state = searchParams.get('state');
    
    if (state) {
      // Redirect to PasswordSetup with just the state parameter
      navigate(`/set-password?state=${state}`);
    }
  }, [searchParams, navigate]);

  const checkResetAttempt = (email) => {
    const resetAttempts = JSON.parse(localStorage.getItem('passwordResetAttempts') || '{}');
    const lastAttempt = resetAttempts[email];
    
    if (!lastAttempt) return true;
    
    const timeSinceLastReset = Date.now() - lastAttempt;
    return timeSinceLastReset >= RESET_COOLDOWN;
  };

  const getTimeRemaining = (email) => {
    const resetAttempts = JSON.parse(localStorage.getItem('passwordResetAttempts') || '{}');
    const lastAttempt = resetAttempts[email];
    
    if (!lastAttempt) return 0;
    
    const remaining = RESET_COOLDOWN - (Date.now() - lastAttempt);
    return Math.max(0, Math.ceil(remaining / 1000 / 60));
  };

  const saveResetAttempt = (email) => {
    const resetAttempts = JSON.parse(localStorage.getItem('passwordResetAttempts') || '{}');
    resetAttempts[email] = Date.now();
    localStorage.setItem('passwordResetAttempts', JSON.stringify(resetAttempts));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setError('Please enter an email address');
      return;
    }
  
    if (!checkResetAttempt(email)) {
      setError(`Please wait ${getTimeRemaining(email)} minutes before requesting another reset`);
      return;
    }
  
    setIsSubmitting(true);
    setError('');
  
    try {
      await mailchimpService.requestPasswordReset(email);
      saveResetAttempt(email);
      setSubmitted(true);
    } catch (error) {
      // Error already handled by the service
      setError('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // If we have URL parameters, don't render anything while redirecting
  if (searchParams.get('oobCode') && searchParams.get('state')) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PageWrapper>
      <CssBaseline />
      <StyledContainer maxWidth="md">
        <StyledResetContainer elevation={0}>
          <LogoWrapper>
            <div className="logo-content">
              <div className="logo" aria-label="EDC Logo" />
            </div>
          </LogoWrapper>

          <Typography
            component="h1"
            variant="h4"
            sx={{
              fontSize: '28px',
              fontWeight: 600,
              color: '#111827',
              marginBottom: '32px',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            Reset Password
          </Typography>

          {submitted ? (
            <Box>
              <Typography
                sx={{
                  color: '#059669',
                  backgroundColor: '#ECFDF5',
                  padding: '16px',
                  borderRadius: '8px',
                  marginBottom: '24px',
                }}
              >
                If an account exists for {email}, a password reset link has been sent.
              </Typography>

              <Box 
                sx={{ 
                  backgroundColor: '#F3F4F6', 
                  padding: '16px', 
                  borderRadius: '8px',
                  marginBottom: '24px',
                  textAlign: 'left'
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  Please note:
                  <ul style={{ marginTop: '8px', paddingLeft: '20px' }}>
                    <li>The email may take a few minutes to arrive</li>
                    <li>Check your spam/junk folder if you don't see it</li>
                    <li>The reset link will expire in 24 hours</li>
                    <li>You can request another reset email for this address in {getTimeRemaining(email)} minutes</li>
                  </ul>
                </Typography>
              </Box>

              <StyledButton
                component={Link}
                to="/login"
                fullWidth
              >
                Return to Login
              </StyledButton>
            </Box>
          ) : (
            <Box component="form" onSubmit={handleSubmit}>
              <InputLabel>Email</InputLabel>
              <StyledTextField
                required
                fullWidth
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError(''); // Clear any errors when email changes
                }}
                autoFocus
              />

              {error && (
                <Typography 
                  color="error" 
                  align="center" 
                  sx={{ mt: 2 }}
                >
                  {error}
                </Typography>
              )}

              <StyledButton
                type="submit"
                fullWidth
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Send Reset Email'}
              </StyledButton>

              <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Link 
                  to="/login" 
                  style={{ 
                    textDecoration: 'none',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: '#4F46E5',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Back to Login
                  </Typography>
                </Link>
              </Box>
            </Box>
          )}
        </StyledResetContainer>
      </StyledContainer>
    </PageWrapper>
  );
};

export default ResetPassword;