import React from 'react';
import { Box, Skeleton, Grid, Card, CardContent } from '@mui/material';

/**
 * Population Trends Section Skeleton
 */
export const PopulationTrendsSkeleton = () => (
  <Box sx={{ width: '100%', pt: 2 }}>
    {/* Header */}
    <Box sx={{ mb: 4 }}>
      <Skeleton variant="text" width="60%" height={32} />
      <Skeleton variant="text" width="90%" height={24} sx={{ mt: 1 }} />
      <Skeleton variant="text" width="80%" height={24} />
    </Box>

    {/* Metrics cards */}
    <Grid container spacing={2} sx={{ mb: 4 }}>
      {[1, 2, 3].map((item) => (
        <Grid item xs={12} md={4} key={item}>
          <Card sx={{ height: 140 }}>
            <CardContent>
              <Skeleton variant="text" width="70%" height={24} />
              <Skeleton variant="text" width="40%" height={36} sx={{ mt: 1 }} />
              <Skeleton variant="text" width="90%" height={20} sx={{ mt: 1 }} />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>

    {/* Chart */}
    <Box 
      sx={{ 
        height: 350,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        p: 2
      }}
    >
      <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" width="100%" height={280} />
    </Box>
  </Box>
);

/**
 * Projected Enrollment Skeleton
 */
export const ProjectionsSkeleton = () => (
  <Box sx={{ width: '100%', pt: 2 }}>
    {/* Header */}
    <Box sx={{ mb: 3 }}>
      <Skeleton variant="text" width="60%" height={32} />
      <Skeleton variant="text" width="85%" height={24} sx={{ mt: 1 }} />
    </Box>

    {/* Projections summary */}
    <Box sx={{ mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: 180 }}>
            <CardContent>
              <Skeleton variant="text" width="70%" height={24} />
              <Skeleton variant="text" width="90%" height={22} sx={{ mt: 1 }} />
              <Skeleton variant="text" width="40%" height={60} sx={{ mt: 2 }} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: 180 }}>
            <CardContent>
              <Skeleton variant="text" width="60%" height={24} />
              <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Skeleton variant="circular" width={60} height={60} />
                <Box sx={{ width: '100%' }}>
                  <Skeleton variant="text" width="90%" height={20} />
                  <Skeleton variant="text" width="60%" height={20} />
                  <Skeleton variant="text" width="40%" height={20} />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>

    {/* Chart */}
    <Box 
      sx={{ 
        height: 400,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        p: 2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="text" width="40%" height={24} />
      </Box>
      <Skeleton variant="rectangular" width="100%" height={310} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Skeleton variant="rectangular" width={120} height={24} />
      </Box>
    </Box>
  </Box>
);

/**
 * Market Share Analysis Skeleton
 */
export const MarketShareSkeleton = () => (
  <Box sx={{ width: '100%', pt: 2 }}>
    {/* Header */}
    <Box sx={{ mb: 3 }}>
      <Skeleton variant="text" width="50%" height={32} />
      <Skeleton variant="text" width="90%" height={24} sx={{ mt: 1 }} />
      <Skeleton variant="text" width="75%" height={24} />
    </Box>

    {/* Market share metrics */}
    <Grid container spacing={3} sx={{ mb: 4 }}>
      <Grid item xs={12} md={4}>
        <Card sx={{ height: 160 }}>
          <CardContent>
            <Skeleton variant="text" width="70%" height={24} />
            <Skeleton variant="text" width="30%" height={48} sx={{ mt: 2 }} />
            <Skeleton variant="text" width="90%" height={20} sx={{ mt: 1 }} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={{ height: 160 }}>
          <CardContent>
            <Skeleton variant="text" width="60%" height={24} />
            <Skeleton variant="text" width="30%" height={48} sx={{ mt: 2 }} />
            <Skeleton variant="text" width="90%" height={20} sx={{ mt: 1 }} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={{ height: 160 }}>
          <CardContent>
            <Skeleton variant="text" width="50%" height={24} />
            <Skeleton variant="circular" width={60} height={60} sx={{ mt: 1, mx: 'auto' }} />
            <Skeleton variant="text" width="70%" height={20} sx={{ mt: 1, mx: 'auto' }} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>

    {/* Chart */}
    <Box 
      sx={{ 
        height: 380,
        p: 0,
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <Skeleton 
        variant="rectangular" 
        width="100%" 
        height={350}
        sx={{ borderRadius: 1 }}
      />
    </Box>
  </Box>
);

/**
 * Area School Comparison Skeleton
 */
export const ComparisonSkeleton = () => (
  <Box sx={{ width: '100%', pt: 2 }}>
    {/* Header */}
    <Box sx={{ mb: 3 }}>
      <Skeleton variant="text" width="60%" height={32} />
      <Skeleton variant="text" width="90%" height={24} sx={{ mt: 1 }} />
    </Box>

    {/* Statistics cards */}
    <Grid container spacing={2} sx={{ mb: 4 }}>
      <Grid item xs={12} md={6}>
        <Card sx={{ height: 170 }}>
          <CardContent>
            <Skeleton variant="text" width="60%" height={24} />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 2 }}>
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="text" width="40%" height={36} />
            </Box>
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="90%" height={20} sx={{ mt: 1 }} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card sx={{ height: 170 }}>
          <CardContent>
            <Skeleton variant="text" width="70%" height={24} />
            <Box sx={{ my: 2 }}>
              <Skeleton variant="rectangular" width="100%" height={60} />
            </Box>
            <Skeleton variant="text" width="100%" height={20} />
            <Skeleton variant="text" width="80%" height={20} sx={{ mt: 1 }} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>

    {/* Map and table */}
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        gap: 3,
        width: '100%',
        minWidth: 0
      }}
    >
      <Box 
        sx={{ 
          flex: { xs: '1 1 100%', lg: '1 1 70%' },
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          p: 2,
          height: 550
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="text" width="50%" height={24} />
          </Box>
          <Skeleton variant="rectangular" width="100%" height={32} sx={{ borderRadius: 1 }} />
        </Box>
        <Skeleton variant="rectangular" width="100%" height={400} sx={{ borderRadius: 1 }} />
      </Box>

      <Box 
        sx={{ 
          flex: { xs: '1 1 100%', lg: '1 1 30%' },
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          p: 2,
          height: 550
        }}
      >
        <Skeleton variant="text" width="70%" height={24} sx={{ mb: 2 }} />
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <Box key={item} sx={{ mb: 2, p: 1, borderBottom: '1px solid', borderColor: 'divider' }}>
            <Skeleton variant="text" width="85%" height={20} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
              <Skeleton variant="text" width="30%" height={18} />
              <Skeleton variant="text" width="20%" height={18} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  </Box>
);

/**
 * Demographic Composition Skeleton
 */
export const DemographicsSkeleton = () => (
  <Box sx={{ width: '100%', pt: 2 }}>
    {/* Header */}
    <Box sx={{ mb: 3 }}>
      <Skeleton variant="text" width="70%" height={32} />
      <Skeleton variant="text" width="90%" height={24} sx={{ mt: 1 }} />
    </Box>

    {/* Analysis cards */}
    <Grid container spacing={2} sx={{ mb: 4 }}>
      {[1, 2].map((item) => (
        <Grid item xs={12} md={6} key={item}>
          <Card sx={{ height: 180 }}>
            <CardContent>
              <Skeleton variant="text" width={item === 1 ? "65%" : "55%"} height={24} />
              <Box sx={{ display: 'flex', gap: 3, my: 2 }}>
                <Skeleton variant="circular" width={60} height={60} />
                <Box sx={{ width: '100%' }}>
                  <Skeleton variant="text" width="90%" height={20} />
                  <Skeleton variant="text" width="70%" height={20} sx={{ mt: 1 }} />
                  <Skeleton variant="text" width="60%" height={20} sx={{ mt: 1 }} />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>

    {/* Charts grid */}
    <Box 
      sx={{ 
        display: 'grid', 
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, 
        gap: 3,
        mt: -2
      }}
    >
      <Box 
        sx={{ 
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          p: 2,
          mt: 2,
          height: 400
        }}
      >
        <Skeleton variant="text" width="60%" height={28} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="100%" height={320} sx={{ borderRadius: 1 }} />
      </Box>

      <Box sx={{ p: 2, height: 400 }}>
        <Skeleton variant="text" width="70%" height={28} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="100%" height={320} sx={{ borderRadius: 1 }} />
      </Box>
    </Box>
  </Box>
);