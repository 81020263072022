import React, { useMemo } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LaunchIcon from '@mui/icons-material/Launch';
import { canAccessExplorers } from '../../../utils/explorerAccess';

const ProjectionsAnalysis = React.memo(({ 
  selectedSchool,
  enrollmentData,
  user
}) => {
  // Move calculations into useMemo before any conditional returns
  const {
    latestActualYear,
    latestActualTotal,
    lastProjectionYear,
    lastProjectionTotal,
    growthRate
  } = useMemo(() => {
    if (!selectedSchool || !enrollmentData) {
      return {
        latestActualYear: null,
        latestActualTotal: null,
        lastProjectionYear: null,
        lastProjectionTotal: null,
        growthRate: null
      };
    }

    const actualYears = Object.keys(enrollmentData.actual_enrollment || {}).sort();
    const latestActualYear = actualYears[actualYears.length - 1];
    const latestActualTotal = latestActualYear ? 
      Object.values(enrollmentData.actual_enrollment[latestActualYear]).reduce((sum, val) => sum + (val || 0), 0) : 
      null;

    const projectionYears = Object.keys(enrollmentData.projections?.median || {}).sort();
    const lastProjectionYear = projectionYears[projectionYears.length - 1];
    const lastProjectionTotal = lastProjectionYear ?
      Object.values(enrollmentData.projections.median[lastProjectionYear]).reduce((sum, val) => sum + (val || 0), 0) :
      null;

    const growthRate = latestActualTotal && lastProjectionTotal ?
      ((lastProjectionTotal - latestActualTotal) / latestActualTotal) * 100 :
      null;

    return {
      latestActualYear,
      latestActualTotal,
      lastProjectionYear,
      lastProjectionTotal,
      growthRate
    };
  }, [selectedSchool, enrollmentData]);

  if (!selectedSchool || !enrollmentData) {
    return null;
  }

  const explorerUrl = `/projections-explorer?school=${selectedSchool.ncessch}`;

  return (
    <Paper 
      elevation={0}
      sx={{
        py: 2,
        px: 3,
        backgroundColor: 'grey.50',
        color: 'text.primary',
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
        position: 'relative',
        overflow: 'hidden',
        mb: 3,
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: 6,
          backgroundColor: 'primary.main',
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8
        }
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography variant="h8" sx={{ fontWeight: 200 }}>
          <strong>{selectedSchool.name}</strong> enrolled <strong>{latestActualTotal?.toLocaleString() || 'N/A'}</strong> students in {latestActualYear}. 
          By {lastProjectionYear}, enrollment is projected to {growthRate > 0 ? 'grow' : 'decline'} to <strong>{lastProjectionTotal?.toLocaleString()}</strong> students
          ({growthRate > 0 ? '+' : ''}{Math.round(growthRate)}% change).
        </Typography>
      </Box>
      {canAccessExplorers(user?.role) && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AutoGraphIcon />}
          endIcon={<LaunchIcon />}
          href={explorerUrl}
          sx={{ 
            backgroundColor: 'primary.dark',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.main'
            }
          }}
        >
          OPEN IN PROJECTIONS EXPLORER
        </Button>
      )}
    </Paper>
  );
});

ProjectionsAnalysis.displayName = 'ProjectionsAnalysis';
export default ProjectionsAnalysis;