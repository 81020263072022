// src/hooks/usePrivacyConsent.js
import { useState, useEffect, useCallback, useRef } from 'react';
import consentStore from '../stores/consentStore';
import { useAuth } from '../auth/AuthContext';

export const usePrivacyConsent = () => {
  const { user, loading: authLoading } = useAuth();
  const [analyticsAllowed, setAnalyticsAllowed] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const initializationInProgress = useRef(false);
  const isInitialized = useRef(false);
  const isMounted = useRef(true);
  const prevUserRef = useRef(user);

  // Set up mount tracking
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const checkGPCSignal = useCallback(() => {
    return navigator.globalPrivacyControl !== undefined ? 
      navigator.globalPrivacyControl : false;
  }, []);

  const handleConsentChoice = useCallback(async (consentData, isTemporary = false) => {
    try {
      const updatedConsent = await consentStore.updateConsent(
        user?.uid,
        consentData,
        isTemporary
      );
      
      setAnalyticsAllowed(updatedConsent.analyticsAllowed);
      setShowBanner(false);
      setSnackbarMessage(updatedConsent.analyticsAllowed ? 
        'Analytics cookies accepted. Thank you for helping us improve our service.' :
        'Analytics cookies declined. Only essential cookies will be used.'
      );
      
      return updatedConsent;
    } catch (error) {
      console.error('Error updating consent:', error);
      setSnackbarMessage('Error saving privacy settings. Please try again.');
      throw error;
    }
  }, [user]);

  // Reset flags when user changes
  useEffect(() => {
    if (prevUserRef.current !== user) {  // User state changed
      isInitialized.current = false;
      initializationInProgress.current = false;
      prevUserRef.current = user;
    }
  }, [user]);

  useEffect(() => {
    const initializeConsent = async () => {
      if (initializationInProgress.current || isInitialized.current) {
        return;
      }

      if (authLoading) {
        return;
      }

      try {
        initializationInProgress.current = true;
        const existingConsent = await consentStore.initializeConsent(user?.uid);
        
        // Continue initialization even if unmounted, but don't update state
        if (existingConsent) {
          if (isMounted.current) {
            setAnalyticsAllowed(existingConsent.analyticsAllowed);
            setShowBanner(false);
          }
        } else {
          const gpcEnabled = checkGPCSignal();
          if (gpcEnabled) {
            if (isMounted.current) {
              await handleConsentChoice({
                analyticsAllowed: false,
                gpcEnforced: true
              }, false);
            }
          } else {
            if (isMounted.current) {
              setAnalyticsAllowed(false);
              const shouldShow = consentStore.shouldShowBanner();
              setShowBanner(shouldShow);
            }
          }
        }
      } catch (error) {
        console.error('Detailed initialization error:', error);
        if (isMounted.current) {
          setAnalyticsAllowed(false);
          setShowBanner(true);
        }
      } finally {
        isInitialized.current = true;
        if (isMounted.current) {
          setInitialized(true);
        }
        initializationInProgress.current = false;
      }
    };

    initializeConsent();
  }, [authLoading, user, checkGPCSignal, handleConsentChoice]);

  return {
    analyticsAllowed,
    showBanner,
    snackbarMessage,
    setSnackbarMessage,
    applyConsent: handleConsentChoice,
    initialized
  };
};