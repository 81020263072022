import React, { useEffect, useRef, useMemo } from 'react';
import { 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Paper,
  Typography,
  Box,
  Divider,
  Autocomplete,
  TextField
} from '@mui/material';
import { SchoolOutlined } from '@mui/icons-material';
import { formatSchoolName, capitalizeWord } from '../../utils/nameFormatter';
import { getSchoolGrades } from '../../utils/schoolUtils';

// Define grades in the order we want them displayed, excluding Pre-K
const GRADES = [
  'K',  // Changed from 'Kindergarten'
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'
];

const DRIVE_TIMES = [5, 10, 15];

const SharedFilters = ({
  schools,
  selectedSchool,
  governanceFilter,
  selectedGrades = [],
  selectedDriveTime,
  schoolTypeFilter,
  availableSchoolTypes,
  onSchoolChange,
  onGovernanceChange,
  onGradeChange,
  onDriveTimeChange,
  onSchoolTypeChange,
  onHasPreKChange,
  showSchoolType = false,
  compact = false,
  sticky = false
}) => {
  // Use ref to track if the initial school grades have been set
  const initialGradesSet = useRef(false);

  // Sort schools once and memoize the result
  const sortedSchools = useMemo(() => {
    return [...schools].sort((a, b) => 
      formatSchoolName(a.name).localeCompare(formatSchoolName(b.name))
    );
  }, [schools]);

  // Effect to set initial grades ONLY when school first changes
  useEffect(() => {
    if (selectedSchool?.grade_range && !initialGradesSet.current) {
      const schoolGrades = getSchoolGrades(selectedSchool.grade_range);
      const hasPreK = selectedSchool.grade_range.includes('Pre-Kindergarten');
      onHasPreKChange?.(hasPreK);
      onGradeChange({ target: { value: schoolGrades } });
      initialGradesSet.current = true;
    } else if (!selectedSchool) {
      initialGradesSet.current = false;
      onGradeChange({ target: { value: [] } });
      onHasPreKChange?.(false);
    }
  }, [selectedSchool, onGradeChange, onHasPreKChange]);

  const filterSize = sticky ? "small" : "medium";
  const filterHeight = sticky ? 40 : 56;

  const formatAddress = (school) => {
    if (!school) return '';
    
    const formattedStreetAddress = school.street_address
      .split(' ')
      .map(capitalizeWord)
      .join(' ');
  
    const formattedCity = school.city
      .split(' ')
      .map(capitalizeWord)
      .join(' ');
  
    return `${formattedStreetAddress}\n${formattedCity}, ${school.state} ${school.zip_code}`;
  };

  const renderFilters = () => (
    <Grid 
      container 
      spacing={2} 
      sx={{ 
        display: { 
          xs: 'grid',  // Grid layout on mobile
          md: 'flex'   // Original flex layout on desktop
        },
        gridTemplateColumns: {
          xs: 'repeat(2, 1fr)',  // 2 columns on mobile
          sm: 'repeat(3, 1fr)',  // 3 columns on small screens
          md: 'none'             // Disable grid on desktop
        },
        gap: 2,
        overflowX: 'auto',      // Preserve horizontal scroll capability
        '& > .MuiGrid-item': {
          width: {
            xs: '100%',         // Full width in grid
            md: 'auto'          // Original width in flex
          }
        }
      }}
    >
      <Grid item style={{ 
        minWidth: 400,
        gridColumn: { xs: 'span 2', sm: 'span 2', md: 'auto' }
      }}>
        <Autocomplete
          size={filterSize}
          options={sortedSchools}
          getOptionLabel={(option) => formatSchoolName(option.name)}
          value={selectedSchool || null}
          onChange={(event, newValue) => {
            initialGradesSet.current = false;
            onSchoolChange({ target: { value: newValue?.ncessch || '' } });
          }}
          isOptionEqualToValue={(option, value) => option.ncessch === value?.ncessch}
          renderOption={(props, option) => (
            <li {...props} key={option.ncessch}>
              <div>
                <Typography variant="body1">{formatSchoolName(option.name)}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {option.city}, {option.state} • NCES ID: {option.ncessch}
                </Typography>
              </div>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="School"
              sx={{ height: filterHeight }}
            />
          )}
        />
      </Grid>
  
      {showSchoolType && (
        <Grid item style={{ minWidth: 150 }}>
          <FormControl fullWidth size={filterSize}>
            <InputLabel id="school-type-select-label">School Type</InputLabel>
            <Select
              labelId="school-type-select-label"
              id="school-type-select"
              value={schoolTypeFilter}
              label="School Type"
              onChange={onSchoolTypeChange}
              sx={{ height: filterHeight }}
            >
              {availableSchoolTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
  
      <Grid item style={{ minWidth: 150 }}>
        <FormControl fullWidth size={filterSize}>
          <InputLabel id="governance-select-label">Governance</InputLabel>
          <Select
            labelId="governance-select-label"
            id="governance-select"
            value={governanceFilter}
            label="Governance"
            onChange={onGovernanceChange}
            sx={{ height: filterHeight }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Charter">Charter</MenuItem>
            <MenuItem value="District">District</MenuItem>
          </Select>
        </FormControl>
      </Grid>
  
      <Grid item style={{ minWidth: 150 }}>
        <FormControl fullWidth size={filterSize}>
          <InputLabel id="drive-time-select-label">Drive Time</InputLabel>
          <Select
            labelId="drive-time-select-label"
            id="drive-time-select"
            value={selectedDriveTime}
            label="Drive Time"
            onChange={onDriveTimeChange}
            sx={{ height: filterHeight }}
          >
            {DRIVE_TIMES.map((time) => (
              <MenuItem key={time} value={time}>
                {time} minutes
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
  
      <Grid item style={{ minWidth: 150, maxWidth: 200 }}>
        <FormControl fullWidth size={filterSize}>
          <InputLabel id="grade-select-label">Grades</InputLabel>
          <Select
            labelId="grade-select-label"
            id="grade-select"
            multiple
            value={selectedGrades.map(grade => 
              grade === 'Kindergarten' ? 'K' : grade
            )}
            onChange={(e) => {
              // Convert K back to Kindergarten for consistency with backend
              const selectedValues = e.target.value.map(grade => 
                grade === 'K' ? 'Kindergarten' : grade
              );
              
              // Sort them according to our rules
              const sortedGrades = [...selectedValues].sort((a, b) => {
                if (a === 'Kindergarten') return -1;
                if (b === 'Kindergarten') return 1;
                return parseInt(a) - parseInt(b);
              });
  
              onGradeChange({ target: { value: sortedGrades } });
            }}
            input={<OutlinedInput label="Grades" />}
            renderValue={(selected) => selected.sort((a, b) => {
              if (a === 'K') return -1;
              if (b === 'K') return 1;
              return parseInt(a) - parseInt(b);
            }).join(', ')}
            sx={{ height: filterHeight }}
          >
            {GRADES.map((grade) => (
              <MenuItem key={grade} value={grade}>
                <Checkbox 
                  checked={selectedGrades.includes(
                    grade === 'K' ? 'Kindergarten' : grade
                  )} 
                />
                <ListItemText primary={grade} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  if (compact) {
    return renderFilters();
  }

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <SchoolOutlined sx={{ fontSize: 24, color: 'primary.main', mr: 1 }} />
            <Typography variant="h6">
              {selectedSchool ? formatSchoolName(selectedSchool.name) : 'Select a School'}
            </Typography>
          </Box>
          {selectedSchool && (
            <Typography variant="body2" color="text.secondary">
              {formatAddress(selectedSchool)}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md="auto">
          <Divider orientation="vertical" flexItem />
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
            Filters
          </Typography>
          {renderFilters()}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SharedFilters;