// utils/districtColorUtils.js
export const getDistrictColor = (row, selectedHeadline) => {
  // Default color for missing data
  if (!row) return '#808080';

  // Define consistent colors
  const GROWING_COLOR = '#008000';   // Green for growing/gaining
  const DECLINING_COLOR = '#FF0000'; // Red for declining/losing
  const NEUTRAL_COLOR = '#808080';   // Gray for stable/newer schools

  // Helper to determine color based on status for population and enrollment
  const getColorByStandardStatus = (status) => {
    switch (status) {
      case 'growing': return GROWING_COLOR;
      case 'declining': return DECLINING_COLOR;
      case 'stable': return NEUTRAL_COLOR;
      default: return NEUTRAL_COLOR;
    }
  };

  // Helper to determine color based on market share status
  const getColorByMarketShareStatus = (status) => {
    switch (status) {
      case 'gaining': return GROWING_COLOR;
      case 'losing': return DECLINING_COLOR;
      case 'stable': return NEUTRAL_COLOR;
      default: return NEUTRAL_COLOR;
    }
  };

  // Determine color based on selected headline and API data structure
  switch (selectedHeadline) {
    case 0: // Area Population Trends
      if (row.population?.status) {
        return getColorByStandardStatus(row.population.status);
      }
      break;

    case 1: // Area Population Projections
      if (row.population?.projection_status) {
        return getColorByStandardStatus(row.population.projection_status);
      }
      break;

    case 2: // School Enrollment Trends 
      // Check for newer schools first
      if (row.is_newer) {
        return NEUTRAL_COLOR;
      }
      
      if (row.enrollment?.status) {
        return getColorByStandardStatus(row.enrollment.status);
      }
      break;

    case 3: // School Enrollment Projections
      if (row.enrollment?.projection_status) {
        return getColorByStandardStatus(row.enrollment.projection_status);
      }
      break;

    case 4: // Market Share Analysis
      // Check for newer schools first
      if (row.is_newer) {
        return NEUTRAL_COLOR;
      }
      
      if (row.market_share?.status) {
        return getColorByMarketShareStatus(row.market_share.status);
      }
      break;

    default:
      return NEUTRAL_COLOR;
  }

  return NEUTRAL_COLOR; // Default color
};