import { schoolsApi } from "../utils/apiService";

class DistrictCacheService {
    constructor() {
      this.DB_NAME = 'districtDataCache';
      this.DB_VERSION = 1;
      this.STORES = {
        metrics: 'metrics',
        teamAccess: 'teamAccess'
      };
    }
  
    async initialize() {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(this.DB_NAME, this.DB_VERSION);
  
        request.onerror = () => reject(request.error);
        request.onsuccess = () => {
          this.db = request.result;
          resolve();
        };
  
        request.onupgradeneeded = (event) => {
          const db = event.target.result;
  
          // Create stores if they don't exist
          if (!db.objectStoreNames.contains(this.STORES.metrics)) {
            const metricsStore = db.createObjectStore(this.STORES.metrics, { keyPath: 'ncessch' });
            metricsStore.createIndex('by_team', 'teams', { multiEntry: true });
          }
  
          if (!db.objectStoreNames.contains(this.STORES.teamAccess)) {
            const teamStore = db.createObjectStore(this.STORES.teamAccess, { keyPath: 'teamId' });
            teamStore.createIndex('by_version', 'version');
          }
        };
      });
    }
  
    async cacheDistrictData(districtData, teamId) {
        if (!districtData?.metrics || !teamId) {
          console.warn('Invalid district data or team ID for caching');
          return;
        }
    
        const tx = this.db.transaction(Object.values(this.STORES), 'readwrite');
        const metricsStore = tx.objectStore(this.STORES.metrics);
        const teamStore = tx.objectStore(this.STORES.teamAccess);
    
        return new Promise((resolve, reject) => {
          // First, get all existing metrics for the team
          const existingMetrics = new Set();
          
          districtData.metrics.forEach(metric => {
            const existingDataRequest = metricsStore.get(metric.ncessch);
            
            existingDataRequest.onsuccess = () => {
              const existingData = existingDataRequest.result;
              
              // Create or update the teams array
              const teams = new Set(existingData?.teams || []);
              teams.add(teamId); // Add the current team ID
    
              // Prepare the metric with team association
              const updatedMetric = {
                ...metric,
                ncessch: metric.ncessch, // Ensure we have the key
                teams: Array.from(teams),
                lastUpdated: new Date().toISOString(),
                // Ensure required nested objects exist
                population: metric.population || {
                  status: 'unknown',
                  current: null,
                  past: null
                },
                enrollment: metric.enrollment || {
                  status: 'unknown',
                  current: null,
                  past: null
                },
                market_share: metric.market_share || {
                  status: 'unknown',
                  current: null,
                  past: null
                }
              };
    
              existingMetrics.add(metric.ncessch);
              metricsStore.put(updatedMetric);
            };
          });
    
          // Update team metadata
          const teamData = {
            teamId,
            version: districtData.version || '1.0',
            lastUpdated: new Date().toISOString(),
            schoolCount: districtData.metrics.length,
            metricIds: Array.from(existingMetrics)
          };
          teamStore.put(teamData);
    
          tx.oncomplete = () => {
            resolve();
          };
          tx.onerror = () => reject(tx.error);
        });
      }
  
    async getTeamMetrics(teamId) {
      if (!this.db) {
        await this.initialize();
      }
  
      if (!teamId) return [];
        
      const tx = this.db.transaction([this.STORES.metrics], 'readonly');
      const metricsStore = tx.objectStore(this.STORES.metrics);
      const teamIndex = metricsStore.index('by_team');
  
      return new Promise((resolve, reject) => {
        const request = teamIndex.getAll(teamId);
  
        request.onsuccess = () => {
          const metrics = request.result;
          // Ensure each metric has the required nested objects
          const processedMetrics = metrics.map(metric => ({
            ...metric,
            population: metric.population || {
              status: 'unknown',
              current: null,
              past: null
            },
            enrollment: metric.enrollment || {
              status: 'unknown',
              current: null,
              past: null
            },
            market_share: metric.market_share || {
              status: 'unknown',
              current: null,
              past: null
            }
          }));
          resolve(processedMetrics);
        };
  
        request.onerror = () => reject(request.error);
      });
    }
  
    async getMetadata(teamId) {
      if (!this.db) {
        await this.initialize();
      }
  
      if (!teamId) return null;
  
      const tx = this.db.transaction([this.STORES.teamAccess], 'readonly');
      const teamStore = tx.objectStore(this.STORES.teamAccess);
  
      return new Promise((resolve) => {
        const request = teamStore.get(teamId);
        
        request.onsuccess = () => {
          resolve(request.result || null);
        };
  
        request.onerror = () => resolve(null);
      });
    }
  
    async clearTeamCache(teamId) {
      const tx = this.db.transaction(Object.values(this.STORES), 'readwrite');
      const metricsStore = tx.objectStore(this.STORES.metrics);
      const teamStore = tx.objectStore(this.STORES.teamAccess);
  
      return new Promise((resolve, reject) => {
        const teamIndex = metricsStore.index('by_team');
        const request = teamIndex.getAll(teamId);
  
        request.onsuccess = () => {
          const metrics = request.result;
          
          // Remove team from metrics
          metrics.forEach(metric => {
            const teams = metric.teams.filter(t => t !== teamId);
            if (teams.length === 0) {
              metricsStore.delete(metric.ncessch);
            } else {
              metricsStore.put({ ...metric, teams });
            }
          });
  
          // Remove team access record
          teamStore.delete(teamId);
        };
  
        tx.oncomplete = () => resolve();
        tx.onerror = () => reject(tx.error);
      });
    }

    async prefetchDistrictData(teamId = 'base') {
        try {
          // Initialize if not already done
          if (!this.db) {
            await this.initialize();
          }
    
          // Check if we already have fresh data
          const metadata = await this.getMetadata(teamId);
          if (metadata) {
            const lastUpdated = new Date(metadata.lastUpdated);
            const now = new Date();
            const hoursSinceUpdate = (now - lastUpdated) / (1000 * 60 * 60);
            
            // If data is fresh (less than 24 hours old), skip prefetch
            if (hoursSinceUpdate < 24) {
              return;
            }
          }
    
          // Fetch new data silently
          const response = await schoolsApi.getDistrictMetrics();
          
          if (response?.metrics?.length > 0) {
            await this.cacheDistrictData(response, teamId);
          }
        } catch (error) {
          // Log but don't throw prefetch errors
          console.error('District data prefetch error:', error);
        }
      }
    
      // Add method to check if we have fresh data
      async hasFreshData(teamId = 'base') {
        if (!this.db) {
          await this.initialize();
        }
    
        const metadata = await this.getMetadata(teamId);
        if (!metadata) return false;
    
        const lastUpdated = new Date(metadata.lastUpdated);
        const now = new Date();
        const hoursSinceUpdate = (now - lastUpdated) / (1000 * 60 * 60);
        
        return hoursSinceUpdate < 24;
      }
  
      async clearAllCache() {
        try {
          // Initialize if not already done
          if (!this.db) {
            await this.initialize();
          }
      
          const tx = this.db.transaction(Object.values(this.STORES), 'readwrite');
          
          return Promise.all(
            Object.values(this.STORES).map(
              storeName => new Promise((resolve, reject) => {
                const store = tx.objectStore(storeName);
                const request = store.clear();
                request.onsuccess = () => resolve();
                request.onerror = () => reject(request.error);
              })
            )
          );
        } catch (error) {
          console.error('Error clearing cache:', error);
          // Optionally rethrow if you want to handle it in the logout flow
          throw error;
        }
      }
  }
  
  export const districtCache = new DistrictCacheService();