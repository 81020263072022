import LoadingErrorWrapper from '../shared_components/loadingErrorWrapper';
import React, { useState, useEffect, Suspense, useMemo, useCallback, useRef } from 'react';
import { 
  Skeleton, 
  Box,
  CircularProgress,
  Typography
} from '@mui/material';
import {
  MapsHomeWork,
  AutoGraphOutlined,
  DescriptionOutlined,
  Explore,
  PeopleOutlined,
  GroupsOutlined,
  CompareArrowsOutlined
} from '@mui/icons-material';

// Shared Components
import PageLayout from '../shared_components/pageLayout';
import AccordionSection from '../shared_components/accordionSection';
import AccordionContainer from '../shared_components/accordionContainer';
import StickyFilters from '../shared_components/stickyFilters';
import InitialSchoolSelector from '../shared_components/initialSchoolSelector';
import NowWhatSection from '../shared_components/templates/nowWhatTemplate';
import CompXNearbyMapPopup from './nearby/CompXNearbyMapPopup';

// Custom Hook
import { useAccordionState } from '../../hooks/useAccordionState';

// Contexts
import { useInsight } from '../../contexts/InsightContext';

// Utils
import { filterSchools, getAvailableSchoolTypes } from '../../utils/schoolUtils';
import { formatSchoolName } from '../../utils/nameFormatter';

// Stores
import useSchoolStore from '../../stores/schoolStore';

// Cache
import { useTeamSchoolCache } from '../../hooks/teamCacheService';

// Components
import CompXCommunityHeadlines from './community/CompXCommunityHeadlines';

// API
import { schoolsApi } from '../../utils/apiService'; 

// Lazy load components
const CompXNearbyHeadlines = React.lazy(() => import('./nearby/CompXNearbyHeadlines'));
const CompXEnrollmentHeadlines = React.lazy(() => import('./enrollment/CompXEnrollmentHeadlines'));

const SectionLoadingPlaceholder = ({ message }) => (
  <Box 
    sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center',
      py: 8 
    }}
  >
    <CircularProgress size={40} sx={{ mb: 2 }} />
    <Typography variant="body1" color="text.secondary">
      {message}
    </Typography>
  </Box>
);

function CompetitiveExplorer() {
  // Get data from team school cache
  const { 
    schools, 
    loading: schoolsLoading, 
  } = useTeamSchoolCache();

  // Use school store directly
  const {
    selectedSchool,
    esriData,
    nearbySchools,
    schoolsEnrollmentData,
    loadingStates,
    error
  } = useSchoolStore();

  // Context declarations
  const { setToolAndSection } = useInsight();
  
  // Refs
  const sectionRefs = useRef({});
  const observerRef = useRef(null);
  const prevNearbyProps = useRef({});
  const initialSchoolLoadRef = useRef(false);

  // State declarations
  const [governanceFilter, setGovernanceFilter] = useState('All');
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedDriveTime, setSelectedDriveTime] = useState(10);
  const [schoolTypeFilter, setSchoolTypeFilter] = useState('All');
  const [availableSchoolTypes, setAvailableSchoolTypes] = useState(['All']);
  const [sectionLoadingStates, setSectionLoadingStates] = useState({
    communityHeadlines: { loading: true, dataReady: false },
    enrollmentTrends: { loading: true, dataReady: false },
    nearby: { loading: true, dataReady: false },
    nowWhat: { loading: false, dataReady: true }
  });

  // Section data loading check
  const isSectionDataLoaded = useCallback((sectionId) => {
    if (!loadingStates || typeof loadingStates !== 'object') {
      return false;
    }

    switch (sectionId) {
      case 'communityHeadlines':
        return !!esriData && 
               !!selectedSchool && 
               !!esriData.drive_times && 
               Object.keys(esriData.drive_times).length > 0 && 
               loadingStates.marketShare === false;
      
      case 'enrollmentTrends': 
        return !!schoolsEnrollmentData.length && 
               !!selectedSchool && 
               !!esriData && 
               !!selectedSchool.grade_range && 
               !!nearbySchools &&
               loadingStates.enrollment === false && 
               loadingStates.nearby === false;

      case 'nearby':
        return !!nearbySchools && 
               Object.keys(nearbySchools?.current || {}).length > 0 &&
               !!schoolsEnrollmentData.length && 
               !!esriData && 
               !!selectedSchool &&
               loadingStates.nearby === false && 
               loadingStates.enrollment === false;

      default: 
        return false;
    }
  }, [esriData, schoolsEnrollmentData.length, nearbySchools, selectedSchool, loadingStates]);

  // Accordion state
  const {
    expandedSections,
    handleExpandedChange,
    handleExpandAll,
    handleCollapseAll,
  } = useAccordionState({
    communityHeadlines: false,
    enrollmentTrends: false,
    nearby: false,
    nowWhat: false
  });

  // Event handlers
  const handleSchoolChange = useCallback((event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      useSchoolStore.getState().selectSchool({
        ...school,
        name: formatSchoolName(school.name)
      });
    }
  }, [schools]);

  const handleGradeChange = useCallback((event) => {
    const value = event.target.value;
    setSelectedGrades(typeof value === 'string' ? value.split(',') : value);
  }, []);

  const handleGovernanceChange = useCallback((event) => {
    setGovernanceFilter(event.target.value);
  }, []);

  const handleDriveTimeChange = useCallback((event) => {
    setSelectedDriveTime(Number(event.target.value));
  }, []);

  const handleSchoolTypeChange = useCallback((event) => {
    setSchoolTypeFilter(event.target.value);
  }, []);

  const handleSectionChange = useCallback((sectionId) => (event, isExpanded) => {
    if (isExpanded && !sectionLoadingStates[sectionId]?.dataReady) {
      event.preventDefault();
      return false;
    }
    
    handleExpandedChange(sectionId)(event, isExpanded);
    if (isExpanded) {
      setToolAndSection('competitive-explorer', sectionId); 
    } else if (!Object.values(expandedSections).some(expanded => expanded)) {
      setToolAndSection('competitive-explorer', null); 
    }
  }, [handleExpandedChange, expandedSections, setToolAndSection, sectionLoadingStates]);

  const getSectionRef = useCallback((sectionId) => (element) => {
    if (element) {
      sectionRefs.current[sectionId] = element;
      if (observerRef.current) {
        observerRef.current.observe(element);
      }
    }
  }, []);

  // Filtered schools computation
  const filteredSchools = useMemo(() => {
    if (!selectedSchool || !nearbySchools) {
      return [];
    }
  
    if (!selectedGrades || selectedGrades.length === 0) {
      return [];
    }
    
    const schoolsForSelectedDriveTime = nearbySchools?.current?.[selectedDriveTime] || [];
    
    const allSchools = [
      { 
        ...selectedSchool, 
        drive_time: 0 
      }, 
      ...schoolsForSelectedDriveTime.map(school => ({
        ...school,
        drive_time: selectedDriveTime
      }))
    ];

    return filterSchools(
      allSchools,
      {
        selectedGrades,
        governanceFilter,
        schoolTypeFilter
      },
      schoolsEnrollmentData
    );
  }, [
    selectedSchool,
    nearbySchools,
    selectedDriveTime,
    selectedGrades,
    governanceFilter,
    schoolTypeFilter,
    schoolsEnrollmentData
  ]);

  // Props for nearby headlines
  const nearbyHeadlinesProps = useMemo(() => ({
    selectedSchool,
    filteredSchools: filteredSchools || [],
    schoolsEnrollmentData: schoolsEnrollmentData || [],
    selectedGrades: selectedGrades || [],
    governanceFilter,
    schoolTypeFilter,
    nearbySchools: nearbySchools || {},
    selectedDriveTime,
    esriData: esriData || {},
    visibleDriveTimes: [selectedDriveTime]
  }), [
    selectedSchool,
    filteredSchools,
    schoolsEnrollmentData,
    selectedGrades,
    governanceFilter,
    schoolTypeFilter,
    nearbySchools,
    selectedDriveTime,
    esriData
  ]);

  // Effects
  useEffect(() => {
    if (schools.length > 0 && !selectedSchool && !initialSchoolLoadRef.current) {
      const params = new URLSearchParams(window.location.search);
      const schoolId = params.get('school');
      
      if (schoolId) {
        const school = schools.find(s => s.ncessch === schoolId);
        if (school) {
          useSchoolStore.getState().selectSchool({
            ...school,
            name: formatSchoolName(school.name)
          });
        }
        initialSchoolLoadRef.current = true;
      }
    }
  }, [schools, selectedSchool]);

  useEffect(() => {
    if (selectedSchool) {
      setSectionLoadingStates({
        communityHeadlines: { loading: true, dataReady: false },
        enrollmentTrends: { loading: true, dataReady: false }, 
        nearby: { loading: true, dataReady: false },
        nowWhat: { loading: false, dataReady: true }
      });
      
      Object.keys(expandedSections).forEach(section => {
        if (expandedSections[section]) {
          handleExpandedChange(section)(null, false);
        }
      });
  
      if (selectedSchool.ncessch) {
        useSchoolStore.getState().markDataStale();
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool?.ncessch, handleExpandedChange]);

  useEffect(() => {
    if (selectedSchool) {
      const controller = new AbortController();
      const signal = controller.signal;
      
      setSectionLoadingStates(prev => ({
        ...prev,
        communityHeadlines: { loading: !isSectionDataLoaded('communityHeadlines'), dataReady: isSectionDataLoaded('communityHeadlines') },
        enrollmentTrends: { loading: !isSectionDataLoaded('enrollmentTrends'), dataReady: isSectionDataLoaded('enrollmentTrends') },
        nearby: { loading: !isSectionDataLoaded('nearby'), dataReady: isSectionDataLoaded('nearby') }
      }));
      
      const loadData = async () => {
        try {
          if (!isSectionDataLoaded('communityHeadlines')) {
            await schoolsApi.getMarketShareData(selectedSchool.ncessch, { signal });
            setSectionLoadingStates(prev => ({
              ...prev,
              communityHeadlines: { loading: false, dataReady: true }
            }));
          }
          
          let nearbyData;
          if (!isSectionDataLoaded('nearby') || !isSectionDataLoaded('enrollmentTrends')) {
            nearbyData = await schoolsApi.getAllNearbySchools(selectedSchool.ncessch, { signal });
          }
          
          if (!isSectionDataLoaded('enrollmentTrends')) {
            await schoolsApi.getSchoolsEnrollmentData(
              [selectedSchool.ncessch],
              selectedSchool.ncessch,
              { signal }
            );
            
            setSectionLoadingStates(prev => ({
              ...prev,
              enrollmentTrends: { loading: false, dataReady: true }
            }));
          }
          
          if (!isSectionDataLoaded('nearby') && nearbyData && !signal.aborted) {
            const schoolIds = new Set([
              selectedSchool.ncessch,
              ...Object.values(nearbyData.current || {})
                .flatMap(schools => schools.map(s => s.ncessch))
            ]);
            
            await schoolsApi.getSchoolsEnrollmentData(
              Array.from(schoolIds),
              selectedSchool.ncessch,
              { signal }
            );
            
            setSectionLoadingStates(prev => ({
              ...prev,
              nearby: { loading: false, dataReady: true }
            }));
          }
        } catch (error) {
          if (error.name !== 'AbortError') {
            console.error('Error loading section data:', error);
          }
        }
      };
      
      loadData();
      
      return () => {
        controller.abort();
      };
    }
  }, [selectedSchool, isSectionDataLoaded]);

  useEffect(() => {
    setToolAndSection('competitive-explorer', null);
    
    return () => {
      const geoExplorerExists = document.querySelector('[data-tool="geographic-explorer"]');
      const compExplorerExists = document.querySelector('[data-tool="competitive-explorer"]');
      
      if (!geoExplorerExists && !compExplorerExists) {
        setToolAndSection(null, null);
      }
    };
  }, [setToolAndSection]);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      let maxVisibility = 0;
      let mostVisible = null;
  
      entries.forEach(entry => {
        const sectionId = entry.target.getAttribute('data-section-id');
        if (entry.intersectionRatio > maxVisibility) {
          maxVisibility = entry.intersectionRatio;
          mostVisible = sectionId;
        }
      });
  
      if (mostVisible && maxVisibility > 0.5) {
        setToolAndSection('competitive-explorer', mostVisible);
      }
    }, {
      threshold: [0, 0.5, 1],
      rootMargin: '0px 0px -10% 0px'
    });
  
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [setToolAndSection]);

  useEffect(() => {
    if (schools.length === 0) {
      useSchoolStore.getState().fetchSchools();
    }
  }, [schools.length]);

  useEffect(() => {
    if (schoolsEnrollmentData.length > 0) {
      const types = getAvailableSchoolTypes(schoolsEnrollmentData);
      setAvailableSchoolTypes(types);
    }
  }, [schoolsEnrollmentData]);

  useEffect(() => {
    const hasExpandedSection = Object.values(expandedSections).some(isExpanded => isExpanded);
    if (!hasExpandedSection) {
      setToolAndSection('competitive-explorer', null);
    }
  }, [expandedSections, setToolAndSection]);

  useEffect(() => {
    const changedProps = {};
    const currentProps = {
      selectedSchool,
      filteredSchools,
      schoolsEnrollmentData,
      selectedGrades,
      selectedDriveTime,
      governanceFilter,
      schoolTypeFilter,
      nearbySchools,
      esriData
    };

    Object.entries(currentProps).forEach(([key, value]) => {
      if (prevNearbyProps.current[key] !== value) {
        changedProps[key] = key;
      }
    });

    prevNearbyProps.current = currentProps;
  }, [
    selectedSchool,
    filteredSchools,
    schoolsEnrollmentData,
    selectedGrades,
    selectedDriveTime,
    governanceFilter,
    schoolTypeFilter,
    nearbySchools,
    esriData
  ]);

  // Define actions for Now What section
  const actions = [
    {
      icon: MapsHomeWork,
      title: 'Geographic Explorer',
      description: 'Explore demographic and enrollment trends in your community',
      path: '/geographic-explorer'
    },
    {
      icon: AutoGraphOutlined,
      title: 'Projections Explorer',
      description: 'Explore historical and projected enrollment data for the next five years',
      path: '/projections-explorer'
    },
    {
      icon: DescriptionOutlined,
      title: 'Data Reports',
      description: 'Download detailed reports, including Esri demographic data for selected schools',
      path: '/data-reports'
    }
  ];

  return (
    <PageLayout
      data-tool="competitive-explorer"
      title="Competitive Explorer"
      description={selectedSchool
        ? "Analyze market share data for the selected school. Use the filters to refine your analysis."
        : "Use this tool to analyze market share by students who reside and attend schools in your area. Start by selecting a school."
      }
    >
      <LoadingErrorWrapper
        loading={schoolsLoading}
        error={error}
        initialLoading={!schools || !schools.length}
        hideSteps={true}
      >
        {!selectedSchool ? (
          <InitialSchoolSelector
            schools={schools}
            selectedSchool={selectedSchool}
            onSchoolChange={handleSchoolChange}
          />
        ) : (
          <React.Fragment>
            <Box sx={{ position: 'relative' }}> 
              <StickyFilters
                schools={schools}
                selectedSchool={selectedSchool}
                governanceFilter={governanceFilter}
                selectedGrades={selectedGrades}
                selectedDriveTime={selectedDriveTime}
                schoolTypeFilter={schoolTypeFilter}
                availableSchoolTypes={availableSchoolTypes}
                onSchoolChange={handleSchoolChange}
                onGovernanceChange={handleGovernanceChange}
                onGradeChange={handleGradeChange}
                onDriveTimeChange={handleDriveTimeChange}
                onSchoolTypeChange={handleSchoolTypeChange}
                expandedSections={expandedSections}
                onExpandAll={handleExpandAll}
                onCollapseAll={handleCollapseAll}
                showSchoolType={true}
              />
    
              <AccordionContainer 
                expandedSections={expandedSections}
                onSectionChange={handleSectionChange}
              >
                <AccordionSection
                  id="communityHeadlines"
                  title="Your Community"
                  summary={`Share of estimated school-age children population within ${selectedDriveTime} minutes of ${selectedSchool?.name || 'the selected school'}`}
                  icon={PeopleOutlined}
                  disabled={!sectionLoadingStates.communityHeadlines.dataReady}
                  loading={sectionLoadingStates.communityHeadlines.loading}
                >
                  <div 
                    ref={getSectionRef("communityHeadlines")} 
                    data-section-id="communityHeadlines"
                  >
                    <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                    {sectionLoadingStates.communityHeadlines.dataReady ? (
                      <CompXCommunityHeadlines
                        selectedSchool={selectedSchool}
                        filteredSchools={filteredSchools || []}
                        selectedDriveTime={selectedDriveTime}
                        schoolsEnrollmentData={schoolsEnrollmentData || []}
                        selectedGrades={selectedGrades || []}
                        esriData={esriData || {}}
                      />
                    ) : (
                      <SectionLoadingPlaceholder 
                        message="Loading community data..." 
                      />
                    )}
                    </Suspense>
                  </div>
                </AccordionSection>
    
                <AccordionSection
                  id="enrollmentTrends"
                  title="Enrollment & Demographic Trends"
                  summary={`Share of students enrolled in public schools within ${selectedDriveTime} minutes of ${selectedSchool?.name || 'the selected school'}`}
                  icon={GroupsOutlined}
                  disabled={!sectionLoadingStates.enrollmentTrends.dataReady}
                  loading={sectionLoadingStates.enrollmentTrends.loading}
                >
                  <div 
                    ref={getSectionRef("enrollmentTrends")} 
                    data-section-id="enrollmentTrends"
                  >
                    <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                      {sectionLoadingStates.enrollmentTrends.dataReady ? (
                        <CompXEnrollmentHeadlines
                          selectedSchool={selectedSchool}
                          filteredSchools={filteredSchools || []}
                          selectedGrades={selectedGrades || []}
                          schoolsEnrollmentData={schoolsEnrollmentData || []}
                          esriData={esriData || {}}
                          selectedDriveTime={selectedDriveTime}
                          nearbySchools={nearbySchools || {}} 
                          governanceFilter={governanceFilter}
                        />
                      ) : (
                        <SectionLoadingPlaceholder 
                          message="Loading enrollment data..." 
                        />
                      )}
                    </Suspense>
                  </div>
                </AccordionSection>
    
                <AccordionSection
                  id="nearby"
                  title="Nearby Schools Comparison"
                  summary={`Area school market share and changes in market share within ${selectedDriveTime} minutes of ${selectedSchool?.name || 'the selected school'}`}
                  icon={CompareArrowsOutlined}
                  disabled={!sectionLoadingStates.nearby.dataReady}
                  loading={sectionLoadingStates.nearby.loading}
                >
                  <div 
                    ref={getSectionRef("nearby")} 
                    data-section-id="nearby"
                  >
                    <Suspense fallback={<Skeleton variant="rectangular" height={500} />}>
                      {sectionLoadingStates.nearby.dataReady ? (
                        <CompXNearbyHeadlines
                          {...nearbyHeadlinesProps}
                          PopupComponent={CompXNearbyMapPopup}
                        />
                      ) : (
                        <SectionLoadingPlaceholder 
                          message="Loading comparison data..." 
                        />
                      )}
                    </Suspense>
                  </div>
                </AccordionSection>
    
                <AccordionSection
                  id="nowWhat"
                  title="Now What?"
                  summary="Continue your analysis with these related tools"
                  icon={Explore}
                  disabled={false}
                  loading={false}
                >
                  <div 
                    ref={getSectionRef("nowWhat")} 
                    data-section-id="nowWhat"
                  >
                    <Suspense fallback={<Skeleton variant="rectangular" height={200} />}>
                      <NowWhatSection 
                        sectionIcon={Explore}
                        actions={actions}
                      />
                    </Suspense>
                  </div>
                </AccordionSection>
              </AccordionContainer>
            </Box>
          </React.Fragment>
        )}
      </LoadingErrorWrapper>
    </PageLayout>
  );
}

export default CompetitiveExplorer;