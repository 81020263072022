import React from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  IconButton, 
  Tooltip
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import ChartWrapper from '../chartWrapper'
import ChartDataLabels from 'chartjs-plugin-datalabels';

const FONT_SIZES = {
  dataLabel: 14,
  axisLabel: 13,
  legend: 13
};

const LineChartTemplate = ({
  icon: Icon,
  title,
  analysis,
  tooltip,
  chartData,
  chartOptions,
  additionalInfo,
  analysisBackgroundColor = 'rgba(25, 118, 210, 0.1)',
  analysisTextColor = 'primary.main',
  height = 400,
  cardProps = {},
  animationEnabled = true  // Add this prop with default
}) => {
  const defaultChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: animationEnabled ? {
      duration: 750,
      easing: 'easeInOutQuart'
    } : false,  // Disable animation completely when not enabled
    layout: {
      padding: {
        top: 40,
        bottom: 8,
        left: 8,
        right: 40
      }
    },
    scales: {
      y: {
        grid: {
          color: '#e0e0e0'
        },
        ticks: {
          font: {
            size: FONT_SIZES.axisLabel,
            weight: 500
          }
        },
        border: { display: false }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: FONT_SIZES.axisLabel,
            weight: 500
          }
        },
        border: { display: false }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: FONT_SIZES.legend,
            weight: 500
          },
          padding: 15,
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      datalabels: {
        font: {
          size: FONT_SIZES.dataLabel,
          weight: 600
        },
        padding: 4,
        offset: 20,
        backgroundColor: 'white',
        borderRadius: 4,
        shadowColor: 'rgba(0,0,0,0.1)',
        shadowBlur: 3,
        shadowOffsetY: 1
      }
    }
  };

  const mergedOptions = {
    ...defaultChartOptions,
    ...chartOptions,
    plugins: {
      ...defaultChartOptions.plugins,
      ...chartOptions?.plugins,
      datalabels: {
        ...defaultChartOptions.plugins.datalabels,
        ...chartOptions?.plugins?.datalabels,
        font: {
          ...defaultChartOptions.plugins.datalabels.font,
          ...chartOptions?.plugins?.datalabels?.font
        }
      }
    },
    layout: {
      ...defaultChartOptions.layout,
      ...(chartOptions?.layout || {}),
      padding: {
        ...defaultChartOptions.layout.padding,
        ...(chartOptions?.layout?.padding || {})
      }
    }
  };

  return (
    <Card 
      {...cardProps} 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        ...(cardProps.sx || {})
      }}
    >
      <CardContent sx={{ 
        flex: 1, 
        display: 'flex', 
        flexDirection: 'column',
        pb: 2,
        '&:last-child': { pb: 2 }
      }}>
        {/* Header Section */}
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            {Icon && <Icon sx={{ color: 'primary.main', mr: 1 }} />}
            <Typography variant="h6" sx={{ 
              flexGrow: 1, 
              fontWeight: 600
            }}>
              {title}
            </Typography>
            {tooltip && (
              <Tooltip title={tooltip}>
                <IconButton size="small">
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          
          {analysis && (
            <Typography 
              variant="body2" 
              sx={{ 
                bgcolor: analysisBackgroundColor,
                color: analysisTextColor,
                p: 1,
                borderRadius: 1,
                fontWeight: 500
              }}
            >
              {analysis}
            </Typography>
          )}
        </Box>

        {/* Chart Section */}
        <Box sx={{ 
          flex: 1,
          height,
          position: 'relative',
          mb: additionalInfo ? 2 : 0
        }}>
          {chartData && (
            <ChartWrapper 
              type="line"
              data={chartData}
              options={mergedOptions}
              plugins={[ChartDataLabels]}
              height={height}
            />
          )}
        </Box>

        {/* Footer Section */}
        <Box sx={{ mt: 'auto', pt: 1 }}>
          {additionalInfo && (
            <Typography 
              variant="caption" 
              color="text.secondary" 
              sx={{ 
                display: 'block',
                textAlign: 'center',
                mb: 1
              }}
            >
              {additionalInfo}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default LineChartTemplate;