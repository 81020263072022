import React from 'react';
import { Box, Container, FormControl, InputLabel, Select, MenuItem, Grid, Tooltip, Button, Divider } from '@mui/material';
import { FilterList, RestartAlt as RestartAltIcon } from '@mui/icons-material';
import DistrictMetricToggles from './DistrictMetricToggles';

const DistrictAnalysisFilters = ({
  filters,
  onFilterChange,
  availableFilters,
  onReset,
}) => {
  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'grey.50',
        backdropFilter: 'blur(8px)',
        zIndex: 1200,
        borderBottom: '1px solid',
        borderColor: 'grey.200',
        py: 1.5,
        mb: 3,
        width: '100%',
        height: 'auto',
        minHeight: 'fit-content',
        boxShadow: '0 2px 4px rgba(0,0,0,0.04)',
      }}
    >
      <Container 
        maxWidth={false}
        sx={{
          px: { xs: 2, sm: 3 },
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2,
          justifyContent: 'space-between'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
            <Tooltip title="Filters">
              <FilterList 
                color="primary" 
                sx={{ opacity: 0.8, '&:hover': { opacity: 1 }, flexShrink: 0 }} 
              />
            </Tooltip>
  
            <Grid container spacing={2} sx={{ flex: 1 }}>
              {/* Network filter removed */}
  
              {/* State Filter */}
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size="small">
                  <InputLabel>State</InputLabel>
                  <Select
                    value={filters.state || ''}
                    label="State"
                    onChange={(e) => onFilterChange('state', e.target.value)}
                  >
                    <MenuItem value="">All States</MenuItem>
                    {availableFilters.states.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
  
              {/* City Filter - Multi-select */}
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size="small">
                  <InputLabel>City</InputLabel>
                  <Select
                    multiple
                    value={filters.city || []}
                    label="City"
                    onChange={(e) => onFilterChange('city', e.target.value)}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={{
                      PaperProps: {
                        sx: { maxHeight: 300 }
                      }
                    }}
                  >
                    {availableFilters.cities.map((city) => (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
  
              {/* Grade Level Filter */}
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth size="small">
                  <InputLabel>Grade Level</InputLabel>
                  <Select
                    value={filters.gradeLevel || ''}
                    label="Grade Level"
                    onChange={(e) => onFilterChange('gradeLevel', e.target.value)}
                  >
                    <MenuItem value="">All Grade Levels</MenuItem>
                    {availableFilters.gradeLevels.map((level) => (
                      <MenuItem key={level} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
  
          {/* Reset Button */}
          <Button
            variant="outlined"
            size="small"
            startIcon={<RestartAltIcon />}
            onClick={onReset}
            sx={{ 
              ml: 2,
              whiteSpace: 'nowrap',
              flexShrink: 0
            }}
          >
            Reset Filters
          </Button>
        </Box>
  
        <Divider />
  
        {/* Metric Toggles */}
        <Box>
          <DistrictMetricToggles 
            filters={filters} 
            onFilterChange={onFilterChange}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default DistrictAnalysisFilters;