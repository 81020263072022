// cacheService.js
class SchoolCacheService {
    constructor() {
        this.DB_NAME = 'schoolDataCache';
        this.DB_VERSION = 1;
        this.CHUNK_SIZE = 250; // Size of chunks for large datasets
        this.STORES = {
          schools: 'schools',
          teamAccess: 'teamAccess',
          searchIndex: 'searchIndex'
        };
      }

    async initialize() {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(this.DB_NAME, this.DB_VERSION);
  
        request.onerror = () => reject(request.error);
        request.onsuccess = () => {
          this.db = request.result;
          resolve();
        };
  
        request.onupgradeneeded = (event) => {
          const db = event.target.result;
  
          // Create stores if they don't exist
          if (!db.objectStoreNames.contains(this.STORES.schools)) {
            const schoolStore = db.createObjectStore(this.STORES.schools, { keyPath: 'ncessch' });
            schoolStore.createIndex('by_team', 'teams', { multiEntry: true });
            schoolStore.createIndex('by_name', 'name');
            schoolStore.createIndex('by_location', 'location');
          }
  
          if (!db.objectStoreNames.contains(this.STORES.teamAccess)) {
            const teamStore = db.createObjectStore(this.STORES.teamAccess, { keyPath: 'teamId' });
            teamStore.createIndex('by_school', 'schools', { multiEntry: true });
          }
  
          if (!db.objectStoreNames.contains(this.STORES.searchIndex)) {
            const searchStore = db.createObjectStore(this.STORES.searchIndex, { keyPath: 'id' });
            searchStore.createIndex('by_term', 'searchTerms', { multiEntry: true });
          }
        };
      });
    }
  
    // Cache school data with team associations
    async cacheSchoolData(schools, teamId) {
      // Handle large datasets by chunking
      const chunks = this._chunkArray(schools, this.CHUNK_SIZE);
      
      for (let i = 0; i < chunks.length; i++) {
        await this._cacheSchoolChunk(chunks[i], teamId);
      }
    }

    async _cacheSchoolChunk(schools, teamId) {
      
      // Return a proper Promise that doesn't resolve until all operations complete
      return new Promise((resolve, reject) => {
        try {
          const tx = this.db.transaction([this.STORES.schools, this.STORES.teamAccess], 'readwrite');
          
          tx.onerror = (event) => {
            reject(event.target.error);
          };
          
          tx.oncomplete = () => {
            resolve();
          };
          
          // Update team access store
          const teamStore = tx.objectStore(this.STORES.teamAccess);
          const schoolStore = tx.objectStore(this.STORES.schools);
          
          const teamRequest = teamStore.get(teamId);
          teamRequest.onsuccess = () => {
            const teamData = teamRequest.result || { teamId, schools: [] };
            const newSchoolIds = schools.map(s => s.ncessch);
            teamData.schools = [...new Set([...teamData.schools, ...newSchoolIds])];
            teamStore.put(teamData);
            
          };
          
          // Loop through schools
          schools.forEach(school => {
            const getRequest = schoolStore.get(school.ncessch);
            getRequest.onsuccess = () => {
              const existingData = getRequest.result;
              const teams = new Set(existingData?.teams || []);
              teams.add(teamId);
              
              const updatedSchool = {
                ...school,
                teams: Array.from(teams),
                lastUpdated: new Date().toISOString()
              };
              
              schoolStore.put(updatedSchool);
            };
          });
        } catch (error) {
          reject(error);
        }
      });
    }

    _chunkArray(array, size) {
      const chunks = [];
      for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
      }
      return chunks;
    }

    // Add optimization for search index
    async _updateSearchIndex(school) {
      const tx = this.db.transaction([this.STORES.searchIndex], 'readwrite');
      const searchStore = tx.objectStore(this.STORES.searchIndex);

      const searchTerms = [
        school.name.toLowerCase(),
        school.city?.toLowerCase(),
        school.state?.toLowerCase(),
        school.ncessch
      ].filter(Boolean);

      return new Promise((resolve, reject) => {
        const request = searchStore.put({
          id: school.ncessch,
          searchTerms,
          lastUpdated: new Date().toISOString()
        });

        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
      });
    }

    // Optimize search performance
    async searchSchools(query, teamIds) {
      const tx = this.db.transaction([this.STORES.schools, this.STORES.searchIndex], 'readonly');
      const schoolStore = tx.objectStore(this.STORES.schools);
      const teamIndex = schoolStore.index('by_team');

      const searchString = query.toLowerCase();
      
      return new Promise((resolve, reject) => {
        const results = new Set();
        const promises = teamIds.map(teamId =>
          new Promise(resolveTeam => {
            const request = teamIndex.getAll(teamId);
            request.onsuccess = () => {
              const schools = request.result;
              schools.forEach(school => {
                if (this._matchesSearch(school, searchString)) {
                  results.add(school);
                }
              });
              resolveTeam();
            };
          })
        );

        Promise.all(promises)
          .then(() => resolve(Array.from(results)))
          .catch(reject);
      });
    }
    
    // Get schools for a specific team
    async getTeamSchools(teamId) {
      if (!this.db) {
        await this.initialize();
      }
    
      if (!teamId) {
        return [];
      }
  
      
      // First check if the team exists in the team access store
      try {
        const tx = this.db.transaction([this.STORES.teamAccess], 'readonly');
        const teamStore = tx.objectStore(this.STORES.teamAccess);
        
        // Use a cleaner promise-based approach
        const teamData = await new Promise((resolve) => {
          const request = teamStore.get(teamId);
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => {
            resolve(null);
          };
        });
        
        if (!teamData || !teamData.schools || !teamData.schools.length) {
          return [];
        }
        
        // Now get the actual school records
        const schoolsTx = this.db.transaction([this.STORES.schools], 'readonly');
        const schoolStore = schoolsTx.objectStore(this.STORES.schools);
        
        // Process in chunks to avoid transaction timeouts with large datasets
        const FETCH_CHUNK_SIZE = 100;
        const chunks = this._chunkArray(teamData.schools, FETCH_CHUNK_SIZE);
        let allSchools = [];
        
        for (const chunk of chunks) {
          const chunkSchools = await Promise.all(
            chunk.map(ncessch => 
              new Promise(resolve => {
                const request = schoolStore.get(ncessch);
                request.onsuccess = () => resolve(request.result);
                request.onerror = () => {
                  resolve(null);
                };
              })
            )
          );
          
          allSchools = [...allSchools, ...chunkSchools.filter(Boolean)];
        }

        return allSchools;
      } catch (error) {
        return [];
      }
    }
  
    // Clear cache for a specific team
    async clearTeamCache(teamId) {
      const tx = this.db.transaction([this.STORES.teamAccess, this.STORES.schools], 'readwrite');
      const teamStore = tx.objectStore(this.STORES.teamAccess);
      const schoolStore = tx.objectStore(this.STORES.schools);
  
      return new Promise((resolve, reject) => {
        const teamRequest = teamStore.get(teamId);
  
        teamRequest.onsuccess = () => {
          const teamData = teamRequest.result;
          if (teamData) {
            // Remove team from school records
            teamData.schools.forEach(ncessch => {
              const schoolRequest = schoolStore.get(ncessch);
              schoolRequest.onsuccess = () => {
                const school = schoolRequest.result;
                if (school) {
                  school.teams = school.teams.filter(t => t !== teamId);
                  if (school.teams.length === 0) {
                    schoolStore.delete(ncessch);
                  } else {
                    schoolStore.put(school);
                  }
                }
              };
            });
  
            // Remove team access record
            teamStore.delete(teamId);
          }
        };
  
        tx.oncomplete = () => resolve();
        tx.onerror = () => reject(tx.error);
      });
    }
  
    // Clear all cached data
    async clearAllCache() {
      try {
        // Initialize the DB if it's not already initialized
        if (!this.db) {
          await this.initialize();
        }
        
        // Now proceed with clearing the cache
        const tx = this.db.transaction(Object.values(this.STORES), 'readwrite');
        
        return Promise.all(
          Object.values(this.STORES).map(
            storeName => new Promise((resolve, reject) => {
              const store = tx.objectStore(storeName);
              const request = store.clear();
              request.onsuccess = () => resolve();
              request.onerror = () => reject(request.error);
            })
          )
        );
      } catch (error) {
        // Return a resolved promise even on error so the app can continue
        return Promise.resolve();
      }
    }
  
    // Helper method for search matching
    _matchesSearch(school, query) {
      const searchString = query.toLowerCase();
      return (
        school.name.toLowerCase().includes(searchString) ||
        school.city?.toLowerCase().includes(searchString) ||
        school.state?.toLowerCase().includes(searchString) ||
        school.ncessch.includes(searchString)
      );
    }

    async cacheDistrictData(districtData, teamId) {
      if (!districtData?.metrics || !teamId) {
        return;
      }
      
      // Use the existing school caching method for district metrics
      await this.cacheSchoolData(districtData.metrics, teamId);
      
      // Store the timestamp when this district data was cached
      const tx = this.db.transaction([this.STORES.teamAccess], 'readwrite');
      const teamStore = tx.objectStore(this.STORES.teamAccess);
      
      return new Promise((resolve, reject) => {
        const teamRequest = teamStore.get(teamId);
        teamRequest.onsuccess = () => {
          const teamData = teamRequest.result || { teamId, schools: [] };
          teamData.lastDistrictUpdate = new Date().toISOString();
          teamData.districtDataVersion = districtData.version || '1.0';
          
          teamStore.put(teamData);
          resolve();
        };
        
        teamRequest.onerror = () => reject(teamRequest.error);
        tx.oncomplete = () => resolve();
        tx.onerror = () => reject(tx.error);
      });
    }
    
    // Get district metadata (when it was last updated, etc.)
    async getDistrictMetadata(teamId) {
      if (!this.db) {
        await this.initialize();
      }
      
      if (!teamId) return null;
      
      const tx = this.db.transaction([this.STORES.teamAccess], 'readonly');
      const teamStore = tx.objectStore(this.STORES.teamAccess);
      
      return new Promise((resolve) => {
        const request = teamStore.get(teamId);
        request.onsuccess = () => {
          const teamData = request.result;
          if (!teamData) {
            resolve(null);
            return;
          }
          
          resolve({
            lastUpdated: teamData.lastDistrictUpdate,
            version: teamData.districtDataVersion,
            schoolCount: teamData.schools?.length || 0
          });
        };
        
        request.onerror = () => resolve(null);
      });
    }
  }
  
  export const schoolCache = new SchoolCacheService();