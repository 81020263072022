// utils/explorerAccess.js

import { ROLES } from '../constants/roleAccess';

// Roles that can access the explorer tools
const EXPLORER_ACCESS_ROLES = [
  ROLES.ADMIN,
  ROLES.UPDATED_WRITE,
  ROLES.UPDATED_READ,
  ROLES.PUBLIC
];

export const canAccessExplorers = (role) => {
  return EXPLORER_ACCESS_ROLES.includes(role);
};