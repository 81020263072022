import { create } from 'zustand';

// Thresholds for different metrics
const POPULATION_THRESHOLD = 2.0;
const PROJECTION_THRESHOLD = 2.0;
const MARKET_SHARE_THRESHOLD = 1.4;
const ENROLLMENT_THRESHOLD = 5.0;

const useSchoolMetricsStore = create((set, get) => ({
  schoolMetrics: {},
  districtMetrics: null,

  setDistrictMetrics: (metrics) => {
    set({ districtMetrics: metrics });
  },

  clearDistrictMetrics: () => {
    set({ districtMetrics: null });
  },
  
  // Individual school metrics
  setSchoolMetrics: (schoolId, metrics) => {
    set((state) => ({
      schoolMetrics: {
        ...state.schoolMetrics,
        [schoolId]: metrics
      }
    }));
  },

  clearSchoolMetrics: (schoolId) => {
    set((state) => {
      const { [schoolId]: _, ...rest } = state.schoolMetrics;
      return { schoolMetrics: rest };
    });
  },

  clearAllMetrics: () => {
    set({ 
      schoolMetrics: {},
      districtMetrics: null 
    });
  },

  // Modified to handle both individual school and district-wide aggregation
  getAggregatedMetrics: () => {
    const { districtMetrics } = get();
    
    // If district metrics exist, use those
    if (districtMetrics) {
      return districtMetrics;
    }

    // Otherwise fall back to aggregating individual school metrics
    const metrics = get().schoolMetrics;
    const schools = Object.values(metrics);
    
    if (schools.length === 0) {
      return {
        populationMetrics: { total: 0, growing: 0, declining: 0, stable: 0 },
        projectionMetrics: { total: 0, growing: 0, declining: 0, stable: 0 },
        marketShareMetrics: { total: 0, growing: 0, declining: 0, stable: 0 },
        enrollmentTrendMetrics: { total: 0, growing: 0, declining: 0, stable: 0 },
        projectedEnrollmentMetrics: { total: 0, growing: 0, declining: 0, stable: 0 }
      };
    }

    // Current Population Metrics (from currentVsPastChange)
    const populationMetrics = schools.reduce((acc, school) => {
      if (school.currentVsPastChange !== undefined) {
        acc.total++;
        if (school.currentVsPastChange >= POPULATION_THRESHOLD) acc.growing++;
        else if (school.currentVsPastChange <= -POPULATION_THRESHOLD) acc.declining++;
        else acc.stable++;
      }
      return acc;
    }, { total: 0, growing: 0, declining: 0, stable: 0 });

    // Population Projections Metrics (from totalChange)
    const projectionMetrics = schools.reduce((acc, school) => {
      if (school.totalChange !== undefined) {
        acc.total++;
        if (school.totalChange >= PROJECTION_THRESHOLD) acc.growing++;
        else if (school.totalChange <= -PROJECTION_THRESHOLD) acc.declining++;
        else acc.stable++;
      }
      return acc;
    }, { total: 0, growing: 0, declining: 0, stable: 0 });

    // Market Share Metrics (from marketShareData.change)
    const marketShareMetrics = schools.reduce((acc, school) => {
      if (school.marketShareData?.change !== undefined) {
        acc.total++;
        if (school.marketShareData.change >= MARKET_SHARE_THRESHOLD) acc.growing++;
        else if (school.marketShareData.change <= -MARKET_SHARE_THRESHOLD) acc.declining++;
        else acc.stable++;
      }
      return acc;
    }, { total: 0, growing: 0, declining: 0, stable: 0 });

    // Enrollment Trend Metrics (from selectedSchoolTrends.selectedSchool.percentChange)
    const enrollmentTrendMetrics = schools.reduce((acc, school) => {
      const percentChange = school.selectedSchoolTrends?.selectedSchool?.percentChange;
      if (percentChange !== undefined) {
        acc.total++;
        if (percentChange >= ENROLLMENT_THRESHOLD) acc.growing++;
        else if (percentChange <= -ENROLLMENT_THRESHOLD) acc.declining++;
        else acc.stable++;
      }
      return acc;
    }, { total: 0, growing: 0, declining: 0, stable: 0 });

    // Projected Enrollment Metrics (from enrollmentProjections)
    const projectedEnrollmentMetrics = schools.reduce((acc, school) => {
      const projections = school.enrollmentProjections?.projections?.median;
      if (projections) {
        const years = Object.keys(projections).sort();
        if (years.length >= 2) {
          const firstYear = years[0];
          const lastYear = years[years.length - 1];
          
          const startValue = Object.values(projections[firstYear]).reduce((sum, val) => sum + (val || 0), 0);
          const endValue = Object.values(projections[lastYear]).reduce((sum, val) => sum + (val || 0), 0);
          
          if (startValue && endValue) {
            const change = ((endValue - startValue) / startValue) * 100;
            acc.total++;
            
            if (change >= ENROLLMENT_THRESHOLD) acc.growing++;
            else if (change <= -ENROLLMENT_THRESHOLD) acc.declining++;
            else acc.stable++;
          }
        }
      }
      return acc;
    }, { total: 0, growing: 0, declining: 0, stable: 0 });

    // Calculate percentages for all metrics
    const calculatePercentages = (metrics) => ({
      ...metrics,
      growingPercent: metrics.total > 0 ? (metrics.growing / metrics.total) * 100 : 0,
      decliningPercent: metrics.total > 0 ? (metrics.declining / metrics.total) * 100 : 0,
      stablePercent: metrics.total > 0 ? (metrics.stable / metrics.total) * 100 : 0
    });

    return {
      populationMetrics: calculatePercentages(populationMetrics),
      projectionMetrics: calculatePercentages(projectionMetrics),
      marketShareMetrics: calculatePercentages(marketShareMetrics),
      enrollmentTrendMetrics: calculatePercentages(enrollmentTrendMetrics),
      projectedEnrollmentMetrics: calculatePercentages(projectedEnrollmentMetrics)
    };
  }
}));

export default useSchoolMetricsStore;