// hooks/useDistrictCache.js
import { useEffect, useState, useCallback } from 'react';
import { districtCache } from '../services/districtCacheService';
import { useTeamCache } from './teamCacheService';
import { schoolsApi } from '../utils/apiService';

export function useDistrictCache(options = { silentLoad: false }) {
  const { activeTeam } = useTeamCache();
  const [loading, setLoading] = useState(!options.silentLoad);
  const [error, setError] = useState(null);
  const [districtData, setDistrictData] = useState(null);

  const loadDistrictData = useCallback(async (silent = false) => {
    try {
      if (!silent) {
        setLoading(true);
      }
      setError(null);
      
      const teamId = activeTeam?.id || 'base';
      await districtCache.initialize();

      // Try to load from cache first
      const metadata = await districtCache.getMetadata(teamId);
      let needsRefresh = true;

      if (metadata) {
        const cachedMetrics = await districtCache.getTeamMetrics(teamId);
        if (cachedMetrics?.length > 0) {
          setDistrictData({ metrics: cachedMetrics, version: metadata.version });
          
          // Check if we need to refresh in background
          const lastUpdated = new Date(metadata.lastUpdated);
          const now = new Date();
          const hoursSinceUpdate = (now - lastUpdated) / (1000 * 60 * 60);
          needsRefresh = hoursSinceUpdate > 24;
          
          // If data is fresh enough, we're done
          if (!needsRefresh) {
            if (!silent) setLoading(false);
            return;
          }
        }
      }

      // If we need fresh data, fetch it
      const response = await schoolsApi.getDistrictMetrics();
      
      if (response?.metrics?.length > 0) {
        await districtCache.cacheDistrictData(response, teamId);
        setDistrictData(response);
      }
    } catch (err) {
      console.error('Error loading district data:', err);
      setError(err.message || 'Failed to load district data');
    } finally {
      if (!silent) {
        setLoading(false);
      }
    }
  }, [activeTeam]);

  // Initial load effect
  useEffect(() => {
    loadDistrictData(options.silentLoad);
  }, [loadDistrictData, options.silentLoad]);

  return { 
    districtData, 
    loading, 
    error,
    hasTeam: Boolean(activeTeam?.id),
    refresh: (silent = false) => loadDistrictData(silent)
  };
}