import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { checkAppVersion, startVersionCheckInterval } from '../services/versionService';

/**
 * Custom hook to handle version checking on route changes and at intervals
 */
export function useVersionHandler() {
  const location = useLocation();
  const previousPath = useRef(location.pathname);
  const hasInitialized = useRef(false);
  
  // Handle overlay cleanup on mount and when component is active
  useEffect(() => {
    // Safety mechanism to clear stuck overlay
    const updateInProgress = localStorage.getItem('update_in_progress');
    if (updateInProgress) {
      const overlay = document.getElementById('version-update-overlay');
      
      // Check if update has been stuck for too long (30 seconds)
      const updateStartTime = parseInt(updateInProgress);
      const currentTime = Date.now();
      
      if (isNaN(updateStartTime) || currentTime - updateStartTime > 30000) {
        // Update is stuck or invalid, clean it up
        localStorage.removeItem('update_in_progress');
        
        if (overlay) {
          overlay.style.display = 'none';
        }
      } else if (overlay) {
        // Update is still valid but we've fully loaded the app, so hide the overlay
        setTimeout(() => {
          overlay.style.display = 'none';
          localStorage.removeItem('update_in_progress');
        }, 500);
      }
    }
  }, []);
  
  // One-time initialization check
  useEffect(() => {
    if (!hasInitialized.current) {
      
      // Initial check on mount with force flag
      checkAppVersion(true);
      
      // Start interval checking
      const stopInterval = startVersionCheckInterval();
      hasInitialized.current = true;
      
      // Clean up interval on unmount
      return () => {
        stopInterval();
      };
    }
  }, []);
  
  // Check version on route changes
  useEffect(() => {
    if (location.pathname !== previousPath.current) {
      // Only check when path actually changes
      checkAppVersion();
      previousPath.current = location.pathname;
    }
  }, [location.pathname]);
  
  // Add additional check for user activity
  useEffect(() => {
    let activityTimeout;
    
    const handleUserActivity = () => {
      clearTimeout(activityTimeout);
      activityTimeout = setTimeout(() => {
        checkAppVersion();
      }, 60000); // Check after 1 minute of inactivity
    };
    
    // Add activity listeners
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    
    return () => {
      clearTimeout(activityTimeout);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
    };
  }, []);
}