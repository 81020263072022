import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { MapContainer, TileLayer, Marker, GeoJSON, useMap, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import PropTypes from 'prop-types';
import { Typography, Box, Divider, IconButton, Collapse, Paper } from '@mui/material';
import { 
  Close as CloseIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
  LocationOn as LocationOnIcon,
  SchoolOutlined as SchoolOutlinedIcon,
  School as SchoolIcon 
} from '@mui/icons-material';

// Import map utilities
import { 
  customIcon, 
  getSchoolColor,
  driveTimeColors,
  colorScales,
} from '../../utils/unifiedMapUtils';

import {
  calculateMarketShares,
  filterSchools,
  deduplicateSchools,
} from '../../utils/schoolUtils';

const Legend = React.memo(({ selectedSchool, mode }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 20,
        right: 10,
        backgroundColor: 'white',
        borderRadius: '5px',
        zIndex: 1000,
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        maxWidth: '200px'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
        <Typography variant="subtitle2">Legend</Typography>
        <IconButton size="small" onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box sx={{ p: 1 }}>
          {selectedSchool && (
            <Box display="flex" alignItems="center" mb={1}>
              {selectedSchool.charter === 'Yes' ? (
                <SchoolIcon sx={{ mr: 1, color: '#4338ca' }} />
              ) : (
                <LocationOnIcon sx={{ mr: 1, color: '#4338ca' }} />
              )}
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {selectedSchool.name}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center" mb={1}>
            <SchoolOutlinedIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body2">Charter School</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <LocationOnOutlinedIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body2">District School</Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          {colorScales[mode].map(({ color, label }) => (
            <Box key={label} display="flex" alignItems="center" mb={1}>
              {color.startsWith('linear-gradient') ? (
                <Box 
                  width={100} 
                  height={20} 
                  mr={1} 
                  sx={{ 
                    background: color,
                    borderRadius: '4px'
                  }} 
                />
              ) : (
                <Box 
                  width={20} 
                  height={20} 
                  bgcolor={color} 
                  mr={1} 
                />
              )}
              <Typography variant="body2">{label}</Typography>
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
});

const MapOverlay = React.memo(({ position, children }) => {
  const map = useMap();
  const [point, setPoint] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!map || !position) return;

    const updatePosition = () => {
      const latLng = L.latLng(position[0], position[1]);
      const point = map.latLngToContainerPoint(latLng);
      setPoint(point);
    };

    updatePosition();

    map.on('zoom', updatePosition);
    map.on('move', updatePosition);
    map.on('moveend', updatePosition);

    return () => {
      map.off('zoom', updatePosition);
      map.off('move', updatePosition);
      map.off('moveend', updatePosition);
    };
  }, [map, position]);

  if (!position) return null;

  return (
    <div style={{
      position: 'absolute',
      left: point.x,
      top: point.y,
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'none',
      zIndex: 900
    }}>
      {children}
    </div>
  );
});

const InfoPanel = React.memo(({ 
  school, 
  data, 
  onClose, 
  PopupComponent, 
  schoolsEnrollmentData,
  selectedGrades,
  mode
}) => {

  if (!school) return null;

  // Find the full school data
  const fullSchoolData = schoolsEnrollmentData.find(d => d.ncessch === school.ncessch);

  // Prepare props based on mode
  const popupProps = mode === 'market-share' ? {
    school,
    marketShareData: data?.marketShareData,
    enrollmentData: data?.enrollmentData,
    selectedGrades
  } : {
    school,
    schoolData: fullSchoolData,
    selectedGrades
  };

  return (
    <Paper
      sx={{
        position: 'absolute',
        left: 10,
        top: 10,
        zIndex: 1100,
        padding: 2,
        width: {
          xs: '90%',
          sm: 330,
          md: 330,
        },
        maxWidth: 500,
        maxHeight: 'calc(100% - 20px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: 'white',
        boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{ 
          position: 'absolute', 
          right: 8, 
          top: 8,
          zIndex: 1200
        }}
      >
        <CloseIcon />
      </IconButton>
      <PopupComponent {...popupProps} />
    </Paper>
  );
});

const SelectionIndicator = React.memo(() => (
  <div className="map-selection-indicator">
    <svg width="40" height="40" viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="18" fill="none" stroke="#3388ff" strokeWidth="2" />
      <circle cx="20" cy="20" r="4" fill="#3388ff" />
    </svg>
  </div>
));

const MapContent = React.memo(({
  mode,
  selectedSchool,
  filteredSchools,
  marketShares: externalMarketShares,
  esriData,
  visibleDriveTimes,
  schoolsEnrollmentData,
  governanceFilter,
  selectedGrades,
  schoolTypeFilter,
  PopupComponent,
  focusedSchool,
  onSchoolSelect,
  initialZoom,
  mapReady,
  mapRef,
  setMapReady
}) => {
  const map = useMap();
  const [geoJsonData, setGeoJsonData] = useState({});
  const [selectedMarkerSchool, setSelectedMarkerSchool] = useState(null);
  const [selectionIndicatorPos, setSelectionIndicatorPos] = useState(null);
  const [nearbySchoolCount, setNearbySchoolCount] = useState(0);

  // Memoize marketShares calculation
  const marketShares = useMemo(() => {
    if (externalMarketShares) return externalMarketShares;
    if (mode !== 'market-share') return {};
    return calculateMarketShares(filteredSchools, schoolsEnrollmentData, selectedGrades);
  }, [mode, filteredSchools, schoolsEnrollmentData, selectedGrades, externalMarketShares]);

  // Process GeoJSON data
  const updateGeoJsonData = useCallback((data) => {
    if (!data?.drive_times || !mapReady || !mapRef?.current) {
      return;
    }
  
    // Process polygon data first
    const newGeoJsonData = {};
    Object.entries(data.drive_times).forEach(([driveTime, timeData]) => {
      if (!timeData?.drive_time_polygon) return;
      
      try {
        let polygonData = timeData.drive_time_polygon;
        if (typeof polygonData === 'string') {
          polygonData = JSON.parse(polygonData);
          if (typeof polygonData === 'string') {
            polygonData = JSON.parse(polygonData);
          }
        }
        
        const coordinates = polygonData.rings;
        if (!coordinates?.length) return;
        
        // Create proper GeoJSON structure
        const geoJsonFeature = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: coordinates
          }
        };
  
        newGeoJsonData[parseInt(driveTime)] = geoJsonFeature;
      } catch (error) {
        console.error(`Error processing drive time ${driveTime}:`, error);
      }
    });
  
    setGeoJsonData(newGeoJsonData);
  
    // Create bounds that include selected school and nearby schools
    const bounds = L.latLngBounds(
      L.latLng(selectedSchool.latitude, selectedSchool.longitude),
      L.latLng(selectedSchool.latitude, selectedSchool.longitude)
    );
  
    // Include all nearby schools within the initial view
    const nearbySchools = filteredSchools
      .filter(school => school.latitude && school.longitude)
      .slice(0, 15);
  
    nearbySchools.forEach(school => {
      bounds.extend(L.latLng(school.latitude, school.longitude));
    });
  
    // Use generous padding
    const paddedBounds = bounds.pad(0.6);
  
    // Apply very flexible zoom constraints
    if (mapRef.current && mapReady) {
      requestAnimationFrame(() => {
        mapRef.current.fitBounds(paddedBounds, {
          padding: [40, 40],
          maxZoom: 12,
          minZoom: 8,
          animate: true
        });
      });
    }
  }, [mapReady, mapRef, selectedSchool, filteredSchools]);
  
  useEffect(() => {
    if (map) {
      const originalFlyTo = map.flyTo;
      map.flyTo = function(...args) {
        if (selectedMarkerSchool) {
          return this;
        }
        return originalFlyTo.apply(this, args);
      };
      return () => {
        map.flyTo = originalFlyTo;
      };
    }
  }, [map, selectedMarkerSchool]);
  
  useEffect(() => {
    if (esriData) {
      updateGeoJsonData(esriData);
    }
  }, [esriData, updateGeoJsonData]);

  useEffect(() => {
    if (map) {
      const originalFlyTo = map.flyTo;
      map.flyTo = function(...args) {
        if (selectedMarkerSchool) {
          return this;
        }
        return originalFlyTo.apply(this, args);
      };
      return () => {
        map.flyTo = originalFlyTo;
      };
    }
  }, [map, selectedMarkerSchool]);

  useEffect(() => {
    if (esriData) {
      updateGeoJsonData(esriData);
    }
  }, [esriData, updateGeoJsonData]);

  // Handle marker clicks with stable reference
  const handleMarkerClick = useCallback((school) => {
    if (!map) return;
    
    setSelectedMarkerSchool(current => 
      current?.ncessch === school.ncessch ? null : school
    );
    setSelectionIndicatorPos(current => 
      current ? null : [school.latitude, school.longitude]
    );
    
    onSchoolSelect?.(school.ncessch);
  }, [map, onSchoolSelect]);

  const handleInfoPanelClose = useCallback(() => {
    setSelectedMarkerSchool(null);
    setSelectionIndicatorPos(null);
    // Don't call map.setView at all
    onSchoolSelect?.(null);
  }, [onSchoolSelect]);

  // Calculate visible schools with proper memoization
  const visibleSchools = useMemo(() => {    
    const filters = {
      selectedGrades,
      governanceFilter,
      schoolTypeFilter,
    };
    
    const filtered = filterSchools(filteredSchools, filters, schoolsEnrollmentData);
    
    const uniqueSchools = deduplicateSchools(filtered);
    
    if (!uniqueSchools.find(school => school.ncessch === selectedSchool.ncessch)) {
      uniqueSchools.unshift(selectedSchool);
    }
    
    const nearbyCount = uniqueSchools.filter(school => 
      school.ncessch !== selectedSchool.ncessch && 
      visibleDriveTimes.includes(school.drive_time)
    ).length;
    setNearbySchoolCount(nearbyCount);
  
    return uniqueSchools;
  }, [
    filteredSchools, 
    selectedGrades, 
    governanceFilter, 
    schoolTypeFilter, 
    schoolsEnrollmentData, 
    selectedSchool,
    visibleDriveTimes
  ]);

  // Memoize markers
  const memoizedMarkers = useMemo(() => 
    visibleSchools
      .filter(school => 
        school?.latitude && 
        school?.longitude &&
        (visibleDriveTimes.includes(school.drive_time) || school.ncessch === selectedSchool.ncessch)
      )
      .map(school => {
        const isSelected = school.ncessch === selectedSchool.ncessch;
        const isFocused = school.ncessch === focusedSchool?.ncessch;
        let finalColor;
        let opacity = 1;
  
        if (isSelected) {
          finalColor = '#4338ca'; // Selected school color
        } else if (isFocused) {
          finalColor = '#2196f3'; // Focused school color
        } else {
          // Use the mode parameter to determine coloring strategy
          finalColor = getSchoolColor(school, schoolsEnrollmentData, mode, marketShares);
          if (focusedSchool) {
            opacity = 0.3;
          }
        }
  
        return (
          <Marker 
            key={school.ncessch}
            position={[school.latitude, school.longitude]} 
            icon={customIcon({ 
              color: finalColor, 
              isSelected: isSelected || isFocused,
              isCharter: school.charter === 'Yes',
              opacity 
            })}
            eventHandlers={{
              click: (e) => {
                e.originalEvent.stopPropagation();
                handleMarkerClick(school);
              }
            }}
          />
        );
      }),
    [
      visibleSchools,
      visibleDriveTimes,
      selectedSchool,
      focusedSchool,
      schoolsEnrollmentData,
      marketShares,
      handleMarkerClick,
      mode  // Add mode to dependencies
    ]
  );

  // Memoize GeoJSON layers
  const memoizedGeoJSON = useMemo(() => {
    return Object.entries(geoJsonData)
      .map(([driveTime, data]) => {
        const isVisible = visibleDriveTimes.includes(parseInt(driveTime));
        const color = driveTimeColors[driveTime];
        
        if (!isVisible || !data) return null;

        return (
          <GeoJSON 
            key={driveTime}
            data={data} 
            style={() => ({
              color: color,
              weight: 2,
              opacity: 1,
              fillColor: color,
              fillOpacity: 0.1,
              zIndex: 400
            })}
            eventHandlers={{
              add: (e) => {}  // Empty handler since logs are removed
            }}
            ref={(layerRef) => {}}  // Empty ref callback since logs are removed
          />
        );
      })
      .filter(Boolean);
  }, [geoJsonData, visibleDriveTimes]);


  // Initialize map
  useEffect(() => {
    if (!map) return;
    
    const handleMapReady = () => {
      map.scrollWheelZoom.disable();
      
      const handleScroll = (e) => {
        if (e.ctrlKey) {
          map.scrollWheelZoom.enable();
        } else {
          map.scrollWheelZoom.disable();
        }
      };
  
      map.on('preclick', () => {
        map.scrollWheelZoom.enable();
      });
      
      map.on('mouseout', () => {
        map.scrollWheelZoom.disable();
      });
  
      document.addEventListener('keydown', handleScroll);
      document.addEventListener('keyup', handleScroll);
  
      return () => {
        document.removeEventListener('keydown', handleScroll);
        document.removeEventListener('keyup', handleScroll);
        map.off('preclick');
        map.off('mouseout');
      };
    };
  
    if (map._loaded) {
      handleMapReady();
    } else {
      map.once('load', handleMapReady);
    }

    return () => {
      if (map) {
        map.off('load');
      }
    };
  }, [map]);

  return (
    <>
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
      />
      {memoizedGeoJSON}
      {memoizedMarkers}
      
      {/* School count */}
      {!selectedMarkerSchool && (
        <div 
          style={{
            position: 'absolute',
            top: '20px',
            left: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            padding: '8px 12px',
            borderRadius: '4px',
            zIndex: 1000,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          <Typography variant="body2">
            Total Nearby Schools: {nearbySchoolCount}
          </Typography>
        </div>
      )}

      {/* Legend */}
      <Legend 
        selectedSchool={selectedSchool}
        mode={mode}
      />

      {/* Info Panel */}
      {/* Inside MapContent return statement */}
      {selectedMarkerSchool && (
        <>
          <InfoPanel 
            school={selectedMarkerSchool}
            data={mode === 'market-share' ? {
              marketShareData: {
                "2023-24": marketShares[selectedMarkerSchool.ncessch]?.marketData?.current,
                "2019-20": marketShares[selectedMarkerSchool.ncessch]?.marketData?.comparison,
                rank: marketShares[selectedMarkerSchool.ncessch]?.marketData?.rank, 
                enrollments: {
                  "2023-24": {
                    school: marketShares[selectedMarkerSchool.ncessch]?.marketData?.currentEnrollment,
                    total: marketShares[selectedMarkerSchool.ncessch]?.marketData?.currentTotalEnrollment
                  },
                  "2019-20": {
                    school: marketShares[selectedMarkerSchool.ncessch]?.marketData?.pastEnrollment,
                    total: marketShares[selectedMarkerSchool.ncessch]?.marketData?.pastTotalEnrollment
                  }
                }
              },
              enrollmentData: {
                category: schoolsEnrollmentData.find(d => d.ncessch === selectedMarkerSchool.ncessch)?.category,
              },
            } : null}
            mode={mode}
            schoolsEnrollmentData={schoolsEnrollmentData}
            onClose={handleInfoPanelClose}
            PopupComponent={PopupComponent}
            selectedGrades={selectedGrades}
          />
        </>
      )}

      {/* Selection Indicator */}
      {selectionIndicatorPos && (
        <MapOverlay position={selectionIndicatorPos}>
          <SelectionIndicator />
        </MapOverlay>
      )}
    </>
  );
});

const UnifiedSchoolMap = React.memo(({ 
  mode = 'geographic',
  selectedSchool,
  filteredSchools,
  marketShares,
  esriData = null,
  visibleDriveTimes = [],
  schoolsEnrollmentData,
  governanceFilter = 'All',
  selectedGrades = [],
  schoolTypeFilter = 'All',
  PopupComponent,
  focusedSchool = null,
  onSchoolSelect,
  initialZoom = 14,
  onMapLoaded
}) => {

  const renderCount = useRef(0);

  useEffect(() => {
    renderCount.current += 1;
  });
  const [mapReady, setMapReady] = useState(false);
  const mapRef = useRef(null);

  // Memoize the map key to prevent unnecessary remounts
  const mapKey = useMemo(() => 
    `${selectedSchool?.ncessch}-${visibleDriveTimes.join('-')}`,
    [selectedSchool?.ncessch, visibleDriveTimes]
  );

  // Stable callback reference
  const handleMapReady = useCallback((e) => {
    mapRef.current = e.target;
    setMapReady(true);
    
    requestAnimationFrame(() => {
      window.dispatchEvent(new Event('resize'));
      if (onMapLoaded) {
        onMapLoaded();
      }
    });
  }, [onMapLoaded]);

  if (!selectedSchool) {
    return <Typography>Please select a school to view the map.</Typography>;
  }

  return (
    <div 
      className="relative w-full h-full" 
      style={{ 
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <MapContainer 
        key={mapKey}
        center={[selectedSchool.latitude, selectedSchool.longitude]}
        zoom={11}  // More moderate initial zoom
        maxZoom={16}  // Allow zooming in quite far
        minZoom={8}   // Allow zooming out quite far
        style={{ 
          position: 'absolute',
          height: '100%', 
          width: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
        zoomControl={false}
        whenReady={(e) => {
          handleMapReady(e);
        }}
        whenCreated={(map) => {}}
      >
        <ZoomControl position="topright" />
        <MapContent 
          mode={mode}
          selectedSchool={selectedSchool}
          filteredSchools={filteredSchools}
          esriData={esriData}
          visibleDriveTimes={visibleDriveTimes}
          schoolsEnrollmentData={schoolsEnrollmentData}
          marketShares={marketShares}
          governanceFilter={governanceFilter}
          selectedGrades={selectedGrades}
          schoolTypeFilter={schoolTypeFilter}
          PopupComponent={PopupComponent}
          focusedSchool={focusedSchool}
          onSchoolSelect={onSchoolSelect}
          initialZoom={11}
          mapReady={mapReady}
          mapRef={mapRef}
          setMapReady={setMapReady}
        />
      </MapContainer>

      <div 
        style={{
          position: 'absolute',
          bottom: '20px',
          left: '20px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          padding: '4px 12px',
          borderRadius: '4px',
          zIndex: 1000,
          maxWidth: '200px',
          pointerEvents: 'none'
        }}
      >
        <Typography variant="caption">
          Hold Ctrl to zoom with scroll wheel, or click to enable zoom
        </Typography>
      </div>
    </div>
  );
});

UnifiedSchoolMap.propTypes = {
  mode: PropTypes.oneOf(['geographic', 'market-share']),
  selectedSchool: PropTypes.shape({
    ncessch: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    charter: PropTypes.string.isRequired,
  }).isRequired,
  filteredSchools: PropTypes.arrayOf(PropTypes.object).isRequired,
  marketShares: PropTypes.object,
  esriData: PropTypes.object,
  visibleDriveTimes: PropTypes.arrayOf(PropTypes.number),
  schoolsEnrollmentData: PropTypes.arrayOf(PropTypes.object).isRequired,
  governanceFilter: PropTypes.string,
  selectedGrades: PropTypes.arrayOf(PropTypes.string),
  schoolTypeFilter: PropTypes.string,
  PopupComponent: PropTypes.elementType.isRequired,
  focusedSchool: PropTypes.object,
  onSchoolSelect: PropTypes.func.isRequired,
  initialZoom: PropTypes.number,
  onMapLoaded: PropTypes.func
};

export default UnifiedSchoolMap;
