import React, { useState, lazy, Suspense } from 'react';
import { 
  Box, 
  Tabs, 
  Tab, 
  Container, 
  Typography, 
  Paper,
  CircularProgress
} from '@mui/material';
import {
  Person,
  Group,
  Analytics as AnalyticsIcon,
  DataObject as DataIcon,
  Feedback as FeedbackIcon,
  Groups as GroupsIcon,
  AddHomeWork as AddHomeWorkIcon
} from '@mui/icons-material';

// Lazy load components instead of eager loading
const UserManagementPanel = lazy(() => import('./user_management/UserManagementPanel'));
const TeamManagementPanel = lazy(() => import('./user_management/TeamManagementPanel'));
const TeamGroupManagementPanel = lazy(() => import('./user_management/TeamGroupManagementPanel'));
const ValidationSuite = lazy(() => import('./validation/ValidationSuite'));
const AnalyticsPanel = lazy(() => import('./AnalyticsPanel'));
const FeedbackPanel = lazy(() => import('./FeedbackPanel'));
const SchoolProcessor = lazy(() => import('./school_processing/SchoolProcessor'));

// Loading component for Suspense
const LoadingPanel = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
    <CircularProgress size={60} />
  </Box>
);

// TabPanel component
function TabPanel({ children, value, index, ...other }) {
  // Only render the component if it's the active tab
  // This prevents unnecessary mounting of non-visible components
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`admin-tabpanel-${index}`}
      aria-labelledby={`admin-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Suspense fallback={<LoadingPanel />}>
            {children}
          </Suspense>
        </Box>
      )}
    </div>
  );
}

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  
  const handleTabChange = (event, newValue) => {
    // Don't clear cache when switching tabs
    // Instead, prefetch data for the tab we're switching to
    if (newValue === 0 && activeTab !== 0) {
      // Prefetch users data in background when switching to user tab
      import('../../services/adminService').then(module => {
        module.adminService.listUsers().catch(console.error);
      });
    } else if (newValue === 1 && activeTab !== 1) {
      // Prefetch teams data in background when switching to team tab
      import('../../services/adminService').then(module => {
        module.adminService.listTeams().catch(console.error);
      });
    } else if (newValue === 2 && activeTab !== 2) {
      // Prefetch team groups data in background when switching to team groups tab
      import('../../services/adminService').then(module => {
        module.adminService.listTeamGroups().catch(console.error);
      });
    }
    
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Paper elevation={0} sx={{ mb: 3, p: 3, bgcolor: 'background.default' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Admin Dashboard
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Manage users, validate data, monitor analytics, and review feedback.
        </Typography>
      </Paper>

      <Paper sx={{ width: '100%', mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="admin dashboard tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab 
            icon={<Person />} 
            label="Users" 
            id="admin-tab-0"
          />
          <Tab 
            icon={<Group />} 
            label="Teams" 
            id="admin-tab-1"
          />
          <Tab 
            icon={<GroupsIcon />} 
            label="Team Groups" 
            id="admin-tab-2"
          />
          <Tab 
            icon={<AddHomeWorkIcon />} 
            label="School Processor" 
            id="admin-tab-3"
          />
          <Tab 
            icon={<DataIcon />} 
            label="Data Validation" 
            id="admin-tab-4"
          />
          <Tab 
            icon={<AnalyticsIcon />} 
            label="Analytics" 
            id="admin-tab-5"
          />
          <Tab 
            icon={<FeedbackIcon />} 
            label="Feedback" 
            id="admin-tab-6"
          />
        </Tabs>
      </Paper>

      {/* Each TabPanel loads only when the tab is active */}
      <TabPanel value={activeTab} index={0}>
        <UserManagementPanel key="user-panel" />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <TeamManagementPanel key="team-panel" />
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <TeamGroupManagementPanel key="team-group-panel" />
      </TabPanel>
      
      <TabPanel value={activeTab} index={3}>
        <SchoolProcessor key="school-processor" />
      </TabPanel>

      <TabPanel value={activeTab} index={4}>
        <ValidationSuite key="validation-suite" />
      </TabPanel>
      
      <TabPanel value={activeTab} index={5}>
        <AnalyticsPanel key="analytics-panel" />
      </TabPanel>

      <TabPanel value={activeTab} index={6}>
        <FeedbackPanel key="feedback-panel" />
      </TabPanel>
    </Container>
  );
};

export default AdminDashboard;