const config = {
    development: {
      apiUrl: 'http://localhost:5001/api',
      // other development settings
    },
    staging: {
      apiUrl: 'https://enrollment-risk-v2-staging.uc.r.appspot.com/api',
      // other staging settings
    },
    production: {
        apiUrl: 'https://enrollment-risk-v2-production.uc.r.appspot.com/api',
        // other staging settings
    }
  };
  
  export default config[process.env.REACT_APP_ENV || 'development'];