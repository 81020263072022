import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { 
  Typography, 
  Box, 
  Paper, 
  Grid, 
  Card, 
  CardContent, 
  Divider, 
  Container, 
  TextField,
  InputAdornment,
  Link,
  LinearProgress
} from '@mui/material';
import { 
  AssessmentOutlined,
  MapOutlined, 
  AutoGraphOutlined, 
  MapsHomeWorkOutlined, 
  DonutSmallOutlined,
  Search as SearchIcon,
  LightbulbOutlined
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { MapContainer, TileLayer, Marker, useMap, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import { formatSchoolName } from '../../utils/nameFormatter';
import { canAccessExplorers } from '../../utils/explorerAccess';
import 'leaflet/dist/leaflet.css';
import { useTeamSchoolCache } from '../../hooks/teamCacheService';
import _ from 'lodash';


// Custom icon for map markers
const markerIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

// Map bounds fitting component
const MapBoundsFitter = ({ schools }) => {
  const map = useMap();
  
  useEffect(() => {
    if (schools.length > 0) {
      const bounds = L.latLngBounds(schools.map(school => [school.latitude, school.longitude]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [schools, map]);
  
  return null;
};

const getFeatureCards = (userRole) => {
  const baseFeatures = [
    {
      title: 'District Analysis',
      description: 'Analyze district-wide trends and patterns with detailed demographic and enrollment data.',
      icon: <MapOutlined fontSize="large" />,
      link: '/district-analysis'
    },
    {
      title: 'School Summary',
      description: 'Get a comprehensive overview of key metrics and performance indicators for any selected school.',
      icon: <AssessmentOutlined fontSize="large" />,
      link: '/school-summary'
    },
    {
      title: 'Support & Resources',
      description: 'Learn about our projections methodology and access helpful resources like video tutorials.',
      icon: <LightbulbOutlined fontSize="large" />,
      link: '/resources'
    }
  ];

  // Additional features for users with explorer access
  const explorerFeatures = [
    {
      title: 'Geographic Explorer',
      description: 'Understand local context with population and enrollment trends. View nearby schools within various drive times.',
      icon: <MapsHomeWorkOutlined fontSize="large" />,
      link: '/geographic-explorer'
    },
    {
      title: 'Competitive Explorer',
      description: 'Analyze market share for schools within a selected radius. Compare enrollment data and demographic information.',
      icon: <DonutSmallOutlined fontSize="large" />,
      link: '/competitive-explorer'
    },
    {
      title: 'Projections Explorer',
      description: 'Explore historical and projected enrollment data for the next five years. View by grade level and add custom data.',
      icon: <AutoGraphOutlined fontSize="large" />,
      link: '/projections-explorer'
    },
  ];

  return canAccessExplorers(userRole) ? [...baseFeatures, ...explorerFeatures] : baseFeatures;
};

// Add this CSS to your styles
const mapStyles = `
  .custom-popup .leaflet-popup-content-wrapper {
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    border: 2px solid #ff4444;
  }
  .custom-popup .leaflet-popup-tip-container {
    display: none;
  }
  .custom-popup .leaflet-popup-content {
    margin: 8px;
    width: auto !important;
  }
`;

// ErrorState component
const ErrorState = () => (
  <Container maxWidth="md">
    <Paper 
      elevation={3} 
      sx={{ 
        p: 4, 
        mt: 4,
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
        borderRadius: 2
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <MapsHomeWorkOutlined 
          sx={{ 
            fontSize: 64, 
            color: 'primary.main',
            mb: 2,
            opacity: 0.8
          }} 
        />
        <Typography variant="h5" color="primary" gutterBottom>
          No Schools Available
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          You currently have no schools assigned to your account.
          This might be due to maintenance or upgrades.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Try back again later or contact{' '}
          <Link 
            href="mailto:support@educationdatacollective.com"
            sx={{ 
              color: 'primary.main',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            support@educationdatacollective.com
          </Link>
        </Typography>
      </Box>
    </Paper>
  </Container>
);

const Home = ({ user }) => {
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = mapStyles;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);
  const displayName = user?.displayName || user?.email || 'Guest';
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const [totalSchoolCount, setTotalSchoolCount] = useState(0);

  // Implement cache hooks with proper error boundaries
  const { 
    schools, 
    loading: schoolsLoading, 
    error: schoolsError,
    refreshTeamSchools,
    loadingStatus,
    isBackgroundLoading,
    totalSchoolsCount
  } = useTeamSchoolCache();

  useEffect(() => {
    // Only set isInitialLoad to false after schools are loaded AND loading is complete
    if (schools.length > 0 && !schoolsLoading) {
      // Add a small delay before showing content to prevent flickering
      const timer = setTimeout(() => {
        setIsInitialLoad(false);
      }, 200);
      return () => clearTimeout(timer);
    }
    
    // Keep the progress animation only during initial loading
    if (schoolsLoading && isInitialLoad) {
      const progressInterval = setInterval(() => {
        setLoadingProgress(prev => {
          if (prev >= 90) {
            clearInterval(progressInterval);
            return prev;
          }
          return prev + 10;
        });
      }, 500);
  
      return () => clearInterval(progressInterval);
    }
  }, [schools.length, schoolsLoading, isInitialLoad]);

  // Clean up search results on unmount
  useEffect(() => {
    return () => {
      if (searchResults.length > 0) {
        setSearchResults([]);
      }
    };
  }, [searchResults]);

  // Fix the map cleanup effect
  useEffect(() => {
    const currentMapRef = mapRef.current;
    return () => {
      if (currentMapRef) {
        currentMapRef.remove();
      }
    };
  }, []);
  
  // Add refresh functionality if needed:
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refreshTeamSchools();
    }, 300000); // Refresh every 5 minutes
  
    return () => clearInterval(refreshInterval);
  }, [refreshTeamSchools]);

  /// Filter schools directly (same pattern as InitialSchoolSelector)
const handleSearch = useCallback((term) => {
  if (!term?.trim()) {
    setSearchResults([]);
    return;
  }
  
  setSearching(true);
  
  // Simple case-insensitive search against name, city, state
  const query = term.toLowerCase().trim();
  const results = schools.filter(school => 
    school.name.toLowerCase().includes(query) ||
    (school.city && school.city.toLowerCase().includes(query)) ||
    (school.state && school.state.toLowerCase().includes(query)) ||
    school.ncessch.includes(query)
  ).slice(0, 15); // Limit to 15 results for performance
  
  setSearchResults(results);
  setSearching(false);
}, [schools]);

useEffect(() => {
  if (isBackgroundLoading) {
    // This assumes you've added a way to export the totalSchoolsRef.current 
    // value from your teamCacheService hook
    setTotalSchoolCount(totalSchoolsCount || schools.length * 2);
  }
}, [isBackgroundLoading, schools.length, totalSchoolsCount]);

// Debounce the search
useEffect(() => {
  const debouncedSearch = _.debounce(handleSearch, 300);
  debouncedSearch(searchTerm);
  return () => debouncedSearch.cancel();
}, [searchTerm, handleSearch]);

  // Progressive loading with cache awareness
  useEffect(() => {
    if (!schoolsLoading && schools.length > 0) {
      // Delay map rendering to prioritize critical UI
      const timer = setTimeout(() => setShowMap(true), 100);
      return () => clearTimeout(timer);
    }
  }, [schoolsLoading, schools]);

  // Calculate unique states and cities
  const metrics = useMemo(() => {
    if (schoolsLoading || !schools.length) return { stateCount: 0, cityCount: 0 };
    
    const uniqueStates = new Set(schools.map(school => school.state));
    const uniqueCities = new Set(schools.map(school => `${school.city}, ${school.state}`));
    
    return {
      stateCount: uniqueStates.size,
      cityCount: uniqueCities.size
    };
  }, [schools, schoolsLoading]);

  const handleSchoolClick = useCallback((school) => {
    // Use query parameters instead of path parameters
    navigate(`/school-summary?school=${school.ncessch}`);
  }, [navigate]);

  const groupedSchools = useMemo(() => {
    if (!schools.length) return {};
    
    const grouped = {};
    schools.forEach(school => {
      const key = `${school.latitude},${school.longitude}`;
      if (!grouped[key]) grouped[key] = [];
      grouped[key].push(school);
    });
    return grouped;
  }, [schools]);
 
  if (isInitialLoad) {
    return (
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Paper 
          elevation={3} 
          sx={{ 
            p: 4, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            gap: 2
          }}
        >
          <Typography variant="h5" color="primary" gutterBottom>
            Loading Your Schools
          </Typography>
          <CircularProgress 
            variant="determinate" 
            value={loadingProgress} 
            size={60}
          />
          <Typography variant="body2" color="text.secondary">
            {loadingProgress < 100 
              ? loadingStatus || "Loading school data..." 
              : "Almost there..."}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            This may take a moment if you have many schools
          </Typography>
        </Paper>
      </Container>
    );
  }
  
  // Only show error if it's not the initial load
  if (schoolsError && !isInitialLoad) {
    return <ErrorState />;
  }

   return (
    <Container maxWidth="xl" sx={{ py: 2 }}>
      {/* Background loading indicator */}
      {isBackgroundLoading && (
        <LinearProgress 
          variant="indeterminate" 
          sx={{ 
            position: 'fixed', 
            top: 0, 
            left: 0, 
            right: 0, 
            height: 2, 
            zIndex: 9999,
            opacity: 0.7
          }} 
        />
      )}
      <Grid container spacing={2}>
        {/* Welcome Section - Always render immediately */}
        <Grid item xs={12} md={4}>
          <Paper 
            elevation={3} 
            sx={{ 
              p: 2, 
              height: '100%', 
              background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)', 
              borderRadius: 2 
            }}
          >
            <Box>
              <Typography 
                variant="h4" 
                component="h1"
                sx={{ 
                  fontWeight: 600,
                  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                  mb: 1
                }}
              >
                Welcome back,
              </Typography>
              <Typography 
                variant="h5" 
                component="div"
                sx={{ 
                  fontWeight: 700,
                  color: 'primary.main',
                  mb: 1
                }}
              >
                {displayName}
              </Typography>
              
              {/* Metrics Section */}
              <Grid container spacing={2} sx={{ mt: 0.5, mb: 1.5 }}>
                <Grid item xs={6}>
                  <Paper elevation={1} sx={{ p: 1.5, textAlign: 'center' }}>
                    <Typography variant="h5" color="primary">
                      {schools.length}
                    </Typography>
                    <Typography variant="body2">Schools</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper elevation={1} sx={{ p: 1.5, textAlign: 'center' }}>
                    <Typography variant="h5" color="primary">
                      {metrics.cityCount}
                    </Typography>
                    <Typography variant="body2">Cities</Typography>
                  </Paper>
                </Grid>
              </Grid>
              
              {/* Loading for schools */}
              {isBackgroundLoading && totalSchoolCount > 0 && (
                <Box 
                  sx={{ 
                    mt: 2, 
                    p: 1.5, 
                    bgcolor: 'info.lightest', 
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'info.light'
                  }}
                >
                  <Typography variant="caption" color="info.main" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={12} color="info" />
                    Loading schools: {schools.length} of ~{totalSchoolCount} 
                    ({Math.round((schools.length / totalSchoolCount || 1) * 100)}%)
                  </Typography>
                  <LinearProgress 
                    variant="determinate" 
                    value={totalSchoolCount > 0 ? (schools.length / totalSchoolCount) * 100 : 0} 
                    sx={{ mt: 1, height: 4, borderRadius: 2 }} 
                    color="info"
                  />
                </Box>
              )}
  
              {/* School Search Section */}
              <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                Start with a school:
              </Typography>
  
              {/* School Search */}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search schools..."
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              <Box sx={{
                maxHeight: '200px',
                overflowY: 'auto',
                border: searchTerm ? '1px solid' : 'none',
                borderColor: 'divider',
                borderRadius: 1,
                mt: 1,
                '&:empty': {
                  display: 'none'
                }
              }}>
                {searching ? (
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  <>
                    {searchResults.map((school) => (
                      <Box
                        key={school.ncessch}
                        onClick={() => handleSchoolClick(school)}
                        sx={{
                          p: 1,
                          cursor: 'pointer',
                          '&:hover': {
                            bgcolor: 'action.hover'
                          },
                          '&:not(:last-child)': {
                            borderBottom: '1px solid',
                            borderColor: 'divider'
                          }
                        }}
                      >
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                          {formatSchoolName(school.name)}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {school.city}, {school.state}
                        </Typography>
                      </Box>
                    ))}
                    {searchTerm && searchResults.length === 0 && (
                      <Box sx={{ p: 1, textAlign: 'center' }}>
                        <Typography variant="caption" color="text.secondary">
                          No schools found matching "{searchTerm}"
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
  
        {/* Map Section - Render after initial load */}
        <Grid item xs={12} md={8}>
          <Paper 
            elevation={3} 
            sx={{ 
              height: '350px',
              borderRadius: 2,
              overflow: 'hidden'
            }}
          >
            {showMap ? (
              <MapContainer style={{ height: "100%", width: "100%" }}>
                <TileLayer
                  url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
                  attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
                />
                <MapBoundsFitter schools={schools} />
                <MarkerClusterGroup
                  chunkedLoading
                  spiderfyOnMaxZoom={true}
                  zoomToBoundsOnClick={true}
                  maxClusterRadius={40}
                  disableClusteringAtZoom={12}
                >
                  {Object.entries(groupedSchools).map(([key, schoolsAtLocation], index) => {
                    const position = [schoolsAtLocation[0].latitude, schoolsAtLocation[0].longitude];
                    return (
                      <Marker 
                        key={index}
                        position={position}
                        icon={markerIcon}
                      >
                        <Popup
                          closeButton={false}
                          autoPan={false}
                          className="custom-popup"
                        >
                          <div style={{
                            padding: '2px',
                            width: '150px',
                          }}>
                            {schoolsAtLocation.map((school, idx) => (
                              <div key={idx}>
                                <div
                                  onClick={() => handleSchoolClick(school)}
                                  style={{
                                    cursor: 'pointer',
                                    padding: '3px 4px',
                                    fontSize: '13px',
                                    color: '#2196F3',
                                    wordWrap: 'break-word',
                                    lineHeight: 1.2,
                                    margin: '2px 0',
                                    textAlign: 'center',
                                    borderRadius: '3px',
                                  }}
                                  onMouseOver={(e) => {
                                    e.currentTarget.style.backgroundColor = '#f0f7ff';
                                    e.currentTarget.style.color = '#1565c0';
                                  }}
                                  onMouseOut={(e) => {
                                    e.currentTarget.style.backgroundColor = 'transparent';
                                    e.currentTarget.style.color = '#2196F3';
                                  }}
                                >
                                  {formatSchoolName(school.name)}
                                </div>
                                {idx < schoolsAtLocation.length - 1 && (
                                  <div style={{
                                    height: '1px',
                                    background: '#e0e0e0',
                                    margin: '2px 10px'
                                  }} />
                                )}
                              </div>
                            ))}
                          </div>
                        </Popup>
                      </Marker>
                    );
                  })}
                </MarkerClusterGroup>
              </MapContainer>
            ) : (
              <Box sx={{ 
                height: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' 
              }}>
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Grid>
  
        {/* Feature Cards Section */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }}>
            <Typography variant="subtitle1" color="text.secondary">
              Available Features
            </Typography>
          </Divider>
        </Grid>
  
        {/* Feature Cards */}
        {getFeatureCards(user?.role).map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              onClick={() => navigate(card.link)}
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                textDecoration: 'none',
                color: 'inherit',
                background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
                transition: 'all 0.3s ease-in-out',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: (theme) => `0 12px 24px ${theme.palette.primary.light}25`,
                  '& .MuiSvgIcon-root': {
                    transform: 'scale(1.1)',
                    color: 'primary.main'
                  }
                },
                '&:active': {
                  transform: 'translateY(-2px)',
                }
              }}
            >
              <CardContent sx={{ p: 2 }}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    mb: 1,
                    '& .MuiSvgIcon-root': {
                      fontSize: '2rem',
                      color: 'text.secondary',
                      transition: 'all 0.3s ease-in-out'
                    }
                  }}
                >
                  {card.icon}
                </Box>
                <Typography 
                  variant="h6" 
                  component="h2" 
                  align="center"
                  sx={{ 
                    fontWeight: 600,
                    mb: 1,
                    fontSize: '1.1rem'
                  }}
                >
                  {card.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  align="center"
                  sx={{ fontSize: '0.875rem' }}
                >
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Home;