import React, { useMemo } from 'react';
import { 
  Autocomplete,
  TextField,
  CircularProgress,
  Box,
  Typography
} from '@mui/material';
import { formatSchoolName } from '../../utils/nameFormatter';
import { useTeamSchoolCache } from '../../hooks/teamCacheService';

const InitialSchoolSelector = ({ selectedSchool, onSchoolChange }) => {
  const { 
    schools, 
    loading, 
    error, 
  } = useTeamSchoolCache();

  // Sort schools and memoize the result
  const sortedSchools = useMemo(() => {
    return [...schools].sort((a, b) => 
      formatSchoolName(a.name).localeCompare(formatSchoolName(b.name))
    );
  }, [schools]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" p={2}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box color="error.main" p={2}>
        Error loading schools: {error}
      </Box>
    );
  }

  return (
    <Autocomplete
      fullWidth
      options={sortedSchools}
      getOptionLabel={(option) => formatSchoolName(option.name)}
      value={selectedSchool || null}
      onChange={(event, newValue) => {
        onSchoolChange({ target: { value: newValue?.ncessch || '' } });
      }}
      isOptionEqualToValue={(option, value) => option.ncessch === value?.ncessch}
      renderOption={(props, option) => (
        <li {...props} key={option.ncessch}>
          <div>
            <Typography variant="body1">{formatSchoolName(option.name)}</Typography>
            <Typography variant="caption" color="text.secondary">
              {option.city}, {option.state} • NCES ID: {option.ncessch}
            </Typography>
          </div>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select a School"
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};

export default InitialSchoolSelector;