import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
  // Get the version and format it to be more user-friendly
  const formatVersion = (version) => {
    if (!version) return 'Unknown';
    
    // Extract semantic version (v1.2.3) from full version string
    const semanticMatch = version.match(/^(v\d+\.\d+\.\d+)/);
    if (semanticMatch) {
      return semanticMatch[1]; // Return just the v1.2.3 part
    }
    
    // For staging versions with the old format, simplify it
    if (version.startsWith('staging-')) {
      // Extract just the commit hash part
      const hash = version.split('-')[2] || '';
      return `v0.0.0-${hash.substring(0, 7)}`; // Show as v0.0.0-abc1234
    }
    
    // For production or any other format, return as is
    return version;
  };
  
  const rawVersion = localStorage.getItem('app_version') || 'Unknown';
  const version = formatVersion(rawVersion);
  
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: 'background.default',
      }}
    >
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://educationdatacollective.com/">
          Education Data Collective
        </Link>{' '}
        {new Date().getFullYear()}
        {' • '}
        <Typography
          component="span"
          variant="body2"
          color="text.secondary"
          sx={{ opacity: 0.8 }}
        >
          Version: {version}
        </Typography>
      </Typography>
    </Box>
  );
};

export default Footer;