import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Alert, 
  Skeleton, 
  Box, 
  Typography,
  Grid,
  LinearProgress,
  CircularProgress
} from '@mui/material';

export const LoadingErrorWrapper = ({ 
  loading, 
  error, 
  children, 
  loadingStep = 0,
  loadingSteps = [
    'Loading school directory',
    'Processing enrollment data',
    'Calculating projections',
    'Building visualizations'
  ],
  loadingComponent,
  errorComponent,
  initialLoading = false,
  skeletonCount = 3,
  containerWidth = "xl",
  hideSteps = false // New prop to hide detailed steps
}) => {
  const [progress, setProgress] = useState(0);
  
  useEffect(() => {
    const progressIncrement = 100 / loadingSteps.length;
    setProgress(Math.min((loadingStep + 1) * progressIncrement, 100));
  }, [loadingStep, loadingSteps.length]);

  // Simple loading state without steps
  if (hideSteps && loading) {
    // Make sure we're not stuck showing loading with cached data
    if (initialLoading === false) {
      return children;
    }
    
    return (
      <Container maxWidth={containerWidth} sx={{ mt: 8, textAlign: 'center' }}>
        <CircularProgress size={40} sx={{ mb: 2 }} />
        <Typography variant="body1" color="text.secondary">
          Loading schools...
        </Typography>
      </Container>
    );
  }

  // Initial loading state (when fetching schools list)
  if (initialLoading && loading) {
    return loadingComponent || (
      <Container maxWidth={containerWidth} sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ mb: 4 }}>
          <LinearProgress variant="determinate" value={progress} sx={{ mb: 3 }} />
          <Box sx={{ px: 2 }}>
            {loadingSteps.map((step, index) => (
              <Box 
                key={step} 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 1 
                }}
              >
                <Box 
                  sx={{ 
                    width: 8, 
                    height: 8, 
                    borderRadius: '50%',
                    bgcolor: index <= loadingStep ? 'primary.main' : 'grey.300',
                    mr: 1
                  }} 
                />
                <Typography 
                  variant="body2"
                  sx={{ 
                    color: index <= loadingStep ? 'primary.main' : 'text.secondary',
                    fontWeight: index <= loadingStep ? 500 : 400
                  }}
                >
                  {step}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
          <Skeleton width={300} />
        </Typography>
        <Skeleton variant="rectangular" height={56} sx={{ mb: 4 }} />
        <Grid container spacing={3}>
          {[...Array(skeletonCount)].map((_, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Skeleton variant="rectangular" height={200} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }

  // Error state
  if (error) {
    return errorComponent || (
      <Container maxWidth="lg">
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      </Container>
    );
  }

  // Content loading state (when fetching additional data)
  if (loading && !initialLoading) {
    return loadingComponent || (
      <Container maxWidth={containerWidth} sx={{ mt: 4 }}>
        <Box sx={{ mb: 4 }}>
          <LinearProgress variant="determinate" value={progress} sx={{ mb: 3 }} />
          <Box sx={{ px: 2 }}>
            {loadingSteps.map((step, index) => (
              <Box 
                key={step} 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 1 
                }}
              >
                <Box 
                  sx={{ 
                    width: 8, 
                    height: 8, 
                    borderRadius: '50%',
                    bgcolor: index <= loadingStep ? 'primary.main' : 'grey.300',
                    mr: 1
                  }} 
                />
                <Typography 
                  variant="body2"
                  sx={{ 
                    color: index <= loadingStep ? 'primary.main' : 'text.secondary',
                    fontWeight: index <= loadingStep ? 500 : 400
                  }}
                >
                  {step}
                </Typography>
              </Box>
            ))}
          </Box>
          <Alert severity="info" sx={{ mt: 2 }}>
            Processing {loadingSteps.length} data components
          </Alert>
        </Box>
      </Container>
    );
  }

  return children;
};

export default LoadingErrorWrapper;