import React, { useState } from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton
} from '@mui/material';
import {
  Close as CloseIcon,
  Map as MapIcon
} from '@mui/icons-material';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import DistrictMapMarkers from './DistrictMapMarkers';
import DistrictMapPopup from './DistrictMapPopup';
import DistrictMapOverlay from './DistrictMapOverlay';

// Map bounds fitting component
const MapBoundsFitter = ({ schools }) => {
  const map = useMap();
  
  React.useEffect(() => {
    if (schools.length > 0) {
      const bounds = L.latLngBounds(schools.map(school => [school.latitude, school.longitude]));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [schools, map]);
  
  return null;
};

const DistrictMapExpanded = ({
  open,
  onClose,
  schools = [],
  onSchoolSelect,
  selectedHeadline = 0
}) => {
  const [selectedSchool, setSelectedSchool] = useState(null);

  const handleSchoolClick = (school) => {
    setSelectedSchool(school);
  };

  const handleClosePopup = () => {
    setSelectedSchool(null);
  };

  const handleSelectAndClose = (school) => {
    if (onSchoolSelect) {
      onSchoolSelect(school);
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Title and Actions */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <MapIcon sx={{ color: 'primary.main' }} />
              <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.125rem' }}>
                All Schools Map
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          
          {/* Subtitle */}
          <Typography 
            variant="body2" 
            sx={{ 
              bgcolor: 'rgba(25, 118, 210, 0.1)',
              color: 'primary.main',
              p: 1,
              borderRadius: 1,
              fontWeight: 500,
            }}
          >
            Click on markers to view school details. Click "View Profile" to navigate to school details.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: '75vh', p: 0 }}>
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
          <MapContainer 
            style={{ height: '100%', width: '100%' }}
            zoom={4}
            center={[39.8283, -98.5795]}
            zoomControl={true}
          >
            <TileLayer
              url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
              attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
            />
            <MapBoundsFitter schools={schools} />
            <DistrictMapMarkers 
              schools={schools}
              selectedHeadline={selectedHeadline}
              onSchoolSelect={handleSchoolClick}
            />
            {selectedSchool && (
              <DistrictMapOverlay position={[selectedSchool.latitude, selectedSchool.longitude]}>
                <DistrictMapPopup
                  school={selectedSchool}
                  onClose={handleClosePopup}
                  onSelectSchool={() => handleSelectAndClose(selectedSchool)}
                  expanded={true}
                />
              </DistrictMapOverlay>
            )}
          </MapContainer>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DistrictMapExpanded;