import React from 'react';
import { Box, Grid, Stack, Skeleton } from '@mui/material';

const DistrictAnalysisLoading = () => {
  // Helper function to create headline skeletons
  const HeadlineSkeleton = () => (
    <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
      <Stack spacing={1}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Skeleton variant="circular" width={24} height={24} />
          <Skeleton variant="text" width="60%" />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <Box sx={{ width: '30%' }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="60%" />
          </Box>
          <Box sx={{ width: '30%' }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="60%" />
          </Box>
          <Box sx={{ width: '30%' }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="60%" />
          </Box>
        </Box>
      </Stack>
    </Box>
  );

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2} sx={{ 
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        flexWrap: 'nowrap'
      }}>
        {/* Headlines column */}
        <Grid item xs={12} lg="auto" sx={{ 
          width: { lg: '360px' },
          flexShrink: 0,
          pr: { lg: 2 }
        }}>
          <Stack spacing={2}>
            {[...Array(5)].map((_, index) => (
              <HeadlineSkeleton key={index} />
            ))}
          </Stack>
        </Grid>

        {/* Map and table column */}
        <Grid item xs={12} lg sx={{ flexGrow: 1 }}>
          <Box sx={{ height: '400px', mb: 3, borderRadius: 1, overflow: 'hidden' }}>
            <Skeleton variant="rectangular" height="100%" />
          </Box>

          <Stack spacing={1}>
            <Box sx={{ display: 'flex', alignItems: 'center', p: 2, bgcolor: 'grey.50' }}>
              {[...Array(4)].map((_, index) => (
                <Box key={index} sx={{ flex: 1, px: 1 }}>
                  <Skeleton variant="text" />
                </Box>
              ))}
            </Box>
            {[...Array(5)].map((_, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', p: 2, borderBottom: 1, borderColor: 'divider' }}>
                {[...Array(4)].map((_, colIndex) => (
                  <Box key={colIndex} sx={{ flex: 1, px: 1 }}>
                    <Skeleton variant="text" />
                  </Box>
                ))}
              </Box>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DistrictAnalysisLoading;