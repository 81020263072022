import React from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { MapsHomeWork, Launch } from '@mui/icons-material';
import { canAccessExplorers } from '../../../utils/explorerAccess';

const DemographicAnalysis = ({ 
  selectedSchool,
  selectedSchoolTrends,
  user
}) => {
  if (!selectedSchool || !selectedSchoolTrends) {
    return null;
  }

  // Create URL with school parameter
  const explorerUrl = `/geographic-explorer?school=${selectedSchool.ncessch}`;

  // Format the demographic difference text
  const getDemographicText = () => {
    if (!selectedSchoolTrends.largestGroup) {
      return 'Demographic comparison data is not available.';
    }
  
    const difference = Math.abs(selectedSchoolTrends.demographicDifference || 0);
    const direction = selectedSchoolTrends.demographicDifference >= 0 ? 'higher' : 'lower';
    const groupText = selectedSchoolTrends.largestGroup;
  
    return {
      main: selectedSchool.name,
      difference: `${Math.round(difference)}%`,
      group: groupText,
      change: selectedSchoolTrends.largestGroupChange ? `${Math.round(Math.abs(selectedSchoolTrends.largestGroupChange))}%` : null,
      direction,
      hasChange: !!selectedSchoolTrends.largestGroupChange,
      isIncrease: selectedSchoolTrends.largestGroupChange > 0
    };
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper 
        elevation={0}
        sx={{
          mb: 2,
          py: 2,
          px: 3,
          backgroundColor: 'grey.50',
          color: 'text.primary',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: 6,
            backgroundColor: 'primary.main',
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8
          }
        }}
      >
        <Box sx={{ flex: 1 }}>
        <Typography variant="h8" sx={{ fontWeight: 200 }}>
            <Box component="span" sx={{ fontWeight: 600 }}>{getDemographicText().main}</Box> serves a{' '}
            <Box component="span" sx={{ fontWeight: 600 }}>{getDemographicText().difference}</Box> {getDemographicText().direction} percentage of{' '}
            <Box component="span" sx={{ fontWeight: 600 }}>{getDemographicText().group}</Box> students compared to nearby schools
            {getDemographicText().hasChange && (
                <>, with a <Box component="span" sx={{ fontWeight: 600 }}>{getDemographicText().change}</Box> {
                getDemographicText().isIncrease ? 'increase' : 'decrease'
                } since 2019</>
            )}.
            </Typography>
        </Box>
        {canAccessExplorers(user?.role) && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<MapsHomeWork />}
            endIcon={<Launch />}
            href={explorerUrl}
            sx={{ 
              backgroundColor: 'primary.dark',
              color: 'white',
              '&:hover': {
                backgroundColor: 'primary.main'
              }
            }}
          >
            OPEN IN GEOGRAPHIC EXPLORER
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default DemographicAnalysis;