import React, { useMemo } from 'react';
import { 
  Box,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { formatSchoolName } from '../../utils/nameFormatter';

const DRIVE_TIMES = [5, 10, 15];

const SchoolSummaryFilters = ({
  schools,
  selectedSchool,
  selectedDriveTime,
  onSchoolChange,
  onDriveTimeChange
}) => {
  // Sort schools and memoize the result
  const sortedSchools = useMemo(() => {
    return [...schools].sort((a, b) => 
      formatSchoolName(a.name).localeCompare(formatSchoolName(b.name))
    );
  }, [schools]);

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'grey.50',
        backdropFilter: 'blur(8px)',
        zIndex: 1200,
        borderBottom: '1px solid',
        borderColor: 'grey.200',
        py: 1.5,
        mb: 3,
        width: '100%',
        height: 'auto',
        minHeight: 'fit-content',
        boxShadow: '0 2px 4px rgba(0,0,0,0.04)',
      }}
    >
      <Container 
        maxWidth={false}
        sx={{
          px: { xs: 2, sm: 3 },
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}
      >
        <FilterList 
          color="primary" 
          sx={{ 
            opacity: 0.8,
            '&:hover': { opacity: 1 }
          }} 
        />
        
        <Grid container spacing={2} sx={{ flex: 1 }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small">
              <InputLabel>School</InputLabel>
              <Select
                value={selectedSchool?.ncessch || ''}
                label="School"
                onChange={onSchoolChange}
                renderValue={(value) => {
                  const school = schools.find(s => s.ncessch === value);
                  return school ? formatSchoolName(school.name) : '';
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400
                    }
                  }
                }}
              >
                {sortedSchools.map((school) => (
                  <MenuItem key={school.ncessch} value={school.ncessch}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Typography variant="body1">
                        {formatSchoolName(school.name)}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {school.city}, {school.state} • NCES ID: {school.ncessch}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Drive Time</InputLabel>
              <Select
                value={selectedDriveTime}
                label="Drive Time"
                onChange={onDriveTimeChange}
              >
                {DRIVE_TIMES.map((time) => (
                  <MenuItem key={time} value={time}>
                    {time} minutes
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SchoolSummaryFilters;