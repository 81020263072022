import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

const InsightContext = createContext(null);

export function useInsight() {
  const context = useContext(InsightContext);
  if (!context) {
    throw new Error('useInsight must be used within an InsightProvider');
  }
  return context;
}

export function InsightProvider({ children }) {
  const [currentTool, setCurrentTool] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);

  // Mapping of tools and their sections to insight content - moved outside component
  const insightContent = useMemo(() => ({
    'geographic-explorer': {
      default: {
        purpose: "The Geographic Explorer helps leaders understand the community in which they are operating, including the nearby student population, enrollment trends, and competition.  This tool sets the foundation for deeper competitive analysis and impacts to future enrollment projections.",
        action: <>Begin by exploring <b>Your Community</b> headlines to understand the nearby student population, including demographics.  Then view <b>Enrollment Trends</b> to see how this impacting schools differently, including shifts over time.  Finally, use the <b>Nearby Schools Comparison</b> section to see specific data points and mapping visuals for area peers.</>
      },
      communityHeadlines: {
        explanations: [
          <><b>Population trends</b> estimate number of children living in the drive time and does not consider if the child attends public school or not.</>,
          <><b>Demographics</b> identifies nearby student and adult population.</>,
          <><b>Economic indicators</b> focus on family income and housing stability.</>,
          <><b>Source</b>: Esri and 2020 U.S. Census</>
        ],
        actions: [
          <>View recent <b>student population changes</b> and 2029 projections, primarily due to housing, migration, and aging.</>,
          <>Compare your <b>campus's enrollment change</b> to the area. Is your enrollment more stable?</>,
          <>Look at the nearby <b>racial, ethnic, and economic profile</b> to understand the nearby population, which are explored more deeply in the Enrollment Trends section.</>
        ],
        lookFors: [
          <><b>Size and change in the number of children</b> living nearby.</>,
          <>If <b>drive times are changed</b>, how do trends vary closer to your campus (5 minutes) versus further away (10 or 15 minutes)?</>
        ]
      },
      enrollmentHeadlines: {
        explanations: [
          <><b>Enrollment</b> is state-reported NCES and differs from average daily attendance or other points in time.</>,
          <>Figures are based on where <b>students attend school</b> for schools located in the drive time radius, meaning some students may not live in the radius.</>,
          <><b>Source</b>: NCES Common Core of Data</>
        ],
        actions: [
          <>Understand <b>overall enrollment shifts</b> and compare your school to the broader area.</>,
          <>Compare <b>demographics</b> to identify enrollment gaps by group and use the toggle to see 5-year trends by group.</>
        ],
        lookFors: [
          <>How is your school maintaining enrollment <b>vis-a-vis the area</b>?</>,
          <>How do your school's <b>demographics compare</b> with nearby schools? For those groups where you serve a <b>higher percentage of students</b>, what is their growth?</>
        ]
      },
      comparisonHeadlines: {
        explanations: [
          <><b>Enrollment</b> is state-reported NCES and differs from average daily attendance or other points in time.</>,
          <>Figures are based on where <b>students attend school</b> for schools located in the drive time radius, meaning some students may not live in the radius.</>,
          <><b>Source</b>: NCES Common Core of Data</>
        ],
        actions: [
          <>View your campus's <b>enrollment change rank</b>. <b>School Changes</b> identifies opening or closing schools since 2019.</>,
          <>Dig into <b>school-specific trends</b> for the closest schools, primary competition, or bucking area trends.</>,
          <>Use the Growth Comparison "<b>Expand View</b>" or the <b>map</b> to see your school's relative size and change versus peers.</>
        ],
        lookFors: [
          <>How does our enrollment change <b>compare to nearby peers</b>?</>,
          <>Which <b>specific schools</b> are maintaining or increasing enrollment? Do their student demographics vary from yours or other factors leading to strength?</>
        ]
      }
    },
    'competitive-explorer': {
      default: {
        purpose: "Building on the Geographic Explorer, the Competitive Explorer allows leaders to go deeper in understanding their school's ability to compete in a changing area. In many areas, a key driver of enrollment changes is the connection between your student’s demographics and the changes in the area’s demographics.",
        action: 
          <>Begin by understanding <b>Your Community</b> and the connection between the area's changing population and your school's market share, which is the percentage of students of those ages attending your school.<br></br><br></br>Then view <b>Enrollment & Demographic Trends</b> to see how market share has changed for demographic groups while <b>Nearby Schools Comparison</b> dives into specific schools to enable detailed analysis.</>
      },
      communityHeadlines: {
        explanations: [
          <><b>Market share</b> is calculated by dividing your school's 2023-24 enrollment by Esri's 2024 estimated nearby grade-specific population in the drivetime.</>,
          <><b>Area population change</b> (x-axis) is out of your control, but reflects the changing number of area children.</>,
          <><b>Market share change</b> (y-axis) is how the percentage of children living in the area that attend your school has changed over the last 4 years.</>
        ],
        actions: [
          <>Understand this <b>summary view</b> of area changes and school trends, then explore underlying dynamics in the sections below.</>,
          <>If you have not already, use the Geographic Explorer to better understand <b>population changes</b> in your area.</>
        ],
        lookFors: [
          <>What is the <b>future outlook</b> when linking area dynamics with our school's competitiveness? The quadrants highlight areas of strength, mixed considerations, or challenges.</>,
        ]
      },
      enrollmentTrends: {
        explanations: [
          <>Four dynamics are spotlighted to connect your school to the area:</>,
          <><b>Percentage of area enrollment</b> for race/ethnic groups</>,
          <><b>Change in area enrollment</b> since 2020 by group</>,
          <><b>Your school's market share</b> by group</>,
          <><b>Change your school's market share</b> by group since 2020</>
        ],
        actions: [
          <>Read the <b>headlines</b> to understand the area's operating environment.</>,
          <>Understand the <b>area's largest student groups</b> and how those groups have changed since 2019.</>,
          <>View your <b>school's market share</b> by student group and change since 2019.</>
        ],
        lookFors: [
          <>What is <b>area growth</b> for our largest student groups?</>,
          <>How has <b>our share of students changed</b> in those largest groups?</>,
          <>What other student groups in the area <b>could we recruit</b>? Have we tried and had difficulty? Or does this present a <b>new opportunity to diversify</b>?</>
        ]
      },
      nearby: {
        explanations: [
          <><b>Market share</b> is calculated by dividing your school's enrollment by total NCES enrollment for schools located in the selected drivetime.</>,
          <><b>Change in market share</b> is the difference between your 2023-24 market share and 2019-20 share.</>
        ],
        actions: [
          <>View the <b>rank of your market share trend</b> versus nearby peers and how that rank has changed since 2019.</>,
          <>Dig deeper into <b>nearby schools</b> using the data table and map.</>
        ],
        lookFors: [
          <>Which nearby schools have <b>increased their market share</b>? What is driving that strength?</>,
          <>Are there <b>surprising competitors</b> that you have not focused on previously?</>
        ]
      }
    },
    'projections-explorer': {
      default: {
        purpose: "The Projections Explorer calculates three forward-looking enrollment scenarios using the school's historical grade-level enrollment.  These are intended to be a starting point using historical entry grade and matriculation data for leaders to use as reference points in setting or evaluating their network targets. Please click here to view the enrollment projections scenarios calculation methodology.",
        action: [
          <>Follow these steps to analyze and plan your school's future enrollment:<br/>
          <ol>
            <li><b>Enter latest enrollment data</b>: analyze the most current information</li>
            <li><b>Review projection scenarios</b>: explore starter enrollment projection scenarios that have been calculated using your school's historical data</li>
            <li><b>Assess risk of meeting targets</b>: compare your network's enrollment targets against projection scenarios to identify potential opportunities and challenges</li>
            <li><b>Adjust scenarios based on strategies</b>: model how improving recruitment, retention, or changes to the area's population or competition could impact enrollment outcomes</li>
            <li><b>Take action</b>: access additional tools and resources to support next steps related to short and long-term planning</li>
          </ol></>
        ]
      },
      risk: {
        explanations: [
          "Risk assessment compares your targets to the projection scenarios and provides feedback based on how it aligns with the high-midpoint-low scenarios."
        ],
        actions: [
          <>Look at longer-term historical enrollment and the <b>midpoint projection scenarios</b>.</>,
          <>Upon entering your <b>enrollment targets</b> by grade and/or year, read the feedback on relative risk.  </>,
          <>Reference the <b>Strategy Impact Analysis</b> section to adjust projection scenarios based on school strategies or area changes.</>
        ],
        lookFors: [
          <>What are <b>overall enrollment trends</b>, which span upwards of a decade between historical and projected years?</>,
          <>Are there <b>specific areas of risk</b>, such as particular grades or broader risk across multiple years, which may suggest difficulty in meeting targets.</>
        ]
      },
      impact: {
        explanations: [
          'Four impacts are available to "dial up or down", impact future projection scenarios and relate to student recruiting and retention, area population, and competition.',
        ],
        actions: [
          <><b>Adjust one or more dials</b> to see the projections impacts and resulting risk assessment.</>,
          <><b>Collaborate with colleagues</b> on these dynamics to build alignment on network targets.</>
        ],
        lookFors: [
          <>Conducting a <b>"what would you have to believe?"</b> exercise builds understanding of what needs to happen to meet targets.  Engaging internal stakeholders on higher targets can build understanding of the operating environment and help manage risk.</>
        ]
      }
    },
    'school-summary': {
      'population': {
        explanations: [
          'Population trends estimate number of children living in the drive time and does not consider if the child attends public school or not.',
          'Source: Esri and 2020 Census',
        ],
        actions: [
          'View recent student population changes and 2029 projections, primarily due to housing, migration, and aging.',
        ],
        lookFors: [
          'Size and change in the number of children living nearby.',  
         'Are trends from the past four years projected to continue?',  
         'Are they projected to stabilize or rebound?', 
         'Keep in mind these are a point-in-time projection from June 2024 and dynamics may have changed or could change in the future.',
        ]
      },
      'projections': {
        explanations: [
          'The Projections Explorer calculates three forward-looking enrollment scenarios using historical grade-level enrollment. These are intended to be a starting point using historical entry grade and matriculation data for leaders to use as reference points in setting or evaluating their network targets.', 
          <>Visit the <Link 
              to={`/resources`}
              style={{ 
                color: '#1976d2',  // MUI default primary color
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >resources</Link> page to view the enrollment projections scenarios calculation methodology.</>,
        ],
        actions: [
          'Review starter projection scenarios using historical enrollment data.',
        ],
        lookFors: [
          'Pay particular attention to the midpoint projection: does it reflect growth, steady, or declines?',
          'Additionally, how wide is the spread between scenarios? Does it appear narrow, or is there a wide range of future outcomes given historical data volatility? ',
        ]
      },
      'market-share': {
        explanations: [
          'Market share is calculated by dividing 2023-24 enrollment by the 2024 estimated nearby grade-specific population in the drivetime from Esri.',
          'Area population change (x-axis) is out of your control, but reflects the changing number of area children.',
          'Market share change (y-axis) is how the percentage of children living in the area that attend your school has changed over the last 4 years.',
        ],
        actions: [
          'Understand this summary view of the combined area changes and school market share trends.',
          'To what extent are forces outside of your control (area population) impacting enrollment versus those where you have some control (market share)?',
        ],
        lookFors: [
          'What is the future outlook when linking area dynamics with school competitiveness?',
          'The quadrants highlight areas of strength, mixed considerations, or challenges.',
        ]
      },
      'comparison': {
        explanations: [
          'Enrollment is state-reported NCES and differs from average daily attendance or other points in time.',
          'Figures are based on where students attend school for schools located in the drive time radius, meaning some students may not live in the radius.',
          'Source: NCES Common Core of Data',
        ],
        actions: [
          'View the campus enrollment change and relative rank versus nearby schools.',
          'Use the map to see nearby schools and click on their pin to view key data poitns for those schools.',
          'Use the Enrollment Changes data table and sort by size or change to understand specific school details.',
        ],
        lookFors: [
          'Which schools are outperforming the area?  What do we know about them as to why they have stronger enrollment?',
          'Are there schools nearby that we were not aware of or are surprised about the key data?',
        ]
      },
      'demographics': {
        explanations: [
          'Demographics identifies the child and adult population within the selected drivetime.',
          'Enrollment uses 2023-24 NCES data for schools of similar grades located within the selected drivetimes.  Five year trends use 2019-20 NCES data.',
          'Source: Esri and 2020 U.S. Census',
        ],
        actions: [
          'View the child population by demographic group and compare it to the school student body.  Similarly compare how your student composition compares with nearby schools to understand gaps or alignment.',
          'Toggle "Demographic Enrollment" to "5-Year Trend" to view enrollment changes by race/ethnicity since 2019-20. Which of your student groups have changed and are those also changing at nearby schools?  Or are there differences?',
        ],
        lookFors: [
          'Relative alignment between the nearby student population and your student body to see areas of alignment or gaps.',
          'How the largest student groups at your school have changed since 2019 and how those changes compare with nearby schools.  If your enrollment has been declining, does this uncover a root cause?',
        ]
      }
    },
    'district-analysis': {
      'population-trends': {
        explanations: [
          'Population trends estimate the number of children in the grades this school offers living in a 10-minute drive time and does not consider if the child attends public school or not.  Stable refers to within 2% of 2020 population size.',
          'Source: Esri Updated Demographics and 2020 U.S. Census',
        ],
        actions: [
          <>Use the <b>filters</b> to view certain groups of schools interest and filter dynamics like schools in a declining population area and with declining market share.</>,
          <>Look at <b>broad trends</b> to see if your campuses are located in areas of growing or declining numbers of children.</>,
          <>Use the map's school color coding and the <b>school details</b> below to view key metrics for your campuses.  </>,
        ],
        lookFors: [
          <><b>Overall</b>, are our campuses located in areas of child population growth?</>,
          <>When connecting key metrics like the area population with enrollment, which campuses are <b>outperforming the area</b>? Which seem to have <b>greater risk</b> because their enrollment is declining more than the area?</>,
          <>Using column sortation in the detailed section, which schools are located in areas with the strongest population trends?  Or most concerning?</>,
        ]
      },
      'population-projections': {
        explanations: [
          <>Population projections estimate the number of children in the grades this school offers living in a 10-minute drive time in 2029 and does not consider if the child attends public school or not. Stable refers to projections within 2% of 2024 population size.</>,
          <>Source: Esri Updated Demographics population projections</>,
        ],
        actions: [
          <>Look at <b>broad trends</b> to see if the areas around your campuses are projected to grow by 2029. How does this compare to how the population has changed since 2020?</>,
          <>Use the map's school color coding and the <b>school details</b> below to see which schools are in growing areas or have projected growth that reverses recent declines.</>,
        ],
        lookFors: [
          <><b>Overall</b>, are our campuses located in areas of where the child population is projected to grow?</>,
          <>Specifically, which campuses are in areas that have <b>declined</b> since 2020 and are projected to <b>decline further</b> by 2029, creating greater concern?</>,
        ]
      },
      'enrollment-trends': {
        explanations: [
          <>Enrollment is state-reported NCES and differs from average daily attendance or other points in time.</>,
          <>Figures are based on where students attend school for schools located in the drive time radius, meaning some students may not live in the radius.</>,
          <>Source: NCES Common Core of Data</>,
        ],
        actions: [
          <>Look at <b>broad trends</b> to see how your set of schools is growing or declining in enrollment. </>,
          <><b>Connect enrollment trends with area population trend</b> in the school details to see areas where you are outperforming the area versus more concerning.</>,
        ],
        lookFors: [
          <>Overall, what percentage of our campuses are increasing enrollment versus decreasing?</>,
          <>Specifically, by sorting columns in the data table, which campuses have experienced the strongest growth? Or largest declines?  What do other metrics show for those schools to give more context? Are those areas also growing or decreasing at higher rates?</>,
        ]
      },
      'enrollment-projections': {
        explanations: [
          <>Three forward-looking enrollment scenarios using the school's historical grade-level enrollment. These are intended to be a starting point using historical entry grade and matriculation data for leaders to use as reference points in setting or evaluating their network targets.</>,
          <>Visit the <Link 
              to={`/resources`}
              style={{ 
                color: '#1976d2',  // MUI default primary color
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >resources</Link> page to view the enrollment projections scenarios calculation methodology.</>,
        ],
        actions: [
          <>Look at <b>broad trends</b> to see how your set of schools is projected to change in enrollment by 2029.</>,
          <>Look at the schol details and <b>connect enrollment projections with enrollment history to understand particular schools of concern</b>.</>,
        ],
        lookFors: [
          <>Overall, what percentage of our campuses are projected to increase enrollment versus decrease?</>,
          <>Specifically, by sorting columns in the data table, which campuses are projected to have the strongest growth? Or largest declines? </>,
        ]
      },
      'market-share': {
        explanations: [
          <>Market share is calculated by dividing your school's 2023-24 enrollment by Esri's 2024 estimated nearby grade-specific population in the drivetime.</>,
          <>Stable refers to market share that is within 1.4%-points of 2020 shares.</>,
          <>Source: Esri and NCES Common Core of Data</>,
        ],
        actions: [
          <>Look at broad trends to see how many schools are gaining share versus losing.</>,
          <>Use the filters to combine dynamics to understand school-level concern, such as schools losing market share in areas that have declining child populations.</>,
        ],
        lookFors: [
          <>Knowing that area populations trends are out of our control, to what extent are we competing for students in these areas by gaining or maintaining market share?</>,
          <>What campuses are <b>particularly strong</b> given combined area and market share dynamics?  Which are <b>particularly concerning</b> given those combined dynamics? </>
        ]
      },
      }
  }), []); // Empty dependency array since this content is static

  // Memoized callbacks
  const setToolAndSection = useCallback((tool, section) => {
    // If we're switching tools (tool changed and section is null), 
    // we want to show default content
    if (tool && !section && tool !== currentTool) {
      setCurrentTool(tool);
      setCurrentSection(null);
      return;
    }
    
    // Normal case - either setting both or clearing both
    setCurrentTool(prev => prev === tool ? prev : tool);
    setCurrentSection(prev => prev === section ? prev : section);
  }, [currentTool]);

  const getCurrentContent = useCallback(() => {
    if (!currentTool || !currentSection) return null;
    return insightContent[currentTool]?.[currentSection] || null;
  }, [currentTool, currentSection, insightContent]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    currentTool,
    currentSection,
    setToolAndSection,
    getCurrentContent,
    insightContent
  }), [
    currentTool,
    currentSection,
    setToolAndSection,
    getCurrentContent,
    insightContent
  ]);

  return (
    <InsightContext.Provider value={contextValue}>
      {children}
    </InsightContext.Provider>
  );
}