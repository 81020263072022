const chartAnimationState = {
    // Track which sections have been animated
    animatedSections: {},
    // Track which school we're viewing
    currentSchool: null,
    
    // Check if animation should run for a section
    shouldAnimate(sectionKey, schoolId) {
      // Reset state if school changes
      if (schoolId !== this.currentSchool) {
        this.currentSchool = schoolId;
        this.animatedSections = {};
      }
      
      // Allow animation only on first render for each section
      const shouldAnimate = !this.animatedSections[sectionKey];
      
      // Mark section as animated
      if (shouldAnimate) {
        this.animatedSections[sectionKey] = true;
      }
      
      return shouldAnimate;
    }
  };
  
  export default chartAnimationState;