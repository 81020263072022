// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { hasRouteAccess } from '../constants/roleAccess';

// Changed to function declaration and explicit export
export default function ProtectedRoute({ children, allowedRoles }) {
  const location = useLocation();
  const { user, loading } = useAuth();
  const currentPath = location.pathname;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Check role from enhanced user object
  if (allowedRoles && !allowedRoles.includes(user.role)) {
    return <Navigate to="/dashboard" replace />;
  }

  // Check route access using role from enhanced user object
  if (!hasRouteAccess(user.role, currentPath)) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
}