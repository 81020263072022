import React, { useState, useMemo } from 'react';
import {
  Drawer,
  IconButton,
  Typography,
  Box,
  useTheme,
  Alert,
  AlertTitle,
  keyframes,
  Fade,
  Divider,
  Button,
  Collapse
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  ExpandMore,
  ExpandLess
} from '@mui/icons-material';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useInsight } from '../../contexts/InsightContext';
import { Link } from 'react-router-dom';

// Video mapping - associates tools and sections with specific video IDs
const videoMapping = {
  'geographic-explorer': {
    default: 'b6a29b34572143b780c57f6b2620f1d1', // Overview of the Geographic Explorer
    communityHeadlines: '0d649ff2d72648319e297a1951a6b9e3', // GeoX: Your Community Deeper Dive
    enrollmentHeadlines: '5bc74c386c4246ddbbc08f63ee6420d6', // GeoX: Enrollment Trends Deeper Dive
    comparisonHeadlines: 'a9eb9189bc5c4b4cad9c7287aed22668', // GeoX: Nearby Schools Comparison Deeper Dive
  },
  'competitive-explorer': {
    default: '10c2543490d34a1dbd300543e3ceb54b', // Overview of the Competitive Explorer
    communityHeadlines: 'e2dc823b00b24c2d87434dbb9d536848', // CompX: Your Community Deeper Dive
    enrollmentTrends: '39ce708acea34c13ad50867780c555ce', // CompX: Enrollment & Demographic Trends Deeper Dive
    nearby: 'd72302f699c541cfaf4239a018aafd2d', // CompX: Nearby Schools Comparison Deeper Dive
  },
  'projections-explorer': {
    default: '3e7a737811b14b2cba3f42f6784f8d83', // Overview of the Projections Explorer
    risk: '7d90dcaa7aa04a84856bd1bf0a9d103e', // ProjectX: Risk Assessment
    impact: 'caebe6dd881b45398daea11abff91414', // ProjectX: Strategy Impact Analysis Deeper Dive
  },
  'school-summary': {
    default: '54e2847091b045539569df7d1fd98df0', // Overview of the School Summary
  },
  'district-analysis': {
    default: '7d0fa0c5a5bb47a2aa9c6c56c486def8', // Overview of All Schools Analysis
  }
};

// Video titles mapping for better user experience
const videoTitles = {
  'b6a29b34572143b780c57f6b2620f1d1': 'Overview of the Geographic Explorer',
  '0d649ff2d72648319e297a1951a6b9e3': 'GeoX: Your Community Deeper Dive',
  '5bc74c386c4246ddbbc08f63ee6420d6': 'GeoX: Enrollment Trends Deeper Dive',
  'a9eb9189bc5c4b4cad9c7287aed22668': 'GeoX: Nearby Schools Comparison Deeper Dive',
  '10c2543490d34a1dbd300543e3ceb54b': 'Overview of the Competitive Explorer',
  'e2dc823b00b24c2d87434dbb9d536848': 'CompX: Your Community Deeper Dive',
  '39ce708acea34c13ad50867780c555ce': 'CompX: Enrollment & Demographic Trends Deeper Dive',
  'd72302f699c541cfaf4239a018aafd2d': 'CompX: Nearby Schools Comparison Deeper Dive',
  '3e7a737811b14b2cba3f42f6784f8d83': 'Overview of the Projections Explorer',
  '7d90dcaa7aa04a84856bd1bf0a9d103e': 'ProjectX: Risk Assessment',
  'caebe6dd881b45398daea11abff91414': 'ProjectX: Strategy Impact Analysis Deeper Dive',
  '54e2847091b045539569df7d1fd98df0': 'Overview of the School Summary',
  '7d0fa0c5a5bb47a2aa9c6c56c486def8': 'Overview of All Schools Analysis'
};

// Simple video component
const VideoEmbed = ({ videoId }) => {
  if (!videoId) return null;

  return (
    <Box sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', mb: 2 }}>
      <iframe 
        title={`Loom video player ${videoId}`}
        src={`https://www.loom.com/embed/${videoId}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
        frameBorder="0" 
        webkitallowfullscreen="true"
        mozallowfullscreen="true" 
        allowFullScreen={true}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      />
    </Box>
  );
};

const pulse = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateX(-5px);
    opacity: 0.7;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const InsightSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const theme = useTheme();
  const { getCurrentContent, currentTool, currentSection, insightContent } = useInsight();

  // Memoize these derived values
  const defaultContent = useMemo(() => 
    currentTool ? insightContent[currentTool]?.default : null,
    [currentTool, insightContent]
  );  

  const content = useMemo(() => {
    return getCurrentContent();
  }, [getCurrentContent]);  

  // Get the appropriate video ID based on current tool and section
  const currentVideoId = useMemo(() => {
    if (!currentTool) return null;
    
    if (currentSection && videoMapping[currentTool]?.[currentSection]) {
      return videoMapping[currentTool][currentSection];
    } else {
      // Default video for the tool if no specific section video exists
      return videoMapping[currentTool]?.default || null;
    }
  }, [currentTool, currentSection]);

  // Get video title
  const currentVideoTitle = useMemo(() => {
    return currentVideoId ? videoTitles[currentVideoId] || 'Tutorial Video' : '';
  }, [currentVideoId]);

  const drawerWidth = isOpen ? 320 : 48;
  
  return (
    <Drawer
      variant="permanent"
      anchor="right"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: isOpen ? '#F5F3FF' : '#6B46C1',
          borderLeft: `1px solid ${isOpen ? '#6B46C1' : '#6B46C1'}`,
          overflowX: 'hidden',
          transition: theme.transitions.create(['width', 'background-color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          boxShadow: '-4px 0 10px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <Box 
        onClick={() => !isOpen && setIsOpen(true)}
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          height: '100%',
          cursor: !isOpen ? 'pointer' : 'default',
          '&:hover': {
            backgroundColor: !isOpen ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
          },
          transition: 'background-color 0.2s ease',
        }}>
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${isOpen ? theme.palette.divider : '#8B5CF6'}`,
          minHeight: 64,
        }}>
          {!isOpen ? (
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              py: 1,
            }}>
              <TipsAndUpdatesRoundedIcon sx={{ color: 'white' }} />
              <Typography
                sx={{
                  writingMode: 'vertical-lr',
                  transform: 'rotate(180deg)',
                  textAlign: 'center',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  my: 2,
                  color: 'white',
                }}
              >
                Insights & Actions
              </Typography>
              <ChevronLeft sx={{ 
                color: 'white',
                animation: `${pulse} 1.5s ease-in-out infinite`,
              }} />
            </Box>
          ) : (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              width: '100%',
              px: 2,
            }}>
              <TipsAndUpdatesRoundedIcon sx={{ color: 'primary.main', mr: 1 }} />
              <Typography sx={{ 
                fontSize: '1rem',
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}>
                Insights & Actions
              </Typography>
              <IconButton 
                onClick={() => setIsOpen(false)}
                sx={{ ml: 'auto' }}
              >
                <ChevronRight />
              </IconButton>
            </Box>
          )}
        </Box>
  
        {isOpen && (
          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            {!currentTool ? (
              <Alert severity="info" sx={{ m: 2 }}>
                <AlertTitle>No Tool Selected</AlertTitle>
                Select a tool to view relevant insights and guidance
              </Alert>
            ) : (
              <Box sx={{ p: 2 }}>
                {/* Video Section */}
                {currentVideoId && (
                  <Box sx={{ mb: 4 }}>
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        mb: 1,
                        cursor: 'pointer'
                      }}
                      onClick={() => setShowVideo(!showVideo)}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                        <VideoLibraryIcon 
                          sx={{ 
                            color: 'primary.main',
                            fontSize: '1.25rem'
                          }} 
                        />
                        <Typography 
                          sx={{ 
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            letterSpacing: '0.01em',
                            color: 'text.primary'
                          }}
                        >
                          Tutorial Video
                        </Typography>
                      </Box>
                      {showVideo ? <ExpandLess /> : <ExpandMore />}
                    </Box>

                    <Collapse in={showVideo}>
                      <Typography
                        sx={{
                          fontSize: '0.875rem',
                          fontWeight: 500,
                          color: 'text.secondary',
                          mb: 1,
                        }}
                      >
                        {currentVideoTitle}
                      </Typography>
                      <VideoEmbed videoId={currentVideoId} />
                      
                      <Button 
                        component={Link} 
                        to="/resources/tutorials" 
                        startIcon={<PlayCircleOutlineIcon />}
                        variant="outlined"
                        size="small"
                        sx={{ mb: 2, mt: 1 }}
                        fullWidth
                      >
                        View All Tutorials
                      </Button>
                    </Collapse>
                    
                    <Divider sx={{ my: 2 }} />
                  </Box>
                )}

                {/* Default Content */}
                {!content && defaultContent ? (
                  <Box>
                    <Box sx={{ mb: 4 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        mb: 2,
                        gap: 1.5
                      }}>
                        <TipsAndUpdatesRoundedIcon 
                          sx={{ 
                            color: 'primary.main',
                            fontSize: '1.25rem'
                          }} 
                        />
                        <Typography 
                          sx={{ 
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            letterSpacing: '0.01em',
                            color: 'text.primary'
                          }}
                        >
                          Purpose
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '0.875rem',
                          color: 'text.secondary',
                          mb: 2,
                          lineHeight: 1.5,
                        }}
                      >
                        {defaultContent.purpose}
                      </Typography>
                    </Box>
  
                    <Box sx={{ mb: 4 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        mb: 2,
                        gap: 1.5
                      }}>
                        <PlaylistAddCheckRoundedIcon 
                          sx={{ 
                            color: 'primary.main',
                            fontSize: '1.25rem'
                          }} 
                        />
                        <Typography 
                          sx={{ 
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            letterSpacing: '0.01em',
                            color: 'text.primary'
                          }}
                        >
                          Action
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '0.875rem',
                          color: 'text.secondary',
                          mb: 2,
                          lineHeight: 1.5,
                        }}
                      >
                        {defaultContent.action}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Fade in={true} timeout={300}>
                    <Box>
                      {content?.explanations?.length > 0 && (
                        <Box sx={{ mb: 4 }}>
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            mb: 2,
                            px: 1,
                            gap: 1.5
                          }}>
                            <QuestionAnswerOutlinedIcon 
                              sx={{ 
                                color: 'primary.main',
                                fontSize: '1.25rem'
                              }} 
                            />
                            <Typography 
                              sx={{ 
                                fontSize: '0.875rem',
                                fontWeight: 600,
                                letterSpacing: '0.01em',
                                color: 'text.primary'
                              }}
                            >
                              Key Explanations
                            </Typography>
                          </Box>
                          <Box sx={{ px: 1 }}>
                            {content.explanations.map((item, i) => (
                              <Typography
                                key={i}
                                sx={{
                                  fontSize: '0.875rem',
                                  color: 'text.secondary',
                                  mb: 1.5,
                                  lineHeight: 1.5,
                                }}
                              >
                                {item}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      )}
  
                      {content?.actions?.length > 0 && (
                        <Box sx={{ mb: 4 }}>
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            mb: 2,
                            px: 1,
                            gap: 1.5
                          }}>
                            <PlaylistAddCheckRoundedIcon 
                              sx={{ 
                                color: 'primary.main',
                                fontSize: '1.25rem'
                              }} 
                            />
                            <Typography 
                              sx={{ 
                                fontSize: '0.875rem',
                                fontWeight: 600,
                                letterSpacing: '0.01em',
                                color: 'text.primary'
                              }}
                            >
                              Recommended Actions
                            </Typography>
                          </Box>
                          <Box sx={{ px: 1 }}>
                            {content.actions.map((item, i) => (
                              <Typography
                                key={i}
                                sx={{
                                  fontSize: '0.875rem',
                                  color: 'text.secondary',
                                  mb: 1.5,
                                  lineHeight: 1.5,
                                }}
                              >
                                {item}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      )}
  
                      {content?.lookFors?.length > 0 && (
                        <Box sx={{ mb: 4 }}>
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            mb: 2,
                            px: 1,
                            gap: 1.5
                          }}>
                            <QueryStatsRoundedIcon 
                              sx={{ 
                                color: 'primary.main',
                                fontSize: '1.25rem'
                              }} 
                            />
                            <Typography 
                              sx={{ 
                                fontSize: '0.875rem',
                                fontWeight: 600,
                                letterSpacing: '0.01em',
                                color: 'text.primary'
                              }}
                            >
                              What to Look For
                            </Typography>
                          </Box>
                          <Box sx={{ px: 1 }}>
                            {content.lookFors.map((item, i) => (
                              <Typography
                                key={i}
                                sx={{
                                  fontSize: '0.875rem',
                                  color: 'text.secondary',
                                  mb: 1.5,
                                  lineHeight: 1.5,
                                }}
                              >
                                {item}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Fade>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default InsightSidebar;