import React, { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { BarChartOutlined } from '@mui/icons-material';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ChartTemplate from '../../shared_components/templates/barChartTemplate';
import { formatGradeList, formatGrade } from '../../../utils/schoolUtils';

const GeoXEnrollmentCompositionChanges = ({
  selectedSchool,
  schoolsEnrollmentData,
  filteredSchools,
  selectedGrades,
  governanceFilter,
  timeframe,
  animationEnabled = true
}) => {
  const theme = useTheme();
  const [viewType, setViewType] = useState('current');

  const handleViewTypeChange = (event, newValue) => {
    if (newValue !== null) {
      setViewType(newValue);
    }
  };

  const processedData = useMemo(() => {
    if (!selectedSchool || !schoolsEnrollmentData?.length) {
      return [];
    }
  
    // Get selected school data
    const selectedSchoolData = schoolsEnrollmentData.find(
      d => d.ncessch === selectedSchool.ncessch
    );
  
    if (!selectedSchoolData) {
      return [];
    }
  
    // Filter and prepare other schools data
    const otherSchools = filteredSchools
      .filter(school => school.ncessch !== selectedSchool.ncessch)
      .map(school => schoolsEnrollmentData.find(d => d.ncessch === school.ncessch))
      .filter(Boolean);

    // Calculate totals by summing across selected grades
    const calculateTotals = (raceData) => {
      if (!raceData) return {};
      const totals = {};
      selectedGrades.forEach(grade => {
        const formattedGrade = formatGrade(grade);
        const gradeData = raceData[formattedGrade] || {};
        Object.entries(gradeData).forEach(([race, count]) => {
          totals[race] = (totals[race] || 0) + (count || 0);
        });
      });
      return totals;
    };

    // Get selected school totals
    const selectedTotals = {
      current: calculateTotals(selectedSchoolData.race_data?.current),
      comparison: calculateTotals(selectedSchoolData.race_data?.comparison)
    };

    // Get nearby schools totals
    const nearbyTotals = {
      current: {},
      comparison: {}
    };

    otherSchools.forEach(school => {
      const currentTotals = calculateTotals(school.race_data?.current);
      const comparisonTotals = calculateTotals(school.race_data?.comparison);

      Object.entries(currentTotals).forEach(([race, count]) => {
        nearbyTotals.current[race] = (nearbyTotals.current[race] || 0) + count;
      });

      Object.entries(comparisonTotals).forEach(([race, count]) => {
        nearbyTotals.comparison[race] = (nearbyTotals.comparison[race] || 0) + count;
      });
    });

    // Calculate overall totals
    const totalEnrollment = {
      current: Object.values(selectedTotals.current).reduce((sum, val) => sum + (val || 0), 0),
      comparison: Object.values(selectedTotals.comparison).reduce((sum, val) => sum + (val || 0), 0)
    };

    const nearbyTotalEnrollment = {
      current: Object.values(nearbyTotals.current).reduce((sum, val) => sum + (val || 0), 0),
      comparison: Object.values(nearbyTotals.comparison).reduce((sum, val) => sum + (val || 0), 0)
    };

    // Process race categories
    const raceCategories = [
      'hispanic',
      'white',
      'black',
      'asian',
      'pacific_islander',
      'american_indian',
      'two_or_more_races'
    ];

    const results = raceCategories.map(race => {
      const schoolCurrent = selectedTotals.current[race] || 0;
      const schoolComparison = selectedTotals.comparison[race] || 0;
      const nearbyCurrent = nearbyTotals.current[race] || 0;
      const nearbyComparison = nearbyTotals.comparison[race] || 0;

      const schoolValue = totalEnrollment.current > 0 
        ? (schoolCurrent / totalEnrollment.current) * 100 
        : 0;
      
      const nearbyValue = nearbyTotalEnrollment.current > 0
        ? (nearbyCurrent / nearbyTotalEnrollment.current) * 100
        : 0;

      const schoolChange = schoolComparison > 0
        ? ((schoolCurrent - schoolComparison) / schoolComparison) * 100
        : 0;

      const nearbyChange = nearbyComparison > 0
        ? ((nearbyCurrent - nearbyComparison) / nearbyComparison) * 100
        : 0;

      return {
        category: race.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        value: schoolValue,
        nearbyValue: nearbyValue,
        schoolCount: schoolCurrent,
        nearbyCount: nearbyCurrent,
        schoolChange: schoolChange,
        nearbyChange: nearbyChange
      };
    });

    return results
      .filter(item => item.value >= 5 || item.nearbyValue >= 5)
      .sort((a, b) => b.value - a.value);
  }, [selectedSchool, schoolsEnrollmentData, filteredSchools, selectedGrades]);


  const getAnalysisSummary = useMemo(() => {
    if (!processedData.length || !selectedSchool?.name) return '';
    
    if (viewType === 'current') {
      const mostPopulous = [...processedData]
        .sort((a, b) => b.value - a.value)[0];
      
      const diffText = mostPopulous.value > mostPopulous.nearbyValue
        ? `${Math.round(mostPopulous.value - mostPopulous.nearbyValue)}% higher than`
        : `${Math.round(mostPopulous.nearbyValue - mostPopulous.value)}% lower than`;
    
      return `${mostPopulous.category} students comprise ${Math.round(mostPopulous.value)}% of enrollment, ${diffText} nearby schools`;
    } else {
      const biggestChange = [...processedData]
        .sort((a, b) => Math.abs(b.schoolChange) - Math.abs(a.schoolChange))[0];
      
      const timeframeText = timeframe?.hasComparison 
        ? 'over five years'
        : `since ${timeframe?.startLabel || '2019'}`;
      
      if (Math.abs(biggestChange.schoolChange) < 0.5) {
        return `${biggestChange.category} student enrollment has not changed (0%) ${timeframeText}`;
      }
      
      const changeDirection = biggestChange.schoolChange >= 0 ? 'increased' : 'decreased';
      return `${biggestChange.category} student enrollment has ${changeDirection} by ${Math.abs(Math.round(biggestChange.schoolChange))}% ${timeframeText}`;
    }
  }, [processedData, selectedSchool?.name, viewType, timeframe]);

  const tooltipText = useMemo(() => {
    if (viewType === 'current') {
      return "Shows current demographic composition";
    }

    if (!timeframe) {
      return "Shows demographic enrollment changes";
    }

    const timeframeText = timeframe.hasComparison
      ? "Shows 5-year demographic enrollment changes (since 2019-20)"
      : `Shows demographic enrollment changes since ${timeframe.startLabel} (school's first year)`;

    return timeframeText;
  }, [viewType, timeframe]);
  
  const additionalInfo = useMemo(() => {
    const baseText = "Only showing demographic groups that comprise 5% or more of enrollment in either the selected school or nearby schools";
    
    if (viewType === 'trend' && timeframe) {
      let note = `Note: ${selectedSchool.name}'s data starts from ${timeframe.startLabel}`;
      if (timeframe.hasExpandedGrades) {
        note += `, when it served grades ${formatGradeList(timeframe.startGrades)}. The school now serves grades ${formatGradeList(timeframe.endGrades)}`;
      }
      return `${baseText}. ${note}.`;
    }
    
    return baseText;
  }, [viewType, timeframe, selectedSchool?.name]);

  const chartData = useMemo(() => {
    if (!processedData.length) return null;
  
    return {
      labels: processedData.map(d => d.category),
      datasets: [
        {
          data: viewType === 'current' 
            ? processedData.map(d => Math.round(d.value * 10) / 10)
            : processedData.map(d => Math.round(d.schoolChange * 10) / 10),
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          borderWidth: 1,
          borderRadius: 4,
          barPercentage: 0.4,
          categoryPercentage: 0.8,
        },
        {
          data: viewType === 'current'
            ? processedData.map(d => Math.round(d.nearbyValue * 10) / 10)
            : processedData.map(d => Math.round(d.nearbyChange * 10) / 10),
          backgroundColor: 'rgba(180, 226, 180, 0.8)',
          borderColor: 'rgba(180, 226, 180, 1)',
          borderWidth: 1,
          borderRadius: 4,
          barPercentage: 0.4,
          categoryPercentage: 0.8,
        }
      ]
    };
  }, [processedData, viewType, theme.palette.primary.main]);

  // Update the chartOptions object in GeoXEnrollmentCompositionChanges:
const chartOptions = useMemo(() => ({
  indexAxis: 'y',
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        generateLabels: () => [{
          text: selectedSchool.name,
          fillStyle: theme.palette.primary.main,
          strokeStyle: theme.palette.primary.main,
        }, {
          text: 'Nearby Schools',
          fillStyle: 'rgba(180, 226, 180, 0.8)',
          strokeStyle: 'rgba(180, 226, 180, 1)',
        }]
      }
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const datasetIndex = context.datasetIndex;
          const index = context.dataIndex;
          const data = processedData[index];
          
          if (viewType === 'current') {
            const value = datasetIndex === 0 ? data.value : data.nearbyValue;
            const count = datasetIndex === 0 ? data.schoolCount : data.nearbyCount;
            return `${value.toFixed(0)}% (${count.toLocaleString()} students)`;
          } else {
            // For trend view
            const change = datasetIndex === 0 ? data.schoolChange : data.nearbyChange;
            const currentCount = datasetIndex === 0 ? data.schoolCount : data.nearbyCount;
            const oldCount = datasetIndex === 0 ? 
              (currentCount / (1 + (data.schoolChange / 100))) :
              (currentCount / (1 + (data.nearbyChange / 100)));
            
            return `${Math.abs(change).toFixed(0)}% ${change >= 0 ? 'increase' : 'decrease'} ` +
                   `(${Math.round(oldCount).toLocaleString()} to ${currentCount.toLocaleString()} students)`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: { color: '#e0e0e0' },
        ticks: {
          callback: (value) => `${Math.round(value)}%`,
          font: {
            weight: 'normal'  // Ensure non-bold font
          }
        },
        border: { display: false },
        suggestedMin: viewType === 'trend' ? -20 : 0,
        suggestedMax: viewType === 'trend' ? 20 : 100
      },
      y: {
        grid: { display: false },
        border: { display: false },
        ticks: {
          font: {
            weight: 'normal'  // Ensure non-bold font
          }
        }
      }
    }
  }
}), [selectedSchool.name, viewType, theme.palette.primary.main, processedData]);

const title = (
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <span style={{ flexGrow: 1, fontSize: '1.125rem', fontWeight: 600 }}>Demographic Enrollment</span>
    <ToggleButtonGroup
      value={viewType}
      exclusive
      onChange={handleViewTypeChange}
      size="small"
      sx={{ mr: 1 }}
    >
      <ToggleButton value="current" sx={{ textTransform: 'none' }}>
        2023-24
      </ToggleButton>
      <ToggleButton value="trend" sx={{ textTransform: 'none' }}>
        5-Year Trend
      </ToggleButton>
    </ToggleButtonGroup>
  </Box>
);

  if (!processedData.length) return null;

  return (
    <ChartTemplate
      icon={BarChartOutlined}
      title={title}
      analysis={getAnalysisSummary}
      tooltip={tooltipText}
      chartData={chartData}
      chartOptions={chartOptions}
      additionalInfo={additionalInfo}
      animationEnabled={animationEnabled}
    />
  );
};

export default GeoXEnrollmentCompositionChanges;