import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Card, 
  CardContent, 
  Box, 
  Button, 
  Tabs,
  Tab,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import DescriptionIcon from '@mui/icons-material/Description';
import SchoolIcon from '@mui/icons-material/School';
import SupportIcon from '@mui/icons-material/Support';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import MethodologyDocs from './MethodologyDocs';
import VideoTutorials from './VideoTutorials';

// Tab Panel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`resources-tabpanel-${index}`}
      aria-labelledby={`resources-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ResourcesHome = () => {
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();

  // Reset tab based on URL parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    
    if (tab === 'get-started') setTabValue(0);
    else if (tab === 'get-help') setTabValue(1);
    else if (tab === 'documentation') setTabValue(2);
    else if (tab === 'tutorials') setTabValue(3);
  }, [location]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Tutorial categories with descriptions
  const tutorialCategories = [
    {
      name: "Geographic Explorer",
      icon: <MapsHomeWorkOutlinedIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      description: "Learn how to analyze demographic patterns and trends in your school's geographic area."
    },
    {
      name: "Competitive Explorer",
      icon: <DonutSmallOutlinedIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      description: "Understand enrollment trends and competition from nearby schools in your market."
    },
    {
      name: "Projections Explorer", 
      icon: <AutoGraphOutlinedIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      description: "Master tools for forecasting future enrollment scenarios and strategic planning."
    },
    {
      name: "School Summary",
      icon: <AssessmentOutlinedIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      description: "Get guidance on analyzing school-level data and creating informative summaries."
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Resources
      </Typography>
      
      {/* Section descriptor */}
      <Box sx={{ my: 3 }}>
        <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
          Wherever you are on your journey, we're here to guide you—helping you explore with clarity, 
          uncover insights with ease, and make data-driven decisions with confidence.
        </Typography>
      </Box>
      
      {/* Tabs navigation */}
      <Paper sx={{ borderRadius: 1, boxShadow: 1, overflow: 'hidden' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          sx={{ 
            borderBottom: 1, 
            borderColor: 'divider',
            '& .MuiTab-root': {
              textTransform: 'uppercase',
              fontWeight: 'medium',
              fontSize: '0.875rem',
            }
          }}
        >
          <Tab 
            icon={<SchoolIcon />} 
            iconPosition="start" 
            label="Get Started" 
            id="resources-tab-0"
            aria-controls="resources-tabpanel-0"
            sx={{ py: 2 }}
          />
          <Tab 
            icon={<HelpOutlineIcon />} 
            iconPosition="start" 
            label="Get Help" 
            id="resources-tab-1"
            aria-controls="resources-tabpanel-1"
            sx={{ py: 2 }}
          />
          <Tab 
            icon={<DescriptionIcon />} 
            iconPosition="start" 
            label="Documentation" 
            id="resources-tab-2"
            aria-controls="resources-tabpanel-2"
            sx={{ py: 2 }}
          />
          <Tab 
            icon={<VideoLibraryIcon />} 
            iconPosition="start" 
            label="Video Tutorials" 
            id="resources-tab-3"
            aria-controls="resources-tabpanel-3"
            sx={{ py: 2 }}
          />
        </Tabs>
        
        {/* Tab Content */}
        <TabPanel value={tabValue} index={0}>
          <Card sx={{ boxShadow: 0, pb: 4 }}>
            <CardContent sx={{ pb: 3 }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={1}>
                  <Box display="flex" justifyContent="center">
                    <SchoolIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={11}>
                  <Typography variant="h6" component="h2" fontWeight="bold" gutterBottom>
                    Just getting started?
                  </Typography>
                  <Typography variant="body1">
                    Join a live, hands-on 1-hour tutorial to chart your course, learn the essentials, 
                    and gain expert tips for seamless navigation.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            
            <Box sx={{ mt: 3, pt: 3, borderTop: '1px solid #e0e0e0' }}>
              <iframe 
                src="https://scheduler.zoom.us/brian-eschbacher/school-explorer-tutorial-open?embed=true" 
                frameBorder="0" 
                style={{ width: '100%', height: '560px' }}
                title="Tutorial Scheduler"
              />
            </Box>
          </Card>
        </TabPanel>
        
        <TabPanel value={tabValue} index={1}>
          <Card sx={{ boxShadow: 0, pb: 4 }}>
            <CardContent sx={{ pb: 3 }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={1}>
                  <Box display="flex" justifyContent="center">
                    <SupportIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={11}>
                  <Typography variant="h6" component="h2" fontWeight="bold" gutterBottom>
                    Exploring but hit a roadblock?
                  </Typography>
                  <Typography variant="body1">
                    Join Office Hours Live with the EDC team—your trusted co-pilots—for real-time answers, 
                    troubleshooting, and hands-on support.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            
            <Box sx={{ mt: 3, pt: 3, borderTop: '1px solid #e0e0e0' }}>
              <iframe 
                src="https://scheduler.zoom.us/brian-eschbacher/school-explorer-office-hours-open?embed=true" 
                frameBorder="0" 
                style={{ width: '100%', height: '560px' }}
                title="Office Hours Scheduler"
              />
            </Box>
          </Card>
        </TabPanel>
        
        <TabPanel value={tabValue} index={2}>
          <Card sx={{ boxShadow: 0, pb: 4 }}>
            <CardContent sx={{ pb: 3 }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={1}>
                  <Box display="flex" justifyContent="center">
                    <DescriptionIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={11}>
                  <Typography variant="h6" component="h2" fontWeight="bold" gutterBottom>
                    Methodology Documentation
                  </Typography>
                  <Typography variant="body1">
                    Learn about our enrollment projection methodologies and calculations.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            
            <Box sx={{ mt: 3, pt: 3, borderTop: '1px solid #e0e0e0' }}>
              <Grid container spacing={3} sx={{ px: 3 }}>
                {/* Preview of methodology documentation topics */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Key Topics
                  </Typography>
                  <List>
                    {['Purpose', 'Methodology Summary', 'Process Summary', 'Projection Confidence Levels', 'Cohort Survival Rate'].map((topic, index) => (
                      <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemIcon>
                          <InfoIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={topic} />
                      </ListItem>
                    ))}
                  </List>
                  <Box sx={{ mt: 2 }}>
                    <Button 
                      variant="contained" 
                      component={Link}
                      to="/resources/methodology"
                      state={{ from: location.pathname }}
                      startIcon={<DescriptionIcon />}
                    >
                      View Full Documentation
                    </Button>
                  </Box>
                </Grid>
                
                {/* Quick view sample of documentation */}
                <Grid item xs={12} md={6}>
                  <Paper variant="outlined" sx={{ p: 2, bgcolor: 'grey.50', height: '100%' }}>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                      How we calculate projections:
                    </Typography>
                    <Typography variant="body2">
                      The tool uses historical data from NCES and the most recent school year to balance
                      longer-term history with recent trends. We create three scenarios: high, midpoint, and
                      low to allow you to see a range of potential outcomes.
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                      <WarningIcon fontSize="small" sx={{ mr: 1, color: 'warning.main' }} />
                      <Typography variant="body2" color="text.secondary" fontStyle="italic">
                        Projections should be treated as a starting point for internal enrollment planning discussions.
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </TabPanel>
        
        <TabPanel value={tabValue} index={3}>
          <Card sx={{ boxShadow: 0, pb: 4 }}>
            <CardContent sx={{ pb: 3 }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={1}>
                  <Box display="flex" justifyContent="center">
                    <VideoLibraryIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={11}>
                  <Typography variant="h6" component="h2" fontWeight="bold" gutterBottom>
                    Video Tutorials
                  </Typography>
                  <Typography variant="body1">
                    Watch step-by-step guides on using our enrollment planning tools.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            
            <Box sx={{ mt: 3, pt: 3, borderTop: '1px solid #e0e0e0' }}>
              <Box sx={{ px: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Tutorial Categories
                </Typography>
                
                <Grid container spacing={4} sx={{ mb: 4 }}>
                  {tutorialCategories.map((category, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Card 
                        variant="outlined" 
                        sx={{ 
                          height: '100%',
                          '&:hover': { 
                            boxShadow: 2,
                            transition: 'box-shadow 0.2s'
                          }
                        }}
                      >
                        <CardContent>
                          <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                            {category.icon}
                            <Box sx={{ ml: 2 }}>
                              <Typography variant="h6" component="h3" gutterBottom>
                                {category.name}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {category.description}
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                
                <Divider sx={{ mb: 4 }} />
                
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button 
                    variant="contained" 
                    component={Link}
                    to="/resources/tutorials"
                    state={{ from: location.pathname }}
                    startIcon={<VideoLibraryIcon />}
                    sx={{ px: 4, py: 1.5 }}
                    size="large"
                  >
                    VIEW ALL TUTORIALS
                  </Button>
                </Box>
              </Box>
            </Box>
          </Card>
        </TabPanel>
      </Paper>
    </Container>
  );
};

const Resources = () => {
  return (
    <Routes>
      <Route path="/" element={<ResourcesHome />} />
      <Route path="/methodology" element={<MethodologyDocs />} />
      <Route path="/tutorials" element={<VideoTutorials />} />
    </Routes>
  );
};

export default Resources;