import { SCHOOL_CATEGORIES } from './schoolCategorization';
import { TREND_COLORS } from '../constants/competitiveExplorer';

export const formatText = (text, defaultColor = 'inherit') => {
  // First split by possible note sections (marked by \n\n<span class="note">)
  const parts = text.split(/(\n\n<span class="note">.*?<\/span>)/);
  
  return parts.map((part, partIndex) => {
    // Check if this is a note section
    if (part.startsWith('\n\n<span class="note">')) {
      // Extract the note text (remove the span tags)
      const noteText = part.replace('\n\n<span class="note">', '').replace('</span>', '');
      return (
        <span 
          key={`note-${partIndex}`} 
          style={{
            display: 'block',
            marginTop: '16px',
            fontSize: '0.75rem',
            fontStyle: 'italic',
            color: 'rgba(0, 0, 0, 0.6)' // or whatever color you want for the note
          }}
        >
          {noteText}
        </span>
      );
    }

    // Handle regular text with bold sections
    return part.split('**').map((boldPart, index) => {
      if (index % 2 === 0) return boldPart;
      
      let color = defaultColor;
      const lowerPart = boldPart.toLowerCase();
      if (['growing', 'increased', 'increase', 'up', 'growth'].some(word => lowerPart.includes(word))) {
        color = TREND_COLORS.positive;
      } else if (['declining', 'decreased', 'decrease', 'down', 'declines'].some(word => lowerPart.includes(word))) {
        color = TREND_COLORS.negative;
      } else if (['steady', 'unchanged', 'remained'].some(word => lowerPart.includes(word))) {
        color = TREND_COLORS.neutral;
      } else if (['new', 'newer'].some(word => lowerPart.includes(word))) {
        color = TREND_COLORS.neutral;
      }
      
      return <strong key={`${partIndex}-${index}`} style={{ color }}>{boldPart}</strong>;
    });
  });
};

/**
 * Format a grade value to match the backend format
 * @param {string} grade - The grade to format (e.g., "1", "Kindergarten")
 * @returns {string} Formatted grade (e.g., "Grade 1", "Grade K")
 */
export const formatGrade = (grade) => {
  if (!grade) return '';
  
  // Handle special cases
  if (grade === 'Kindergarten' || grade === 'Grade K' || grade === 'K') return 'Kindergarten';
  if (grade === 'Pre-Kindergarten' || grade === 'Grade PK' || grade === 'PK') return 'Pre-Kindergarten';
  
  // Check if grade already has 'Grade' prefix
  if (grade.startsWith('Grade ')) return grade;
  
  // For numeric grades
  return `Grade ${grade}`;
};

export const sortGrades = (gradesInput) => {
  if (!gradesInput) return [];

  // If it's a string, split it into an array
  const grades = Array.isArray(gradesInput) ? gradesInput : gradesInput.split(', ');

  const gradeOrder = {
    'Pre-K': -2,
    'Pre-Kindergarten': -2,
    'K': -1,
    'Kindergarten': -1
  };

  return [...grades].sort((a, b) => {
    const orderA = gradeOrder[a] || parseInt(a);
    const orderB = gradeOrder[b] || parseInt(b);
    return orderA - orderB;
  });
};


/**
 * School utility functions for filtering, calculations, and formatting
 */

// Helper to convert grade range string to array of grades
export const parseGradeRange = (gradeRange) => {
  if (!gradeRange) return [];
    
  // Handle the "Grades 5-8" format
  const gradesMatch = gradeRange.match(/Grades? (\d+)-(\d+)/);
  if (gradesMatch) {
    const startNum = parseInt(gradesMatch[1]);
    const endNum = parseInt(gradesMatch[2]);
    
    const grades = [];
    for (let i = startNum; i <= endNum; i++) {
      grades.push(i.toString());
    }
    return grades;
  }

  // Handle "Grade X - Grade Y" format
  const gradeToGradeMatch = gradeRange.match(/Grade (\d+) - Grade (\d+)/);
  if (gradeToGradeMatch) {
    const startNum = parseInt(gradeToGradeMatch[1]);
    const endNum = parseInt(gradeToGradeMatch[2]);
    const grades = Array.from({length: endNum - startNum + 1}, (_, i) => (startNum + i).toString());
    return grades;
  }

  // Handle "Pre-Kindergarten - Grade 4" format
  const preKMatch = gradeRange.match(/(?:Pre-Kindergarten - )?Grade (\d+)/);
  if (preKMatch) {
    const endGrade = parseInt(preKMatch[1]);
    const grades = ['Kindergarten'];
    for (let i = 1; i <= endGrade; i++) {
      grades.push(i.toString());
    }
    
    // Add Pre-K if it exists in the original string
    if (gradeRange.includes('Pre-Kindergarten')) {
      grades.unshift('Pre-Kindergarten');
    }
    
    return grades;
  }

  // Handle "Pre-Kindergarten - Kindergarten" explicitly
  if (gradeRange === 'Pre-Kindergarten - Kindergarten') {
    return ['Pre-Kindergarten', 'Kindergarten'];
  }

  return [];
};

export const filterSchools = (schools, {
  selectedGrades,
  governanceFilter,
  schoolTypeFilter
}, enrollmentData) => {
  if (!schools) return [];

  return schools.filter(school => {
    // School type filter
    if (schoolTypeFilter !== 'All') {
      const schoolType = enrollmentData?.find(e => e.ncessch === school.ncessch)?.school_type || school.school_type;
      if (schoolType !== schoolTypeFilter) {
        return false;
      }
    }

    // Governance filter
    if (governanceFilter !== 'All') {
      const isCharter = school.charter === 'Yes';
      if ((governanceFilter === 'Charter' && !isCharter) || 
          (governanceFilter === 'District' && isCharter)) {
        return false;
      }
    }

    // Grade filter - use hasGradeOverlap instead of manual comparison
    if (selectedGrades && selectedGrades.length > 0) {
      return hasGradeOverlap(school.grade_range, selectedGrades.join(', '));
    }

    return true;
  });
};

export const getSchoolGrades = (gradeRange) => {
  const grades = parseGradeRange(gradeRange);
  // Filter out Pre-K from the returned grades
  return grades.filter(grade => grade !== 'Pre-Kindergarten');
};

export const getAvailableSchoolTypes = (enrollmentData) => {
  if (!enrollmentData?.length) {
    return ['All'];
  }

  const types = new Set(['All']);
  enrollmentData.forEach(school => {
    if (school.school_type) {
      types.add(school.school_type);
    }
  });

  return Array.from(types).sort();
};

export const calculateEnrollment = (enrollmentByGrade, selectedGrades) => {

  if (!enrollmentByGrade || !selectedGrades || selectedGrades.length === 0) {
    return 0;
  }

  let total = 0;
  selectedGrades.forEach(grade => {
    if (grade === 'Kindergarten') {
      const kValue = enrollmentByGrade['Kindergarten'] || 0;
      total += kValue;
    } else {
      const gradeKey = `Grade ${grade}`;
      const gradeValue = enrollmentByGrade[gradeKey] || 0;
      total += gradeValue;
    }
  });

  return total;
};

export const calculateMarketShares = (filteredSchools, schoolsEnrollmentData, selectedGrades) => {
  if (!filteredSchools?.length || !schoolsEnrollmentData?.length || !selectedGrades?.length) {
    return {};
  }

  // Deduplicate schools
  const uniqueSchools = deduplicateSchools(filteredSchools);

  const years = ['2017', '2022'];
  const marketShares = {};

  years.forEach(year => {
    const yearKey = year === '2017' ? 'five_years_ago' : 'current';
    
    const totalEnrollment = uniqueSchools.reduce((sum, school) => {
      const schoolData = schoolsEnrollmentData.find(s => s.ncessch === school.ncessch);
      if (!schoolData?.enrollment_by_grade?.[yearKey]) return sum;
      return sum + calculateEnrollment(schoolData.enrollment_by_grade[yearKey], selectedGrades);
    }, 0);

    uniqueSchools.forEach(school => {
      const schoolData = schoolsEnrollmentData.find(s => s.ncessch === school.ncessch);
      marketShares[school.ncessch] = marketShares[school.ncessch] || {};
      
      if (!schoolData?.enrollment_by_grade?.[yearKey]) {
        marketShares[school.ncessch][year] = 0;
        return;
      }

      const schoolEnrollment = calculateEnrollment(
        schoolData.enrollment_by_grade[yearKey], 
        selectedGrades
      );

      marketShares[school.ncessch][year] = totalEnrollment > 0 ? 
        schoolEnrollment / totalEnrollment : 0;
    });
  });

  // Calculate changes
  uniqueSchools.forEach(school => {
    if (marketShares[school.ncessch]) {
      marketShares[school.ncessch].change = 
        (marketShares[school.ncessch]['2022'] || 0) - 
        (marketShares[school.ncessch]['2017'] || 0);
    }
  });

  return marketShares;
};

// Formatting helpers
export const formatMarketShare = (value, decimals = 1) => {
  if (value === null || value === undefined) return '0';
  return (value * 100).toFixed(decimals);
};

// Age conversion
export const gradeToAge = (grade) => {
  if (grade === 'Kindergarten') return 5;
  return parseInt(grade) + 5;
};

export const getAgeRangeFromGrades = (grades) => {
  if (!grades || grades.length === 0) return { min: 4, max: 17 }; // Default age range

  const ages = grades.map(grade => gradeToAge(grade));
  return {
    min: Math.min(...ages),
    max: Math.max(...ages)
  };
};

// New function to filter age data by grade range
export const filterAgeData = (ageData, selectedGrades) => {
  if (!ageData || !selectedGrades || selectedGrades.length === 0) return ageData;

  const filteredData = {};
  Object.entries(ageData).forEach(([year, counts]) => {
    filteredData[year] = counts.filter((_, index) => {
      const age = index + 4; // Convert index to age
      return selectedGrades.some(grade => {
        // Special case for Kindergarten
        if (grade === 'Kindergarten') return age === 5;
        
        const targetAge = gradeNum => gradeNum + 5; // e.g., Grade 9 = age 14
        const gradeAge = targetAge(parseInt(grade.replace('Grade ', '')));
        return age === gradeAge;
      });
    });
  });
  return filteredData;
};

// Updated calculateTotalChildren to handle grade filtering
export const calculateTotalChildren = (yearData, selectedGrades) => {
  if (!yearData) return 0;
  return yearData.reduce((sum, count) => sum + count, 0);
};

// Utility for deduplicating schools array
export const deduplicateSchools = (schools) => {
  if (!schools?.length) return [];
  return Array.from(new Map(schools.map(school => [school.ncessch, school])).values());
};

/**
 * Calculate race percentage taking into account grade filtering and data structure
 * @param {Object} school - School data object
 * @param {string} field - Race category field name
 * @param {Array} selectedGrades - Array of selected grades to include
 * @returns {number} Percentage value (0-100)
 */
export const calculateRacePercentage = (school, field, selectedGrades = []) => {
  if (!school?.race_data?.current) {
    return 0;
  }

  // Helper to standardize grade format
  const standardizeGrade = (grade) => {
    if (grade === 'Pre-Kindergarten') return 'PK';
    if (grade === 'Kindergarten') return 'K';
    return grade.replace('Grade ', '');
  };

  // Helper to check if a grade should be included
  const shouldIncludeGrade = (grade) => {
    if (!selectedGrades || selectedGrades.length === 0) return true;
    if (grade === 'Pre-Kindergarten') return false; // Always exclude Pre-K
    return selectedGrades.includes(standardizeGrade(grade));
  };

  let raceCount = 0;
  let totalCount = 0;

  // Handle grade-level race data
  if (typeof school.race_data.current === 'object' && !Array.isArray(school.race_data.current)) {
    Object.entries(school.race_data.current).forEach(([grade, gradeData]) => {
      if (shouldIncludeGrade(grade)) {
        raceCount += gradeData[field] || 0;
        // Sum all race categories for total
        Object.values(gradeData).forEach(count => {
          totalCount += count || 0;
        });
      }
    });
  } 
  // Handle flat race data structure
  else {
    raceCount = school.race_data.current[field] || 0;
    totalCount = Object.values(school.race_data.current).reduce((sum, count) => sum + (count || 0), 0);
  }

  return totalCount > 0 ? (raceCount / totalCount) * 100 : 0;
};

// Race mapping
export const raceFieldMap = {
  'Asian': 'asian',
  'Black': 'black',
  'Hispanic': 'hispanic',
  'Multi': 'two_or_more_races',
  'Nat. Am.': 'american_indian',
  'Pac. Isl.': 'pacific_islander',
  'White': 'white'
};

// Race categories mapping
export const RACE_CATEGORIES = {
  american_indian: 'Native American',
  asian: 'Asian',
  black: 'Black',
  hispanic: 'Hispanic',
  pacific_islander: 'Pacific Islander',
  white: 'White',
  two_or_more_races: 'Multiracial'
};

// Calculate the total enrollment
export const calculateTotalEnrollment = (schools, schoolsEnrollmentData, selectedGrades) => {
  const enrollmentData = schools.map(school => 
    schoolsEnrollmentData.find(d => d.ncessch === school.ncessch)
  ).filter(Boolean);

  const oldEnrollment = enrollmentData.reduce((sum, school) => 
    sum + calculateEnrollment(school.enrollment_by_grade?.five_years_ago, selectedGrades), 0);
  const newEnrollment = enrollmentData.reduce((sum, school) => 
    sum + calculateEnrollment(school.enrollment_by_grade?.current, selectedGrades), 0);
  
  return {
    oldEnrollment,
    newEnrollment,
    percentChange: oldEnrollment > 0 ? ((newEnrollment - oldEnrollment) / oldEnrollment) * 100 : 0
  };
};

//  Calculate the race compitions
export const calculateDemographicComposition = (school) => {
  if (!school) return [];
  
  const results = Object.entries(RACE_CATEGORIES).map(([key, label]) => {
    // Use calculateRacePercentage for consistency
    const schoolPct = calculateRacePercentage(school, key);
    const schoolCurrent = school.race_data?.[key] || 0;
    const schoolOld = school.race_data_five_years_ago?.[key] || 0;
    const schoolChange = schoolOld > 0 ? ((schoolCurrent - schoolOld) / schoolOld * 100) : 0;

    return {
      category: label,
      value: schoolPct,
      count: schoolCurrent,
      oldCount: schoolOld,
      change: Math.round(schoolChange * 10) / 10
    };
  });

  return results.filter(d => d.value >= 5); // 5% threshold
};

// Color constants for visualization
export const CHART_COLORS = {
  GROWING: 'rgb(34, 197, 94)',  // green
  DECLINING: 'rgb(239, 68, 68)', // red
  NEUTRAL: 'rgb(156, 163, 175)', // grey
  PRIMARY: 'rgb(25, 118, 210)'   // blue
};

export const CHART_OPACITY = 0.8;
export const SIGNIFICANT_CHANGE_THRESHOLD = 5;

/**
 * Get color for trend based on percentage change
 * @param {number} percentChange - The percentage change
 * @returns {string} RGB color string
 */
export const getTrendColor = (percentChange) => {
  if (Math.abs(percentChange) < SIGNIFICANT_CHANGE_THRESHOLD) {
    return CHART_COLORS.NEUTRAL;
  }
  return percentChange > 0 ? CHART_COLORS.GROWING : CHART_COLORS.DECLINING;
};

/**
 * Convert RGB color to RGBA with opacity
 * @param {string} color - RGB color string
 * @param {number} opacity - Opacity value between 0 and 1
 * @returns {string} RGBA color string
 */
export const getColorWithOpacity = (color, opacity = CHART_OPACITY) => {
  return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
};

// Helper to get background color for trend summaries
export const getTrendBackgroundColor = (color, opacity = 0.1) => {
  return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
};

const capitalizeWord = (word) => {
  // List of known acronyms and words that should remain uppercase or have specific capitalization
  const specialCases = {
    'KIPP': 'KIPP',
    'IDEA': 'IDEA',
    'DSST:': 'DSST:',
    'STRIVE': 'STRIVE',
    'ISD': 'ISD',
    'STEM': 'STEM',
    'PK': 'PK',
    'PRE-K': 'Pre-K',
    'K-8': 'K-8',
    'K-12': 'K-12',
    '3D': '3D',
  };
  
  if (specialCases[word.toUpperCase()]) {
    return specialCases[word.toUpperCase()];
  }
  
  // List of lowercase words (articles, conjunctions, prepositions)
  const lowercaseWords = ['a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'on', 'at', 'to', 'from', 'by', 'of'];
  
  if (lowercaseWords.includes(word.toLowerCase())) {
    return word.toLowerCase();
  }
  
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

const formatSchoolName = (name) => {
  return name
    .split(' ')
    .map((word, index) => {
      // Always capitalize the first and last word, regardless of what they are
      if (index === 0 || index === name.split(' ').length - 1) {
        return capitalizeWord(word);
      }
      return capitalizeWord(word);
    })
    .join(' ');
};

export { formatSchoolName, capitalizeWord };

/**
 * Format a list of grades ensuring proper sorting and consistent display
 * @param {string|array} grades - Comma-separated string or array of grades
 * @param {boolean} useRangeFormat - Whether to use range format (e.g., "Grades 6-12") for consecutive grades
 * @returns {string} Formatted grade list
 */
export const formatGradeList = (grades, useRangeFormat = false) => {
  if (!grades) return '';
  
  // Convert string input to array if needed
  const gradeArray = Array.isArray(grades) ? [...grades] : grades.split(', ');
  
  // Sort function for grades
  const sortGrades = (a, b) => {
    if (a === 'Pre-Kindergarten') return -1;
    if (b === 'Pre-Kindergarten') return 1;
    if (a === 'Kindergarten' || a === 'K') return -1;
    if (b === 'Kindergarten' || b === 'K') return 1;
    
    const numA = parseInt(a.replace('Grade ', ''));
    const numB = parseInt(b.replace('Grade ', ''));
    return numA - numB;
  };

  // Sort the grades
  gradeArray.sort(sortGrades);

  // Standardize format (convert "Grade X" to just "X" and "Kindergarten" to "K")
  const standardizedGrades = gradeArray.map(grade => {
    if (grade === 'Kindergarten') return 'K';
    if (grade === 'Pre-Kindergarten') return 'Pre-K';
    return grade.replace('Grade ', '');
  });

  if (useRangeFormat) {
    // Find consecutive sequences
    const numericGrades = standardizedGrades
      .filter(g => !['Pre-K', 'K'].includes(g))
      .map(g => parseInt(g));
    
    if (numericGrades.length > 2) {
      const min = Math.min(...numericGrades);
      const max = Math.max(...numericGrades);
      const isConsecutive = max - min === numericGrades.length - 1;
      
      if (isConsecutive) {
        const prefix = standardizedGrades.filter(g => ['Pre-K', 'K'].includes(g));
        if (prefix.length) {
          return `${prefix.join(', ')}, Grades ${min}-${max}`;
        }
        return `Grades ${min}-${max}`;
      }
    }
  }

  return standardizedGrades.join(', ');
};

/**
 * Parse grade data from enrollment data into a formatted string
 * @param {Object} enrollmentData - Enrollment data object
 * @param {string} timepoint - Which timepoint to use ('current' or 'comparison')
 * @returns {string} Formatted grade string
 */
export const getGradesFromEnrollment = (enrollmentData, timepoint = 'current') => {
  if (!enrollmentData?.enrollment_by_grade?.[timepoint]) return 'N/A';
  
  const grades = Object.entries(enrollmentData.enrollment_by_grade[timepoint])
    .filter(([_, enrollment]) => enrollment > 0)
    .map(([grade]) => grade);
  
  return formatGradeList(grades, true); // Use range format when appropriate
};

export const getSchoolTimeframe = (schoolData) => {
  if (!schoolData?.enrollment_by_grade) {
    return null;
  }

  // Get current (2023-24) grades with enrollment
  const currentGrades = Object.entries(schoolData.enrollment_by_grade.current || {})
    .filter(([_, value]) => value > 0)
    .map(([grade]) => grade)
    .sort();

  // Check if school has 2019 data
  const hasComparisonData = schoolData.enrollment_by_grade.comparison && 
    Object.values(schoolData.enrollment_by_grade.comparison)
      .some(value => value > 0);

  // Format grades for display
  const formatInternalGrades = (grades) => {
    return formatGradeList(
      grades
        .map(grade => grade === 'Kindergarten' ? 'K' : grade.replace('Grade ', ''))
        .join(', ')
    );
  };

  let startYear;
  let startGrades;
  let endGrades = currentGrades;

  if (hasComparisonData) {
    startYear = 2019;
    startGrades = Object.entries(schoolData.enrollment_by_grade.comparison)
      .filter(([_, value]) => value > 0)
      .map(([grade]) => grade)
      .sort();
  } else {
    const yearKeys = Object.keys(schoolData.enrollment_by_grade)
      .filter(key => key !== 'current' && key !== 'comparison' && !isNaN(parseInt(key)))
      .sort();

    if (yearKeys.length > 0) {
      startYear = parseInt(yearKeys[0]);
      startGrades = Object.entries(schoolData.enrollment_by_grade[yearKeys[0]])
        .filter(([_, value]) => value > 0)
        .map(([grade]) => grade)
        .sort();
    } else {
      startYear = 2023;
      startGrades = currentGrades;
    }
  }

  const hasExpandedGrades = startGrades.length < endGrades.length;

  return {
    startYear,
    endYear: 2024,
    startLabel: `${startYear}-${(startYear + 1).toString().slice(-2)}`,
    endLabel: '2023-24',
    yearCount: 2024 - startYear,
    hasComparison: hasComparisonData || startYear < 2023,
    startGrades: formatInternalGrades(startGrades),
    endGrades: formatInternalGrades(endGrades),
    hasExpandedGrades,
    gradeChange: {
      start: startGrades.length,
      end: endGrades.length,
      difference: endGrades.length - startGrades.length
    }
  };
};

// Helper for getting comparison data
export const getComparisonData = (schoolData, type = 'enrollment') => {
  if (!schoolData?.enrollment_by_grade) return null;

  const comparison = schoolData.enrollment_by_grade.comparison || {};
  const hasComparisonData = Object.values(comparison).some(value => value > 0);

  // If has valid comparison data, use it
  if (hasComparisonData) {
    return comparison;
  }

  // Otherwise return empty/null to indicate no historical data
  return null;
};

// Helper to format date range text
export const getDateRangeText = (timeframe, schoolName) => {
  if (timeframe?.hasComparison) {
    return "over the past five years";
  }
  return `since ${timeframe?.startLabel || '2023'}`;
};

// Helper to format academic year
export const formatAcademicYear = (year) => {
  const startYear = parseInt(year);
  const endYear = startYear + 1;
  return `${startYear}-${endYear.toString().slice(-2)}`;
};

// Add these new utility functions to schoolUtils.js

/**
 * Get the earliest year with enrollment data for a school
 * @param {Object} schoolData - The school's enrollment data
 * @returns {Object} The earliest year's enrollment data or null
 */
export const getEarliestEnrollmentData = (schoolData) => {
  if (!schoolData?.enrollment_by_grade) return null;

  // Check for comparison (2019) data first
  const comparison = schoolData.enrollment_by_grade.comparison || {};
  if (Object.values(comparison).some(value => value > 0)) {
    return comparison;
  }

  // Get all years excluding 'current' and 'comparison'
  const yearKeys = Object.keys(schoolData.enrollment_by_grade)
    .filter(key => key !== 'current' && key !== 'comparison' && !isNaN(parseInt(key)))
    .sort();

  return yearKeys.length > 0 ? schoolData.enrollment_by_grade[yearKeys[0]] : null;
};

/**
 * Calculate enrollment statistics for a school or group of schools
 * @param {Object|Array} schools - Single school data object or array of school data objects
 * @param {Array} selectedGrades - Array of selected grade levels
 * @returns {Object} Enrollment statistics
 */
export const calculateEnrollmentStats = (schools, selectedGrades) => {
  const schoolArray = Array.isArray(schools) ? schools : [schools];
  
  // If school is NEW, only return current enrollment data
  if (schoolArray[0]?.category === SCHOOL_CATEGORIES.NEW) {
    const currentData = schoolArray[0]?.enrollment_by_grade?.current || {};
    return {
      oldEnrollment: 0,
      newEnrollment: calculateEnrollment(currentData, selectedGrades),
      schoolCount: 1,
      percentChange: 0,
      hasValidComparison: false
    };
  }
  
  const stats = schoolArray.reduce((acc, school) => {
    const comparisonData = getEarliestEnrollmentData(school);
    const currentData = school?.enrollment_by_grade?.current || {};

    const oldEnrollment = calculateEnrollment(comparisonData || {}, selectedGrades);
    const newEnrollment = calculateEnrollment(currentData, selectedGrades);

    return {
      oldEnrollment: acc.oldEnrollment + (Number.isFinite(oldEnrollment) ? oldEnrollment : 0),
      newEnrollment: acc.newEnrollment + (Number.isFinite(newEnrollment) ? newEnrollment : 0),
      schoolCount: acc.schoolCount + 1
    };
  }, { oldEnrollment: 0, newEnrollment: 0, schoolCount: 0 });

  const validComparison = stats.oldEnrollment > 0;

  return {
    ...stats,
    percentChange: validComparison ? ((stats.newEnrollment - stats.oldEnrollment) / stats.oldEnrollment) * 100 : 0,
    hasValidComparison: validComparison
  };
};

/**
 * Calculate comprehensive enrollment trends for a school and its comparison groups
 * @param {Object} params - Parameters object
 * @returns {Object} Enrollment trends data
 */
export const calculateEnrollmentTrends = ({
  selectedSchool,
  selectedSchoolData,
  filteredSchools,
  schoolsEnrollmentData,
  selectedGrades,
  governanceFilter
}) => {
  if (!selectedSchool || !selectedSchoolData || !filteredSchools?.length || !schoolsEnrollmentData?.length || !selectedGrades?.length) {
    return null;
  }

  const timeframe = getSchoolTimeframe(selectedSchoolData);
  
  // Get filtered comparison schools
  const otherSchools = filteredSchools
    .filter(school => school.ncessch !== selectedSchool.ncessch)
    .map(school => schoolsEnrollmentData.find(d => d.ncessch === school.ncessch))
    .filter(Boolean);

  const charterSchools = otherSchools.filter(s => s?.charter === "Yes");
  const districtSchools = otherSchools.filter(s => s?.charter !== "Yes");

  // Calculate stats for each group
  const selectedStats = calculateEnrollmentStats(selectedSchoolData, selectedGrades);
  const totalStats = calculateEnrollmentStats(otherSchools, selectedGrades);
  const charterStats = calculateEnrollmentStats(charterSchools, selectedGrades);
  const districtStats = calculateEnrollmentStats(districtSchools, selectedGrades);

  const getDemographicData = (schoolData, selectedGrades) => {
    if (!schoolData?.race_data) return null;
  
    // Calculate school demographics for current timepoint
    const currentDemographics = {};
    let currentTotal = 0;
  
    // Process current demographics by grade
    selectedGrades.forEach(grade => {
      const gradeKey = formatGrade(grade);
      const gradeData = schoolData.race_data?.current?.[gradeKey] || {};
      
      Object.entries(gradeData).forEach(([race, count]) => {
        currentDemographics[race] = (currentDemographics[race] || 0) + (count || 0);
        currentTotal += (count || 0);
      });
    });
  
    // Calculate percentages and find largest group
    const currentPercentages = {};
    let largestGroup = null;
    let largestPercentage = 0;
  
    Object.entries(currentDemographics).forEach(([race, count]) => {
      const percentage = (count / currentTotal) * 100;
      currentPercentages[race] = percentage;
      
      if (percentage > largestPercentage) {
        largestGroup = race;
        largestPercentage = percentage;
      }
    });
  
    // Calculate area demographics
    const areaDemographics = {};
    let areaTotal = 0;
  
    // Process area demographics for other schools
    otherSchools.forEach(school => {
      selectedGrades.forEach(grade => {
        const gradeKey = formatGrade(grade);
        const gradeData = school.race_data?.current?.[gradeKey] || {};
        
        Object.entries(gradeData).forEach(([race, count]) => {
          areaDemographics[race] = (areaDemographics[race] || 0) + (count || 0);
          areaTotal += (count || 0);
        });
      });
    });
  
    // Calculate area percentages
    const areaPercentages = {};
    Object.entries(areaDemographics).forEach(([race, count]) => {
      areaPercentages[race] = (count / areaTotal) * 100;
    });
  
    // Calculate change in largest group
    const largestGroupPastCount = selectedGrades.reduce((sum, grade) => {
      const gradeKey = formatGrade(grade);
      return sum + (schoolData.race_data?.comparison?.[gradeKey]?.[largestGroup] || 0);
    }, 0);
  
    const largestGroupCurrentCount = currentDemographics[largestGroup] || 0;
    const largestGroupChange = largestGroupPastCount > 0 ?
      ((largestGroupCurrentCount - largestGroupPastCount) / largestGroupPastCount) * 100 : 0;
  
    return {
      largestGroup: RACE_CATEGORIES[largestGroup],
      demographicDifference: currentPercentages[largestGroup] - (areaPercentages[largestGroup] || 0),
      largestGroupChange
    };
  };

  return {
    selectedSchool: {
      ...selectedStats,
      timeframe
    },
    total: totalStats,
    category: selectedSchoolData.category,
    charter: charterStats,
    district: districtStats,
    isNotable: Math.abs((selectedStats.percentChange || 0) - (totalStats.percentChange || 0)) >= 5,
    difference: (selectedStats.percentChange || 0) - (totalStats.percentChange || 0),
    ...getDemographicData(selectedSchoolData, selectedGrades)
  };
};

/**
 * Get appropriate comparison text based on difference
 * @param {number} difference - The difference in percentage points
 * @param {boolean} isNotable - Whether the difference is notable
 * @returns {string} Comparison text
 */
export const getComparisonText = (difference, isNotable) => {
  if (Math.abs(difference) < 1) return 'similar to';
  if (!isNotable) return difference > 0 ? 'higher than' : 'lower than';
  return difference > 0 ? 'notably higher than' : 'notably lower than';
};

/**
 * Get background color for trend container based on change percentage
 * @param {number} change - The percentage change
 * @returns {string} Hex color code for background
 */
export const getTrendBackgroundByChange = (change) => {
  if (!change) return '#f5f5f5';
  if (change >= 5) return '#e8f5e9';
  if (change <= -5) return '#ffebee';
  return '#f5f5f5';
};

/**
 * Add opacity to a base color for trend backgrounds
 * @param {string} color - Base color
 * @param {number} opacity - Opacity value between 0 and 1
 * @returns {string} RGBA color string
 */
export const getTrendBackgroundWithOpacity = (color, opacity = 0.1) => {
  return color.replace('rgb', 'rgba').replace(')', `, ${opacity})`);
};

/**
 * Calculate population-based market share
 * @param {Object} params
 * @param {Object} params.school - School object
 * @param {Object} params.enrollmentData - School's enrollment data
 * @param {Array} params.selectedGrades - Selected grades
 * @param {Object} params.esriData - ESRI demographic data
 * @param {number} params.selectedDriveTime - Selected drive time
 * @param {string} params.timepoint - 'current' or 'comparison'
 * @returns {number} Market share percentage (0-100)
 */
export const calculatePopulationMarketShare = ({
  school,
  enrollmentData,
  selectedGrades,
  esriData,
  selectedDriveTime,
  timepoint = 'current'
}) => {
  if (!school || !enrollmentData || !esriData || !selectedGrades?.length) {
    return 0;
  }

  // Get school enrollment for the timepoint
  const enrollment = calculateEnrollment(
    enrollmentData?.enrollment_by_grade?.[timepoint] || {},
    selectedGrades
  );

  // Get total population for the same ages as the selected grades
  const communityData = esriData?.drive_times?.[selectedDriveTime];
  if (!communityData?.ages['4_17']) return 0;

  // Filter age data based on selected grades
  const filteredAgeData = filterAgeData(communityData.ages['4_17'], selectedGrades);
  
  // Get the appropriate year's population data
  const yearData = timepoint === 'current' ? 
    filteredAgeData.current : 
    filteredAgeData['2020'];  // Use 2020 data for comparison timepoint

  const totalPopulation = calculateTotalChildren(yearData);

  return totalPopulation > 0 ? (enrollment / totalPopulation) * 100 : 0;
};

/**
 * Calculate enrollment-based market share
 * @param {Object} params
 * @param {Object} params.school - School object
 * @param {Object} params.enrollmentData - School's enrollment data
 * @param {Array} params.selectedGrades - Selected grades
 * @param {Object} params.nearbySchools - Nearby schools data
 * @param {Object} params.schoolsEnrollmentData - All schools' enrollment data
 * @param {string} params.timepoint - 'current' or 'comparison'
 * @param {number} params.selectedDriveTime - Selected drive time
 * @returns {number} Market share percentage (0-100)
 */
export const calculateEnrollmentMarketShare = ({
  school,
  enrollmentData,
  selectedGrades,
  nearbySchools,
  schoolsEnrollmentData,
  timepoint = 'current',
  governanceFilter = 'All'
}) => {
  if (!school || !enrollmentData || !nearbySchools || !selectedGrades?.length) {
    return 0;
  }

  // Get school's enrollment for the timepoint
  const schoolEnrollment = calculateEnrollment(
    enrollmentData?.enrollment_by_grade?.[timepoint] || {},
    selectedGrades
  );

  // Get the appropriate set of nearby schools based on timepoint
  const nearbySchoolsList = timepoint === 'current' ?
    nearbySchools?.current?.['10'] || [] :
    nearbySchools?.comparison?.['10'] || [];

  // Filter nearby schools by governance type
  const filteredNearbySchools = nearbySchoolsList.filter(nearbySchool => {
    if (governanceFilter === 'All') return true;
    const isCharter = nearbySchool.charter === 'Yes';
    return (governanceFilter === 'Charter' && isCharter) || 
           (governanceFilter === 'District' && !isCharter);
  });

  // Calculate total enrollment of filtered nearby schools
  const totalNearbyEnrollment = filteredNearbySchools.reduce((total, nearbySchool) => {
    const nearbyEnrollmentData = schoolsEnrollmentData.find(
      d => d.ncessch === nearbySchool.ncessch
    );
    
    if (!nearbyEnrollmentData) return total;

    return total + calculateEnrollment(
      nearbyEnrollmentData?.enrollment_by_grade?.[timepoint] || {},
      selectedGrades
    );
  }, 0);

  // Only include the selected school's enrollment if it matches the governance filter
  const shouldIncludeSelectedSchool = governanceFilter === 'All' ||
    (governanceFilter === 'Charter' && school.charter === 'Yes') ||
    (governanceFilter === 'District' && school.charter !== 'Yes');

  const totalEnrollment = shouldIncludeSelectedSchool ? 
    totalNearbyEnrollment + schoolEnrollment :
    totalNearbyEnrollment;

  const relevantSchoolEnrollment = shouldIncludeSelectedSchool ? schoolEnrollment : 0;

  return totalEnrollment > 0 ? (relevantSchoolEnrollment / totalEnrollment) * 100 : 0;
};

/**
 * Calculate comprehensive market share data for a school
 * @param {Object} params - All parameters needed for both market share calculations
 * @returns {Object} Market share statistics including both types and changes
 */
export const calculateMarketShareStats = (params) => {
  const { enrollmentData } = params;
  
  // Use school category to determine if it's a new school
  const isNewSchool = enrollmentData?.category === SCHOOL_CATEGORIES.NEW;

  // Calculate current market shares
  const currentPopulationShare = calculatePopulationMarketShare({
    ...params,
    timepoint: 'current'
  });

  const currentEnrollmentShare = calculateEnrollmentMarketShare({
    ...params,
    timepoint: 'current'
  });

  // For new schools, explicitly set past shares and changes to 0
  if (isNewSchool) {
    return {
      population: {
        current: currentPopulationShare,
        past: 0,
        change: 0
      },
      enrollment: {
        current: currentEnrollmentShare,
        past: 0,
        change: 0
      },
      hasValidHistory: false,
      isNewSchool: true
    };
  }

  // For established schools, calculate normally
  const pastPopulationShare = calculatePopulationMarketShare({
    ...params,
    timepoint: 'comparison'
  });

  const pastEnrollmentShare = calculateEnrollmentMarketShare({
    ...params,
    timepoint: 'comparison'
  });

  const populationShareChange = currentPopulationShare - pastPopulationShare;
  const enrollmentShareChange = currentEnrollmentShare - pastEnrollmentShare;

  return {
    population: {
      current: currentPopulationShare,
      past: pastPopulationShare,
      change: populationShareChange
    },
    enrollment: {
      current: currentEnrollmentShare,
      past: pastEnrollmentShare,
      change: enrollmentShareChange
    },
    hasValidHistory: pastPopulationShare > 0 && pastEnrollmentShare > 0,
    isNewSchool: false
  };
};

export const calculateTotalEnrollmentChange = (currentSchools, comparisonSchools, enrollmentData, selectedGrades) => {
  const getCurrentEnrollment = (schools) => schools.reduce((sum, school) => {
    const data = enrollmentData.find(d => d.ncessch === school.ncessch);
    return sum + calculateEnrollment(data?.enrollment_by_grade?.current || {}, selectedGrades);
  }, 0);

  const getComparisonEnrollment = (schools) => schools.reduce((sum, school) => {
    const data = enrollmentData.find(d => d.ncessch === school.ncessch);
    return sum + calculateEnrollment(data?.enrollment_by_grade?.comparison || {}, selectedGrades);
  }, 0);

  const currentEnrollment = getCurrentEnrollment(currentSchools);
  const comparisonEnrollment = getComparisonEnrollment(comparisonSchools);

  return comparisonEnrollment > 0 ? 
    ((currentEnrollment - comparisonEnrollment) / comparisonEnrollment) * 100 : 
    0;
};

export const calculateMarketShareChange = (currentSchools, comparisonSchools, enrollmentData, selectedGrades) => {
  const currentShare = calculateGroupMarketShare(currentSchools, enrollmentData, selectedGrades, 'current');
  const comparisonShare = calculateGroupMarketShare(comparisonSchools, enrollmentData, selectedGrades, 'comparison');
  
  return currentShare - comparisonShare;
};

const calculateGroupMarketShare = (schools, enrollmentData, selectedGrades, timepoint) => {
  const groupEnrollment = schools.reduce((sum, school) => {
    const data = enrollmentData.find(d => d.ncessch === school.ncessch);
    return sum + calculateEnrollment(data?.enrollment_by_grade?.[timepoint] || {}, selectedGrades);
  }, 0);

  const totalEnrollment = enrollmentData.reduce((sum, data) => 
    sum + calculateEnrollment(data?.enrollment_by_grade?.[timepoint] || {}, selectedGrades), 0);

  return totalEnrollment > 0 ? (groupEnrollment / totalEnrollment) * 100 : 0;
};

/**
 * Calculate FRL percentage using total school enrollment including PreK
 * @param {Object} school - School data object containing enrollment and FRL data
 * @returns {Object} Object containing percentage and DMS flag status
 */
export const calculateFRLPercentage = (school) => {
  // Early return if no FRL data
  if (!school?.frl_data?.current) {
    return { percentage: null, dmsFlag: false, status: 'No Data' };
  }

  const frlCount = school.frl_data.current.count;
  
  // Get total enrollment including PreK if it exists
  const getTotalEnrollment = (enrollmentData) => {
    if (!enrollmentData) return 0;
    
    // Handle both enrollment_by_grade and enrollmentByGrade property names
    const gradeData = enrollmentData.current || {};
    return Object.values(gradeData).reduce((sum, count) => 
      sum + (count || 0), 0);
  };

  // Try both property names for enrollment data
  const totalEnrollment = getTotalEnrollment(school.enrollment_by_grade) || 
                         getTotalEnrollment(school.enrollmentByGrade) || 
                         school.currentEnrollment || 0;

  // Calculate percentage
  const percentage = totalEnrollment > 0 && frlCount !== undefined ? 
    Math.round((frlCount / totalEnrollment) * 100) : 
    null;

  return {
    percentage,
    dmsFlag: school.frl_data.current.dms_flag === "Reported" || 
             school.frl_data.current.dms_flag === true || 
             false,
    status: percentage === null ? 'No Data' : 'Has Data'
  };
};

/**
 * Check if two grade ranges or lists have any overlap
 * @param {string|string[]} grades1 - First grade range or list
 * @param {string|string[]} grades2 - Second grade range or list
 * @returns {boolean} True if there is overlap
 */
export const hasGradeOverlap = (grades1, grades2) => {
  if (!grades1 || !grades2) return false;
  
  try {
    // Convert inputs to arrays if they're not already
    const array1 = Array.isArray(grades1) 
      ? grades1 
      : grades1.split(',').map(g => g.trim());
    const array2 = Array.isArray(grades2) 
      ? grades2 
      : grades2.split(',').map(g => g.trim());

    // If either input looks like a grade range, parse it
    const grades1Array = array1.length === 1 && (
      array1[0].includes('-') || 
      array1[0].includes('Kindergarten') || 
      array1[0].includes('Pre-Kindergarten')
    ) ? parseGradeRangeToArray(array1[0]) : array1;
    
    const grades2Array = array2.length === 1 && (
      array2[0].includes('-') || 
      array2[0].includes('Kindergarten') || 
      array2[0].includes('Pre-Kindergarten')
    ) ? parseGradeRangeToArray(array2[0]) : array2;

    // Check for overlap
    const hasOverlap = grades1Array.some(g1 => 
      grades2Array.some(g2 => {
        const match = g1.toString().toLowerCase() === g2.toString().toLowerCase();
        if (match) {
        }
        return match;
      })
    );

    return hasOverlap;

  } catch (error) {
    console.error('Error in hasGradeOverlap:', error);
    console.error('Inputs:', { grades1, grades2 });
    return false;
  }
};

export const filterNearbySchools = ({
  rawNearbySchools,
  selectedGrades,
  governanceFilter,
  schoolTypeFilter
}) => {
  if (!rawNearbySchools?.current) return {};
  
  const filterSchoolsByGrades = (schools, timepoint = 'current') => {
    return schools.filter(school => {
      const schoolGrades = rawNearbySchools.gradeData[school.ncessch]?.[timepoint];
      return hasGradeOverlap(schoolGrades, selectedGrades);
    });
  };

  return {
    current: {
      5: filterSchoolsByGrades(rawNearbySchools.current[5], 'current'),
      10: filterSchoolsByGrades(rawNearbySchools.current[10], 'current'),
      15: filterSchoolsByGrades(rawNearbySchools.current[15], 'current')
    },
    comparison: {
      5: filterSchoolsByGrades(rawNearbySchools.comparison[5], 'comparison'),
      10: filterSchoolsByGrades(rawNearbySchools.comparison[10], 'comparison'),
      15: filterSchoolsByGrades(rawNearbySchools.comparison[15], 'comparison')
    }
  };
};

/**
 * Safely get grade range from school data
 * @param {Object} school - School object
 * @param {Object} schoolsEnrollmentData - Enrollment data for all schools
 * @returns {string} Formatted grade range
 */
export const getSchoolGradeRange = (school, schoolsEnrollmentData) => {
  // First try grade_range from the school object
  if (typeof school?.grade_range === 'string') {
    return school.grade_range;
  }

  // Next try to get from enrollment data
  const enrollmentData = schoolsEnrollmentData?.find(d => d.ncessch === school.ncessch);
  if (enrollmentData?.enrollment_by_grade?.current) {
    const grades = Object.entries(enrollmentData.enrollment_by_grade.current)
      .filter(([_, count]) => count > 0)
      .map(([grade]) => grade)
      .sort();
    
    if (grades.length > 0) {
      const first = grades[0];
      const last = grades[grades.length - 1];
      return `${first} - ${last}`;
    }
  }

  // If all else fails, try grades property
  if (Array.isArray(school.grades)) {
    return school.grades.join(' - ');
  }

  console.warn('No valid grade range found for school:', school.ncessch);
  return '';
};

/**
 * Enhanced filter for nearby schools with grade overlap
 */
export const filterNearbySchoolsByGrades = (schools, selectedGrades, schoolsEnrollmentData) => {
  if (!schools || !selectedGrades || !Array.isArray(selectedGrades)) {
    console.warn('Invalid input to filterNearbySchoolsByGrades:', { 
      schoolsCount: schools?.length,
      selectedGrades,
      hasEnrollmentData: !!schoolsEnrollmentData
    });
    return [];
  }

  return schools.filter(school => {
    const gradeRange = getSchoolGradeRange(school, schoolsEnrollmentData);

    return hasGradeOverlap(gradeRange, selectedGrades.join(', '));
  });
};

export const debugFilteredSchools = (schools, selectedGrades) => {
  return schools.map(school => ({
    ncessch: school.ncessch,
    name: school.name,
    gradeRange: school.grade_range,
    selectedGrades,
    hasOverlap: hasGradeOverlap(school.grade_range, selectedGrades.join(', '))
  }));
};

/**
 * Process nearby schools data with enhanced error handling
 */
export const processNearbySchools = (nearbySchools, selectedSchool, selectedGrades, schoolsEnrollmentData) => {
  if (!nearbySchools?.current || !selectedSchool) {
    console.warn('Missing required data for processing nearby schools');
    return [];
  }

  try {
    // Get schools for the first available drive time
    const driveTime = Object.keys(nearbySchools.current)[0];
    const schoolsList = [
      selectedSchool,
      ...(nearbySchools.current[driveTime] || [])
    ];

    return filterNearbySchoolsByGrades(schoolsList, selectedGrades, schoolsEnrollmentData);
  } catch (error) {
    console.error('Error processing nearby schools:', error);
    return [];
  }
};

/**
 * Parse a grade range string into an array of standardized grades
 * @param {string} gradeRange - Grade range string (e.g., "Pre-Kindergarten - Grade 6")
 * @returns {string[]} Array of standardized grades
 */
const parseGradeRangeToArray = (gradeRange) => {
  if (!gradeRange) return [];
  

  // Handle "Pre-Kindergarten - Kindergarten" format
  if (gradeRange === 'Pre-Kindergarten - Kindergarten') {
    const grades = ['Pre-Kindergarten', 'Kindergarten'];
    return grades;
  }

  // Handle "Kindergarten - Grade X" format
  const kMatch = gradeRange.match(/Kindergarten - Grade (\d+)/);
  if (kMatch) {
    const endGrade = parseInt(kMatch[1]);
    const grades = ['Kindergarten'];
    for (let i = 1; i <= endGrade; i++) {
      grades.push(i.toString());
    }
    return grades;
  }

  // Handle "Pre-Kindergarten - Grade X" format
  const pkMatch = gradeRange.match(/Pre-Kindergarten - Grade (\d+)/);
  if (pkMatch) {
    const endGrade = parseInt(pkMatch[1]);
    const grades = ['Pre-Kindergarten', 'Kindergarten'];
    for (let i = 1; i <= endGrade; i++) {
      grades.push(i.toString());
    }
    return grades;
  }

  // Handle "Grades X-Y" format
  const gradesMatch = gradeRange.match(/Grades (\d+)-(\d+)/);
  if (gradesMatch) {
    const startGrade = parseInt(gradesMatch[1]);
    const endGrade = parseInt(gradesMatch[2]);
    const grades = [];
    for (let i = startGrade; i <= endGrade; i++) {
      grades.push(i.toString());
    }
    return grades;
  }

  // Handle single grade formats
  if (gradeRange === 'Pre-Kindergarten') return ['Pre-Kindergarten'];
  if (gradeRange === 'Kindergarten') return ['Kindergarten'];
  
  // Handle any other formats by returning as single item array
  return [gradeRange];
};

export const calculateGradeFilteredPopulation = ({
  esriData,
  selectedDriveTime,
  selectedGrades,
  includePreK = false
}) => {
  if (!esriData?.drive_times?.[selectedDriveTime]?.ages?.['4_17']) {
    return {
      past: 0,
      current: 0,
      future: 0
    };
  }
  const ageData = esriData.drive_times[selectedDriveTime].ages['4_17'];
  
  const filteredAgeData = filterAgeData(ageData, selectedGrades);

  const totals = {
    past: calculateTotalChildren(filteredAgeData['2020'], selectedGrades),
    current: calculateTotalChildren(filteredAgeData.current, selectedGrades),
    future: calculateTotalChildren(filteredAgeData.future, selectedGrades)
  };

  return totals;
};