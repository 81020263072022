import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';

const reportCards = [
  {
    title: 'ESRI Data by Drive Time',
    description: 'View and download ESRI demographics data based on drive time from schools.',
    icon: <PeopleAltOutlinedIcon sx={{ fontSize: 48 }} />,
    link: '/data-reports/drive-time',
    available: false
  },
  {
    title: 'Enrollment Trends',
    description: 'Analyze historical and projected enrollment data for schools.',
    icon: <InsightsOutlinedIcon sx={{ fontSize: 48 }} />,
    link: '/data-reports/enrollment-trends',
    available: false
  },
  {
    title: 'Marketshare Reports',
    description: 'Explore detailed marketshare data for competitor schools.',
    icon: <DonutSmallOutlinedIcon sx={{ fontSize: 48 }} />,
    link: '/data-reports/marketshare',
    available: false
  }
];

const DataReportsPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold' }}>
        Data Reports
      </Typography>
      <Grid container spacing={4}>
        {reportCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                opacity: card.available ? 1 : 0.6,
                bgcolor: card.available ? 'background.paper' : 'action.disabledBackground'
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                  {card.icon}
                </div>
                <Typography gutterBottom variant="h5" component="h2" align="center">
                  {card.title}
                </Typography>
                <Typography align="center">
                  {card.description}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center', paddingBottom: '1rem' }}>
                {card.available ? (
                  <Button
                    component={RouterLink}
                    to={card.link}
                    variant="contained"
                    color="primary"
                  >
                    View Report
                  </Button>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Coming Soon!
                  </Typography>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DataReportsPage;