// teamCacheService.js
import { schoolCache } from '../services/cacheService';
import { useAuth } from '../auth/AuthContext';
import { useState, useEffect, useCallback, useRef } from 'react';
import { schoolsApi } from '../utils/apiService';

export function useTeamCache() {
  const { user } = useAuth();
  const [activeTeam, setActiveTeam] = useState(null);
  const [availableTeams, setAvailableTeams] = useState([]);

  useEffect(() => {
    if (user?.teams) {
      const teams = Object.entries(user.teams).map(([id, data]) => ({
        id,
        ...data
      }));
      setAvailableTeams(teams);
      
      if (!activeTeam && teams.length > 0) {
        setActiveTeam(teams[0]);
      }
    }
  }, [user, activeTeam]);

  return {
    activeTeam,
    availableTeams,
    setActiveTeam
  };
}

export function useTeamSchoolCache() {
  const { activeTeam, availableTeams } = useTeamCache();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [schools, setSchools] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState('Initializing...');
  const [loadingProgress, setLoadingProgress] = useState(0);
  
  // Use a ref to track if background loading is in progress
  const bgLoadingRef = useRef(false);
  // Track the total expected schools to calculate progress
  const totalSchoolsRef = useRef(0);

  // Define loadFreshSchoolsInBackground with useCallback first
  const loadFreshSchoolsInBackground = useCallback(async () => {
    if (bgLoadingRef.current) return; // Prevent multiple parallel background loads
    
    try {
      bgLoadingRef.current = true;
      setLoadingStatus('Refreshing data in background...');
      
      const freshSchools = await schoolsApi.getAllSchools();
      totalSchoolsRef.current = freshSchools.length;
      
      // Update schools state with fresh data
      setSchools(freshSchools);
      
      // Cache fresh schools
      if (activeTeam) {
        await schoolCache.cacheSchoolData(freshSchools, activeTeam.id);
      }
      
      setLoadingStatus('Data refreshed');
      setLoadingProgress(100);
    } catch (err) {
      console.error('Background refresh failed:', err);
      // Don't update error state to avoid disrupting the UI
    } finally {
      bgLoadingRef.current = false;
    }
  }, [activeTeam]); // Add activeTeam as dependency

  // Two-phase school loading: initial fast load + background complete load
  const loadSchools = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      setLoadingStatus('Checking cache...');
      setLoadingProgress(10);
      
      // Initialize cache
      await schoolCache.initialize();
      
      // First try to get schools from cache
      let cachedSchools = [];
      if (activeTeam) {
        cachedSchools = await schoolCache.getTeamSchools(activeTeam.id);
      }
      
      // If we have cached schools, use them immediately
      if (cachedSchools.length > 0) {
        setLoadingStatus('Using cached data...');
        setLoadingProgress(90);
        setSchools(cachedSchools);
        
        // Still refresh in background for fresh data
        setTimeout(() => {
          loadFreshSchoolsInBackground();
        }, 2000);
        setLoading(false);
        return;
      }
      
      // No cache - we need to load schools from scratch with two-phase loading
      setLoadingStatus('Loading initial schools...');
      setLoadingProgress(30);
      
      try {
        // FIRST PHASE: Get the first page quickly (paginated)
        const initialResponse = await schoolsApi.getSchoolsPaginated(1, 100);
        
        if (initialResponse && initialResponse.schools) {
          // Update initial state and progress
          setSchools(initialResponse.schools);
          setLoadingProgress(70);
          
          // Store total number for progress tracking
          totalSchoolsRef.current = initialResponse.total;
          
          // Mark loading complete to make UI interactive
          setLoading(false);
          setLoadingStatus('Loaded initial schools');
          
          // SECOND PHASE: Start background loading if there are more pages
          if (initialResponse.pages > 1) {
            bgLoadingRef.current = true;
            
            // Delay slightly to allow UI to become responsive
            setTimeout(async () => {
              try {
                let allSchools = [...initialResponse.schools];
                
                // Fetch remaining pages in background
                for (let page = 2; page <= initialResponse.pages; page++) {
                  setLoadingStatus(`Loading additional schools (${page}/${initialResponse.pages})...`);
                  
                  const nextPageResponse = await schoolsApi.getSchoolsPaginated(page, 100);
                  if (nextPageResponse && nextPageResponse.schools) {
                    // Combine with existing schools
                    allSchools = [...allSchools, ...nextPageResponse.schools];
                    
                    // Update progress
                    const progress = Math.floor(((page - 1) / (initialResponse.pages - 1)) * 100);
                    setLoadingProgress(progress);
                    
                    // Update state incrementally to show progress
                    setSchools(allSchools);
                  }
                }
                
                // When complete, cache all schools
                if (activeTeam && allSchools.length > 0) {
                  await schoolCache.cacheSchoolData(allSchools, activeTeam.id);
                }
                
                setLoadingStatus('All schools loaded');
                setLoadingProgress(100);
              } catch (err) {
                console.error('Background loading failed:', err);
                // Don't set error state to avoid disrupting UI
              } finally {
                bgLoadingRef.current = false;
              }
            }, 500);
          } else {
            // Only one page, cache it
            if (activeTeam) {
              await schoolCache.cacheSchoolData(initialResponse.schools, activeTeam.id);
            }
            setLoadingProgress(100);
          }
        } else {
          // No schools returned
          setError('No schools available.');
          setLoading(false);
        }
      } catch (err) {
        console.error('Error loading schools:', err);
        setError('Failed to load schools. Please try again.');
        setLoading(false);
      }
    } catch (err) {
      setError(err.message);
      setLoadingStatus('Error loading schools');
      setLoading(false);
    }
  }, [activeTeam, loadFreshSchoolsInBackground]); // Fixed: Added missing dependency

  // Publicly exposed refresh function
  const refreshTeamSchools = useCallback(() => {
    if (bgLoadingRef.current) return; // Don't start if already loading
    loadFreshSchoolsInBackground();
  }, [loadFreshSchoolsInBackground]); // Fixed: Added missing dependency

  // Load schools when component mounts or active team changes
  useEffect(() => {
    loadSchools();
  }, [loadSchools]);

  return {
    schools,
    loading,
    error,
    loadingStatus,
    loadingProgress,
    refreshTeamSchools,
    activeTeam,
    availableTeams,
    isBackgroundLoading: bgLoadingRef.current,
    totalSchoolsCount: totalSchoolsRef.current
  };
}