import LoadingErrorWrapper from '../shared_components/loadingErrorWrapper';
import React, { useState, useEffect, Suspense, useCallback, useRef } from 'react';
import {
  Skeleton,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
  AlertTitle,
  Typography,
  Snackbar,
} from '@mui/material';
import {
  DescriptionOutlined,
  Explore,
  TrendingUp,
  MapsHomeWork,
  Assessment,
  DonutSmallOutlined,
  AutoGraphOutlined,
} from '@mui/icons-material';

// Import SchoolStore
import useSchoolStore from '../../stores/schoolStore';

// Shared Components
import PageLayout from '../shared_components/pageLayout';
import AccordionSection from '../shared_components/accordionSection';
import AccordionContainer from '../shared_components/accordionContainer';
import InitialSchoolSelector from '../shared_components/initialSchoolSelector';
import ProjectionsFilters from '../shared_components/projectionsFilters';
import NowWhatSection from '../shared_components/templates/nowWhatTemplate';
import SplitLayout from '../shared_components/splitLayout';

// Other Components
import ProjectXEnrollmentEditor from './ProjectXEnrollmentEditor';

// Custom Hooks
import { useAccordionState } from '../../hooks/useAccordionState';
import { useInsight } from '../../contexts/InsightContext';

// Utils
import { formatSchoolName } from '../../utils/nameFormatter';
import { schoolsApi } from '../../utils/apiService';

// Contexts
import { useAuth } from '../../auth/AuthContext';

// Cache
import { useTeamSchoolCache } from '../../hooks/teamCacheService';

// Constants
const GRADES = ['Kindergarten', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

// Lazy loaded components
const ProjectXDashboard = React.lazy(() => import('./dashboard/ProjectXDashboard'));
const ProjectXTargetsAssessment = React.lazy(() => import('./ProjectXTargetsAssessment'));
const ProjectXStrategyImpact = React.lazy(() => import('./ProjectXStrategyImpact'));

function ProjectionsExplorer() {
  // Get insight context
  const { setToolAndSection } = useInsight();

  // Get data from team school cache
  const { 
    schools, 
    loading: schoolsLoading, 
  } = useTeamSchoolCache();

  // Get data from SchoolStore
  const {
    selectedSchool,
    contentLoading,
  } = useSchoolStore();
  
  // State management
  const [enrollmentData, setEnrollmentData] = useState(null);
  const [error, setError] = useState(null);
  const [timeframe, setTimeframe] = useState('5-year');
  const [view, setView] = useState('school-level');
  const [selectedGrades, setSelectedGrades] = useState(['total']);
  const [availableGrades, setAvailableGrades] = useState(['total']);
  const [openDataDialog, setOpenDataDialog] = useState(false);
  const [openEnrollmentDialog, setOpenEnrollmentDialog] = useState(false);
  const [hasSkippedDataEntry, setHasSkippedDataEntry] = useState(false);
  const [adjustedProjections, setAdjustedProjections] = useState(null);
  const [enrollmentTargets, setEnrollmentTargets] = useState({});
  const [selectedScenarios, setSelectedScenarios] = useState(['low', 'median', 'high']);
  const [showSuccess, setShowSuccess] = useState(false);
  const initialSchoolLoadRef = useRef(false);
  const [hasShownDialog, setHasShownDialog] = useState(false);
  const [sectionLoadingStates, setSectionLoadingStates] = useState({
    risk: { loading: true, dataReady: false },
    impact: { loading: true, dataReady: false },
    actions: { loading: false, dataReady: true } // "Now What?" is always ready
  });

  // Use accordion state hook
  const {
    expandedSections,
    handleExpandedChange,
    handleExpandAll,
    handleCollapseAll,
  } = useAccordionState({
    risk: false,
    impact: false,
    actions: false,
  });

  const isSectionDataLoaded = useCallback((sectionId) => {
    const { loadingStates = {} } = useSchoolStore.getState();
    
    switch (sectionId) {
      case 'risk':
        return !!enrollmentData && !!selectedSchool && !loadingStates.projections;
      case 'impact':
        return !!enrollmentData && !!selectedSchool && !loadingStates.projections;
      case 'actions':
        return true; // Always ready
      default: 
        return false;
    }
  }, [enrollmentData, selectedSchool]);

  // Initial mount effect - sets default insights
  useEffect(() => {
    setToolAndSection('projections-explorer', null);
  }, [setToolAndSection]);  

  useEffect(() => {
    if (schools.length > 0 && !initialSchoolLoadRef.current) {
      const params = new URLSearchParams(window.location.search);
      const schoolId = params.get('school');
      
      if (schoolId) {
        const school = schools.find(s => s.ncessch === schoolId);
        if (school) {
          useSchoolStore.getState().selectSchool({
            ...school,
            name: formatSchoolName(school.name)
          });
        }
      }
      initialSchoolLoadRef.current = true;
    }
  }, [schools]);
  
  // Reset the initialSchoolLoadRef when the component unmounts
  useEffect(() => {
    return () => {
      initialSchoolLoadRef.current = false;
    };
  }, []);

  // Initialize schools on mount
  useEffect(() => {
    if (schools.length === 0) {
      useSchoolStore.getState().fetchSchools();
    }
  }, [schools.length]);

  // Monitor expanded sections and update insights accordingly
  useEffect(() => {
    const hasExpandedSection = Object.values(expandedSections).some(isExpanded => isExpanded);
    if (!hasExpandedSection) {
      setToolAndSection('projections-explorer', null);
    }
  }, [expandedSections, setToolAndSection]);

  // Get user from AuthContext
  const { user } = useAuth();
  
  // Helper function to check if user can edit data
  const canEditData = useCallback(() => {
    const editRoles = ['admin', 'updated_write'];
    return editRoles.includes(user?.role);
  }, [user?.role]);


  const fetchProjections = useCallback(async () => {
    if (!selectedSchool?.ncessch) return;
    
    // Set component loading states
    setSectionLoadingStates({
      risk: { loading: true, dataReady: false },
      impact: { loading: true, dataReady: false },
      actions: { loading: false, dataReady: true }
    });
    
    // Update store state to mark projections as loading
    useSchoolStore.setState(state => ({
      loadingStates: { ...state.loadingStates, projections: true }
    }));
    
    setAdjustedProjections(null);
    setEnrollmentTargets({});
    
    try {
      const data = await schoolsApi.getEnrollmentProjections(selectedSchool.ncessch);
      setEnrollmentData(data);
      
      // Update component loading states
      setSectionLoadingStates(prev => ({
        ...prev,
        risk: { loading: false, dataReady: true },
        impact: { loading: false, dataReady: true }
      }));
      
      // Update store loading state
      useSchoolStore.setState(state => ({
        loadingStates: { ...state.loadingStates, projections: false }
      }));
  
      // Process grades...
      const grades = new Set();
      const latestYear = Object.keys(data.actual_enrollment || {}).sort().pop();
      const latestEnrollment = data.actual_enrollment[latestYear] || {};
      
      Object.values(data.actual_enrollment || {}).forEach(yearData => {
        Object.entries(yearData || {}).forEach(([grade, enrollment]) => {
          let normalizedGrade;
          if (grade === '0' || grade === 'K') {
            normalizedGrade = 'Kindergarten';
          } else if (!['PK', 'Pre-K', 'PreK', 'Pre-Kindergarten'].includes(grade)) {
            normalizedGrade = grade.replace('Grade ', '');
          }
          if (normalizedGrade && (latestEnrollment[grade] === undefined || latestEnrollment[grade] > -1)) {
            grades.add(normalizedGrade);
          }
        });
      });
  
      const sortedGrades = GRADES.filter(grade => grades.has(grade));
      setAvailableGrades(sortedGrades);
    
      // Only show dialog if:
      // 1. User has edit permissions
      // 2. No user data exists
      // 3. We haven't shown the dialog yet
      // 4. The page is fully loaded
      if (!data?.based_on_user_data && 
          canEditData() && 
          !hasShownDialog && 
          !schoolsLoading && 
          !contentLoading) {
        setOpenDataDialog(true);
        setHasShownDialog(true);
      }
  
    } catch (error) {
      console.error('Error fetching projections:', error);
      setError('Failed to fetch projections: ' + (error.message || 'Unknown error'));
    }
  }, [selectedSchool?.ncessch, canEditData, hasShownDialog, schoolsLoading, contentLoading]);

  useEffect(() => {
    if (!enrollmentData) return;
    
    const sortedGrades = availableGrades.filter(grade => grade !== 'total');
    if (view === 'grade-level' && sortedGrades.length > 0) {
      if (selectedGrades.length === 0 || selectedGrades[0] === 'total') {
        setSelectedGrades([sortedGrades[0]]);
      }
    } else if (view === 'school-level' && !selectedGrades.includes('total')) {
      setSelectedGrades(['total']);
    }
  }, [view, enrollmentData, availableGrades, selectedGrades]);

  // Fetch projections when school changes
  useEffect(() => {
    fetchProjections();
  }, [selectedSchool, fetchProjections]);

  // Handlers
  const handleSchoolChange = (event) => {
    const schoolId = event.target.value;
    const school = schools.find(s => s.ncessch === schoolId);
    if (school) {
      // Reset all local state first
      setEnrollmentData(null);
      setError(null);
      setTimeframe('5-year');
      setView('school-level');
      setSelectedGrades(['total']);
      setAvailableGrades(['total']);
      setOpenDataDialog(false);
      setOpenEnrollmentDialog(false);
      setHasSkippedDataEntry(false);
      setAdjustedProjections(null);
      setEnrollmentTargets({});
      setSelectedScenarios(['low', 'median', 'high']);
      setShowSuccess(false);
      setHasShownDialog(false);  // Reset the dialog shown state
  
      // Reset section loading states
      setSectionLoadingStates({
        risk: { loading: true, dataReady: false },
        impact: { loading: true, dataReady: false },
        actions: { loading: false, dataReady: true }
      });

      // Then update store with new school
      useSchoolStore.getState().selectSchool({
        ...school,
        name: formatSchoolName(school.name)
      });
    }
  };

  const handleGradeChange = (event) => {
    const value = event.target.value;
    if (view === 'school-level') {
      setSelectedGrades(['total']);
    } else {
      const newSelection = Array.isArray(value) ? value : [value];
      if (newSelection.length === 0) return;
      setSelectedGrades([...newSelection].sort((a, b) => {
        if (a === 'Kindergarten') return -1;
        if (b === 'Kindergarten') return 1;
        return parseInt(a) - parseInt(b);
      }));
    }
  };

  const handleViewChange = (event, newView) => {
    if (newView) {
      setView(newView);
      if (newView === 'grade-level' && (!selectedGrades.length || selectedGrades[0] === 'total')) {
        const validGrades = availableGrades.filter(grade => grade !== 'total');
        if (validGrades.length > 0) {
          setSelectedGrades([validGrades[0]]);
        }
      } else if (newView === 'school-level' && !selectedGrades.includes('total')) {
        setSelectedGrades(['total']);
      }
    }
  };

  const handleAccordionChange = (section) => (event, isExpanded) => {
    // Prevent expanding if data isn't ready
    if (isExpanded && !sectionLoadingStates[section]?.dataReady) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    
    handleExpandedChange(section)(event, isExpanded);
    if (isExpanded) {
      setToolAndSection('projections-explorer', section);
    } else if (!Object.values(expandedSections).some(expanded => expanded)) {
      setToolAndSection('projections-explorer', null);
    }
  };

  const handleDataEntrySuccess = async () => {
    try {
      await schoolsApi.updateProjections(selectedSchool.ncessch);
      
      const freshData = await schoolsApi.getEnrollmentProjections(selectedSchool.ncessch);
      setEnrollmentData(freshData);
      setHasSkippedDataEntry(false);
      setShowSuccess(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSkipDataEntry = () => {
    setHasSkippedDataEntry(true);
    setOpenDataDialog(false);
    setHasShownDialog(true);  // Mark dialog as shown
  };

  const handleScenariosChange = (newScenarios) => {
    setSelectedScenarios(newScenarios);
  };

  const handleProjectionsUpdate = useCallback((adjusted) => {
    setAdjustedProjections(adjusted || null);
  }, []);

  // Render methods
  const renderWarningElement = () => {
    if (!enrollmentData?.based_on_user_data && hasSkippedDataEntry && canEditData()) {
      return (
        <Alert 
          severity="info" 
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setOpenEnrollmentDialog(true)}
            >
              Enter Data
            </Button>
          }
          sx={{ 
            width: '100%',
            '& .MuiAlert-message': {
              padding: '4px 0'
            },
            mb: 2
          }}
        >
          <AlertTitle>Missing Latest Enrollment Data</AlertTitle>
          Your projections are based on historical data only. For more accurate projections, please add your latest enrollment data.
        </Alert>
      );
    }
    return null;
  };

  const renderRiskAssessment = () => {
    if (!selectedSchool || !enrollmentData) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <ProjectXTargetsAssessment 
        enrollmentData={enrollmentData}
        timeframe={timeframe}
        selectedGrades={selectedGrades}
        view={view}
        onTargetsChange={setEnrollmentTargets}
        selectedScenarios={selectedScenarios}
        school={selectedSchool} 
        currentUser={user} 
      />
    );
  };

  const renderFilters = () => {
    return (
      <ProjectionsFilters
        schools={schools}
        selectedSchool={selectedSchool}
        timeframe={timeframe}
        view={view}
        selectedGrades={selectedGrades}
        availableGrades={availableGrades}
        selectedScenarios={selectedScenarios}  
        onSchoolChange={handleSchoolChange}
        onTimeframeChange={(e, value) => value && setTimeframe(value)}
        onViewChange={handleViewChange}
        onGradeChange={handleGradeChange}
        onScenariosChange={handleScenariosChange} 
        expandedSections={expandedSections}
        onExpandAll={handleExpandAll}
        onCollapseAll={handleCollapseAll}
        warningElement={
          <Box sx={{ width: '100%' }}>
            {renderWarningElement()}
          </Box>
        }
      />
    );
  };

  const renderMainDashboard = () => {
    // If any of the required projections data is ready, render the dashboard
    const dashboardDataReady = isSectionDataLoaded('risk');
    
    if (!dashboardDataReady) {
      return <Skeleton variant="rectangular" height={400} />;
    }
  
    return (
      <Box sx={{ mt: 0 }}> 
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          bgcolor: 'background.paper'
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
          }}>
            <AutoGraphOutlined sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
              Enrollment Trends & Projections
            </Typography>
          </Box>
        </Box>
        <Box sx={{ p: 1 }}> 
        <Suspense fallback={<Skeleton variant="rectangular" height={400} />}>
          <ProjectXDashboard 
            school={selectedSchool}
            enrollmentData={{
              ...enrollmentData,
              projections: adjustedProjections || enrollmentData?.projections
            }}
            selectedGrades={selectedGrades}
            timeframe={timeframe}
            view={view}
            targets={enrollmentTargets}
            selectedScenarios={selectedScenarios}
            adjustedProjections={adjustedProjections}
            onEditEnrollment={() => setOpenEnrollmentDialog(true)}
          />
          </Suspense>
        </Box>
      </Box>
    );
  };

  const renderRightAccordions = () => {
    return (
      <AccordionContainer 
        expandedSections={expandedSections}
        onSectionChange={handleAccordionChange}
      >
        <AccordionSection
          id="risk"
          title="Risk Assessment"
          summary="Compare your network's enrollment targets to projection scenarios"
          icon={Assessment}
          disabled={!sectionLoadingStates.risk.dataReady}
          loading={sectionLoadingStates.risk.loading}
        >
          <div>
            <Suspense fallback={<Skeleton variant="rectangular" height={400} />}>
              {sectionLoadingStates.risk.dataReady ? (
                renderRiskAssessment()
              ) : (
                <Box 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    py: 8 
                  }}
                >
                  <CircularProgress size={40} sx={{ mb: 2 }} />
                  <Typography variant="body1" color="text.secondary">
                    Loading risk assessment data...
                  </Typography>
                </Box>
              )}
            </Suspense>
          </div>
        </AccordionSection>

        <AccordionSection
          id="impact"
          title="Strategy Impact Analysis"
          summary="Model the impact of different recruitment and retention strategies"
          icon={TrendingUp}
          disabled={!sectionLoadingStates.impact.dataReady}
          loading={sectionLoadingStates.impact.loading}
        >
          <div>
            <Suspense fallback={<Skeleton variant="rectangular" height={400} />}>
              {sectionLoadingStates.impact.dataReady ? (
                <ProjectXStrategyImpact 
                  enrollmentData={enrollmentData}
                  onProjectionsUpdate={handleProjectionsUpdate}
                  timeframe={timeframe}
                  selectedGrades={selectedGrades}
                  view={view}
                  enrollmentTargets={enrollmentTargets}
                  selectedScenarios={selectedScenarios}
                  selectedSchool={selectedSchool}
                />
              ) : (
                <Box 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    py: 8 
                  }}
                >
                  <CircularProgress size={40} sx={{ mb: 2 }} />
                  <Typography variant="body1" color="text.secondary">
                    Loading strategy impact data...
                  </Typography>
                </Box>
              )}
            </Suspense>
          </div>
        </AccordionSection>

        <AccordionSection
          id="actions"
          title="Now What?"
          summary="Continue to explore with other enrollment planning tools"
          icon={Explore}
        >
          <div>
            <NowWhatSection 
              sectionIcon={Explore}
              actions={[
                {
                  icon: MapsHomeWork,
                  title: 'Geographic Explorer',
                  description: 'Explore demographic and enrollment trends',
                  path: '/geographic-explorer'
                },
                {
                  icon: DonutSmallOutlined,
                  title: 'Competitive Explorer',
                  description: 'Analyze market share data',
                  path: '/marketshare-analyzer'
                },
                {
                  icon: DescriptionOutlined,
                  title: 'Data Reports',
                  description: 'Download detailed reports and analysis',
                  path: '/data-reports'
                }
              ]}
            />
          </div>
        </AccordionSection>
      </AccordionContainer>
    );
  };

  return (
    <>
      <PageLayout
        title="Projections Explorer"
        description={selectedSchool
          ? "Refine your analysis using the time frame and grade filters. Click on any headline card to view more detailed information and insights about the selected metrics."
          : "Use this tool to explore enrollment projections and plan future scenarios. Start by selecting a school."
        }
      >
        <LoadingErrorWrapper
          loading={schoolsLoading && schools.length === 0}
          error={error}
          initialLoading={schools.length === 0}
          hideSteps={true}
        >
          {!selectedSchool ? (
            <InitialSchoolSelector
              schools={schools}
              selectedSchool={selectedSchool}
              onSchoolChange={handleSchoolChange}
            />
          ) : (
            <Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
              <LoadingErrorWrapper
                loading={contentLoading}
                error={error}
                initialLoading={!enrollmentData && selectedSchool}
                hideSteps={true}
              >
                <SplitLayout
                  topContent={renderFilters()}
                  leftContent={renderMainDashboard()}
                  rightContent={renderRightAccordions()}
                />
              </LoadingErrorWrapper>
            </Suspense>
          )}
        </LoadingErrorWrapper>
      </PageLayout>
  
      {/* Initial data entry dialog */}
      <Dialog
        open={openDataDialog}
        onClose={() => setOpenDataDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add Latest Enrollment Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To ensure the most accurate enrollment projections, we recommend adding your latest enrollment data. 
            Would you like to enter this information now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSkipDataEntry} variant="outlined" color="inherit">
            Skip for Now
          </Button>
          <Button
            onClick={() => {
              setOpenDataDialog(false);
              setOpenEnrollmentDialog(true);
            }}
            variant="contained"
            color="primary"
            autoFocus
          >
            Enter Enrollment Data
          </Button>
        </DialogActions>
      </Dialog>
  
      {/* Enrollment editor */}
      {selectedSchool && openEnrollmentDialog && (
        <ProjectXEnrollmentEditor
          open={openEnrollmentDialog}
          onClose={() => {
            setOpenEnrollmentDialog(false);
            setHasShownDialog(true);  // Mark dialog as shown when closing editor
          }}
          school={selectedSchool}
          onSuccess={handleDataEntrySuccess}
          existingData={enrollmentData}
          isEdit={!!enrollmentData?.based_on_user_data}
        />
      )}
  
      {/* Success notification */}
      <Snackbar 
        open={showSuccess} 
        autoHideDuration={4000} 
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={() => setShowSuccess(false)}>
          Enrollment data successfully submitted
        </Alert>
      </Snackbar>
    </>
  );
}

export default ProjectionsExplorer;