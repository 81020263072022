import React, { useMemo } from 'react';
import { Marker } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { customIcon } from '../../../utils/unifiedMapUtils';
import { getDistrictColor } from '../../../utils/districtColorUtils';

const DistrictMapMarkers = ({ schools, selectedHeadline, onSchoolSelect }) => {
  const markersData = useMemo(() => {
    return schools.map(school => {
      const color = getDistrictColor(school, selectedHeadline);
      const isCharter = school.charter === 'Yes';

      return {
        ...school,
        color,
        isCharter
      };
    });
  }, [schools, selectedHeadline]);

  return (
    <MarkerClusterGroup
      chunkedLoading
      spiderfyOnMaxZoom={true}
      zoomToBoundsOnClick={true}
      maxClusterRadius={40}
      disableClusteringAtZoom={12}
    >
      {markersData.map((school, index) => (
        <Marker
          key={school.ncessch || index}
          position={[school.latitude, school.longitude]}
          icon={customIcon({
            color: school.color,
            isCharter: school.isCharter,
            isSelected: false
          })}
          eventHandlers={{
            click: () => onSchoolSelect(school)
          }}
        />
      ))}
    </MarkerClusterGroup>
  );
};

export default DistrictMapMarkers;