import React from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  IconButton, 
  Tooltip
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend,
  ChartDataLabels
);

// Font size constants for consistency
const FONT_SIZES = {
  dataLabel: 14,
  axisLabel: 13,
  legend: 13
};

// Helper function to determine if a color is light
const isLightColor = (color) => {
  // Default to dark text for pastel/light colors
  if (color.includes('rgba')) {
    const [, r, g, b, a] = color.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.6 || parseFloat(a) < 0.5;
  }
  if (color.includes('rgb')) {
    const [, r, g, b] = color.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.6;
  }
  return true;
};

const BarChartTemplate = ({
  icon: Icon,
  title,
  analysis,
  tooltip,
  chartData,
  chartOptions,
  additionalInfo,
  analysisBackgroundColor = 'rgba(25, 118, 210, 0.1)',
  analysisTextColor = 'primary.main',
  cardProps = {},
  animationEnabled = true  // Add this prop with default
}) => {
  // Process chart data with minimal overrides
  const processedChartData = {
    ...chartData,
    datasets: chartData?.datasets.map(dataset => ({
      ...dataset,
      borderWidth: 0.5,
      borderRadius: 4,
      barPercentage: 0.9,
      categoryPercentage: 0.8
    }))
  };

  const defaultChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: animationEnabled ? {
      duration: 750,
      easing: 'easeInOutQuart'
    } : false,  // Disable animation completely when not enabled
    layout: {
      padding: {
        top: 16,
        bottom: 8,
        left: 8,
        right: 8
      }
    },
    scales: {
      x: {
        grid: { color: '#e0e0e0' },
        ticks: {
          font: {
            size: FONT_SIZES.axisLabel,
            weight: 400
          }
        },
        border: { display: false }
      },
      y: {
        grid: { display: false },
        ticks: {
          font: {
            size: FONT_SIZES.axisLabel,
            weight: 400
          },
          padding: 8
        },
        border: { display: false }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          font: {
            size: FONT_SIZES.legend,
            weight: 500
          },
          padding: 15,
          boxWidth: 15,
          boxHeight: 15
        }
      },
      datalabels: {
        formatter: (value) => {
          // Always format as percentage
          return `${Math.round(value)}%`;
        },
        font: {
          weight: 600,  // This makes the labels bold
          size: FONT_SIZES.dataLabel
        },
        padding: 6,
        color: (context) => {
          const dataset = context.dataset;
          const dataIndex = context.dataIndex;
          const value = context.raw;
          const backgroundColor = dataset.backgroundColor;
          const chart = context.chart;
          const meta = chart.getDatasetMeta(context.datasetIndex);
          const barSize = meta.data[dataIndex].width || meta.data[dataIndex].height;
          const valueSize = String(Math.abs(value)).length * (FONT_SIZES.dataLabel * 0.7);
          const isTooSmall = barSize < valueSize || Math.abs(value) < 5;
    
          // For the demographics chart (2 datasets)
          if (chart.data.datasets.length === 2) {
            // First dataset (blue bars)
            if (context.datasetIndex === 0) {
              return '#FFFFFF';  // Always white on blue
            }
            // Second dataset (green bars)
            return '#333333';  // Always dark on light green
          }
          
          // For single dataset charts
          // Handle array of colors
          const color = Array.isArray(backgroundColor) 
            ? backgroundColor[dataIndex]
            : backgroundColor;
          
          if (isTooSmall) {
            return '#666666';
          }
          
          return isLightColor(color) ? '#333333' : '#FFFFFF';
        },
        anchor: (context) => {
          const value = context.raw;
          const chart = context.chart;
          const meta = chart.getDatasetMeta(context.datasetIndex);
          const barSize = meta.data[context.dataIndex].width || meta.data[context.dataIndex].height;
          const valueSize = String(Math.abs(value)).length * (FONT_SIZES.dataLabel * 0.7);
          const isTooSmall = barSize < valueSize || Math.abs(value) < 5;
          
          // For demographic pairs
          if (chart.data.datasets.length === 2) {
            // Keep all labels inside for cleaner look
            return 'center';
          }
          
          // For single dataset charts
          return isTooSmall ? 'end' : 'center';
        },
        align: (context) => {
          const value = context.raw;
          const chart = context.chart;
          const meta = chart.getDatasetMeta(context.datasetIndex);
          const barSize = meta.data[context.dataIndex].width || meta.data[context.dataIndex].height;
          const valueSize = String(Math.abs(value)).length * (FONT_SIZES.dataLabel * 0.7);
          const isTooSmall = barSize < valueSize || Math.abs(value) < 5;
          
          // For demographic pairs
          if (chart.data.datasets.length === 2) {
            // Keep all labels inside for cleaner look
            return 'center';
          }
          
          // For single dataset charts
          return isTooSmall ? 'right' : 'center';
        }
      }
    }
  };

  const mergedOptions = {
    ...defaultChartOptions,
    ...chartOptions,
    plugins: {
      ...defaultChartOptions.plugins,
      ...chartOptions?.plugins,
      datalabels: {
        ...defaultChartOptions.plugins.datalabels,
        ...chartOptions?.plugins?.datalabels,
        font: {
          ...defaultChartOptions.plugins.datalabels.font,
          ...chartOptions?.plugins?.datalabels?.font,
          size: FONT_SIZES.dataLabel // Enforce consistent font size
        },
        formatter: defaultChartOptions.plugins.datalabels.formatter, // Preserve formatter
        color: defaultChartOptions.plugins.datalabels.color, // Preserve color function
        anchor: defaultChartOptions.plugins.datalabels.anchor, // Preserve anchor function
        align: defaultChartOptions.plugins.datalabels.align // Preserve align function
      }
    }
  };

  return (
    <Card 
      {...cardProps} 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        boxShadow: 'none',
        border: '1px solid',
        borderColor: 'divider',
        ...(cardProps.sx || {})
      }}
    >
      <CardContent sx={{ 
        flex: 1, 
        display: 'flex', 
        flexDirection: 'column',
        pb: 2,
        '&:last-child': { pb: 2 }
      }}>
        {/* Header Section */}
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            {Icon && <Icon sx={{ color: 'primary.main', mr: 1 }} />}
            <Typography variant="h6" sx={{ 
              flexGrow: 1, 
              fontWeight: 600
            }}>
              {title}
            </Typography>
            {tooltip && (
              <Tooltip title={tooltip}>
                <IconButton size="small">
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          
          {analysis && (
            <Typography 
              variant="body2" 
              sx={{ 
                bgcolor: analysisBackgroundColor,
                color: analysisTextColor,
                p: 1,
                borderRadius: 1,
                fontWeight: 400
              }}
            >
              {analysis}
            </Typography>
          )}
        </Box>

        {/* Chart Section */}
        <Box sx={{ 
          flex: 1,
          minHeight: 250,
          position: 'relative',
          mb: additionalInfo ? 2 : 0,
          height: 'auto',
          '& canvas': {
            minHeight: '250px !important'
          }
        }}>
          {chartData && (
            <Bar 
              data={processedChartData}
              options={mergedOptions}
            />
          )}
        </Box>

        {/* Footer Section */}
        <Box sx={{ mt: 'auto', pt: 1 }}>
          {additionalInfo && (
            <Typography 
              variant="caption" 
              color="text.secondary" 
              sx={{ 
                display: 'block',
                textAlign: 'center',
                mb: 1
              }}
            >
              {additionalInfo}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default BarChartTemplate;