import React, { useMemo, useState } from 'react';
import { BarChartOutlined } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup, Box } from '@mui/material';
import BarChartTemplate from '../../shared_components/templates/barChartTemplate';
import { CHART_COLORS, getColorWithOpacity } from '../../../utils/schoolUtils';

const GeoXCommunityDemographics = ({ 
  esriData, 
  selectedDriveTime,
  cardProps = {} ,
  animationEnabled = true
}) => {
  const [demographicType, setDemographicType] = useState('child');

  const handleDemographicTypeChange = (event, newValue) => {
    if (newValue !== null) {
      setDemographicType(newValue);
    }
  };

  const demographics = useMemo(() => {
    return esriData?.drive_times?.[selectedDriveTime]?.racial_percentages?.[demographicType] || {};
  }, [esriData, selectedDriveTime, demographicType]);

  const filteredData = useMemo(() => {
    const races = [
      'hispanic', 
      'white', 
      'black', 
      'asian', 
      'pacific_islander', 
      'american_indian', 
      'other_races', 
      'two_or_more_races'
    ];
  
    return races
      .map(race => ({
        label: race,
        value: Math.round((demographics[race] || 0) * 100)
      }))
      .filter(item => item.value >= 5)
      .sort((a, b) => b.value - a.value);
  }, [demographics]);

  const getAnalysisSummary = useMemo(() => {
    if (!filteredData.length) return '';
    
    const topGroup = filteredData[0];
    const secondGroup = filteredData[1];
    
    if (!secondGroup) return '';

    const difference = Math.abs(topGroup.value - secondGroup.value);
    const formattedLabel = topGroup.label
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return `${formattedLabel} residents represent the largest demographic at ${topGroup.value}%, ${difference} percentage points higher than any other group`;
  }, [filteredData]);

  const chartData = useMemo(() => ({
    labels: filteredData.map(item => 
      item.label.split('_').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' ')
    ),
    datasets: [{
      data: filteredData.map(item => item.value),
      backgroundColor: getColorWithOpacity(CHART_COLORS.PRIMARY, 0.8),
      borderColor: CHART_COLORS.PRIMARY,
      barPercentage: 0.95,
      categoryPercentage: 0.85
    }]
  }), [filteredData]);

  const title = (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <span style={{ flexGrow: 1, fontSize: '1.125rem', fontWeight: 600 }}>Demographics</span>
      <ToggleButtonGroup
        value={demographicType}
        exclusive
        onChange={handleDemographicTypeChange}
        size="small"
        sx={{ mr: 1 }}
      >
        <ToggleButton value="child" sx={{ textTransform: 'none' }}>
          Child
        </ToggleButton>
        <ToggleButton value="adult" sx={{ textTransform: 'none' }}>
          Adult
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  return (
    <BarChartTemplate
      icon={BarChartOutlined}
      title={title}
      analysis={getAnalysisSummary}
      tooltip={`Racial composition of ${demographicType === 'child' ? 'school-age children' : 'adults'} in the area`}
      chartData={chartData}
      chartOptions={{
        indexAxis: 'y',
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: (context) => `${context.parsed.x}% of ${demographicType === 'child' ? 'school-age children' : 'adults'}`
            }
          }
        },
        scales: {
          x: {
            grid: { color: '#e0e0e0' },
            ticks: {
              callback: (value) => `${Math.round(value)}%`
            },
            border: { display: false }
          },
          y: {
            grid: { display: false },
            border: { display: false }
          }
        }
      }}
      animationEnabled={animationEnabled}
      additionalInfo="Only showing demographic groups that comprise 5% or more of population"
      analysisBackgroundColor={getColorWithOpacity(CHART_COLORS.PRIMARY, 0.1)}
      analysisTextColor="primary.main"
      cardProps={cardProps}
    />
  );
};

export default GeoXCommunityDemographics;