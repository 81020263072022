import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Box
} from '@mui/material';
import { TableChart } from '@mui/icons-material';

const SimplePopulationTable = ({ 
  schools, 
  selectedSchoolTrends, 
  focusedSchool,
  onSchoolSelect
}) => {
  const [orderBy, setOrderBy] = useState('currentEnrollment');
  const [order, setOrder] = useState('desc');

  if (!schools?.length) return null;

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Calculate changes and create sortable data structure
  const schoolsWithChanges = schools.map(school => {
    const change = school.isMainSchool ? 
      selectedSchoolTrends?.selectedSchool?.percentChange :
      school.currentEnrollment && school.previousEnrollment ?
        ((school.currentEnrollment - school.previousEnrollment) / school.previousEnrollment) * 100 :
        null;
    
    return {
      ...school,
      enrollmentChange: change
    };
  });

  // Sort schools, but always keep selected school at top
  const sortedSchools = [...schoolsWithChanges].sort((a, b) => {
    // Always keep selected school at top regardless of sorting
    if (a.isMainSchool) return -1;
    if (b.isMainSchool) return 1;

    let comparison = 0;
    switch (orderBy) {
      case 'name':
        comparison = a.name.localeCompare(b.name);
        break;
      case 'enrollmentChange':
        // Handle null values in comparison
        if (a.enrollmentChange === null) return 1;
        if (b.enrollmentChange === null) return -1;
        comparison = a.enrollmentChange - b.enrollmentChange;
        break;
      case 'currentEnrollment':
        // Handle null values in comparison
        if (!a.currentEnrollment) return 1;
        if (!b.currentEnrollment) return -1;
        comparison = a.currentEnrollment - b.currentEnrollment;
        break;
      default:
        comparison = 0;
    }
    return order === 'desc' ? -comparison : comparison;
  });

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100%' // Fill the container height
    }}>
      {/* Header with title and info */}
      <Box sx={{ mb: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          mb: 1
        }}>
          <TableChart sx={{ color: 'primary.main' }} />
          <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
            Enrollment Changes
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            bgcolor: 'rgba(25, 118, 210, 0.1)',
            color: 'primary.main',
            p: 1,
            borderRadius: 1,
            fontWeight: 500
          }}
        >
          Click on a school to see them on the map
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontStyle: 'italic', color: 'text.secondary', mt: 1 }}
        >
          All enrollment data shown for overlapping grades only
        </Typography>
      </Box>

      <TableContainer sx={{ 
        flex: 1, // Take up remaining space
        overflow: 'auto'
      }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleSort('name')}
                >
                  <Typography variant="subtitle2" fontWeight="bold">School Name</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'enrollmentChange'}
                  direction={orderBy === 'enrollmentChange' ? order : 'asc'}
                  onClick={() => handleSort('enrollmentChange')}
                >
                  <Typography variant="subtitle2" fontWeight="bold">Change</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'currentEnrollment'}
                  direction={orderBy === 'currentEnrollment' ? order : 'asc'}
                  onClick={() => handleSort('currentEnrollment')}
                >
                  <Typography variant="subtitle2" fontWeight="bold">23-24 Enrollment</Typography>
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSchools.map((school) => {
              const isHighlighted = school.ncessch === focusedSchool?.ncessch || school.isMainSchool;
              const isOthersGreyedOut = focusedSchool && !isHighlighted;

              return (
                <TableRow 
                  key={school.ncessch}
                  onClick={() => onSchoolSelect(school.ncessch)}
                  sx={{ 
                    backgroundColor: isHighlighted ? 'primary.lighter' : 'inherit',
                    opacity: isOthersGreyedOut ? 0.5 : 1,
                    cursor: 'pointer',
                    '&:nth-of-type(odd)': { 
                      backgroundColor: isHighlighted ? 'primary.lighter' : 'rgba(0, 0, 0, 0.02)' 
                    },
                    '&:hover': {
                      backgroundColor: isHighlighted ? 'primary.lighter' : 'action.hover'
                    },
                    transition: 'all 0.2s ease-in-out'
                  }}
                >
                  <TableCell>
                    <Typography 
                      variant="body2"
                      sx={{ 
                        fontWeight: isHighlighted ? 'bold' : 'normal',
                        color: isHighlighted ? 'primary.main' : 'text.primary'
                      }}
                    >
                      {school.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography 
                      variant="body2"
                      sx={{ 
                        color: school.enrollmentChange > 0 ? 'success.main' : 
                               school.enrollmentChange < 0 ? 'error.main' : 
                               'text.secondary',
                        fontStyle: school.enrollmentChange === null ? 'italic' : 'normal'
                      }}
                    >
                      {typeof school.enrollmentChange === 'number' ? (
                        <>
                          {school.enrollmentChange > 0 ? '+' : ''}
                          {Math.round(school.enrollmentChange)}%
                        </>
                      ) : (
                        'New/Newer School'
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2">
                      {school.currentEnrollment?.toLocaleString() || 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SimplePopulationTable;