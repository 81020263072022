// In MarketShareAnalysis.js
import React from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import { canAccessExplorers } from '../../../utils/explorerAccess';

const MarketShareAnalysis = ({ 
  selectedSchool,
  selectedSchoolTrends,
  marketShareData,
  user
}) => {
  if (!selectedSchool || !marketShareData) {
    return null;
  }

  // Create URL with school parameter
  const explorerUrl = `/competitive-explorer?school=${selectedSchool.ncessch}`;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper 
        elevation={0}
        sx={{
          mb: 2,
          py: 2,
          px: 3,
          backgroundColor: 'grey.50',
          color: 'text.primary',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            width: 6,
            backgroundColor: 'primary.main',
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8
          }
        }}
      >
        <Box sx={{ flex: 1 }}>
        <Typography variant="h8" sx={{ fontWeight: 200 }}>
          <strong>{selectedSchool.name}</strong> enrolls <strong>{
            selectedSchoolTrends?.selectedSchool?.newEnrollment?.toLocaleString() || 'N/A'
          }</strong> students, representing <strong>{
            Math.round(marketShareData?.current) || 'N/A'
          }%</strong> of area children{
            marketShareData?.metadata?.isNewOrNewer 
              ? ` (opened in ${selectedSchoolTrends?.selectedSchool?.timeframe?.endLabel || 'N/A'})`
              : marketShareData?.current != null && marketShareData?.past != null
                ? <>, <strong>{marketShareData.current > marketShareData.past ? 'up' : 'down'}</strong> from {Math.round(marketShareData.past)}% in 2020</>
                : ''
          }.
        </Typography>
        </Box>
        {canAccessExplorers(user?.role) && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<DonutSmallOutlinedIcon />}
          endIcon={<LaunchIcon />}
          href={explorerUrl}
          sx={{ 
            backgroundColor: 'primary.dark',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.main'
            }
          }}
        >
          OPEN IN COMPETITIVE EXPLORER
        </Button>
      )}
      </Paper>
    </Box>
  );
};

export default MarketShareAnalysis;