import React, { useState, useMemo } from 'react';
import { 
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  TableSortLabel,
} from '@mui/material';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  TrendingFlat as TrendingFlatIcon,
  School as SchoolIcon,
  LocationOn as LocationOnIcon,
  Fullscreen as FullscreenIcon,
  TableChart as TableChartIcon,
} from '@mui/icons-material';
import DistrictTableColorCodedRow from './DistrictTableColorCodedRow';
import DistrictAnalysisTableExpanded from './DistrictAnalysisTableExpanded';

const TrendIndicator = ({ trend, value }) => {
  const getIcon = () => {
    switch (trend) {
      case 'up':
        return <TrendingUpIcon sx={{ color: 'success.main' }} />;
      case 'down':
        return <TrendingDownIcon sx={{ color: 'error.main' }} />;
      default:
        return <TrendingFlatIcon sx={{ color: 'action.disabled' }} />;
    }
  };

  const getColor = () => {
    switch (trend) {
      case 'up':
        return 'success.main';
      case 'down':
        return 'error.main';
      default:
        return 'text.secondary';
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 1,
      justifyContent: 'center', // Add this to center the content
      width: '100%' // Add this to ensure full width
    }}>
      {getIcon()}
      <Typography sx={{ color: getColor(), fontSize: '0.875rem' }}>
        {value > 0 ? '+' : ''}{value}%
      </Typography>
    </Box>
  );
};

const DistrictAnalysisTable = ({
  schools = [],
  selectedHeadline,
  onSchoolSelect,
  isCollapsed,
  onToggleCollapse,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [expandedModalOpen, setExpandedModalOpen] = useState(false);

  // Define column groups
  const columnGroups = [
    {
      title: '',  // Empty for school name
      columns: ['name']
    },
    {
      title: '2024',
      columns: ['enrollment', 'marketShare']
    },
    {
      title: 'Historical Trends (since 2020)',
      columns: ['populationTrend', 'enrollmentTrend', 'marketShareTrend']
    },
    {
      title: 'Forward-Looking (through 2029)',
      columns: ['populationProjection', 'enrollmentProjection']
    }
  ];

  // Column definitions
  const columns = useMemo(() => [
    {
      id: 'name',
      label: 'School name',
      sortable: true,
      align: 'left',
      render: (row) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {row.charter === 'Yes' ? (
            <SchoolIcon sx={{ color: 'primary.main', fontSize: 16 }} />
          ) : (
            <LocationOnIcon sx={{ color: 'primary.main', fontSize: 16 }} />
          )}
          <Typography sx={{ fontSize: '0.875rem' }}>{row.name}</Typography>
        </Box>
      )
    },
    {
      id: 'enrollment',
      label: 'Enrollment',
      sortable: true,
      align: 'center',
      render: (row) => {
        // Use enrollment.current from API
        if (row.enrollment && typeof row.enrollment.current === 'number') {
          return <Typography sx={{ fontSize: '0.875rem' }}>
            {row.enrollment.current.toLocaleString()}
          </Typography>;
        }
        
        return <Typography>N/A</Typography>;
      }
    },
    {
      id: 'marketShare',
      label: 'Market Share',
      group: '2024',
      sortable: true,
      align: 'center',
      render: (row) => {
        // Use pre-calculated market share from API
        if (row.market_share && typeof row.market_share.current === 'number') {
          return <Typography sx={{ fontSize: '0.875rem' }}>
            {Math.round(row.market_share.current)}%
          </Typography>;
        }
        
        return <Typography>N/A</Typography>;
      }
    },
    {
      id: 'populationTrend',
      label: 'Area Student Population',
      group: 'Historical Trends (since 2020)',
      sortable: true,
      align: 'center',
      render: (row) => {
        if (row.population?.status && typeof row.population.trend === 'number') {
          const trendValue = row.population.trend;
          
          // Get trend direction from status for consistency
          let trendDirection;
          if (row.population.status === 'growing') {
            trendDirection = 'up';
          } else if (row.population.status === 'declining') {
            trendDirection = 'down';
          } else {
            trendDirection = 'neutral';
          }
          
          return <TrendIndicator 
            trend={trendDirection}
            value={Math.round(trendValue)} 
          />;
        }
        
        return <Typography>N/A</Typography>;
      }
    }
    ,
    {
      id: 'enrollmentTrend',
      label: 'Enrollment',
      group: 'Historical Trends (since 2020)',
      sortable: true,
      align: 'center',
      render: (row) => {
        // Check if it's a new or newer school
        if (row.is_newer) {
          return <Typography sx={{ 
            fontSize: '0.875rem',
            color: 'text.secondary',
            fontStyle: 'italic'
          }}>
            New School
          </Typography>;
        }
    
        // Use the enrollment status to determine trend direction for consistency
        if (row.enrollment && typeof row.enrollment.trend === 'number') {
          const trendValue = row.enrollment.trend;
          
          // Get trend direction from status for consistency with row color
          let trendDirection;
          if (row.enrollment.status === 'growing') {
            trendDirection = 'up';
          } else if (row.enrollment.status === 'declining') {
            trendDirection = 'down';
          } else {
            trendDirection = 'neutral';
          }
          
          return <TrendIndicator 
            trend={trendDirection}
            value={Math.round(trendValue)} 
          />;
        }
        
        return <Typography>N/A</Typography>;
      }
    },
    {
      id: 'marketShareTrend',
      label: 'Market Share',
      group: 'Historical Trends (since 2020)',
      sortable: true,
      align: 'center',
      render: (row) => {
        // Check if it's a new or newer school - only applicable for market share analysis
        if (row.is_newer) {
          return <Typography sx={{ 
            fontSize: '0.875rem',
            color: 'text.secondary',
            fontStyle: 'italic'
          }}>
            New School
          </Typography>;
        }
    
        // Use the market_share status to determine trend direction
        if (row.market_share && typeof row.market_share.trend === 'number') {
          const trendValue = row.market_share.trend;
          // Map market share status to trend direction
          let trendDirection;
          if (row.market_share.status === 'gaining') {
            trendDirection = 'up';
          } else if (row.market_share.status === 'losing') {
            trendDirection = 'down';
          } else {
            trendDirection = 'neutral';
          }
          
          return <TrendIndicator 
            trend={trendDirection}
            value={Math.round(trendValue)} 
          />;
        }
        
        return <Typography>N/A</Typography>;
      }
    },
    {
      id: 'populationProjection',
      label: 'Area Population',
      group: 'Forward-Looking',
      sortable: true,
      align: 'center',
      render: (row) => {
        if (row.population?.projection_status && typeof row.population.projection_trend === 'number') {
          const trendValue = row.population.projection_trend;
          
          // Get trend direction from projection_status for consistency
          let trendDirection;
          if (row.population.projection_status === 'growing') {
            trendDirection = 'up';
          } else if (row.population.projection_status === 'declining') {
            trendDirection = 'down';
          } else {
            trendDirection = 'neutral';
          }
          
          return <TrendIndicator 
            trend={trendDirection}
            value={Math.round(trendValue)} 
          />;
        }
        
        return <Typography>N/A</Typography>;
      }
    },
    {
      id: 'enrollmentProjection',
      label: 'School Enrollment',
      group: 'Forward-looking',
      sortable: true,
      align: 'center',
      render: (row) => {
        // Use the enrollment projection status to determine trend direction
        if (row.enrollment?.projection_status && typeof row.enrollment.projection_trend === 'number') {
          const trendValue = row.enrollment.projection_trend;
          
          // Get trend direction from projection_status for consistency with color
          let trendDirection;
          if (row.enrollment.projection_status === 'growing') {
            trendDirection = 'up';
          } else if (row.enrollment.projection_status === 'declining') {
            trendDirection = 'down';
          } else {
            trendDirection = 'neutral';
          }
          
          return <TrendIndicator 
            trend={trendDirection}
            value={Math.round(trendValue)} 
          />;
        }
        
        return <Typography>N/A</Typography>;
      }
    }
  ], []);

  const sortedSchools = useMemo(() => {
    if (!schools || schools.length === 0) return [];
    
    // Create a copy to avoid mutating the original array
    return [...schools].sort((a, b) => {
      let aValue, bValue;
      
      // Handle different column types appropriately
      switch (orderBy) {
        case 'name':
          aValue = a.name || '';
          bValue = b.name || '';
          // String comparison
          return order === 'asc' 
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
            
        case 'enrollment':
          aValue = a.enrollment?.current || 0;
          bValue = b.enrollment?.current || 0;
          break;
          
        case 'marketShare':
          aValue = a.market_share?.current || 0;
          bValue = b.market_share?.current || 0;
          break;
          
        case 'populationTrend':
          aValue = a.population?.trend || 0;
          bValue = b.population?.trend || 0;
          break;
          
        case 'enrollmentTrend':
          // Special handling for newer schools
          if (a.is_newer && !b.is_newer) return order === 'asc' ? 1 : 1;
          if (!a.is_newer && b.is_newer) return order === 'asc' ? -1 : -1;
          if (a.is_newer && b.is_newer) return 0;
          
          aValue = a.enrollment?.trend || 0;
          bValue = b.enrollment?.trend || 0;
          break;
          
        case 'marketShareTrend':
          // Special handling for newer schools
          if (a.is_newer && !b.is_newer) return order === 'asc' ? 1 : 1;
          if (!a.is_newer && b.is_newer) return order === 'asc' ? -1 : -1;
          if (a.is_newer && b.is_newer) return 0;
          
          aValue = a.market_share?.trend || 0;
          bValue = b.market_share?.trend || 0;
          break;
          
        case 'populationProjection':
          aValue = a.population?.projection_trend || 0;
          bValue = b.population?.projection_trend || 0;
          break;
          
        case 'enrollmentProjection':
          aValue = a.enrollment?.projection_trend || 0;
          bValue = b.enrollment?.projection_trend || 0;
          break;
          
        default:
          return 0;
      }
      
      // Handle null/undefined values
      if (aValue === null || aValue === undefined) aValue = 0;
      if (bValue === null || bValue === undefined) bValue = 0;
      
      // Default numeric comparison
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    });
  }, [schools, order, orderBy]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Collapsed state rendering
  if (isCollapsed) {
    return (
      <Paper sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TableChartIcon sx={{ color: 'primary.main' }} />
          <Typography variant="subtitle1">School Details</Typography>
        </Box>
        <Button onClick={onToggleCollapse} endIcon={<KeyboardArrowDownIcon />}>
          Expand Table
        </Button>
      </Paper>
    );
  }

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        width: '100%', 
        overflow: 'hidden',
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1
      }}
    >
      <Box sx={{ 
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TableChartIcon sx={{ color: 'primary.main' }} />
          <Typography variant="h6" sx={{ fontSize: '1rem' }}>School Details</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button 
            variant="contained"
            size="small"
            onClick={() => setExpandedModalOpen(true)}
            endIcon={<FullscreenIcon fontSize="small" />}
          >
            Expand View
          </Button>
          <Button 
            onClick={onToggleCollapse} 
            endIcon={<KeyboardArrowUpIcon />}
            size="small"
          >
            Collapse
          </Button>
        </Box>
      </Box>
      
      <TableContainer sx={{ 
          maxHeight: '400px',  // This will show approximately 5 rows
          overflow: 'auto'     // Enables both vertical and horizontal scrolling
        }}>
        <Table stickyHeader size="small">
          <TableHead>
            {/* Column Group Headers */}
            <TableRow>
              {columnGroups.map((group, index) => (
                <TableCell
                  key={group.title || index}
                  colSpan={group.columns.length}
                  align="center"
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'common.white',
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    p: 1,
                    borderBottom: 0,
                    '&:not(:last-child)': {
                      borderRight: '1px solid',
                      borderRightColor: 'primary.light',
                    },
                    ...(index === 0 && {
                      bgcolor: 'transparent',
                      borderRight: 'none'
                    })
                  }}
                >
                  {group.title}
                </TableCell>
              ))}
            </TableRow>
            {/* Column Headers with enhanced sorting */}
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                  align={column.align || 'center'}
                  sx={{
                    bgcolor: 'background.paper',
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    py: 0.5,
                    px: 1,
                    lineHeight: 1.2,
                    borderBottom: '2px solid',
                    borderBottomColor: 'primary.main',
                    '&:not(:last-child)': {
                      borderRight: '1px solid',
                      borderRightColor: 'divider',
                    },
                    ...(index === 0 && {
                      position: 'sticky',
                      left: 0,
                      bgcolor: 'background.paper',
                      zIndex: 3,
                      align: 'left'
                    })
                  }}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleRequestSort(column.id)}
                      sx={{
                        '.MuiTableSortLabel-icon': {
                          opacity: orderBy === column.id ? 1 : 0.5,
                        },
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSchools.map((row) => (
              <DistrictTableColorCodedRow
                key={row.ncessch}
                row={row}
                columns={columns}
                selectedHeadline={selectedHeadline}
                onSchoolSelect={onSchoolSelect}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DistrictAnalysisTableExpanded
      open={expandedModalOpen}
      onClose={() => setExpandedModalOpen(false)}
      schools={schools}
      selectedHeadline={selectedHeadline}
      onSchoolSelect={onSchoolSelect}
    />
    </Paper>
    
  );
};

export default DistrictAnalysisTable;