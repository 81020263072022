// mailchimpService.js
import apiClient from '../utils/apiClient';
import { handleApiError } from '../utils/apiService';

// Define API endpoints
const ENDPOINTS = {
  SEND_INVITE: '/mailchimp/send-user-invite',
  PASSWORD_RESET: '/mailchimp/custom-password-reset',
  TEST_EMAIL: '/mailchimp/test-mailchimp-email',
  CONTACT_FORM: '/mailchimp/contact',
  TRACK_LOGIN: '/mailchimp/track-login',
  VERIFY_RESET_STATE: '/auth/verify-reset-state',      
  COMPLETE_RESET: '/auth/complete-password-reset',      
  BULK_EMAILS: '/mailchimp/bulk-emails',
  PASSWORD_CREATED: '/auth/password-created',           
  GET_USER_BY_EMAIL: '/auth/get-user-by-email'         
};

const mailchimpService = {
  // User invitations
  sendUserInvite: async (uid, enqueueSnackbar) => {
    try {
      const response = await apiClient.post(`${ENDPOINTS.SEND_INVITE}/${uid}`, {}, {
        timeout: 30000
      });

      if (response.status === 429) {
        const error = new Error(response.message || 'Rate limit exceeded');
        error.status = 429;
        error.json = async () => response;
        throw error;
      }

      return response;
    } catch (error) {
      console.error('Error sending Mailchimp invitation:', error);
      if (enqueueSnackbar) {
        handleApiError(error, enqueueSnackbar);
      }
      throw error;
    }
  },

  // Password reset
  requestPasswordReset: async (email, enqueueSnackbar) => {
    try {
      // Call the API but don't store the response since we don't use it
      await apiClient.post(ENDPOINTS.PASSWORD_RESET, { email });
      
      // Always return success to avoid revealing which emails exist
      return { success: true };
    } catch (error) {
      console.error('Error requesting password reset:', error);
      if (enqueueSnackbar) {
        handleApiError(error, enqueueSnackbar);
      }
      // Still return success to avoid revealing which emails exist
      return { success: true };
    }
  },

  // Test email
  sendTestEmail: async (enqueueSnackbar) => {
    try {
      const response = await apiClient.post(ENDPOINTS.TEST_EMAIL);
      return response;
    } catch (error) {
      console.error('Error sending test email:', error);
      if (enqueueSnackbar) {
        handleApiError(error, enqueueSnackbar);
      }
      throw error;
    }
  },

  // Contact form submission
  submitContactForm: async (formData, enqueueSnackbar) => {
    try {
      const response = await apiClient.post(ENDPOINTS.CONTACT_FORM, formData);
      return response;
    } catch (error) {
      console.error('Error submitting contact form:', error);
      if (enqueueSnackbar) {
        handleApiError(error, enqueueSnackbar);
      }
      throw error;
    }
  },

  // Bulk email sending
  sendBulkEmails: async (userIds, enqueueSnackbar) => {
    try {
      const response = await apiClient.post(ENDPOINTS.BULK_EMAILS, { userIds });
      return response;
    } catch (error) {
      console.error('Error sending bulk emails:', error);
      if (enqueueSnackbar) {
        handleApiError(error, enqueueSnackbar);
      }
      throw error;
    }
  },

  // Track the password creation process
  trackPasswordCreated: async (email, enqueueSnackbar) => {
    try {
      const response = await apiClient.post(ENDPOINTS.PASSWORD_CREATED, { email });
      return response;
    } catch (error) {
      console.error('Error tracking password creation:', error);
      if (enqueueSnackbar) {
        handleApiError(error, enqueueSnackbar);
      }
      // This is internal tracking, so we can ignore errors
      return { success: true };
    }
  },

  verifyResetState: async (state, email, enqueueSnackbar) => {
    try {
      const response = await apiClient.post(ENDPOINTS.VERIFY_RESET_STATE, { 
        state, 
        email 
      });
      return response;
    } catch (error) {
      console.error('Error verifying reset state:', error);
      if (enqueueSnackbar) {
        handleApiError(error, enqueueSnackbar);
      }
      throw error;
    }
  },
  
  completePasswordReset: async (state, uid, enqueueSnackbar) => {
    try {
      const response = await apiClient.post(ENDPOINTS.COMPLETE_RESET, {
        state,
        uid
      });
      return response;
    } catch (error) {
      console.error('Error completing password reset:', error);
      if (enqueueSnackbar) {
        handleApiError(error, enqueueSnackbar);
      }
      throw error;
    }
  },
  
  getUserByEmail: async (email, enqueueSnackbar) => {
    try {
      const response = await apiClient.post('/auth/get-user-by-email', { email });
      return response;
    } catch (error) {
      console.error('Error getting user by email:', error);
      if (enqueueSnackbar) {
        handleApiError(error, enqueueSnackbar);
      }
      throw error;
    }
  },

  setPasswordDirectly: async (email, password, state) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/mailchimp/set-password-direct`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password,
          state
        }),
      });
    
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to set password');
      }
    
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error setting password:', error);
      throw error;
    }
  },

  trackLogin: async (email, enqueueSnackbar) => {
    try {
      const response = await apiClient.post(ENDPOINTS.TRACK_LOGIN, { email });
      return response;
    } catch (error) {
      console.error('Error tracking login:', error);
      if (enqueueSnackbar) {
        handleApiError(error, enqueueSnackbar);
      }
      // Don't throw - we don't want to disrupt login
      return { success: false, error: error.message };
    }
  },
};

export default mailchimpService;