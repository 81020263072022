import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { 
  ThemeProvider, 
  createTheme, 
  CssBaseline, 
  Box,
  CircularProgress,
} from '@mui/material';
import Sidebar from './components/internal_layout/Sidebar';
import ManagePreferences from './components/internal_layout/ManagePreferences';
import InsightSidebar from './components/internal_layout/InsightSidebar';
import { InsightProvider } from './contexts/InsightContext';
import Footer from './components/internal_layout/Footer';
import Home from './components/home/Home';
import DistrictAnalysis from './components/district_analysis/DistrictAnalysis';
import Login from './components/external_site/Login';
import ResetPassword from './components/external_site/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import AddressDataVisualization from './components/AddressDataVisualization';
import DriveTimePage from './components/DriveTimePage';
import SchoolSummary from './components/school_summary/SchoolSummary';
import GeographicExplorer from './components/geographic_explorer/GeographicExplorer';
import CompetitiveExplorer from './components/competitive_explorer/CompetitiveExplorer';
import DataReportsPage from './components/DataReportsPage';
import ProjectionsExplorer from './components/projections_explorer/ProjectionsExplorer';
import TermsOfService from './components/external_site/TermsOfService'; 
import PrivacyNotice from './components/external_site/PrivacyNotice';
import HomePage from './components/external_site/HomePage';
import AboutUs from './components/external_site/AboutUs';
import Contact from './components/external_site/Contact';
import ProductPage from './components/external_site/ProductPage';
import AdminDashboard from './components/admin/AdminDashboard';
import PrivacyConsentManager from './components/external_site/PrivacyConsentManager';
import Resources from './components/resources/Resources';
import VideoTutorials from './components/resources/VideoTutorials';
import MethodologyDocs from './components/resources/MethodologyDocs';
import CacheTester from './components/admin/CacheTester';
import { AuthProvider, useAuth } from './auth/AuthContext';
import useSchoolStore from './stores/schoolStore';
import { getAnalytics, setUserId, logEvent } from 'firebase/analytics';
import { SnackbarProvider } from 'notistack';
import PasswordSetup from './components/external_site/PasswordSetup';
import { SessionWarningDialog } from './auth/components/SessionWarningDialog';
import { usePrivacyConsent } from './hooks/usePrivacyConsent';
import { useVersionHandler } from './hooks/useVersionHandler';


const theme = createTheme({
  palette: {
    primary: {
      main: '#4338ca',
      light: '#6366f1',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    grey: {
      200: '#f3f4f6',
    },
  },
});

function AppContent() {
  const { user, loading } = useAuth();
  const location = useLocation();
  const { analyticsAllowed, initialized } = usePrivacyConsent();
  
  // Add this line to use the version check hook
  useVersionHandler();

  const shouldHideInsightSidebar = () => {
    const hiddenPaths = ['/dashboard', '/data-reports', '/data-reports/drive-time', '/admin', '/preferences', '/resources', '/resources/methodology', '/resources/tutorials', '/cache-test'];
    return hiddenPaths.includes(location.pathname);
  };
  
  // Simplified analytics tracking
  useEffect(() => {
    if (!user || !initialized || !analyticsAllowed) return;
    
    const analytics = getAnalytics();
    setUserId(analytics, user.uid);
    const sessionStartTime = Date.now();
    
    logEvent(analytics, 'session_start', {
      user_id: user.uid,
      email: user.email,
    });

    return () => {
      logEvent(analytics, 'session_end', {
        user_id: user.uid,
        session_duration: Date.now() - sessionStartTime
      });
    };
  }, [user, initialized, analyticsAllowed]);

  // Simplified store initialization
  useEffect(() => {
    if (!loading && user) {
      useSchoolStore.getState().fetchSchools().catch(error => {
        console.error('Error fetching schools:', error);
        useSchoolStore.getState().clearAllData();
      });
    } else if (!loading && !user) {
      useSchoolStore.getState().clearAllData();
    }
  }, [loading, user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleSidebarToggle = () => {};

  const publicPages = ['/', '/about', '/login', '/reset-password', '/terms-of-service', '/privacy-notice', '/product', '/contact', '/set-password'];
  const isPublicPage = publicPages.includes(location.pathname);
  const hideInsightSidebar = shouldHideInsightSidebar();

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          pl: isPublicPage ? 0 : '70px',
          // Only add right padding if insight sidebar should be shown
          pr: (isPublicPage || hideInsightSidebar) ? 0 : '60px',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        {/* Routes remain the same */}
        <Box sx={{ 
          flexGrow: 1,
          width: '100%',
          boxSizing: 'border-box',
          overflow: 'visible',
          position: 'relative',
        }}>
          {/* Public routes */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/set-password" element={<PasswordSetup />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-notice" element={<PrivacyNotice />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/contact" element={<Contact />} />

            {/* Protected routes */}
            <Route path="/dashboard" 
              element={<ProtectedRoute><Home user={user} /></ProtectedRoute>} 
            />

            <Route path="/district-analysis" 
              element={<ProtectedRoute><DistrictAnalysis /></ProtectedRoute>} 
            />

            <Route path="/address-visualization" 
              element={<ProtectedRoute allowedRoles={['admin']}><AddressDataVisualization /></ProtectedRoute>} 
            />

            <Route path="/school-summary" 
              element={<ProtectedRoute><SchoolSummary /></ProtectedRoute>} 
            />

            <Route path="/school-summary/:schoolId" 
              element={<ProtectedRoute><SchoolSummary /></ProtectedRoute>} 
            />

            <Route path="/projections-explorer" 
              element={
                <ProtectedRoute allowedRoles={['admin', 'updated_write', 'updated_read', 'public']}>
                  <ProjectionsExplorer />
                </ProtectedRoute>
              } 
            />

            <Route path="/geographic-explorer" 
              element={
                <ProtectedRoute allowedRoles={['admin', 'updated_write', 'updated_read', 'public']}>
                  <GeographicExplorer />
                </ProtectedRoute>
              } 
            />

            <Route path="/competitive-explorer" 
              element={
                <ProtectedRoute allowedRoles={['admin', 'updated_write', 'updated_read', 'public']}>
                  <CompetitiveExplorer />
                </ProtectedRoute>
              } 
            />

            <Route path="/data-reports" 
              element={
                <ProtectedRoute allowedRoles={['admin']}>
                  <DataReportsPage />
                </ProtectedRoute>
              } 
            />

            <Route path="/data-reports/drive-time" 
              element={
                <ProtectedRoute allowedRoles={['admin']}>
                  <DriveTimePage />
                </ProtectedRoute>
              } 
            />

            <Route path="/admin" 
              element={<ProtectedRoute allowedRoles={['admin']}><AdminDashboard /></ProtectedRoute>} 
            />

            <Route path="/cache-test" 
              element={<ProtectedRoute allowedRoles={['admin']}><CacheTester /></ProtectedRoute>} 
            /> 

            <Route path="/preferences" 
              element={<ProtectedRoute><ManagePreferences /></ProtectedRoute>} 
            />

            <Route path="/resources" 
              element={<ProtectedRoute><Resources /></ProtectedRoute>} 
            />

            <Route path="/resources/methodology"
              element={<ProtectedRoute><MethodologyDocs /></ProtectedRoute>}
            />

            <Route path="/resources/tutorials"
              element={<ProtectedRoute><VideoTutorials /></ProtectedRoute>}
            />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
        {!isPublicPage && <Footer />}
      </Box>

      {!isPublicPage && (
        <>
          {/* Navigation Sidebar - fixed position */}
          <Box sx={{ position: 'fixed', left: 0, top: 0, height: '100vh', zIndex: 1200 }}>
            <Sidebar 
              user={user}
              onToggle={handleSidebarToggle}
            />
          </Box>

          {/* Insight Sidebar - only show if not in hidden paths */}
          {!hideInsightSidebar && (
            <Box sx={{ position: 'fixed', right: 0, top: 0, height: '100vh', zIndex: 1200 }}>
              <InsightSidebar />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

function AppWithRouter() {
  const navigate = useNavigate();
  
  const handleLogoutNavigation = () => {
    navigate('/login');
  };

  return (
    <AuthProvider onLogout={handleLogoutNavigation}>
      <InsightProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            autoHideDuration={5000}
          >
            <PrivacyConsentManager />
            <SessionWarningDialog /> 
            <AppContent />
          </SnackbarProvider>
        </ThemeProvider>
      </InsightProvider>
    </AuthProvider>
  );
}

function App() {
  return (
    <Router>
      <AppWithRouter />
    </Router>
  );
}

export default App;