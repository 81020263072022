import React, { useState, useMemo } from 'react';
import { schoolCache } from '../../services/cacheService';
import { districtCache } from '../../services/districtCacheService';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { schoolsApi } from '../../utils/apiService';
import { 
  Box, 
  Button, 
  Typography, 
  Paper, 
  List, 
  ListItem, 
  CircularProgress,
  Tabs,
  Tab
} from '@mui/material';
import { useAuth } from '../../auth/AuthContext';

const CacheTester = () => {
  const [testResults, setTestResults] = useState([]);
  const [isRunning, setIsRunning] = useState(false);
  const [debugInfo, setDebugInfo] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const { user } = useAuth();

  // Get user's teams with additional metadata
  const userTeams = useMemo(() => {
    if (!user?.teams || typeof user.teams !== 'object') {
      return [];
    }
  
    return Object.entries(user.teams).map(([teamId, teamData]) => ({
      id: teamId,
      name: teamData.name || 'Unnamed Team',
      role: teamData.role || 'member',
      added: teamData.added
    }));
  }, [user]);
  
  const teamIds = useMemo(() => 
    userTeams.map(team => team.id),
    [userTeams]
  );

  const addTestResult = (result) => {
    setTestResults(prev => [...prev, result]);
  };

  const runSchoolCacheTests = async () => {
    setIsRunning(true);
    setTestResults([]);
    let schools = [];
    
    try {
      // Step 1: Initialize Cache
      addTestResult({ name: 'School Cache Initialization', status: 'running' });
      await schoolCache.initialize();
      addTestResult({ name: 'School Cache Initialization', status: 'passed' });

      // Step 2: Load Schools
      addTestResult({ name: 'Load Schools', status: 'running' });
      try {
        schools = await schoolsApi.getAllSchools();
        addTestResult({ 
          name: 'Load Schools', 
          status: 'passed',
          details: `Loaded ${schools.length} schools from API`
        });
      } catch (error) {
        console.error('Failed to load schools:', error);
        addTestResult({ 
          name: 'Load Schools', 
          status: 'failed',
          details: error.message
        });
        throw error;
      }

      // Step 3: Store in Cache
      addTestResult({ name: 'Basic Cache Storage', status: 'running' });
      try {
        await schoolCache.cacheSchoolData(schools, 'base');
        addTestResult({ 
          name: 'Basic Cache Storage', 
          status: 'passed',
          details: `Cached ${schools.length} schools`
        });
      } catch (error) {
        console.error('Failed to cache schools:', error);
        addTestResult({ 
          name: 'Basic Cache Storage', 
          status: 'failed',
          details: error.message
        });
        throw error;
      }

      // Step 4: Team-based Caching
      if (teamIds.length > 0) {
        addTestResult({ name: 'Team Cache Setup', status: 'running' });
        try {
          const db = getFirestore();
          
          for (const teamId of teamIds) {
            const team = userTeams.find(t => t.id === teamId);
            const teamDoc = await getDoc(doc(db, 'teams', teamId));
            
            if (!teamDoc.exists()) {
              console.warn(`Team ${teamId} not found in Firestore`);
              continue;
            }
      
            const teamSchools = teamDoc.data().schools || {};
            const teamSchoolIds = Object.keys(teamSchools);
            const filteredSchools = schools.filter(school => 
              teamSchoolIds.includes(school.ncessch)
            );
      
            await schoolCache.cacheSchoolData(filteredSchools, teamId);
            addTestResult({ 
              name: `Team Cache: ${team?.name || teamId}`, 
              status: 'passed',
              details: `Cached ${filteredSchools.length} schools for team`
            });
          }
        } catch (error) {
          console.error('Failed to cache team data:', error);
          addTestResult({ 
            name: 'Team Cache Setup', 
            status: 'failed',
            details: error.message
          });
          throw error;
        }
      }

      // Step 5: Test Search
      if (schools.length > 0) {
        addTestResult({ name: 'Search Test', status: 'running' });
        try {
          const testSchool = schools[0];
          const searchTerm = testSchool.name.slice(0, 3);
          const results = await schoolCache.searchSchools(searchTerm, ['base']);
          
          addTestResult({ 
            name: 'Search Test', 
            status: 'passed',
            details: `Search for "${searchTerm}" found ${results.length} results`
          });
        } catch (error) {
          console.error('Search test failed:', error);
          addTestResult({ 
            name: 'Search Test', 
            status: 'failed',
            details: error.message
          });
          throw error;
        }
      }

      setDebugInfo({
        totalSchools: schools.length,
        teamCount: teamIds.length,
        teams: userTeams.map(t => t.name),
        lastUpdated: new Date().toISOString()
      });

    } catch (error) {
      console.error('Test suite error:', error);
      addTestResult({ 
        name: 'Test Suite', 
        status: 'failed',
        details: error.message 
      });
    } finally {
      setIsRunning(false);
    }
  };

  const runDistrictCacheTests = async () => {
    setIsRunning(true);
    setTestResults([]);
    
    try {
      // Step 1: Initialize District Cache
      addTestResult({ name: 'District Cache Initialization', status: 'running' });
      await districtCache.initialize();
      addTestResult({ name: 'District Cache Initialization', status: 'passed' });

      // Step 2: Load District Data
      addTestResult({ name: 'Load District Data', status: 'running' });
      try {
        const districtData = await schoolsApi.getDistrictMetrics();
        addTestResult({ 
          name: 'Load District Data', 
          status: 'passed',
          details: `Loaded ${districtData.metrics.length} district metrics from API`
        });

        // Step 3: Cache District Data
        if (teamIds.length > 0) {
          const testTeamId = teamIds[0];
          
          addTestResult({ name: 'District Cache Storage', status: 'running' });
          await districtCache.cacheDistrictData(districtData, testTeamId);
          
          // Verify storage
          const metadata = await districtCache.getMetadata(testTeamId);
          if (metadata?.lastUpdated) {
            addTestResult({ 
              name: 'District Cache Storage', 
              status: 'passed',
              details: `Cached ${districtData.metrics.length} metrics with timestamp ${metadata.lastUpdated}`
            });
          }

          // Test retrieval
          addTestResult({ name: 'District Cache Retrieval', status: 'running' });
          const cachedMetrics = await districtCache.getTeamMetrics(testTeamId);
          addTestResult({ 
            name: 'District Cache Retrieval', 
            status: 'passed',
            details: `Retrieved ${cachedMetrics.length} metrics from cache`
          });

          setDebugInfo({
            totalMetrics: cachedMetrics.length,
            teamCount: teamIds.length,
            teams: userTeams.map(t => t.name),
            lastUpdated: metadata.lastUpdated
          });
        } else {
          addTestResult({ 
            name: 'District Cache Tests', 
            status: 'skipped',
            details: 'No teams available for testing'
          });
        }
      } catch (error) {
        console.error('District cache tests failed:', error);
        addTestResult({ 
          name: 'District Cache Tests', 
          status: 'failed',
          details: error.message
        });
      }
    } catch (error) {
      console.error('Test suite error:', error);
      addTestResult({ 
        name: 'Test Suite', 
        status: 'failed',
        details: error.message 
      });
    } finally {
      setIsRunning(false);
    }
  };

  const clearCache = async () => {
    try {
      setTestResults([{ name: 'Clear Cache', status: 'running' }]);
      if (activeTab === 0) {
        await schoolCache.clearAllCache();
        setTestResults([{ 
          name: 'Clear School Cache', 
          status: 'passed',
          details: 'School cache cleared successfully' 
        }]);
      } else {
        await districtCache.clearAllCache();
        setTestResults([{ 
          name: 'Clear District Cache', 
          status: 'passed',
          details: 'District cache cleared successfully' 
        }]);
      }
    } catch (error) {
      console.error('Clear cache error:', error);
      setTestResults([{ 
        name: 'Clear Cache', 
        status: 'failed',
        details: error.message 
      }]);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>Cache Testing Utility</Typography>
      
      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)}
        sx={{ mb: 2 }}
      >
        <Tab label="School Cache" />
        <Tab label="District Cache" />
      </Tabs>
      
      <Box sx={{ mb: 2 }}>
        <Button 
          variant="contained" 
          onClick={activeTab === 0 ? runSchoolCacheTests : runDistrictCacheTests} 
          disabled={isRunning}
          sx={{ mr: 1 }}
        >
          {isRunning ? 'Running Tests...' : 'Run Tests'}
        </Button>
        <Button 
          variant="outlined" 
          onClick={clearCache}
          disabled={isRunning}
        >
          Clear Cache
        </Button>
      </Box>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Test Results</Typography>
        <List>
          {testResults.map((result, index) => (
            <ListItem key={index} sx={{ 
              display: 'flex', 
              alignItems: 'center',
              color: result.status === 'passed' ? 'success.main' : 
                     result.status === 'failed' ? 'error.main' : 
                     result.status === 'warning' ? 'warning.main' :
                     'text.primary'
            }}>
              {result.status === 'running' ? (
                <CircularProgress size={20} sx={{ mr: 1 }} />
              ) : null}
              <Box>
                <Typography variant="body1">
                  {result.name}: {result.status.toUpperCase()}
                </Typography>
                {result.details && (
                  <Typography variant="caption" display="block">
                    {result.details}
                  </Typography>
                )}
              </Box>
            </ListItem>
          ))}
        </List>
      </Paper>

      {debugInfo && (
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>Cache Status</Typography>
          <List>
            <ListItem>
              <Typography variant="body2">
                {activeTab === 0 ? 'Cached Schools' : 'Cached Metrics'}: {
                  activeTab === 0 ? debugInfo.totalSchools : debugInfo.totalMetrics
                }
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                Teams ({debugInfo.teamCount}): {debugInfo.teams.join(', ') || 'None'}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                Last Updated: {debugInfo.lastUpdated}
              </Typography>
            </ListItem>
          </List>
        </Paper>
      )}
    </Box>
  );
};

export default CacheTester;